import React from 'react'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import '../css/nosotros.css'
import bgNosotros from '../assets/images/nosotros.png'
import Footer from '../components/Footer'

import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'
import Slider from '../components/sliders/SlideNosotros'
import Timeline from '../components/TimeLine'


const logos = [
    './images/esr.png',
    './images/imss.png',
    './images/lrqa.png',
    './images/prime.png',
    './images/distintivo-h.png',
    './images/safe-travel.png',
    './images/esr-inclusion.png',
    './images/punto-limpio.png',
    // Añade más logos aquí...
];

  
export default function Nosotros() {

    //Slide 
    const slides = [
        <img src="../images/nosotros/nosotros-HEADER01.webp" alt="Slide 1" />,
        <img src="../images/nosotros/nosotros-HEADER02.webp" alt="Slide 2" />,
        <img src="../images/nosotros/nosotros-HEADER04.webp" alt="Slide 4" />,
        <img src="../images/nosotros/nosotros-HEADER05.webp" alt="Slide 5" />,
        <img src="../images/nosotros/nosotros-HEADER07.webp" alt="Slide 7" />,
        <img src="../images/nosotros/nosotros-HEADER08.webp" alt="Slide 8" />,
        <img src="../images/nosotros/nosotros-HEADER09.webp" alt="Slide 9" />,
        <img src="../images/nosotros/nosotros-HEADER10.webp" alt="Slide 10" />,
        <img src="../images/nosotros/nosotros-HEADER11.webp" alt="Slide 11" />,        
        <img src="../images/nosotros/nosotros-HEADER13.webp" alt="Slide 13" />,
        <img src="../images/nosotros/nosotros-HEADER14.webp" alt="Slide 14" />,
        // Agrega más slides según sea necesario
      ];
    
  return (
    <>

        <section style={{paddingBottom:'6rem'}}>
            <div className='slider_nosotros'>            
                <Slider slides={slides} />
                <div className="header_text_nosotros">
                    <h2>Acerca de nosotros </h2>
                    <p>Somos el  grupo logístico-naviero de bandera mexicana líder en el país. Conectamos pasajeros desde y hacia Baja California Sur y ofrecemos soluciones logísticas integrales 360  impulsando el desarrollo del cabotaje en México. </p> 
                </div>
            </div>
        </section>

        <section>
            <div className='container'>
                <div className='mision_vision_nosotros'>
                    <div className='box_mision_vision_nosotros'>
                        <div className='box_icon_mision_vision_nosotros'>
                            <div className='icon_mision_vision_nosotros'><i class="fi fi-bs-flag"></i></div>
                        </div>
                        <h3>Misión</h3>
                        <p>Desarrollando mar y tierra</p>
                    </div>
                    <div className='box_mision_vision_nosotros'>
                        <div className='box_icon_mision_vision_nosotros'>
                            <div className='icon_mision_vision_nosotros'><i class="fi fi-br-binoculars"></i></div>
                        </div>
                        <h3>Visión</h3>
                        <p>Ser un consorcio mexicano de empresas innovadoras que anclado en su experiencia y valores desarrolle un portafolio de nuevos negocios</p>
                    </div>
                    <div className='box_mision_vision_nosotros'>
                        <div className='box_icon_mision_vision_nosotros'>
                            <div className='icon_mision_vision_nosotros'><i class="fi fi-ss-bullseye-arrow"></i></div>
                        </div>
                        <h3>Nuestro propósito</h3>
                        <p>Navegando con rumbo para mejorar la vida de nuestras comunidades.</p>
                    </div>
                    
                </div>
            </div>
        </section>
    
  
        <section className='valores_nosotros'>
            <div className='container'>
            <div className='title_nosotros'>
                    <h2>Nuestros Valores</h2>
                </div>
                <div className='valores_bf'>
                    <div className='box_valores_bf'>
                        <h3>Humildad y respeto</h3>
                        <ul>
                            <li>Aprendemos, evolucionamos y mejoramos cada día.</li>
                            <li>Nos comunicamos todo, lo bueno y lo malo. Dialogamos abiertamente, pero soportamos siempre una sola decisión.</li>
                            <li>Escuchamos antes de tomar decisiones.</li>
                            <li>Tenemos una actitud de constante aprendizaje.</li>
                            <li>La diversidad nos hace más competitivos, respetamos las individualidades, valorando a cada quien por sus talentos.</li>
                        </ul>
                    </div>
                    <div className='box_valores_bf'>
                        <h3>Pasión por el servicio y por nuestros clientes </h3>
                        <ul>
                            <li>Servimos a nuestros clientes con pasión.</li>
                            <li>Ofrecemos a nuestros clientes la mejor experiencia posible.</li>
                            <li>Nos importa su opinión, su permanencia y sus resultados.</li>                            
                        </ul>
                    </div>
                </div>
                <div className='valores_bf'>
                    <div className='box_valores_bf'>
                    <h3>Responsabilidad e integridad </h3>
                        <ul>
                            <li>Hacemos lo que decimos y nos comprometemos con ello.</li>
                            <li>Si identificamos un problema, lo resolvemos.</li>
                            <li>Atendemos el hoy y nos preparamos para el mañana.</li>
                            <li>Pensamos en el futuro, planeamos y ejecutamos.</li>
                            <li>Somos responsables con el medio ambiente y con nuestro entorno social.</li>
                        </ul>
                    </div>
                    <div className='box_valores_bf'>
                        <h3>Compromiso con nuestro nombre</h3>
                        <ul>
                            <li>Hacemos las cosas cada día mejor, hacemos la diferencia y fortalecemos nuestra compañía.</li>
                            <li>Baja Ferries es sinónimo de confianza, esfuerzo, pasión, orgullo en todo lo que hacemos y en cómo lo hacemos.</li>
                        </ul>

                    </div>
                </div>

            </div>
        </section>


        <section>
            <div className='numeralia'>
                <div className='container'>
                    <div className='numeralia_content'>
                        <div className='numeralia_row'>
                            <div className='numeralia_box'>
                                <label>Realizamos</label>
                                <p>986 VIAJES</p>
                                <label>En el mar de Cortés</label>
                            </div>
                            <div className='numeralia_box'>
                                <label></label>
                                <p>186 MIL<br /> MILLAS</p>
                                <label>Navegadas</label>
                            </div>
                            <div className='numeralia_box'>
                                <label>Conectamos a </label>
                                <p>230 MIL <br />PASAJEROS </p>
                                <label>Con su destino</label>
                            </div>
                        </div>
                        <div className='numeralia_row'>
                            <div className='numeralia_box'>
                                <label>Transportamos</label>
                                <p>2.5 MILLONES <br /> DE TONELADAS</p>
                                <label>De mercancías</label>
                            </div>
                            <div className='numeralia_box'>
                                <label></label>
                                <p>32 ROTACIONES<br />EN EL </p>
                                <label>Pacífico Mexicano</label>
                            </div>
                            <div className='numeralia_box'> 
                            <label></label>                               
                                <p>4 <br /> BUQUES</p>                                
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
            
        <section>
           <div className='linea_tiempo'>
            <div className='container'>
                <Timeline />
            </div>
           </div>
        </section>
        <section>
            <div className='codigo_etica'>
                <div className='container'>
                    <div className='codigo_etica_box'>
                        <h2> <strong>Ser ético</strong>  es mi elección</h2>
                        <p>Nuestro compromiso con la transparencia, integridad y respeto es la base nuestras operaciones. </p>

                        <div class="box_content-btn"><a className="navbar-link" href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/" target="_blank" rel="noopener noreferrer">Consultar código de ética</a></div>
                    </div>
                </div>
            </div>
           
        </section>
        <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Consulta nuestras tarifas</h4>
                                </div>
                                <div>
                                    <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/tarifas">Ver tarifas</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Preguntas frecuentes</h4>
                                </div>
                                <div>
                                    <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envíanos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación, nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>
        {/*
        <section className='container'>
            <LogoCarousel logos={logos} />
        </section>
  */}
        <Footer />   
         <Outlet />
    </>
  )
}
