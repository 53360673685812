import React, {useState} from 'react'
import axios from 'axios';
import '../css/header_pages.css'
import '../css/contacto.css'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'
import Maps from '../components/frames/MapsContacto'
import facebook from '../assets/images/spotify/facebook.svg'
import instagram from '../assets/images/spotify/instagram.svg'
import twitter from '../assets/images/spotify/twitter.svg'
import spotify_logo from '../assets/images/spotify/spotify.svg'
import youtube from '../assets/images/spotify/youtube.svg'
import ContactoZoho from '../components/frames/ContactoZoho';
import Footer from '../components/Footer'

const TabContacto = ({ label, onClick, activo }) => {
    return (
    <div className={`tabContacto ${activo ? 'activo' : ''}`} onClick={onClick}>
        {label}
        
    </div>
    );
};

export default function Contacto() {
    const [nombre, setNombre] = useState('');
	const [empresa, setEmpresa] = useState('');
	const [telefono, setTelefono] = useState('');
    const [servicio, setServicio] = useState('');
	const [email, setEmail] = useState('');
	const [mensaje, setMensaje] = useState('');
	const [aceptoPoliticas, setAceptoPoliticas] = useState(false);

	const [alert, setAlert] = useState(false);

	const enviarCorreo = async () => {
        try {
            //await axios.post('http://localhost:3001/enviar-correo', {
                await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', {
                nombre,
                empresa,
                telefono,
				servicio,
                email,
                mensaje,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
    
            // Limpiar campos después de enviar el correo
            setNombre('');
            setEmpresa('');
            setTelefono('');
			setServicio('');
            setEmail('');
            setMensaje('');
    
            setAlert(true);
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };

    const [activoTab, setactivoTab] = useState(0);

    const handleTabClick = (index) => {
        setactivoTab(index);
    };
    const nombresTabs = [
        "Corporativo La Paz",
        "Pichilingue",
        "Cabo San Lucas",
        "Topolobampo",
        "Mazatlán",
        "Los Mochis",
    ];


    const url_corpo = "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d821.5779420470524!2d-110.31071482204835!3d24.14888829756332!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86afd343b139ddd5%3A0x326eaf70aee954b3!2sBaja%20Ferries!5e0!3m2!1ses!2smx!4v1700246510732!5m2!1ses!2smx";
    const url_pichi = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3636.9794795752364!2d-110.33031128768734!3d24.277447268245783!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86afcd63f890f631%3A0x56a3c4ff0453303c!2sBaja%20Ferries%20Pichilingue!5e0!3m2!1ses!2smx!4v1700263523325!5m2!1ses!2smx&z=7";
    const url_cabo = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3675.4067798226693!2d-109.93126150321045!3d22.898362400000003!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86af4b7a5845e941%3A0xcfce73276d0b6994!2sBaja%20Ferries!5e0!3m2!1ses!2smx!4v1700263797354!5m2!1ses!2smx";
    const url_topolobampo = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1862661.635792728!2d-111.01570380547236!3d24.241393774975638!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86ba396dbd80e3fb%3A0xac786c856b93aee1!2sTopolobampo!5e0!3m2!1ses!2smx!4v1700263894487!5m2!1ses!2smx";
    const url_mazatlan = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d331372.07754552935!2d-107.18009267324395!3d23.417915667365566!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f5393e4afb043%3A0xe71d0ebd60c94d1f!2sBaja%20Ferries!5e0!3m2!1ses!2smx!4v1700263965490!5m2!1ses!2smx";
    const url_mochis = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1874562.6189287559!2d-108.2693593404462!3d23.415149783997617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x869f5393e4afb043%3A0xe71d0ebd60c94d1f!2sBaja%20Ferries!5e0!3m2!1ses!2smx!4v1700264007767!5m2!1ses!2smx";


  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Contáctanos</h3>
                        <p>Contáctenos y le responderemos a la brevedad posible</p>
                    </div>                
                </div>
            </div>
        </section>

        <section className='contacto'>
            <div className='container'>
                <div className='d-none d-sm-none d-md-block'>
                    <div className='box_content_contacto'>
                        <div className='tabs_contacto'>
                           
                            <div className="tab-container-contacto">
                                <TabContacto
                                label="Corporativo La Paz"
                                onClick={() => handleTabClick(0)}
                                activo={activoTab === 0}
                                />
                                <TabContacto
                                label="Pichilingue"
                                onClick={() => handleTabClick(1)}
                                activo={activoTab === 1}
                                />
                                 <TabContacto
                                label="Cabo San Lucas"
                                onClick={() => handleTabClick(2)}
                                activo={activoTab === 2}                                
                                />

                                 <TabContacto
                                label="Topolobampo"
                                onClick={() => handleTabClick(3)}
                                activo={activoTab === 3}
                                
                                />
                                 <TabContacto
                                label="Mazatlán"
                                onClick={() => handleTabClick(4)}
                                activo={activoTab === 4}
                                
                                />
                                 <TabContacto
                                label="Los Mochis"
                                onClick={() => handleTabClick(5)}
                                activo={activoTab === 5}
                                
                                />
                            </div>
                            <div className="content-container-contacto">
                                {activoTab === 0 && 
                                <div className='info_contacto'>                                    
                                   
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                                <h2>Baja California Sur</h2>
                                                <h3>Corporativo La Paz, B.C.S.</h3>
                                            </div>

                                            <p>Ignacio Allende No. 1025</p>
                                            <p>Col. Centro, C.P. 23000</p>
                                            <p>La Paz, Baja California Sur, México</p>

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                <p><i class="fi fi-sr-phone-office"></i> +52 (612) 123 6600</p>
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                            
                                        </div> 
                                        <div className='content_contacto_map'>
                                            <Maps locationUrl={url_corpo}/>
                                        </div> 
                                    </div>                                                                                                       
                                </div>}
                                {activoTab === 1 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Baja California Sur</h2>
                                              <h3>Pichilingue, La Paz, B.C.S.</h3>
                                            </div>
                                            

                                            <p>Interior Muelle de Altura API,</p>
                                            <p>Lt. 1, Mzn. F, C.P. 23208</p>
                                            <p>La Paz, Baja California Sur, México</p>

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_pichi}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>}    
                                {activoTab === 2 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Baja California Sur</h2>
                                              <h3>Cabo San Lucas, B.C.S.</h3>
                                            </div>
                                            

                                            <p>Plaza Golden local 3</p>
                                            <p>Blvd. Miguel Hidalgo e/Blvd Constituyentes y</p>
                                            <p>Calle Colonos Legendarios</p>
                                            <p>Cabo San Lucas, Baja California Sur, México.</p>

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_cabo}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>} 
                                {activoTab === 3 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Sinaloa</h2>
                                              <h3>Topolobampo, Sinaloa</h3>
                                            </div>
                                            
                                            <p>Muelle de Transbordadores S/N</p>
                                            <p>Interior Instalaciones API, Col. Cerro de la Gallina</p>
                                            <p>C.P. 81370, Topolobampo, Sinaloa. México</p>
                                            
                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> contactcenter@bajaferries.com.mx</p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_topolobampo}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>} 
                                {activoTab === 4 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Sinaloa</h2>
                                              <h3>Mazatlán, Sinaloa.</h3>
                                            </div>                                                                            

                                            <p>Ave. Emilio Barragán y Prol. Carnaval S/N</p>
                                            <p>Fracc. Playa Sur, C.P. 82040, Instalaciones API</p>
                                            <p>Mazatlán, Sinaloa, México.</p>                                            

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_mazatlan}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>} 
                                {activoTab === 5 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Sinaloa.</h2>
                                              <h3>Los Mochis, Sinaloa.</h3>
                                            </div>  

                                            <p>Plaza Punto Los Mochis, Locales S63, S64 y S65</p>
                                            <p>Blvd. A. Rosales 985 Sur esq. Blvd. Centenario</p>
                                            <p>Col. Centro. C.P. 81200.</p>
                                            <p>Los Mochis, Sinaloa. México.</p>                                            

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_mochis}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>}                                        
                            </div>
                        </div>

                    </div>

                </div>
                <div className='d-block d-sm-block d-md-none'>
                    <div className='box_content_contacto'>
                        <div className='tabs_contacto'>
                        <div class="dropdown">
                                <button className="dropbtn">
                                    {nombresTabs[activoTab]} <i class="fi fi-br-caret-down"></i>
                                </button>
                                <div className="dropdown-content">
                                    <div className='dropdown_options'>
                                        {nombresTabs.map((nombre, index) => (
                                            <label
                                                key={index}
                                                onClick={() => handleTabClick(index)}
                                                className={activoTab === index ? 'activo' : ''}>
                                                {nombre}
                                            </label>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            
                            <div className="content-container-contacto">
                                {activoTab === 0 && 
                                <div className='info_contacto'>                                    
                                   
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                                <h2>Baja California Sur</h2>
                                                <h3>Corporativo La Paz, B.C.S.</h3>
                                            </div>

                                            <p>Ignacio Allende No. 1025</p>
                                            <p>Col. Centro, C.P. 23000</p>
                                            <p>La Paz, Baja California Sur, México</p>

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                <p><i class="fi fi-sr-phone-office"></i> +52 (612) 123 6600</p>
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                            
                                        </div> 
                                        <div className='content_contacto_map'>
                                            <Maps locationUrl={url_corpo}/>
                                        </div> 
                                    </div>                                                                                                       
                                </div>}
                                {activoTab === 1 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Baja California Sur</h2>
                                              <h3>Pichilingue, La Paz, B.C.S.</h3>
                                            </div>
                                            

                                            <p>Interior Muelle de Altura API,</p>
                                            <p>Lt. 1, Mzn. F, C.P. 23208</p>
                                            <p>La Paz, Baja California Sur, México</p>

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_pichi}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>}    
                                {activoTab === 2 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Baja California Sur</h2>
                                              <h3>Cabo San Lucas, B.C.S.</h3>
                                            </div>                                          

                                            <p>Plaza Golden local 3</p>
                                            <p>Blvd. Miguel Hidalgo e/Blvd Constituyentes y</p>
                                            <p>Calle Colonos Legendarios</p>
                                            <p>Cabo San Lucas, Baja California Sur, México.</p>

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_cabo}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>} 
                                {activoTab === 3 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Sinaloa</h2>
                                              <h3>Topolobampo, Sinaloa</h3>
                                            </div>
                                            
                                            <p>Muelle de Transbordadores S/N</p>
                                            <p>Interior Instalaciones API, Col. Cerro de la Gallina</p>
                                            <p>C.P. 81370, Topolobampo, Sinaloa. México</p>
                                            
                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_topolobampo}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>} 
                                {activoTab === 4 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Sinaloa</h2>
                                              <h3>Mazatlán, Sinaloa.</h3>
                                            </div>                                                                            

                                            <p>Ave. Emilio Barragán y Prol. Carnaval S/N</p>
                                            <p>Fracc. Playa Sur, C.P. 82040, Instalaciones API</p>
                                            <p>Mazatlán, Sinaloa, México.</p>                                            

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_mazatlan}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>} 
                                {activoTab === 5 && 
                                <div className='info_contacto'>                                                                       
                                    <div className='content_contacto_info'>

                                        <div className='info_contacto_text'>
                                            <div className='info_contacto_title'>
                                              <h2>Sinaloa.</h2>
                                              <h3>Los Mochis, Sinaloa.</h3>
                                            </div>  

                                            <p>Plaza Punto Los Mochis, Locales S63, S64 y S65</p>
                                            <p>Blvd. A. Rosales 985 Sur esq. Blvd. Centenario</p>
                                            <p>Col. Centro. C.P. 81200.</p>
                                            <p>Los Mochis, Sinaloa. México.</p>                                            

                                            <div className='info_contacto_icon'>
                                                <p><i class="fi fi-sr-headset"></i> 800 337 7437</p>
                                                
                                                <p><i class="fi fi-sr-envelope"></i> <a href="mailto:contactcenter@bajaferries.com.mx">contactcenter@bajaferries.com.mx</a> </p>
                                            </div>
                                          
                                      </div> 
                                      <div className='content_contacto_map'>
                                          <Maps locationUrl={url_mochis}/>
                                      </div> 
                                    </div>                                                                                                       
                                </div>}                                        
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </section>
        <section>
           <div className='container'>
            <div className='contact_form_contacto'>
                    <div className='contact_form_contacto_box'>
                        <div className='box_form_contact'>
                            <h2>¡Síguenos en redes!</h2>
                            <p>¿Quieres saber más sobre nosotros? o ¿Te interesa uno de nuestros servicios? Déjanos tus comentarios.</p>
                            <ul className='redes_list_contacto'>
                                <li>
                                    <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                                </li>                                
                                <li>
                                    <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                                </li>
                                <li>                                        
                                    <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/@bajaferries2914" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </li>
                                <li>
                                    <a href="https://open.spotify.com/embed/playlist/1k0o8USJkBoebsREqNY1mn" rel='noopener noreferrer' target="_blank"><img src={spotify_logo} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </li>
                            </ul>
                            
                        </div>
                    </div>
                    <div  className='contact_form_contacto_box'>
                        
                        <div class="formulario">
                            {/*<div>
                                <h2>Envíanos tus comentarios</h2>
                                <p>Completa el formulario de contacto a continuación y cuéntanos lo que necesitas. Estaremos encantados de proporcionarte asistencia personalizada o brindarte más detalles sobre nuestros servicios.</p>
                            </div>
                            {alert &&
                                <div className='alertMessage'>
                                    <label>¡Tu formulario ha sido enviado con éxito!</label>
                                    <p>Nos pondremos en contacto contigo pronto.</p>
                                </div>
                            }

                                <div class="form-group">
                                    <input
                                        type="text"
                                        placeholder="Tu nombre"
                                        value={nombre}
                                        onChange={(e) => setNombre(e.target.value)}
                                    />
                                    <input
                                        type="text"
                                        placeholder="Nombre de tu empresa (opcional)"
                                        value={empresa}
                                        onChange={(e) => setEmpresa(e.target.value)}
                                    />
                                    
                                </div>
                                <div class="form-group">
                                    <input type='text'
                                        value={telefono}
                                        onChange={(e) => setTelefono(e.target.value)} 
                                        placeholder="Teléfono" />

                                    <select value={servicio}
                                        onChange={(e) => setServicio(e.target.value)}>
                                        <option>¿Qué servicio te interesa?</option>
                                        <option>Tarifas</option>
                                        <option>Horarios</option>
                                        <option>Otro</option>
                                                     							
                                    </select>
                                </div>
                                <div>
                                    <input type="email"
                                        placeholder="Email"
                                        value={email}
                                        onChange={(e) => setEmail(e.target.value)}
                                        />
                                </div>
                                <div>
                                    <textarea 
                                        placeholder="¿Cómo podemos ayudarte?"
                                        value={mensaje}
                                        onChange={(e) => setMensaje(e.target.value)}
                                        >
                                    </textarea>
                                </div>
                                <div className='chackbox_text'>
                                    <input type="checkbox" name="" checked={aceptoPoliticas} onChange={() => setAceptoPoliticas(!aceptoPoliticas)} /> Acepto el aviso legal y las política de privacidad. 
                                </div>
                                <div class="btn_enviar_formulario_contact">
                                    
                                    <button
                                        class="btn_enviar"
                                        disabled={!aceptoPoliticas}
                                        onClick={enviarCorreo}
                                        >
                                        Enviar
                                    </button>

                                </div>
                                */}
                                <ContactoZoho />
                        </div>
                    </div>
                    
                </div>
            </div>                         
        </section>
            
        <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Consulta nuestras tarifas</h4>
                                </div>
                                <div>
                                    <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/tarifas">Ver tarifas</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Preguntas frecuentes</h4>
                                </div>
                                <div>
                                    <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envianos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <Outlet />

        <Footer />
        

        
    </>
  )
}
