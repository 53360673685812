import React, {useState} from 'react'
import '../../css/booking.css'
import ReservaMobile from './ReservaMobile';

import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/locale/es_ES';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import sinVehiculo from '../../assets/images/iconReserva/camion-recogida_nocar.svg'
import motocicleta from '../../assets/images/iconReserva/motocicleta.svg'
import vehiculo from '../../assets/images/iconReserva/camion-recogida-2.svg'
import motorhome from '../../assets/images/iconReserva/casa-rodante.svg'
dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

// eslint-disable-next-line arrow-body-style
const disabledDate = (current) => {
  // Can not select days before today and today
  return current && current < dayjs().startOf('day');
};

export default function Booking() {
    
    const [boxPasajero, setBoxPasajero] = useState(false);
	const [boxAuto, setBoxAuto] = useState(false);
	const [adultoCount, setAdultoCount] = useState(1);
	const [infanteCount, setInfanteCount] = useState(0);
	const [menorCount, setMenorCount] = useState(0);
	const [mayorCount, setMayorCount] = useState(0);
	const [isChecked, setIsChecked] = useState(false);
	const [selectedVehiculo, setSelectedVehiculo] = useState('');
	const [cantVehiculo, setCantVehiculo] = useState(0);

	const [selectedTravelType, setSelectedTravelType] = useState('RETURN'); // Nuevo estado
    const [formData, setFormData] = useState({
		ruta: 'P~PIC-P~TPB',		
		travelType: 'RETURN', // Establece el valor predeterminado a "RETURN"
		fechaIda: '',
		fechaVuelta: '',
	});

     const [inputClass, setInputClass] = useState('box_booking initial');

     const handleInputCupon= () => {
        setInputClass('box_booking typing'); // Cambia la clase cuando el usuario empieza a escribir
      };

    const handleChangeReservation = e => {		
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'travelType') {
            setSelectedTravelType(value);
        }

        //console.log(e.target.name, e.target.value);
        setFormData({...formData,[name]: value});
    };
    const handleBoxAuto = () => {
		setBoxAuto(!boxAuto);
	}

	const handleBoxPasajero = () => {
		setBoxPasajero(!boxPasajero);
	}
    
  	
	// Manejadores para sumar
	const handleAdultoCount = () => {
		setAdultoCount(adultoCount + 1);		
	};
	const handleInfanteCount = () => {
		setInfanteCount(infanteCount + 1);		
	};
	const handleMenorCount = () => {
		setMenorCount(menorCount + 1);		
	};
	const handleMayorCount = () => {
		setMayorCount(mayorCount + 1);		
	};

	// Manejadores para restar
    const handleAdultoCountDecrement = () => {
        setAdultoCount(adultoCount > 0 ? adultoCount - 1 : 0);
    };

    const handleInfanteCountDecrement = () => {
        setInfanteCount(infanteCount > 0 ? infanteCount - 1 : 0);
    };

    const handleMenorCountDecrement = () => {
        setMenorCount(menorCount > 0 ? menorCount - 1 : 0);
    };

    const handleMayorCountDecrement = () => {
        setMayorCount(mayorCount > 0 ? mayorCount - 1 : 0);
    };

    // Vehiculo seleccionado 
	const handleOptionVehiculo = (option) =>{
		setSelectedVehiculo(option);

		if(option === ''){
			setCantVehiculo(0);
		}else{
			setCantVehiculo(1);
		}
		
		console.log('Opción seleccionada:', selectedVehiculo);
		
	}

	// Suma de los clics de ambos botones
	const totalClicks = adultoCount + infanteCount + menorCount + mayorCount;


    //FormData
    const reservation = (e) => {
        e.preventDefault();

        const parts = formData.ruta.split('-');
        const rutaRegreso = `${parts[1]}-${parts[0]}`;
        //console.log(`${parts[1]}-${parts[0]}`); 

        //console.log('Booking', formData);       
        
        //console.log(formData);
        if (selectedTravelType === 'RETURN') {
            // Acciones específicas para el tipo de viaje de "RETURN"
            window.open(
                `https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&	
				cw_journeysearch_j2_passengers[0][ctg]=ADL&
				cw_journeysearch_j2_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j2_passengers[1][ctg]=CHD&
				cw_journeysearch_j2_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j2_passengers[2][ctg]=INF&
				cw_journeysearch_j2_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j2_passengers[3][ctg]=RET&
				cw_journeysearch_j2_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j2_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j2_vehicles[0][qty]=1&	
				bookingFlow=RETURN&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&
				cw_journeysearch_j1_route=${formData.ruta}&
				cw_journeysearch_j2_date=${formData.fechaVuelta}T00:00:00&
				cw_journeysearch_j2_route=${rutaRegreso}
				`	
            );

        } else if (selectedTravelType === 'ONEWAY') {
            // Acciones específicas para el tipo de viaje de "ONEWAY"
            window.open(
                `https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&				
				bookingFlow=ONEWAY&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.ruta}`				
            );
        }
    };

    const handleDateChange = (date, dateString, type) => {
		console.log('Fecha seleccionada: ', dateString);
		setFormData({
		  ...formData,
		  [type]: dateString,
		});
	};
	
	const handleDateChangeReturn = (dates, dateStrings) => {
		const [fechaIda, fechaVuelta] = dateStrings;
		console.log('Fechas seleccionadas:', dateStrings);
		setFormData({
		  ...formData,
		  fechaIda,
		  fechaVuelta,
		});
	};	
    

  
  return (
    <>
    
    <section className='d-none d-sm-none d-md-block'>
        <div  className='booking_fijo'>
            <div className='container'>
                <form className='formBooking_fijo' onSubmit={reservation}>                 
                    <div className='radioBooking_fijo'>
                        <div className='radioButons'>
                            <input type="radio" name="travelType" value="RETURN"  className='radio'  checked={selectedTravelType === 'RETURN'} onChange={handleChangeReservation} />
                            <label>Ida y vuelta</label>
                        </div>
                        <div><label>|</label></div>
                        <div className='radioButons'>
                            <input type="radio" name="travelType" value="ONEWAY" className='radio' onChange={handleChangeReservation}/>
                            <label>Solo Ida</label>
                        </div>
                    </div>	
                    {selectedTravelType === 'RETURN' &&(
                        <div className='content_booking'>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-ship"></i></div>
                                </div>
                                <div className='item_booking'>
                                    <label>Ruta</label>
                                    <select name='ruta' value={formData.ruta} onChange={handleChangeReservation}>
                                        <option value={'P~PIC-P~TPB'}>La Paz - Topolobampo</option>
                                        <option value={'P~TPB-P~PIC'}>Topolobampo - La Paz</option>
                                        <option value={'P~PIC-P~MZT'}>La Paz - Mazatlán</option>
                                        <option value={'P~MZT-P~PIC'}>Mazatlán - La Paz</option>
                                    </select>
                                </div>
                            </div>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-check-in-calendar"></i></div>
                                </div>
                                <div className='item_booking'>
                                    <label>Fecha</label>
                                    <ConfigProvider locale={es_ES}>  
                                        <Space direction="vertical" size={12} className="custom-datepicker">
                                            <RangePicker disabledDate={disabledDate}  onChange={handleDateChangeReturn}/>						
                                        </Space>					
                                    </ConfigProvider>
                                </div>
                            </div>
                                           
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-user"></i></div>
                                </div>
                                <div className='item_booking' >
                                    <div style={{display:'grid'}} onClick={handleBoxPasajero}>
                                        <label>Personas</label>
                                        <label>{totalClicks} <i class="fi fi-rr-angle-small-down"></i> </label>
                                    </div>
                                    {boxPasajero && (
                                    <div className='modalPasajero booking'>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Adulto:</label>
                                                <label>De 12 a 99</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {adultoCount} </label>
                                                <div onClick={handleAdultoCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleAdultoCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>														
                                        </div>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Infante:</label>
                                                <label>De 0 a 2 años de edad</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {infanteCount} </label>
                                                <div onClick={handleInfanteCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleInfanteCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>
                                        </div>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Menor:</label>
                                                <label>De 3 a 11 años</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {menorCount} </label>
                                                <div onClick={handleMenorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleMenorCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>														
                                        </div>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Adulto mayor:</label>
                                                <label>A partir de 60 años</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {mayorCount} </label>
                                                <div onClick={handleMayorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleMayorCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>
                                        </div>
                                        <button onClick={handleBoxPasajero}>Aceptar</button>
                                    </div>
                                    )}
                                </div>                            
                            </div>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-bs-car"></i></div>
                                </div>
                                <div className='item_booking' >
                                    <div style={{display:'grid'}} onClick={handleBoxAuto}>
                                        <label>Vehículo</label>
                                        <div>
                                            <label>{cantVehiculo} </label>
                                            <i class="fi fi-rr-angle-small-down"></i>
                                        </div>
                                        
                                    </div>                                   		
                                  	
                                    {boxAuto && (						
                                    <div className='modalAuto booking'>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('')}>
                                            <div><img src={sinVehiculo} alt='' /> </div>
                                            <label>Sin vehículo</label>
                                        </div>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('MC')}>								
                                            <div><img src={motocicleta} alt='' /></div>
                                            <label>Motocicleta</label>																								
                                        </div>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('CAR')}>								
                                            <div><img src={vehiculo} alt='' /></div>
                                            <label>Automóvil/Pick Up</label>								
                                        </div>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('MHO')}>								
                                            <div><img src={motorhome} alt='' /></div>
                                            <label>Motorhome/RV</label>														
                                        </div>
                                        <button onClick={handleBoxAuto}>Aceptar</button>
                                    </div>
                                    )}																			
                                    
                                </div>                            
                            </div>
                            <div className={inputClass} style={{width: '130px'}}>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-ticket"></i></div>
                                </div>
                                <div className='item_booking'>
                                    <label>Insertar código</label>
                                    <input type='text'  onChange={handleInputCupon}/>
                                </div>
                                                                
                            </div>
                            <div className='box_booking'>
                                <button>Reservar</button>
                            </div>                      
                        </div>
                    )}
                    {selectedTravelType === 'ONEWAY' &&(
                        <div className='content_booking'>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-ship"></i></div>
                                </div>
                                <div className='item_booking'>
                                    <label>Ruta</label>
                                    <select name='ruta' value={formData.ruta} onChange={handleChangeReservation}>
                                        <option value={'P~PIC-P~TPB'}>La Paz - Topolobampo</option>
                                        <option value={'P~TPB-P~PIC'}>Topolobampo - La Paz</option>
                                        <option value={'P~PIC-P~MZT'}>La Paz - Mazatlán</option>
                                        <option value={'P~MZT-P~PIC'}>Mazatlán - La Paz</option>
                                    </select>
                                </div>
                            </div>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-ship"></i></div>
                                </div>
                                <div className='item_booking'>
                                    <label>Salida</label>
                                    {/*<input type='date'  name="fechaIda" min={getTodayDate()} onChange={handleChangeReservation}/>*/}
                                    <ConfigProvider locale={es_ES}>  
                                        <Space direction="vertical" size={24} className="custom-datepicker">
                                            <DatePicker disabledDate={disabledDate} name="fechaIda"  onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')}/>						
                                        </Space>					
                                    </ConfigProvider>
                                </div>                                
                            </div>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-user"></i></div>
                                </div>
                                <div className='item_booking' style={{width: '120px'}} >
                                <div style={{display:'grid'}} onClick={handleBoxPasajero}>
                                        <label>Personas</label>
                                        <label>{totalClicks} <i class="fi fi-rr-angle-small-down"></i> </label>
                                    </div>
                                    {boxPasajero && (
                                    <div className='modalPasajero booking'>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Adulto:</label>
                                                <label>De 12 a 99</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {adultoCount} </label>
                                                <div onClick={handleAdultoCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleAdultoCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>														
                                        </div>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Infante:</label>
                                                <label>De 0 a 2 años de edad</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {infanteCount} </label>
                                                <div onClick={handleInfanteCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleInfanteCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>
                                        </div>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Menor:</label>
                                                <label>De 3 a 11 años</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {menorCount} </label>
                                                <div onClick={handleMenorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleMenorCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>														
                                        </div>
                                        <div className='content_cant_pasajero'>
                                            <div className='box_cant_pasajero'>
                                                <label>Adulto mayor:</label>
                                                <label>A partir de 60 años</label>
                                            </div>
                                            <div className='box_cant_pasajero'>
                                                <label> {mayorCount} </label>
                                                <div onClick={handleMayorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                <div  onClick={handleMayorCount}><i class="fi fi-sr-square-plus"></i></div>
                                            </div>
                                        </div>
                                        <button onClick={handleBoxPasajero}>Aceptar</button>
                                    </div>
                                    )}
                                </div>                                
                            </div>
                            <div className='box_booking'>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-rs-car-side"></i></div>
                                </div>
                                <div className='item_booking'  onClick={handleBoxAuto}>
                                    <div style={{display:'grid'}}>
                                        <label>Vehículo</label>
                                        <label>{cantVehiculo} <i class="fi fi-rr-angle-small-down"></i> </label>
                                    </div>                                   		
                                  	
                                    {boxAuto && (						
                                    <div className='modalAuto booking'>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('')}>
                                            <div><i class="fi fi-ss-ship"></i></div>
                                            <label>Sin vehículo</label>
                                        </div>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('MC')}>								
                                            <div><i class="fi fi-ss-ship"></i></div>
                                            <label>Motocicleta</label>																								
                                        </div>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('CAR')}>								
                                            <div><i class="fi fi-ss-ship"></i></div>
                                            <label>Automóvil/Pick Up</label>								
                                        </div>
                                        <div className='content_auto' onClick={() => handleOptionVehiculo('MHO')}>								
                                            <div><i class="fi fi-ss-ship"></i></div>
                                            <label>Motorhome/RV</label>														
                                        </div>
                                        <button onClick={handleBoxAuto}>Aceptar</button>
                                    </div>
                                    )}																			
                                    
                                </div>                            
                            </div>
                            

                            <div className={inputClass} style={{width: '160px'}}>
                                <div className='icon_booking'>
                                    <div><i class="fi fi-ss-ticket"></i></div>
                                </div>
                                <div className='item_booking'>
                                    <label>Insertar código</label>
                                    <input type='text'  onChange={handleInputCupon}/>
                                </div>
                                                                
                            </div>
                            <div className='box_booking'>                                                             
                                <button>Reservar</button>
                            </div>                      
                        </div>
                    )}                                        
                </form>
            </div>
        </div>
    </section>
    <section class="d-block d-sm-block d-md-none">                                       
        <ReservaMobile />                               
    </section>
    
    </>
  )
}
function getTodayDate() {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
  }



  
