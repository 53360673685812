import React from 'react'

import bgNosotros from '../assets/images/nosotros.png'
import '../css/puertos.css'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import pichilingue from '../assets/images/pichilingue.webp';
import mazatlan from '../assets/images/mazatlan.jpg';
import topolobampo from '../assets/images/topolobampo.jpg';

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'




export default function Puertos() {
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Puertos y autoridades</h3>
                        <p>Consulte la información respecto a las terminales portuarias y las autoridades que operan en estas instalaciones.</p>
                    </div>                
                </div>
            </div>
        </section>
        <section className='container'>
            <div className='nosotros'>
                <div className='nosotros_text'>
                    <h2>Puertos </h2>
                    <p>
                        Las terminales portuarias de Pichilingue, Topolobampo y Mazatlán son operadas por la Administración Portuaria Integral (API). Si tiene alguna duda o queja respecto a estacionamientos, tarifas de báscula, patios de carga, salas de espera, sanitarios e infraestructura portuaria en general, puede presentar sus observaciones en las oficinas de la API de cada puerto ya que no son responsabilidad de <strong>Baja Ferries</strong>. 
                    </p>
                </div>
                <div className='nosotros_img'>
                    <img src={bgNosotros} alt='nosotros'/>
                </div>
            </div>
        </section>
        <section className='container'>
            <div className='box_terminales'>
                <h2>Terminales portuarias</h2>
                <p>Para expresar su opinión le proporcionamos el contacto de cada puerto:</p>
            </div>
            <Row>
                <Col span={8}>
                    <div className='content_terminales'>
                    <div >
                        <div className="cardTerminales">
                            <img src={pichilingue} alt="pichilingue" className='imgDestino' />                        
                            <div className="textTerminales">
                                <h4>API BCS</h4> 
                                <p>La puerta de entrada al Mar de Cortés, ideal para unas vacaciones rodeadas de naturaleza, aventura y gastronomía.</p> 
                                <a href='https://apibcs.com.mx/quejas-y-sugerencias/' target="_blank" rel='noopener noreferrer' >ir a la página</a>
                            </div>
                        </div>
                        </div>
                        
                    </div>

                </Col>
                <Col span={8}>
                    <div className='content_antesViajar'>
                    <div>
                        <div className="cardTerminales">
                            <img src={mazatlan} alt="mazatlan" className='imgDestino' />                        
                            <div className="textTerminales">
                                <h4>API Topolobampo</h4> 
                                <p>Conocida como “La Perla del Pacífico” es de los destinos más visitados en México por turistas nacionales e internacionales.</p> 
                                <a href='https://apibcs.com.mx/quejas-y-sugerencias/' target="_blank" rel='noopener noreferrer' >ir a la página</a>
                            </div>
                        </div>

                    </div>
                        
                    </div>
                </Col>
                <Col span={8}>
                    <div className='content_paraViajar'> 
                    <div >
                        <div className="cardTerminales">
                            <img src={topolobampo} alt="topolobampo" className='imgDestino' />                        
                            <div className="textTerminales">
                                <h4>API Mazatlán</h4> 
                                <p>La primera parada del ferrocarril Chihuahua-Pacífico, constituye la base perfecta para iniciar la expedición hacia las Barrancas del Cobre.</p> 
                                <a href='https://apibcs.com.mx/quejas-y-sugerencias/' target="_blank" rel='noopener noreferrer' >ir a la página</a>
                            </div>
                        </div>
                        </div>
                        
                    </div>
                </Col>
            </Row>
        </section>
        <section className='container'>
            <div className='box_autoridades'>
                <div className='content_autoridades'>
                    <h2>Autoridades</h2>
                    <p>Las terminales portuarias tiene procesos similares a las de un aeropuerto para cuidar la seguridad de los pasajeros. A continuación te informamos sobre las autoridades presentes en los puertos.</p>
                    <p>Fuera y dentro del puerto operan diferentes autoridades como FGR, SAT (Aduana), SEDENA, Secretaría de Marina, Policía Federal Preventiva, Servicio Nacional de Sanidad, Inocuidad y Calidad Agroalimentaria y Migración, quienes desempeñan funciones de revisión que incluyen la solicitud de documentos específicos.</p>
                    <p>Este proceso es independiente a Baja Ferries y puede demorar su embarque, por lo que le recomendamos llegar con tiempo suficiente a su puerto de salida.</p>
                </div>
            </div>
        </section>
        <div className="container" id="content">
    <div>
        <h3>Contáctanos</h3>
    </div>
    <Row>
        
        <Col span={8}>
            <div className='box_group-card'>
                <div className='box_content-card contact-box-card'>
                    <div className='box_icon-card'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='boxes_content'>
                        <div className='box_content-title'>
                            <h2>Envianos un WhatsApp</h2>
                        </div>
                        <div className='box_content-subtext'>
                            <p>
                                Te responderemos lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                            </p>
                        </div>
                        <div className='box_content-btn'>
                            <Link to="?">Enviar WhatsApp </Link>                                  
                        </div>
                    </div>
                

                </div>
            </div>
        </Col>
        <Col span={8}>
            <div className='box_group-card'>
                <div className='box_content-card contact-box-card'>
                    <div className='box_icon-card'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='boxes_content'>
                        <div className='box_content-title'>
                            <h2>Dinos que opinas</h2>
                        </div>
                        <div className='box_content-subtext'>
                            <p>
                                Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                            </p>
                        </div>
                        <div className='box_content-btn'>
                            <Link to="?">Contactar</Link>                                      
                        </div>
                    </div>
                    


                </div>
            </div>
        </Col>
        <Col span={8}>
            <div className='box_group-card'>
                <div className='box_content-card contact-box-card'>
                    <div className='box_icon-card'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='boxes_content'>
                        <div className='box_content-title'>
                            <h2>Encuentra el punto de ventas más cercano</h2>
                        </div>
                        <div className='box_content-subtext'>
                            <p>
                                Realizamos periódicamente actividades de mantenimiento y mejoras planificadas para ofrecerte una experiencia de usuario. 
                            </p>
                        </div>
                        <div className='box_content-btn'>
                            <Link to="?">Buscar </Link>                                      
                        </div>
                    </div>                                  
                </div>
            </div>
        </Col>
       
    </Row>

    
</div>
   <Outlet />
    </>
  )
}
