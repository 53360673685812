import React, { useState } from "react";
import '../css/rutas_pruebas.css'
import { Col, Row,  } from 'antd';
import { Outlet, Link } from "react-router-dom";
import Carousel from '../components/Carousel'
import Footer from '../components/Footer';
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import buque_cali from '../assets/images/iconRutasHorario/california-star.svg'
import MapaCali from '../components/frames/MapRoutes'
/*
Muestra todo el itinerario
{itinerario_LP_TP.map((viaje, index) => (
    <div key={index}>
        <div>{viaje.embarque}</div>
        <div>{viaje.salida}</div>
        <div>{viaje.duracion}</div>
        <div>{viaje.sinViaje || ''}</div>
    </div>
))}
 */
const servicios = [
    '../images/iconRutasHorario/alimentos.svg',
    '../images/iconRutasHorario/asientos.svg',
    '../images/iconRutasHorario/cabina.svg',
    '../images/iconRutasHorario/camioncito.svg',
    '../images/iconRutasHorario/maleta.svg',
    '../images/iconRutasHorario/mascota.svg',
    '../images/iconRutasHorario/vehiculo.svg',

    // Añade más logos aquí...
  ];

export default function Rutas_pruebas() {
    const [isRutaVisible, setIsRutaVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('La Paz - Topolobampo');

    const toggleIsRutaVisible = () => {
        setIsRutaVisible(!isRutaVisible)
    };
    // Manejar la selección del usuario
    const handleRouteSelection = (route) => {
        setSelectedOption(route);
        setIsRutaVisible(!isRutaVisible)
    };
    //Itinerario La Paz - Topolobampo
    const itinerario_LP_TP = [
        {
          dia: 'Lunes',
          embarque: '11:30',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Martes',
          embarque: '11:30',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '11:30',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '11:30',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '11:30',
          salida: '13:30 HRS',
        duracion: '08:00 HRS',
        },
        {
          dia: 'Sábado',
          embarque: '11:30',
          salida: '23:00 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        }
    ];
         //Itinerario La Paz - Topolobampo
    const itinerario_TP_LP = [
        {
          dia: 'Lunes',
          embarque: '23:59',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Martes',
          embarque: '23:59',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '23:59',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '23:59',
          salida: '13:30 HRS',
          duracion: '08:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '23:59',
          salida: '13:30 HRS',
        duracion: '08:00 HRS',
        },
        {
          dia: 'Sábado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Domingo',
          embarque: '21:00 HRS',
          salida: '23:00 HRS',
          duracion: '08:00 HRS',
          sinViaje: 'No hay viajes disponibles',
        }
    ];
    const itinerario_LP_MZ = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Martes',
          embarque: '17:00',
          salida: '19:00 HRS',
          duracion: '12:00 HRS',
        },
        {
          dia: 'Miercoles',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Jueves',
          embarque: '17:00',
          salida: '19:00 HRS',
          duracion: '12:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Sábado',
          embarque: '17:00',
          salida: '19:00 HRS',
          duracion: '12:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        }
    ];
    const itinerario_MZ_LP = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Martes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Miercoles',
          embarque: '14:00 HRS',
          salida: '16:00 HRS',
          duracion: '12:00 HRS',          
        },
        {
          dia: 'Jueves',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Viernes',
          embarque: '14:00 HRS',
          salida: '16:00 HRS',
          duracion: '12:00 HRS',
        },
        {
          dia: 'Sábado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes disponibles',
        },
        {
          dia: 'Domingo',
          embarque: '14:00 HRS',
          salida: '16:00 HRS',
          duracion: '12:00 HRS',
        }
    ];
     
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Rutas y Horarios</h3>
                        <p>Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!</p>
                    </div>                
                </div>
            </div>
        </section>

        <section className="itinerario">
            <div>
                <div className="container">
                    <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                        <div className='select_ruta'>
                            <label>Selecciona una ruta</label>
                            <label>{selectedOption} <i class="fi fi-br-angle-small-down"></i></label>
                        </div>      
                    </div>
                    {isRutaVisible && 
                        <div className='optionListRoutes'>
                            <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                            <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                            <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                            <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                        </div>
                    }                                                                                                                                                                                                  
                </div>
            </div>
            <div className="">
                <div className="container">
                    <div className="container_itinerario">
                        {selectedOption === 'La Paz - Topolobampo' &&(
                        <div className="content_rutas">
                            <div className="content_itinerario">
                                <h2>LUNES A VIERNES</h2>
                                
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="box_itinerario">
                                        <div className="item_itinerario"><label>Embarque:</label><label> {viaje.embarque}</label></div>
                                        <span></span>
                                        <div className="item_itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        <span></span>
                                        <div className="item_itinerario"><label>Llegada:</label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            </div>
                            <div className="content_itinerario">
                                <h2>SÁBADO</h2>
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Sábado')
                                .map((viaje, index) => (
                                    <div key={index} className="box_itinerario">
                                        <div className="item_itinerario"><label>Embarque:</label><label>{viaje.embarque}</label> </div>
                                        <span></span>
                                        <div className="item_itinerario"><label>Salida:</label><label> {viaje.salida}</label></div>
                                        <span></span>
                                        <div className="item_itinerario"><label>Llegada:</label><label> {viaje.duracion}</label></div>                            
                                    </div>
                                ))}                   
                            </div>
                            <div className="content_itinerario">
                                <h2>Domingo</h2>
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="box_itinerario">
                                        {/*
                                        <div className="item_itinerario">Embarque: {viaje.embarque}</div>
                                        <div className="item_itinerario">Salida: {viaje.salida}</div>
                                        <div className="item_itinerario">Llegada: {viaje.duracion}</div>
                                        */}
                                        <div className="item_itinerario">{viaje.sinViaje || ''}</div>
                                    </div>
                                ))}                   
                            </div>
                            <div className="content_itinerario"> 
                                <p>• Itinerario sujeto a cambios sin previo aviso</p>
                            </div>
                            <div className="available_services">
                                <div className="available_services_img">
                                    <h3>Servicios disponibles</h3>
                                    <Carousel logos={servicios} />
                                </div>
                                <div className="available_services_buque">
                                    <div><p>Esta ruta será a bordo del</p></div>
                                    <div className="box_available_services"><img src={buque_cali} alt="buque california star" /></div>
                                    <div><p>Conoce más de <Link to="/servicios-california-star">nuestro buque</Link></p></div>
                                </div>
                                
                            </div>
                        </div>
                        )}
                        {selectedOption === 'Topolobampo - La Paz' &&(
                        <div className="content_rutas">
                        <div className="content_itinerario">
                            <h2>LUNES A VIERNES</h2>
                            
                            {itinerario_TP_LP
                            .filter(viaje => viaje.dia === 'Lunes')
                            .map((viaje, index) => (
                                <div key={index} className="box_itinerario">
                                    <div className="item_itinerario"><label>Embarque:</label><label> {viaje.embarque}</label></div>
                                    <span></span>
                                    <div className="item_itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                    <span></span>
                                    <div className="item_itinerario"><label>Llegada:</label><label>{viaje.duracion}</label> </div>                                                        
                                </div>
                            ))}
                        </div>
                        <div className="content_itinerario">
                            <h2>SÁBADO</h2>
                            {itinerario_TP_LP
                            .filter(viaje => viaje.dia === 'Sábado')
                            .map((viaje, index) => (
                                <div key={index} className="box_itinerario">
                                    <div className="item_itinerario">{viaje.sinViaje || ''}</div>                          
                                </div>
                            ))}                   
                        </div>
                        <div className="content_itinerario">
                            <h2>Domingo</h2>
                            {itinerario_TP_LP
                            .filter(viaje => viaje.dia === 'Domingo')
                            .map((viaje, index) => (
                                <div key={index} className="box_itinerario">                                    
                                   <div className="item_itinerario"><label>Embarque:</label><label> {viaje.embarque}</label></div>
                                    <span></span>
                                    <div className="item_itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                    <span></span>
                                    <div className="item_itinerario"><label>Llegada:</label><label>{viaje.duracion}</label> </div>                                                                       
                                </div>
                            ))}                   
                        </div>
                        <div className="content_itinerario"> 
                            <p>• Itinerario sujeto a cambios sin previo aviso</p>
                        </div>
                        <div className="available_services">
                            <h3>Servicios disponibles</h3>
                            <Carousel logos={servicios} />
                        </div>
                    </div>
                        )}
                        {selectedOption === 'La Paz - Mazatlan' &&(
                        <div className="content_rutas">
                            <div className="content_itinerario">
                                <h2>MARTES, JUEVES Y SÁBADOS</h2>
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Martes')
                                .map((viaje, index) => (
                                    <div key={index} className="box_itinerario">
                                        <div className="item_itinerario"><label>Embarque:</label><label>{viaje.embarque}</label> </div>
                                        <span></span>
                                        <div className="item_itinerario"><label>Salida:</label><label> {viaje.salida}</label></div>
                                        <span></span>
                                        <div className="item_itinerario"><label>Llegada:</label><label> {viaje.duracion}</label></div>                                                                   
                                    </div>
                                ))}
                            </div>
                            <div className="content_itinerario">
                                <h2>LUNES, MIÉRCOLES, VIERNES Y DOMINGO</h2>
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="box_itinerario">
                                        <div className="item_itinerario">{viaje.sinViaje || ''}</div>                                                                  
                                    </div>
                                ))}                   
                            </div>
                                                                               
                        <div className="content_itinerario"> 
                            <p>• Itinerario sujeto a cambios sin previo aviso</p>
                        </div>
                        <div className="available_services">
                            <h3>Servicios disponibles</h3>
                            <Carousel logos={servicios} />
                        </div>
                    </div>
                        )}
                        {selectedOption === 'Mazatlan - La Paz' &&(
                        <div className="content_rutas">
                        <div className="content_itinerario">
                            <h2>MIÉRCOLES, VIERNES Y DOMINGO</h2>
                            {itinerario_MZ_LP
                            .filter(viaje => viaje.dia === 'Viernes')
                            .map((viaje, index) => (
                                <div key={index} className="box_itinerario">
                                    <div className="item_itinerario"><label>Embarque:</label><label>{viaje.embarque}</label> </div>
                                    <span></span>
                                    <div className="item_itinerario"><label>Salida:</label><label> {viaje.salida}</label></div>
                                    <span></span>
                                    <div className="item_itinerario"><label>Llegada:</label><label> {viaje.duracion}</label></div>                                                                   
                                </div>
                            ))}
                        </div>
                        <div className="content_itinerario">
                                <h2> LUNES, MARTES, JUEVES Y SÁBADOS</h2>
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="box_itinerario">
                                        <div className="item_itinerario">{viaje.sinViaje || ''}</div>                                                                  
                                    </div>
                                ))}                   
                        </div>                     
                        <div className="content_itinerario"> 
                            <p>• Itinerario sujeto a cambios sin previo aviso</p>
                        </div>
                        <div className="available_services">
                            <h3>Servicios disponibles</h3>
                            <Carousel logos={servicios} />
                        </div>
                    </div>
                        )}                        
                        <div className="content_mapa">
                            <MapaCali />
                        </div>
                    </div>
                   
                </div>
            </div>
        </section>

        <section className='callToAction'>
            <div className="container">
            <div>
                <h3>¿Listo para reservar?</h3>
            </div>
            <Row>
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Consulta nuestras tarifas</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Reservar ahora</Link>                                      
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Col>   
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Preguntas frecuentes</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Consultar </Link>                                      
                                </div>
                            </div>                                  
                        </div>
                    </div>
                </Col>      
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Envianos un WhatsApp</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Te responderemos lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Enviar WhatsApp </Link>                                  
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>        
                    
            </Row>    
            </div>
            <Outlet />
        </section>
    <Outlet />
    <Footer />
        
        
        
        </>
  )
}
