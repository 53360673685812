// QuestionDetails.js

import React, { useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { Outlet,  } from "react-router-dom";
import '../../../css/answer.css'
import ShareButton from '../../ShareButton';
import bookingIcon from '../../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import documentacion from '../../../assets/images/iconsPreguntas/documentacion.png'
import avisos from '../../../assets/images//iconsPreguntas/megafono.png'
import carga from '../../../assets/images/iconsPreguntas/barco-de-carga.png'



import Contactanos from '../../contacto/Contactanos';
import Footer from '../../Footer';
const questions = [
    {
        id: 1,
        text: '¿Cuáles son las formas de pago?',
        answer: ( 
            <ul>
                <li>En los puntos de venta, aceptamos pagos con tarjeta de débito, tarjeta de crédito y en efectivo. En el Contact Center, aceptamos pagos mediante depósito, transferencia bancaria, y tarjeta de débito o crédito. En nuestra página web, aceptamos pagos solamente con tarjeta de débito o crédito.</li>                
            </ul>
        ),
    },
    {
        id: 2,
        text: '¿Puedo facturar mi compra?',
        answer: ( 
            <ul>
                {/*<li>Si, la facturación de tus boletos se deberá solicitar al momento de la compra brindando todos los datos actualizados.</li>   
                <li>En caso de no solicitarla en ese momento, en las próximas 48 horas ingresando al portal de facturación de nuestra página web en el apartado "facturación"</li>                */}
                <li>Puedes solicitar la factura de tu compra enviando un correo a facturas@bajaferries.com.mx, adjuntando la constancia de situación fiscal actualizada y tu número de reservación.</li>
                <li>Si solicitas la factura el mismo día de tu compra, puedes llamar al contact center, indicar tus datos de facturación, y te la enviarán en el transcurso del día.</li>
            </ul>
        ),
    },
  
    {
        id: 4,
        text: '¿Cómo puedo obtener el recibo de pago?',
        answer: ( 
            <ul>
                <li>La confirmación de tu reserva es tu recibo de pago y debe llegar de manera automática al correo que proporcionaste al momento de realizar tu pago.</li>
                <li>En caso de no haberlo recibido puedes comunicarte a nuestro Contact Center para solicitar el reenvío. </li>            
            </ul>
        ),
    },
    

];


function DetailPagosFactura() {
    const [modal, setModal] = useState(false);
  const { id } = useParams();
  const question = questions.find(q => q.id === parseInt(id));

  if (!question) {
    return <div>Pregunta no encontrada</div>;
  }
 
  const shareUrl = `http://localhost:3000/preguntas-frecuentes-antes-viajar/${id}`;
  const title = 'Preguntas frecuentes';

  const showBoxShare = () => {
    setModal(!modal)
}

  return (

    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Preguntas frecuentes</h3>
                    <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                </div>                
            </div>
        </div>
    </section>

    <section className='container'>
        <div className='box_titulo_faqs '>
            <div className='lista_preguntas'>
                <div className='answer_pgunas_frcuentes'>
                    <h1>{question.text}</h1>
                    <p> {question.answer}</p>
                </div>
            </div>
            <div className='lista_temas'>
                <h3>Otros temas frecuentes</h3>
                <div className='box_lista_temas'>
                    <ul>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={bookingIcon} alt='antesViajar' />
                                <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                            </div>
                        </li>
                        <li> 
                            <div className='content_lista_temas'>
                                <img src={documentacion} alt='antesViajar' />
                                <Link to="/documentacion">Documentación</Link> 
                            </div>
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={reservar} alt='antesViajar' />
                                <Link to="/pagos-facturas">Reservar</Link> 
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={carga} alt='antesViajar' />
                                <Link to="/carga">Carga</Link>  
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={avisos} alt='antesViajar' />
                                <Link to="/automovil">Automóvil</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>

    <section>
        <div className='container'>
            <div className='box_share'>
                <div><p>¿Fue útil esta información?</p></div>     
                <div>
                    <div className='btn_share' onClick={showBoxShare}>Compartir <i class="fi fi-rr-share"></i></div>
                    {modal && (
                        <div className='box_share_options'>
                            <ShareButton url={shareUrl} title={title} />
                        </div>
                    )}
                </div>
                
            </div>
        </div>        
    </section>


    <Contactanos />
    <Footer />


    
    </>
  );
}



export default DetailPagosFactura;
