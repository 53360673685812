import React from 'react'
import '../css/aviso_privacidad.css'
import Footer from '../components/Footer'

export default function Aviso_privacidad() {
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Aviso de privacidad </h3>
                        <p></p>
                    </div>                
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className='aviso_privacidad'>
                    <h3>PROTECCIÓN DE DATOS</h3>
                    <p><strong>1. OBJETIVO</strong> </p>
                    <p>Informar a los Clientes sobre la protección de datos y asegurar la integridad de los mismos.</p>
                    <p><strong>2. POLÍTICAS</strong></p>                    
                    <h3>LEY FEDERAL DE PROTECCIÓN DE DATOS PERSONALES EN POSESIÓN DE LOS PARTICULARES.</h3>
                    <p><strong>Responsable de la protección de sus datos personales.</strong></p>
                    <p><strong>BAJA FERRIES S.A.P.I. DE C.V.,</strong> con domicilio en Allende No. 1025, esquina Marcelo Rubio, Colonia Centro, C.P.23000, La Paz, Baja California Sur; es responsable del tratamiento de sus datos personales.</p>
                    <p><strong>Forma y medios de contacto.</strong></p>
                    <p>Puede contactarnos, redactando un documento en idioma español, dirigido al Subdirector de Seguridad de Información o representante legal de la empresa C.P. Sergio Pinzón Sopeña y hacerlo llegar a cualquiera de los siguientes correos electrónicos, sergio.pinzon@bajaferries.com.mx, clientes@bajaferries.com.mx o en su caso al siguiente domicilio: Allende No. 1025, esquina Marcelo Rubio, Colonia Centro, C.P., 23000, La Paz, Baja California Sur o llamando al teléfono: 612 123 66 00 ext. 1130, 1181.</p>
                    <p><strong>Fines de los datos personales recabados.</strong></p>
                    <p>Sus datos personales serán utilizados para fines de seguimiento, actualización y confirmación en cuanto a productos y servicios contratados; con fines promocionales y de contratación; fines financieros y crediticios; dar cumplimiento a obligaciones contraídas con nuestros Clientes; evaluar la calidad del servicio y realizar estudios sobre hábitos de consumo y preferencias.</p>
                    <p><strong>Datos recabados y medios de obtención de datos personales.</strong></p>
                    <p>Los datos personales que recabamos de usted, con los fines descritos en el presente aviso de privacidad, son recabados de manera personal, cuando usted nos los proporciona directamente; por vía de nuestro portal en Internet (www.bajaferries.com) al ingresar sus datos o utilizar nuestros servicios en línea, y cuando obtenemos información a través de otras fuentes que están permitidas por la Ley.</p>
                    <p>Los datos personales que recabamos de forma directa cuando usted mismo nos los proporciona por diversos medios, como cuando los proporciona para ingresar a nuestro programa de lealtad, participar en promociones, ofertas o cuando contrata con nosotros algún producto o servicio.</p>
                    <p>Los datos que recabamos de manera directa y vía Internet, son los siguientes: Nombre y apellidos, género (masculino o femenino), fecha de nacimiento, teléfono, correo electrónico y dirección física.</p>
                    <p>Toda vez que la Ley permite otras fuentes de allegarnos de información como lo son directorios telefónicos, de servicios y laborales, los datos que por dichos medios podemos obtener son nombre y apellidos, teléfono, correo electrónico y dirección física.</p>
                    <p>En cuanto a los datos financieros como lo son su número de tarjeta de crédito, fecha de expiración de la misma de conformidad con los artículos 8°, 10° y 37° de la Ley no son considerados datos que requieran de su consentimiento expreso para ser utilizados.</p>
                    <p><strong>Con qué fines recabamos y utilizamos tus datos personales.</strong></p>
                    <p>Los datos personales recabados serán utilizados únicamente para dar seguimiento, actualización y confirmación del servicio de transporte marítimo de pasajeros y productos contratados, dar seguimiento a tu reservación, realizar encuestas acerca del servicio contratado, así como para que recibas por e-mail información de las promociones y servicios adicionales que nosotros o nuestros socios comerciales ofrecemos.</p>
                    <p><strong>Limitaciones al uso de datos.</strong></p>
                    <p>Usted puede cancelar su suscripción para recibir promociones, ofertas y servicios de manera telefónica, por correo electrónico y vía correo postal haciendo llegar una carta escrita en idioma español donde indique su solicitud y los datos necesarios como lo son Nombre completo (nombre o nombres y apellido o apellidos) copia simple de su identificación oficial o en medios electrónicos versión digitalizada de la misma (escaneo), número de socio o de cuenta (en caso de tener uno), teléfono y dirección física o electrónica a la dirección para fines de notificaciones relacionadas al caso a la siguiente dirección: Allende No. 1025, esquina Marcelo Rubio, Colonia Centro, C.P. 23000, La Paz, Baja California Sur, o al correo electrónico: clientes@bajaferries.com.mx .</p>
                    <p><strong>Ejercicio de los Derechos ARCO.</strong></p>
                    <p>Usted tiene derecho de acceder a los datos personales que poseemos, a los detalles de tratamiento de los mismos, a la rectificación en el caso de ser estos inexactos o incompletos, a cancelarlos cuando sea su consideración que no son necesarios para alguna de las finalidades contenidas en el presente Aviso de Privacidad, que son utilizados para finalidades no consentidas y finalmente a oponerse al tratamiento de dichos datos para fines específicos y que deberá de manera clara expresar.</p>
                    <p><strong>Mecanismos para el ejercicio de los Derechos ARCO.</strong></p>
                    <p>Los derechos antes descritos se ejercen a través de la presentación de la solicitud respectiva que por escrito y en idioma español debe presentar de manera gratuita en la dirección de Allende No. 1025, esquina Marcelo Rubio, Colonia Centro, C.P. 23000, La Paz, Baja California Sur o para mayor información comunicarse al teléfono: 6121270511, con atención al C.P. Sergio Pinzón Sopeña; o bien hacerla llegar por correo postal, previo pago del porte correspondiente, a la antes mencionada dirección y con atención a la misma persona.</p>
                    <p>Para mayor información sobre la Seguridad de Información comunicarse al teléfono: 612 123 6600 ext. 1130, 1181.</p>
                    <p>El plazo para atender su solicitud será en un máximo de veinte días contados a partir de la fecha de recepción de su solicitud de acceso, rectificación, cancelación u oposición.</p>
                    <p>Le hacemos patente que es el Instituto Federal de Acceso a la Información y Protección de Datos Personales quien tiene encomendado velar por sus derechos ARCO y el encargado de regular y verificar la observancia de la Ley. Derecho de revocar su consentimiento para el tratamiento de datos.</p>
                    <p>En todo tiempo usted puede revocar el consentimiento que nos ha entregado para el tratamiento de sus datos personales con el propósito que se deje de hacer uso de los mismos, para ello es necesario que presente su petición según descrito en éste mismo aviso en la parte de “Forma y medios de contacto”.</p>
                    <p>Su solicitud deberá indicar nombre completo (nombre o nombres y apellido o apellidos) copia simple de su identificación oficial o, en medios electrónicos, versión digitalizada de la misma (escaneo), indicación del correo electrónico o dirección física que designe para notificaciones y algún número telefónico de contacto.</p>
                    <p>En un plazo máximo de veinte días contados a partir de la fecha de recepción de su solicitud de revocación le daremos contestación sobre la procedencia de la misma, por medio de correo electrónico dirigido a su persona, enviado a la dirección de correo electrónico que haya indicado en la propia solicitud o enviando carta al domicilio físico que haya indicado a tales efectos (los veinte días se darán por cumplidos al momento de entregar el documento al servicio postal).</p>
                    <p><strong>Transferencia de datos, nacional e internacional.</strong></p>
                    <p>Sus datos personales pueden ser compartidos, transferidos y tratados dentro y fuera de los Estados Unidos Mexicanos, por personas distintas a ésta empresa, y con los terceros que nos prestan el servicio de hosting del sitio Web y terceros relacionados con dicho proveedor, servicios de call center, servicios adicionales que hayas contratado. Agencias de publicidad, instituciones bancarias, así como a nuestras afiliadas y subsidiarias, En ese sentido, su información puede ser compartida con Instituciones Financieras, bancarias y crediticias para fines informativos, promocionales, publicitarios y mercantiles. Con fines de prestar el servicio público de transporte marítimo de forma adecuada. Lo cual, al hacer uso de nuestro sistema Web, aceptas expresamente.</p>
                    <p>Sus datos personales no serán transmitidos a terceros sin su consentimiento, de conformidad con el artículo 37 de la Ley, incluidas las demás excepciones previstas en dicho ordenamiento legal. Únicamente podrán ser transferidos a terceros distintos de los mencionados en el párrafo anterior mediante: (i) consentimiento expreso, (ii) por resolución u orden de autoridad judicial o administrativa y (iii) cuando se trate de información personal que por disposición de un Ley sea considerada pública.</p>
                    <p><strong>Modificaciones al presente aviso de privacidad.</strong></p>
                    <p>Nos queda reservado el derecho de efectuar, en cualquier tiempo, modificaciones o actualizaciones al presente aviso de privacidad. Las modificaciones que se efectúen se pondrán a disposición del público a través de algunos o todos los siguientes medios: anuncios visibles en nuestros establecimientos, vía nuestra página de Internet, vía correo electrónico a la dirección más reciente que tengamos de usted, por medio de publicaciones en periódicos de circulación nacional, revistas, carteles, grabaciones sonoras o personalmente por medio de nuestros encargados al momento de acudir a cualquiera de nuestras instalaciones.</p>
                    <p><strong>Protección.</strong></p>
                    <p>La seguridad y la confidencialidad de los datos que los usuarios proporcionen al contratar un servicio o comprar un producto en línea estarán protegidos por un servidor seguro bajo el protocolo Secure Socket Layer (SSL), de tal forma que los datos enviados se transmitirán encriptados para asegurar su resguardo.</p>
                    <p>Para verificar que se encuentra en un entorno protegido asegúrese de que aparezca una S en la barra de navegación. Sin embargo, y a pesar de contar cada día con herramientas más seguras, la protección de los datos enviados a través de Internet no se puede garantizar al 100%; Por lo que una vez recibidos, se hará todo lo posible por salvaguardar la información.</p>
                    <p><strong>Información en Internet.</strong></p>
                    <p>Hacemos de su conocimiento que cuando accede a nuestro Portal en Internet es recibida por nuestra parte información referente a sus cookies (término definido abajo en la parte de “Definiciones”) y web beacons (término definido abajo en la parte de “Definiciones”) para obtener información como lo es su tipo de navegador y sistema operativo, las páginas de Internet que ha visitado recientemente, los vínculos que recientemente ha seguido, la dirección IP de su computadora, el sitio que cerro antes de entrar a nuestro portal en Internet.</p>
                    <p><strong>Datos personales sensibles.</strong></p>
                    <p>Hacemos de su conocimiento que para cumplir con las finalidades detalladas en el presente aviso de privacidad, serán recabados y tratados datos personales sensibles, como aquellos que refieren a: Parentesco, preferencia sexual, religión, creencias filosóficas, condición física, estado de salud, discapacidad, fotografías y documentos médicos, entre algunos otros.</p>
                    <p>Sus datos personales sensibles serán tratados con las más estrictas medidas de seguridad para garantizar su confidencialidad. Su conocimiento en relación al manejo de estos datos debe ser expreso, así en razón de dar cabal cumplimiento al artículo noveno de la Ley, debe expresar el mismo estampando su firma y nombre a continuación:</p>
                    <p>Yo_____________________________________ autorizo expresamente que mis datos personales sensibles sean tratados y transferidos conforme a los términos y condiciones del presente aviso de privacidad ______________________.</p>
                    <p><strong>Consentimiento tácito, en términos del artículo octavo de la Ley.</strong></p>
                    <p>En caso que usted desee manifestar oposición, a que sus datos personales sean tratados conforme a lo establecido en el presente aviso de privacidad deberá enviar su negativa por escrito en idioma español a la siguiente dirección:</p>
                    <p>Allende No. 1025, esquina Marcelo Rubio, Colonia Centro, C.P.23000, La Paz, Baja California Sur o para mayor información comunicarse al teléfono: 612123 66 00 ext. 1130, 1181.</p>
                    
                </div>
            </div>
        </section>
        <Footer />
    
    </>
  )
}
