import React, { useState } from 'react';

import { SearchOutlined } from '@ant-design/icons';
import { Input,  } from 'antd';

const SearchBar = ({ words, onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    onSearch(newSearchTerm);
  };

  return (
    <div>
       {
        /*
        <Input
            value={searchTerm}
            size="large"
            onChange={handleInputChange}
            placeholder="Haz una pregunta..."
            prefix={<SearchOutlined className="site-form-item-icon" />}
        />
        
        */
       }
        
        <input type="text" placeholder="Haz una pregunta..." class="custom-input" value={searchTerm} onChange={handleInputChange}></input>
        

    </div>
  );
};

export default SearchBar;
