import React, { useRef } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import '../css/sostenibilidad.css';
import sostenibilidad from '../assets/video/sostenibilidad.mp4';
import economia_y_sociedad from '../assets/images/sostenibilidad/EconomiaYSociedad.png'
import bienestar_empresarial from '../assets/images/sostenibilidad/bienestar-empresarial.png'
import ecosistemas_maritimos from '../assets/images/sostenibilidad/EcosistemasMarinos.png'
import ecosistemas_terrestres from '../assets/images/sostenibilidad/ecosistemas-terrestre.png'
import logoRSE from '../assets/images/sostenibilidad/rse-logo.png'
import home from '../assets/images/sostenibilidad/home.webp'
import rse_blanco from '../assets/images/sostenibilidad/RSE-blanco.png'
import Menu from '../components/header/MenuSostenibilidad'


const pluginWrapper = () => {
  require('../assets/fullpage.scrollHorizontally.min');
};

const Sostenibilidad = () => {
  const videoRef = useRef(null);

  return (
    <>    
    <ReactFullpage
      pluginWrapper={pluginWrapper}
      licenseKey={'6M3WI-JHD26-EKNE7-XJ017-ONTYM'}
      
      // Scrolling
      scrollingSpeed={1000}
      scrollHorizontally={true}
      scrollHorizontallyKey={'C7586C54-FDD24350-842EB13E-835617CC'}
      credits={false}      
      
      // Accessibility
      keyboardScrolling={true}
      animateAnchor={true}
      recordHistory={true}

      // Design
      //controlArrows={false}
      controlArrows={false}
      controlArrowsHTML= {[
        '<div class="fp-arrow"></div>', 
        '<div class="fp-arrow"></div>'
      ]}

      // Navigation
      slidesNavigation={true}
	    slidesNavPosition={'bottom'}
      navigation= {true}
      navigationPosition={'right'}      
      showActiveTooltip={true}

      onLeave={(origin, destination, direction) => {
        // Pause the video when leaving the first section
        if (origin.index === 0 && videoRef.current) {
          videoRef.current.pause();
        }
      }}
      afterLoad={(origin, destination, direction) => {
        // Play the video when returning to the first section
        if (destination.index === 0 && videoRef.current) {
          videoRef.current.play();
        }
      }}
      render={({ state, fullpageApi }) => {
        return (
          <ReactFullpage.Wrapper>
            <Menu fullpageApi={fullpageApi} />
            <div className="section">
              <div className='d-none d-sm-none d-md-block'>
                <div className='hero_sostenibilidad'>
                  {/*<Menu />  */}
                  <video muted loop autoPlay={true} width="750" height="500" ref={videoRef}>
                    <source src={sostenibilidad} type="video/mp4" />
                  </video>
                  <div className='icons_pilares'>                                   
                      <div className='icon_pilar'> <div className='icon_pilar_img'><img src={economia_y_sociedad} alt='economina y sociedad sostenibilidad baja ferries' /> </div><p>Economia y <br/>Sociedad</p> </div>
                      <div className='icon_pilar'><div className='icon_pilar_img'><img src={bienestar_empresarial} alt='economina y sociedad sostenibiidad baja ferries' /></div><p>Bienestar <br/>Empresarial</p></div>
                      <div className='icon_pilar'><div className='icon_pilar_img'><img src={ecosistemas_terrestres} alt='economina y sociedad sostenibilidad baja ferries' /></div><p>Ecosistemas <br/>Terrestres</p></div>
                      <div className='icon_pilar'><div className='icon_pilar_img'><img src={ecosistemas_maritimos} alt='economina y sociedad sostenibilidad baja ferries' /></div><p>Ecosistemas <br/>Marinos</p></div>                    
                  </div> 
                    <div className='btn_bottom_hero_rse'>
                      
                        <button class="btn"  onClick={() => fullpageApi.moveSectionDown()}>
                          <div class="scroll"> </div>
                      </button>                                     
                    </div>               
                </div>
              </div>   
              <div className='d-block d-sm-block d-md-none'>
                <div className='home_rse_mobile'>
                  <div>
                      <img src={rse_blanco} alt='responsabilidad social empresarial baja ferries' />
                  </div>                  
                  <div className='icons_pilares_mobile'>
                      <div className='icon_pilar'> <div className='icon_pilar_img'><img src={economia_y_sociedad} alt='economina y sociedad sostenibilidad baja ferries' /> </div><p>Economia y <br/>Sociedad</p> </div>
                      <div className='icon_pilar'><div className='icon_pilar_img'><img src={bienestar_empresarial} alt='economina y sociedad sostenibiidad baja ferries' /></div><p>Bienestar <br/>Empresarial</p></div>
                      <div className='icon_pilar'><div className='icon_pilar_img'><img src={ecosistemas_terrestres} alt='economina y sociedad sostenibilidad baja ferries' /></div><p>Ecosistemas <br/>Terrestres</p></div>
                      <div className='icon_pilar'><div className='icon_pilar_img'><img src={ecosistemas_maritimos} alt='economina y sociedad sostenibilidad baja ferries' /></div><p>Ecosistemas <br/>Marinos</p></div> 
                  </div>
                </div>

              </div>         
              
            </div>
            <div className="section">
              <div className="slide">
                <div className='rse-economia-sociedad'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Economia  <br/> y Sociedad</h2>
                        <div className='rse_text_left'>
                          <h3>Estabilidad Macroeconómica</h3>
                          <p>
                            Generar las condiciones adecuadas y apropiadas para el crecimiento económico del país.
                          </p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={economia_y_sociedad} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                  */}
                </div>
              </div>
              <div className="slide">
                <div className='rse-economia-sociedad'>
                <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <div className='rse-economia-sociedad-title'>
                          {/*<div className='rse-economia-sociedad-title-img'>
                            <img src={economia_y_sociedad} alt='economina y sociedad sostenibilidad baja ferries' />
        </div>*/}
                          <div>
                            <h2>Economia  <br/> y Sociedad</h2>
                          </div>                         
                        </div>                        
                        <div className='rse_text_left'>
                          <h3>Estabilidad Macroeconómica</h3>
                          <p>
                            Generar las condiciones adecuadas y apropiadas para el crecimiento económico del país.
                          </p>
                        </div>
                        <div className='rse_text_left'>
                          <h3>Grupos de interés</h3>
                          <p>
                          Establecer relaciones de equidad con nuestros grupos de interés.
                          </p>
                        </div>
                        <div className='rse_text_left'>
                          <h3>Eficiencia Microeconómica</h3>
                          <p>Estimular la productividad, la competitividad y agregar valor a la producción regional.
                          </p>
                        </div>
                        <div className='rse_text_left'>
                          <h3>Anticorrupción</h3>
                          <p>Velar por la transparencia como una forma de transmitir y generar confianza de nuestra sociedad.
                          </p>
                        </div>                                              
                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={economia_y_sociedad} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
            {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                      <button onClick={() => fullpageApi.moveSlideRight()}>
                        <i class="fi fi-rs-arrow-right"></i>
                      </button>
                  </div>
                  <div className='btn_bottom_rse'>
                      <button onClick={() => fullpageApi.moveSectionDown()}>
                        <i class="fi fi-rr-arrow-down"></i>
                      </button>                                       
                  </div>
      */}
                </div>                
              </div>
              <div className="slide">
                <div className='rse-economia-sociedad'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <div className='rse-economia-sociedad-title'>
                          <div className='rse-economia-sociedad-title-img'>
                            <img src={economia_y_sociedad} alt='economina y sociedad sostenibilidad baja ferries' />
                          </div>
                          <div>
                            <h2>Economia  <br/> y Sociedad</h2>
                          </div>                         
                        </div>
                        
                        <div className='rse_text_left'>
                          <h3>Proveedores</h3>
                          <p>Adoptar practicas responsables en la gestión de la cadena de suministro.</p>
                          <p>Procurar que todos los participantes en la cadena de suministro cumplan con principios y valores relativos al comportamiento ético empresarial y gestión transparente, las buenas prácticas laborales, la salud y seguridad, el medio ambiente, la calidad de los productos y servicios, así como el desarrollo de prácticas responsables en la cadena, impulsando el respeto a los derechos humanos y laborales.</p>
                        </div>
                        <div className='rse_text_left'>
                          <h3>Colaboradores</h3>
                          <p>Establecer prácticas que permitan generar un equilibrio entre la vida laboral y familiar de todos sus colaboradores.
                            <p>Respetar y fomentar la diversidad y la promoción de la igualdad de oportunidades, así como la no discriminación.</p>
                          </p>
                        </div>                                          
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <div className='rse_text_left'>
                            <h3>Clientes</h3>
                            <p>Construir relaciones laborales de largo plazo basadas en la confianza y respeto para beneficio mutuo.</p>
                            <p>Dar soluciones que generen beneficios reales.</p>
                            <p>Informar con veracidad y oportunidad a nuestros clientes sobre cualquier aspecto relevante, relativo a nuestros servicios.</p>
                          </div>
                      </div>
                    </div>
                  </div>
{/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>  
*/}
                </div>                
              </div>
            </div>
            <div className="section">
              <div className="slide">
              <div className='rse-bienestar-empresarial'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Bienestar  <br/> Empresarial</h2>
                        <div className='rse_text_left'>
                          <h3>Estabilidad Macroeconómica</h3>
                          <p>
                            Generar las condiciones adecuadas y apropiadas para el crecimiento económico del país.
                          </p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={bienestar_empresarial} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                */}
                </div>
              </div>
              <div className="slide">
                <div className='rse-bienestar-empresarial'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Bienestar  <br/> Empresaria</h2>
                        <div className='rse_text_left'>
                          <h3>Ética empresarial</h3>
                          <p>Promover el comportamiento ético en la gestión de la organización y el desarollo del negocio.
                          </p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={bienestar_empresarial} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                  */}
                </div>
              </div>
              <div className="slide">
                <div className='rse-bienestar-empresarial'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Bienestar  <br/> Empresarial</h2>
                        <div className='rse_text_left'>
                          <h3>Gobernanza Corporativa</h3>
                          <p>Mantener prácticas de gobierno corporativo, basadas en la ética y la transparencia empresarial.
                          </p>
                        </div>
                        <div className='rse_text_left'>
                          <h3>Transparencia</h3>
                          <p>Ser transparente con las actividades de RSE reportando de manera anual el desempeño de las actuaciones en ese ámbito.
                          </p>
                        </div>
                        <div className='rse_text_left'>
                          <h3>Bienestar empresarial</h3>
                          <p>Asegurar la calidad de vida de los colaboradores en la empresa. </p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={bienestar_empresarial} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                  */}
                </div>
              </div>
            </div>
            <div className="section">
              <div className="slide">
              <div className='rse-ecosistemas-terrestre'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Ecosistemas  <br/> Terrestre</h2>
                        <div className='rse_text_left'>
                          <h3>Medio ambiente </h3>
                          <p>Promover y coordinar acciones contra el cambio climático.</p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={bienestar_empresarial} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                */}
                </div>
              </div>
              <div className="slide">
                <div className='rse-ecosistemas-terrestre'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Ecosistemas  <br/> Terrestre</h2>
                        <div className='rse_text_left'>
                          <h3>Medio ambiente </h3>
                          <p>Promover y coordinar acciones contra el cambio climático.</p>
                          <p>Contar con prácticas para prevenir la contaminación de cualquier tipo, mitigando riesgos ambientales significativos de nuestras operaciones y minimizando sus impactos.</p>
                          <p>Promover dentro y fuera de la organización una cultura de respeto al medio ambiente y el cuidado de los recursos naturales</p>
                        </div>

                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={bienestar_empresarial} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                  */}
                </div>
              </div>
            
            </div>
            <div className="section">
              <div className="slide">
              <div className='rse-ecosistemas-marinos'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Ecosistemas  <br/> Marinos</h2>
                        <div className='rse_text_left'>
                          <h3>Objetivo</h3>
                          <p>Mitigar el impacto que genera nuestra actividad en el medio ambiente.</p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <img src={bienestar_empresarial} alt='economina y sociedad sostenibilidad baja ferries' />
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                */}
                </div>
              </div>
              <div className="slide">
                <div className='rse-ecosistemas-marinos'>
                  <div className='container'>
                    <div className='rse-economia-sociedad-content'>
                      <div className='rse-economia-sociedad-side-left'>
                        <h2>Ecosistemas  <br/> Marinos</h2>
                        <div className='rse_text_left'>
                          <h3>Objetivos</h3>
                          <p> Mitigar el impacto que genera nuestra actividad en el medio ambiente.</p>
                        </div>
                        <button onClick={() => fullpageApi.moveSlideRight()} className='btn_readmore_rse'>
                          Leer más
                        </button>                       
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <div className='rse_text_left'>                            
                            <p>Contribuir al bienestar de los ecosistemas costeros y marinos.</p>                            
                            <p>Promover la actuación de las empresas y la sociedad en general para contribuir a la prevención ypreservación de nuestros ecosistemas terrestres y marinos.</p>
                          </div>
                      </div>
                    </div>
                  </div>
                  {/* 
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSlideLeft()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                  <div className='btn_right_rse'>
                    <button onClick={() => fullpageApi.moveSlideRight()}>
                      <i class="fi fi-rs-arrow-right"></i>
                    </button>
                  </div>
                  <div className='btn_bottom_rse'>
                    <button onClick={() => fullpageApi.moveSectionDown()}>
                      <i class="fi fi-rr-arrow-down"></i>
                    </button>                                       
                  </div>
                  */}
                </div>
              </div>
            
            </div>
            <div className="section">
              <div className="slide">
              <div className='rse-form'>
                  <div className='container'>
                    <div className='rse-form-content'>
                      <div className='rse-form-side-left'>
                        <div className='rse-form-box-img'>
                          <img src={logoRSE} alt='somos agentes de cambio' />
                        </div>
                        <h3>Regístrate y súmate a las acciones por un mundo sustentable</h3>
                        <div className='rse_text_left_form'>
                          <div className='rse_form_box_input'>
                            <input type='email' placeholder='Correo'/>
                          </div>
                          <div className='rse_form_box_input'>
                            <input type='password' placeholder='Contraseña'/>
                          </div>
                          <div className='rse_form_box_input'>
                            <button>Registrar</button>
                          </div>                          
                        </div>                                          
                      </div>
                      <div className='rse-economia-sociedad-side-right'>
                        <div className='rse_text_left'>
                          <div className='rse_form_text_box'>
                            <label>Somos </label><br/>
                            <label>#agentesdecambio</label>
                            <p>En Baja Ferries estamos comprometidos con la responsabilidad social.</p>
                            <p>Con nuestras acciones buscamos generar valor en las comunidades donde operamos, cuidando de la sociedad, la economia y el medio hambiente.</p>
                            <p>Unete a nuestros proyectos de sustentabilidad ¡Próximamente!</p>
                          </div>
                        </div> 
                      </div>
                    </div>                    
                  </div>  
                  {/*                
                  <div className='btn_left_rse'>
                    <button onClick={() => fullpageApi.moveSectionUp()}>
                      <i class="fi fi-rr-arrow-left"></i>
                    </button>                                       
                  </div>
                */}
                </div>
              </div>

            </div>
          </ReactFullpage.Wrapper>
        );
      }}
    />
    </>
  );
};

export default Sostenibilidad;