import React, { useState, useEffect } from 'react'
import '../css/ruta_prueba.css'
import '../css/callToAction.css'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import '../css/reservaFija.css'
import iconAlimentos from '../assets/images/iconRutasHorario/alimentos.svg'
import iconVehiculo from '../assets/images/iconRutasHorario/vehiculo.svg'
import iconCabina from '../assets/images/iconRutasHorario/cabina.svg'
import iconEquipaje from '../assets/images/iconRutasHorario/maleta.svg'
import iconCarga from '../assets/images/iconRutasHorario/camioncito.svg'
import iconAsientos from '../assets/images/iconRutasHorario/asientos.svg'
import iconMascota from '../assets/images/iconRutasHorario/mascota.svg'
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import Footer from '../components/Footer'
import ReservaVertical from '../components/form/ReservaVertical';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
// Token de acceso de Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kaHJhIiwiYSI6ImNsbDJnZTJjbzJnMHAzZW15YnhhOWJkOGEifQ.pP3n_58agJfYHp7fUzrUbw';


const TabRutas = ({ label, onClick, activo, icon }) => {
    return (
    <div className={`tabRutas ${activo ? 'activo' : ''}`} onClick={onClick}>
        {label}
        {icon}
    </div>
    );
};


export default function Rutas_prueba  () {
    const [isRutaVisible, setIsRutaVisible] = useState(false);
    const [rutaVisible, setRutaVisible] = useState(false);
    const [mostrarServicios, setMostrarServicios] = useState(false);
    const [selectedOption, setSelectedOption] = useState('La Paz - Topolobampo');
    const [currentDate, setCurrentDate] = useState(new Date());
    const daysAbreviado = ['DOM', 'LUN', 'MAR', 'MIE', 'JUE', 'VIE', 'SAB'];
    const days = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
    const [selectedDay, setSelectedDay] = useState (null);
    const [selectedDayName, setSelectedDayName] = useState (null);
    const [selectedRouteDetails, setSelectedRouteDetails] = useState(null);
    const [showHorario, setShowHorario] = useState (true)
    const [rutaImagen, setRutaImagen] = useState('');
    const defaultImage = '/images/rutas/mapa.webp';
    const [activoTab, setactivoTab] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);//fecha seleccionada

	const [isPasajeroVisible, setIsPasajeroVisible] = useState(false);
	const [cantidadPasajero, setCantidadPasajero] = useState('1');
	const [ruta, setRuta] = useState('La Paz - Topolobampo');
	const[rutaOrigen, setOrigen] = useState('P~PIC');
	const[rutaDestino, setDestino] = useState('P~TPB');
	const [selectedTravelType, setSelectedTravelType] = useState('RETURN'); // Nuevo estado
    const [selectedDayIndex, setSelectedDayIndex] = useState(null);
    const [formData, setFormData] = useState({
		origen: rutaOrigen,
		destino: rutaDestino,
		travelType: 'RETURN', // Establece el valor predeterminado a "RETURN"
		fechaIda: '',
		fechaVuelta: '',		
	});

    /*Maps*/
    const [map, setMap] = useState(null);
    const [markers, setMarkers] = useState([]);
    /**/


    const handleTabClick = (index) => {
        setactivoTab(index);
    };

    const routesData = {
        Monday: [
        {
            routeName: 'La Paz - Topolobampo',
            schedule: {
            boarding: '12:00',
            departure: '13:30',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Topolobampo - La Paz',
            schedule: {
            boarding: '22:30',
            departure: '23:59',
            duration: '08:00 hrs'
            }
        }],
        Tuesday: [
        {
            routeName: 'La Paz - Topolobampo',
            schedule: {
            boarding: '12:00',
            departure: '13:30',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Topolobampo - La Paz',
            schedule: {
            boarding: '22:30',
            departure: '23:59',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'La Paz - Mazatlan',
            schedule: {
            boarding: '18:00',
            departure: '19:00',
            duration: '12:00 hrs'
            }
        } ],
        Wednesday: [
        {
            routeName: 'La Paz - Topolobampo',
            schedule: {
            boarding: '12:00',
            departure: '13:30',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Topolobampo - La Paz',
            schedule: {
            boarding: '22:30',
            departure: '23:59',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Mazatlan - La Paz',
            schedule: {
            boarding: '25:00',
            departure: '16:00',
            duration: '12:00 hrs'
            }
        }],
        Thursday: [
        {
            routeName: 'La Paz - Topolobampo',
            schedule: {
            boarding: '12:00',
            departure: '13:30',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Topolobampo - La Paz',
            schedule: {
            boarding: '22:30',
            departure: '23:59',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'La Paz - Mazatlan',
            schedule: {
            boarding: '18:00',
            departure: '19:00',
            duration: '12:00 hrs'
            }
        }],
        Friday: [
        {
            routeName: 'La Paz - Topolobampo',
            schedule: {
            boarding: '12:00',
            departure: '13:30',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Topolobampo - La Paz',
            schedule: {
            boarding: '22:30',
            departure: '23:59',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Mazatlan - La Paz',
            schedule: {
            boarding: '25:00',
            departure: '16:00',
            duration: '12:00 hrs'
            }
        }],
        Saturday: [
        {
            routeName: 'La Paz - Topolobampo',
            schedule: {
            boarding: '22:00',
            departure: '23:00',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'La Paz - Mazatlan',
            schedule: {
            boarding: '18:00',
            departure: '19:00',
            duration: '12:00 hrs'
            }
        }],            
        Sunday: [
        {
            routeName: 'Topolobampo - La Paz',
            schedule: {
            boarding: '22:00',
            departure: '23:00',
            duration: '08:00 hrs'
            }
        },
        {
            routeName: 'Mazatlan - La Paz',
            schedule: {
            boarding: '25:00',
            departure: '16:00',
            duration: '12:00 hrs'
            }
        }],
    };

    const toggleIsRutaVisible = () => {
        setIsRutaVisible(!isRutaVisible)
    };

    const toggleRutaVisible = () => {
        setRutaVisible(!rutaVisible)
    };

    // Utiliza routesDetails en la función handleOptionClick para determinar qué detalles de la ruta mostrar
    const handleOptionClick = (option) => {
        setSelectedOption(option);
        setIsRutaVisible(!isRutaVisible)
       
    };

    // Inicializa selectedDayName en el día actual utilizando useEffect
    useEffect(() => {
        const currentDayName = currentDate.toLocaleString('en-us', { weekday: 'long' });
        setSelectedDayName(currentDayName);
        setRutaImagen(defaultImage);
    }, []);
/*
    const getWeekDaysList = () => {
        const today = currentDate;
        const weekDaysList = [];

        for (let i = 0; i < 7; i++) {
            const day = new Date(today.getFullYear(), today.getMonth(), today.getDate() - today.getDay() + i);
             
            // Verifica si el día actual coincide con el día en la lista
            const isCurrentDay = day.toDateString() === today.toDateString();
            
            weekDaysList.push(
                <div className={`day_list ${isCurrentDay ? 'current_day' : ''}`} key={i} onClick={() => toggleDetails(day)}>
                    <span className='diaAbreviado'>{daysAbreviado[i]}</span>{' '}
                    <span className='numeroDia'>{day.getDate()}</span>                    
                </div>
            );

          }

        return weekDaysList;
    };
    */
/*
    const getWeekDaysList = () => {
        const today = new Date(); // Obtener la fecha actual
        const weekDaysList = [];
    
        // Encuentra el primer día de la semana (lunes)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + 1);
    
        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeek);
            day.setDate(startOfWeek.getDate() + i);
    
            const isCurrentDay = day.toDateString() === today.toDateString();
            const isSelectedDay = selectedDayIndex === i;
    
            weekDaysList.push(
                <div className={`day_list ${isCurrentDay ? 'current_day' : ''} ${isSelectedDay ? 'selected_day' : ''}`} key={i} onClick={() => toggleDetails(day, i)}>
                    <span className='diaAbreviado'>{daysAbreviado[(i + 1) % 7]}</span>{' '}
                    <span className='numeroDia'>{day.getDate()}</span>                    
                </div>
            );
        }
    
        return weekDaysList;
    };
    
    */
    /*

    const handlePreviousWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 7);
        setCurrentDate(newDate);
    };

    const handleNextWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 7);
        setCurrentDate(newDate);
    };*/
    /*
    const handlePreviousWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() - 7);
        setCurrentDate(newDate);
    };
    */
    const handlePreviousWeek = () => {
        setCurrentDate(new Date()); // Establecer la fecha actual
    };
    const handleNextWeek = () => {
        const newDate = new Date(currentDate);
        newDate.setDate(currentDate.getDate() + 7);
        setCurrentDate(newDate);
    };
    
    const getWeekDaysList = () => {
        const today = new Date(currentDate); // Utiliza currentDate
        const weekDaysList = [];
        
        // Encuentra el primer día de la semana (lunes)
        const startOfWeek = new Date(today);
        startOfWeek.setDate(today.getDate() - today.getDay() + 1);
        
        for (let i = 0; i < 7; i++) {
            const day = new Date(startOfWeek);
            day.setDate(startOfWeek.getDate() + i);
        
            const isCurrentDay = day.toDateString() === today.toDateString();
            const isSelectedDay = selectedDayIndex === i;
        
            weekDaysList.push(
                <div className={`day_list ${isCurrentDay ? 'current_day' : ''} ${isSelectedDay ? 'selected_day' : ''}`} key={i} onClick={() => toggleDetails(day, i)}>
                    <span className='diaAbreviado'>{daysAbreviado[(i + 1) % 7]}</span>{' '}
                    <span className='numeroDia'>{day.getDate()}</span>                    
                </div>
            );
        }
        
        return weekDaysList;
    };
    
/*
    
    const toggleDetails = (day, index) => {
        const selectedDayName = day.toLocaleString('en-us', { weekday: 'long' });
        setSelectedDay(day);
        setSelectedDayName(selectedDayName);
        setSelectedDayIndex(index);
        setSelectedDate(day); // Actualiza el estado con la fecha seleccionada
    };
    */
    const toggleDetails = (day, index) => {
        const selectedDayName = day.toLocaleString('en-us', { weekday: 'long' });
        setSelectedDay(day);
        setSelectedDayName(selectedDayName);
        setCurrentDate(day); // Actualiza la fecha actual al día seleccionado por el usuario
        setSelectedDayIndex(index);
    };
    

    const getCurrentDate = () => {
        const today = currentDate;
        const currentDay = days[today.getDay()];
        const currentMonth = today.toLocaleString('default', { month: 'long' });
        const currentYear = today.getFullYear();

        return (
            <div>
                <span>{currentDay}</span>{' '}
                <span>{today.getDate()} </span>
                <span className=''> de{' '} {currentMonth.toLowerCase()} </span> 
                <span className=''>{currentYear}</span>
            </div>
        );
    };

    const getCurrentWeek = () => {
        const today = currentDate;
        const dayOfWeek = today.getDay();
        const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek - 1));
        const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - dayOfWeek));

        const weekStartDay = startOfWeek.getDate();
        const weekStartMonth = startOfWeek.toLocaleString('default', { month: 'long' });
        const weekEndDay = endOfWeek.getDate();
        const weekEndMonth = endOfWeek.toLocaleString('default', { month: 'long' });
        const weekNumber = getWeekNumber(today);

        //return `Lun ${weekStartDay} - Dom ${weekEndDay} de ${weekStartMonth} ${today.getFullYear()}`;
        return `Semana ${weekNumber}, Lun ${weekStartDay} - Dom ${weekEndDay}`;
    };
    
    const getCurrentWeekPhone = () => {
        const today = currentDate;
        const dayOfWeek = today.getDay();
        const startOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - (dayOfWeek - 1));
        const endOfWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() + (7 - dayOfWeek));

        const weekStartDay = startOfWeek.getDate();
        const weekStartMonth = startOfWeek.toLocaleString('default', { month: 'long' });
        const weekEndDay = endOfWeek.getDate();
        const weekEndMonth = endOfWeek.toLocaleString('default', { month: 'long' });
        const weekNumber = getWeekNumber(today);

        //return `Lun ${weekStartDay} - Dom ${weekEndDay} de ${weekStartMonth} ${today.getFullYear()}`;
        return `Lun ${weekStartDay} - Dom ${weekEndDay}  Semana ${weekNumber}`;
        
    };
  
    const getWeekNumber = (date) => {
        const onejan = new Date(date.getFullYear(), 0, 1);
        return Math.ceil((((date - onejan) / 86400000) + onejan.getDay() + 1) / 7);
    };
		//FormData
    const reservation = (e) => {
        e.preventDefault();

        console.log('Ruta Agregada', formData);

        // Realiza aquí la acción que desees con los datos del formulario
        console.log(formData);
        if (selectedTravelType === 'RETURN') {
            // Acciones específicas para el tipo de viaje de "RETURN"
            window.open(
                `https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&cw_journeysearch_j1_passengers[1][ctg]=ADL&cw_journeysearch_j1_passengers[1][qty]=1&cw_journeysearch_j2_passengers[1][ctg]=ADL&cw_journeysearch_j2_passengers[1][qty]=1&bookingFlow=RETURN&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}&cw_journeysearch_j2_date=${formData.fechaVuelta}T00:00:00&cw_journeysearch_j2_route=${formData.origen}-${formData.destino}`	
            );

        } else if (selectedTravelType === 'ONEWAY') {
            // Acciones específicas para el tipo de viaje de "ONEWAY"
            window.open(
                `https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&cw_journeysearch_j1_passengers[1][ctg]=ADL&cw_journeysearch_j1_passengers[1][qty]=1&cw_journeysearch_j2_passengers[1][ctg]=ADL&cw_journeysearch_j2_passengers[1][qty]=1&bookingFlow=ONEWAY&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}`	
            );
        }                    
    };

    const handleChangeReservation = e => {		
        const name = e.target.name;
        const value = e.target.value;

        if (name === 'travelType') {
            setSelectedTravelType(value);
        }

        //console.log(e.target.name, e.target.value);
        setFormData({...formData,[name]: value});
    };
    const handleMostrarServicios = () => {
        setMostrarServicios(!mostrarServicios);    
    };

    /* Function maps */
    // Función para mostrar la ruta seleccionada
    const showRoute = (coordinates) => {
        
        if (map) {
        // Lógica para mostrar la ruta en el mapa
        map.getSource('route').setData({
            type: 'FeatureCollection',
            features: [
            {
                type: 'Feature',
                geometry: {
                type: 'LineString',
                coordinates: coordinates
                }
            }
            ]
        });

         // Agregar marcadores solo en la primera y última coordenada
         const startMarker = new mapboxgl.Marker().setLngLat(coordinates[0]).addTo(map);
         const endMarker = new mapboxgl.Marker().setLngLat(coordinates[coordinates.length - 1]).addTo(map);
 
         // Guardar los marcadores para poder limpiarlos más tarde
         setMarkers([startMarker, endMarker]);

        }
    };

    // Limpiar marcadores
    const clearMarkers = () => {
        markers.forEach(marker => marker.remove());
        setMarkers([]);
    };

  // Manejar la selección del usuario
  const handleRouteSelection = (route) => {
    clearMarkers(); // Limpiar los marcadores anteriores
    setSelectedOption(route);
    setIsRutaVisible(!isRutaVisible)

    switch (route) {
      case 'La Paz - Topolobampo':        
        showRoute([
            [-110.327473, 24.275435],
            [-110.327650, 24.274789],
            [-110.327678, 24.273909],
            [-110.327487, 24.267109],
            [-110.327510, 24.259804],
            [-110.328043, 24.257397],
            [-110.336970, 24.246213],
            [-110.338420, 24.245689],
            [-110.341144, 24.246395],
            [-110.345243, 24.250474],
            [-110.353965, 24.266607],
            [-110.357039, 24.289822],
            [-110.359388, 24.309069],
            [-110.350219, 24.354059],

            [-110.348830, 24.358184],
            [-110.344600, 24.363167],
            [-110.340006, 24.366954],
            [-110.328264, 24.373530],
            [-110.166521, 24.480128],
            [-110.076424, 24.571724],
            [-109.968686, 24.686899],
            [-109.191069, 25.508345],
            [-109.166063, 25.527144],
            [-109.134978, 25.551045],
            [-109.131329, 25.553301],
            [-109.128356, 25.554276],
            [-109.115381, 25.555861],
            [-109.095109, 25.558970],
            [-109.062628, 25.575868],
            [-109.056119, 25.579721],
            [-109.055033, 25.582511],
            [-109.054471, 25.585714],
            [-109.054762, 25.588164],
            [-109.054936, 25.588794],
            [-109.055790, 25.589529],
            [-109.058662, 25.590877],

        ]);
        break;
      case 'La Paz - Mazatlan':
        showRoute([ 
            [-110.327473, 24.275435],
            [-109.058662, 25.590877],
         ]);
        break;
        case 'Topolobampo - La Paz':        
        showRoute([
            [-110.327473, 24.275435],
            [-110.327650, 24.274789],
            [-110.327678, 24.273909],
            [-110.327487, 24.267109],
            [-110.327510, 24.259804],
            [-110.328043, 24.257397],
            [-110.336970, 24.246213],
            [-110.338420, 24.245689],
            [-110.341144, 24.246395],
            [-110.345243, 24.250474],
            [-110.353965, 24.266607],
            [-110.357039, 24.289822],
            [-110.359388, 24.309069],
            [-110.350219, 24.354059],

            [-110.348830, 24.358184],
            [-110.344600, 24.363167],
            [-110.340006, 24.366954],
            [-110.328264, 24.373530],
            [-110.166521, 24.480128],
            [-110.076424, 24.571724],
            [-109.968686, 24.686899],
            [-109.191069, 25.508345],
            [-109.166063, 25.527144],
            [-109.134978, 25.551045],
            [-109.131329, 25.553301],
            [-109.128356, 25.554276],
            [-109.115381, 25.555861],
            [-109.095109, 25.558970],
            [-109.062628, 25.575868],
            [-109.056119, 25.579721],
            [-109.055033, 25.582511],
            [-109.054471, 25.585714],
            [-109.054762, 25.588164],
            [-109.054936, 25.588794],
            [-109.055790, 25.589529],
            [-109.058662, 25.590877],

        ]);
        break;
      case 'Mazatlan - La Paz':
        showRoute([ 
            [-110.327473, 24.275435],
            [-109.058662, 25.590877],
         ]);
        break;
      default:
        break;
    }
  };

 // Crear el mapa cuando el componente se monte
 React.useEffect(() => {
  const initializeMap = () => {
    const mapInstance = new mapboxgl.Map({
      container: 'map',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [-108.931, 25.177],
      zoom: 5.5
    });

    // Agregar la fuente y la capa para mostrar la ruta
    mapInstance.on('load', () => {
      mapInstance.addSource('route', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: []
        }
      });

      mapInstance.addLayer({
        id: 'route',
        type: 'line',
        source: 'route',
        layout: {
          'line-join': 'round',
          'line-cap': 'round'
        },
        paint: {
          'line-color': '#888',
          'line-width': 8
        }
      });
       // Mostrar la ruta por defecto
       showRoute([-110.3108, 24.1426], [-109.0645, 25.6019]); // Aquí colocas las coordenadas de la ruta por defecto
    });

    setMap(mapInstance);
  };

  // Inicializar el mapa
  initializeMap();

  // Limpiar el mapa cuando el componente se desmonte
  return () => map?.remove();

}, []);
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Rutas y Horarios</h3>
                        <p>Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!</p>
                    </div>                
                </div>
            </div>
        </section>

        <section className="d-none d-sm-none d-md-block">
           <div className='ruta_horario'>
            <div className='container'>
                <div  className='booking_fijo'>
                    <div className=''>
                        <form className='formBooking_fijo' onSubmit={reservation}> 
                        {/* Radio buttons */}
                            <div className='radioBooking_fijo'>
                                <div className='radioButons'>
                                    <input type="radio" name="travelType" value="RETURN"  className='radio'  checked={selectedTravelType === 'RETURN'} onChange={handleChangeReservation} />
                                    <label>Ida y vuelta</label>
                                </div>
                                <div><label>|</label></div>
                                <div className='radioButons'>
                                    <input type="radio" name="travelType" value="ONEWAY" className='radio' onChange={handleChangeReservation}/>
                                    <label>Solo Ida</label>
                                </div>
                            </div>	
                            
                            <div className='horizontal_form'>
                                {/* Origen - Destino */}
                                <div>
                                    <div className='box_select_ruta_horario' >
                                        <div className='select_ruta_horario' onClick={toggleRutaVisible} >
                                            <div className='icon_select_ruta_horario'>
                                                <i class="fi fi-ss-ship"></i>
                                            </div>
                                            <div className='select_ruta'>
                                                <label>Ruta</label>
                                                <label>{selectedOption}</label>
                                            </div>
                                            <div className='icon_select_ruta_horario'>
                                                <i class="fi fi-sr-angle-square-down"></i>
                                            </div>
                                        </div>    
                                        <div>
                                            {rutaVisible && 
                                            <div className='optionListRoutes'>
                                                <div className='optionRoutes' onClick={() => handleOptionClick('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                                <div className='optionRoutes' onClick={() => handleOptionClick('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                                <div className='optionRoutes' onClick={() => handleOptionClick('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                                <div className='optionRoutes' onClick={() => handleOptionClick('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                
                                {/* Inputs Fecha */}
                                {/* mostrarCamposFecha && */ formData.travelType ==='RETURN' && (
                                <>
                                    <div className='box_select_ruta_horario' >
                                        <div className='select_ruta_horario'>
                                            <div className='icon_select_ruta_horario'>
                                                <i class="fi fi-sr-calendar-check"></i>
                                            </div>
                                            <div className='select_ruta'>
                                                <label>Salida</label>
                                                <div className='contentBooking_horizontal'>
                                                    <div className='box_fechaSalida_fija'>
                                                        {/*<label>Ida</label>	*/}				
                                                        <input type="date" name="fechaIda" min={getTodayDate()} onChange={handleChangeReservation}  />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            <div className='icon_select_ruta_horario'>
                                                <i class="fi fi-sr-angle-square-down"></i>
                                            </div>
                                        </div>  							                                    
                                    </div>

                                    <div className='box_select_ruta_horario' >
                                        <div className='select_ruta_horario'>
                                            <div className='icon_select_ruta_horario'>
                                                <i class="fi fi-sr-calendar-check"></i>
                                            </div>
                                            <div className='select_ruta'>
                                                <label>Llegada</label>
                                                <div className='contentBooking_horizontal'>

                                                    <div className='box_fechaRegreso_fija'>
                                                        {/*<label>Regreso</label>*/}
                                                        <input type="date" name="fechaVuelta" min={getTodayDate()} onChange={handleChangeReservation}  />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='icon_select_ruta_horario'>
                                                <i class="fi fi-sr-angle-square-down"></i>
                                            </div>
                                        </div>                                    
                                    </div>
                                </>                        
                                )}

                                {/* mostrarCamposFecha && */ formData.travelType === 'ONEWAY' && (
                                <div className='contentBooking_horizontal'>
                                    <div className='box_fechaSoloIda_fija'>
                                        {/*<label>Salida</label> */}
                                        <div className='box_select_ruta_horario' >
                                            <div className='select_ruta_horario'>
                                                <div className='icon_select_ruta_horario'>
                                                    <i class="fi fi-sr-calendar-check"></i>
                                                </div>
                                                <div className='select_ruta'>
                                                    <label>Salida</label>
                                                    <div className='contentBooking_horizontal'>

                                                        <div className='box_fechaRegreso_fija'>
                                                            {/*<label>Ida</label>*/}
                                                            <input type="date" name="fechaIda" min={getTodayDate()} onChange={handleChangeReservation}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='icon_select_ruta_horario'>
                                                    <i class="fi fi-sr-angle-square-down"></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>        
                                )}
            {/*}
                                <div>
                                    <div className='box_select_ruta_horario' >
                                        <div className='select_ruta_horario' onClick={toggleIsPasajeroVisible} >
                                            <div className='icon_select_ruta_horario'>
                                            <i class="fi fi-sr-person-simple"></i>
                                            </div>
                                            <div className='select_ruta'>
                                                <label>Pasajeros</label>
                                                <label>{cantidadPasajero}</label>
                                            </div>
                                            <div className='icon_select_ruta_horario'>								
                                                <i class="fi fi-sr-angle-square-down"></i>
                                            </div>
                                        </div>    
                                        <div>
                                            {isPasajeroVisible && 
                                            <div className='optionListPasajeros'>
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('1')}>1</div>        
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('2')}>2</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('3')}>3</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('4')}>4</div>    
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('5')}>5</div>        
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('6')}>6</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('7')}>7</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('8')}>8</div> 
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('9')}>9</div>        
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('10')}>10</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('11')}>11</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('12')}>12</div>     
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('13')}>13</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('14')}>14</div>    
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('15')}>15</div>        
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('16')}>16</div>   
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('17')}>17</div>
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('18')}>18</div> 
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('19')}>19</div>        
                                                <div className='optionPasajero' onClick={() => handlePasajeroClick('20')}>20 o más</div>                    
                                            </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                        */}
                                <div>
                                <div className='box_select_ruta_horario' >
                                   
                                        <div className='select_ruta_horario'>
                                        
                                            <div className='icon_select_ruta_horario'>
                                            <i class="fi fi-ss-ticket"></i>
                                            </div>
                                            <div className='select_ruta'>
                                                <label>Código de promoción </label>
                                                <div className='contentBooking_horizontal'>
                                                    <div className='box_fechaSalida_fija'>
                                                        {/*<label>Codigo de promocion</label>	*/}				
                                                        <input type="text" name="code" />
                                                    </div>
                                                    
                                                </div>
                                            </div>
                                            
                                        </div>  							
                                        
                                    </div>
                                </div>

                                <div className='contentBooking_horizontal btnBuscar_fijo'>																
                                    <button type='submit' className='btnBuscarViaje'>  
                                        <div className='textPlaceholder_fijo'>
                                            <span class="textBuscar">Reservar </span> 
                                        </div>	
                                    </button>																															
                                </div> 
                            </div>
                        </form>
                    </div>
                    </div>
                    <div className='routes_schedules'>
                        <div className='box_content_ruta_horario'>
                            <div className='tabs_routes_schedules'>
                                <div className="tab-container-routes-schedules">
                                    <TabRutas
                                    label={( 
                                        <div>
                                            <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                                                <div className='select_ruta'>
                                                    <label>Selecciona una ruta</label>
                                                    <label>{selectedOption} <i class="fi fi-br-angle-small-down"></i></label>
                                                </div>      
                                            </div>
                                            {isRutaVisible && 
                                                <div className='optionListRoutes'>
                                                    <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                                    <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                                    <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                                    <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                                                </div>
                                            }                                                                                                                                                                                                  
                                        </div>
                                    )}
                                    onClick={() => handleTabClick(0)}
                                    activo={activoTab === 0}
                                    />
                                    
                                </div>
                                <div className="content-container-ruta_horario">
                                    {activoTab === 0 && 
                                    <div className='info_ruta'>
                                        <div className='info_ruta_title_services'>
                                            <div className='info_ruta_title'>
                                                {/*<h4>{getCurrentDate()}  </h4>
                                                <div className='horarios_ruta_horario_title'>
                                                    <h4><i class="fi fi-rr-track"></i> {selectedOption}</h4>
                                                </div> 
                                                
                                                <div>
                                                    <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                                                        <div className='select_ruta'>
                                                            <label>Selecciona una ruta</label>
                                                            <label>{selectedOption} <i class="fi fi-br-angle-small-down"></i></label>
                                                        </div>      
                                                    </div>
                                                    {isRutaVisible && 
                                                        <div className='optionListRoutes'>
                                                            <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                                            <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                                            <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                                            <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                                                        </div>
                                                    }                                                                                                                                                                                                  
                                                </div>
                                                */}
                                            </div>
                                            <div className='info_ruta_services'>
                                                <h3 onClick={handleMostrarServicios} className='mostrar_servicios'>Mostrar servicios <i class="fi fi-rr-angle-small-down"></i></h3>                                                                                        
                                            </div>
                                        </div>
                                        <div>
                                        {mostrarServicios && 
                                                    <div className='list_ruta_services'>
                                                    <div className='icons_ruta_services'>
                                                        <div className='icon_ruta_services'>
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconAlimentos} alt='' />
                                                            </div>                                                         
                                                            <p>Alimentos y bebidas</p>
                                                        </div>
                                                        <div className='icon_ruta_services'>
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconVehiculo} alt='' />
                                                            </div>                                                          
                                                            <p>Viaja con tu vehiculo</p>
                                                        </div>
                                                        <div className='icon_ruta_services'>
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconCabina} alt='' />
                                                            </div>                                                           
                                                            <p>Viaja en cabina</p>
                                                        </div>                                                                                                                                                            
                                                    </div>
                                                    <div className='icons_ruta_services'>
                                                        <div className='icon_ruta_services'>                                                         
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconEquipaje} alt='' />
                                                            </div> 
                                                            <p>Equipaje documentada</p>
                                                        </div>
                                                        <div className='icon_ruta_services'>                                                         
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconCarga} alt='' />
                                                            </div> 
                                                            <p>Viaja con carga</p>
                                                        </div>
                                                        <div className='icon_ruta_services'>
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconAsientos} alt='' />
                                                            </div>                                                          
                                                            <p>Alimentos y bebidas</p>
                                                        </div> 
                                                        <div className='icon_ruta_services'>
                                                            <div className='icon_ruta_services_img'>
                                                                <img src={iconMascota} alt='' />
                                                            </div>                                                            
                                                            <p>Viaja con tu mascota</p>
                                                        </div>   
                                                        
                                                    </div>
                                                </div>
                                                }  
                                        </div>

                                        
                                        <h4>{getCurrentDate()}  </h4>                                       
                                        <div>
                                            <div className='list_ruta_horario'>
                                                <ul>
                                                    {getWeekDaysList().map((day, index) => (
                                                        <li key={index}>{day}</li>
                                                    ))}
                                                </ul>                                               
                                            </div>
                                            <div className='box_btn_ruta_horario'>
                                                <div className='btn_ruta_horario'>
                                                    <button onClick={handlePreviousWeek}><i class="fi fi-rr-angle-circle-left"></i> Hoy</button>
                                                    <button onClick={handleNextWeek}>Semana siguiente <i class="fi fi-rr-angle-circle-right"></i></button>
                                                </div>                                                
                                            </div>
                                        </div>  
                                        {/*
                                        <div className='horarios_list'>
                                        
                                            <div className='horarios_ruta_horario'>                                                                                                                                                                                               
                                                <div className='content_horarios_list'>
                                                    {routesData[selectedDayName] && routesData[selectedDayName].length > 0 ? (
                                                        routesData[selectedDayName].map((route, index) => {
                                                            const normalizedRouteName = route.routeName.trim().toLowerCase();
                                                            const normalizedSelectedOption = selectedOption.trim().toLowerCase();
                                                            if (normalizedRouteName === normalizedSelectedOption) {
                                                                return (
                                                                    <div key={index} className='horario_list'>
                                                                        <div className='box_horario_list'>
                                                                            <label>Embarque</label>
                                                                            <p>{route.schedule.boarding}</p>
                                                                        </div>
                                                                        <div className='box_horario_list'>
                                                                            <label>Salida</label>
                                                                            <p>{route.schedule.departure}</p>
                                                                        </div>
                                                                        <div className='box_horario_list'>
                                                                            <label>Duración aprox.</label>
                                                                            <p>{route.schedule.duration}</p>
                                                                        </div>
                                                                    </div>
                                                                   
                                                                ) 
                                                            }else{
                                                                
                                                                <p>No hay viajes disponibles para este día.</p>
                                                                
                                                            }
                                                            return null;
                                                        })
                                                    ) : (
                                                        <p>No hay viajes disponibles para este día.</p>
                                                    )}
                                                </div>                                                                                                                                                                                                                      
                                            </div>                 
                                        </div>   
                                                    */}
<div className='horarios_list'>
    <div className='horarios_ruta_horario'>
        <div className='content_horarios_list'>
            {routesData[selectedDayName] && routesData[selectedDayName].length > 0 && routesData[selectedDayName].some(route => route.routeName.trim().toLowerCase() === selectedOption.trim().toLowerCase()) ? (
                routesData[selectedDayName]
                    .filter(route => route.routeName.trim().toLowerCase() === selectedOption.trim().toLowerCase())
                    .map((route, index) => (
                        <div key={index} className='horario_list'>
                            <div className='box_horario_list'>
                                <label>Embarque</label>
                                <p>{route.schedule.boarding}</p>
                            </div>
                            <div className='box_horario_list'>
                                <label>Salida</label>
                                <p>{route.schedule.departure}</p>
                            </div>
                            <div className='box_horario_list'>
                                <label>Duración aprox.</label>
                                <p>{route.schedule.duration}</p>
                            </div>
                        </div>
                    ))
            ) : (
                <p>No hay viajes disponibles para este día.</p>
            )}
        </div>
    </div>
</div>










                               
                                    </div>}                                                                         
                                </div>
                            </div>
                        </div>
                                                
                        <div className='box_content_mapa_ruta_horario'>                                                                                    
                            <div id="map" style={{ width: '100%', height: '500px' }} />                                                                                                              
                        </div>
                    </div>
                </div>
           </div>
        </section>
        
        <section className='d-block d-sm-block d-md-none'>
        <div>
                    <ReservaVertical />
                </div>
            <div className='ruta_horario'>
                
                <div className='container'>
                
      
      
                    <div className='routes_schedules'>
                        <div className='box_content_ruta_horario'>
                            <div className='tabs_routes_schedules'>                                
                                <div className="content-container-ruta_horario">
                                    {activoTab === 0 && 
                                    <div className='info_ruta'>
                                        <div className='info_ruta_title_services'>
                                            <div className='info_ruta_title'>
                                                <h4>{getCurrentDate()}  </h4>
                                            </div>                                            
                                        </div>                                       
                                        <div>
                                            <div className='list_ruta_horario'>
                                                <ul>
                                                    {getWeekDaysList().map((day, index) => (
                                                        <li key={index}>{day}</li>
                                                    ))}
                                                </ul>
                                            </div>
                                            {/* 
                                            <div className='btn_ruta_horario'>
                                                <button onClick={handlePreviousWeek}><i class="fi fi-rr-angle-circle-left"></i> Semana Anterior</button>
                                                <button onClick={handleNextWeek}>Semana Siguiente <i class="fi fi-rr-angle-circle-right"></i></button>
                                            </div>
                                            */}
                                        </div>    

                                        <div className='semana_ruta_horario'>
                                            <div className='content_semana_ruta_horario'>
                                                {getCurrentWeekPhone()}
                                            </div>
                                            <div className='btn_semana_ruta_horario'>
                                                <button><label>Ver otra semana</label> <i class="fi fi-rr-calendar-days"></i></button>
                                            </div>
                                        </div>                                 
                                    </div>}                                                                         
                                </div>
                            </div>
                        </div>
                        
                        <div className='horarios_list'>
                        <div className='horarios_ruta_horario'>
                            <div className='horarios_ruta_horario_title'>
                                <h4><i class="fi fi-ss-track"></i>  {selectedOption}</h4>
                            </div>
                                {/*
                                {routesData[selectedDayName] &&
                                    routesData[selectedDayName].map((route, index) => {
                                        const normalizedRouteName = route.routeName.trim().toLowerCase();
                                        const normalizedSelectedOption = selectedOption.trim().toLowerCase();
                                        if (normalizedRouteName === normalizedSelectedOption) {
                                            return (
                                                <div key={index}>
                                                    <div className='box_horario_list'>
                                                        <label>Embarque</label>
                                                        <p>{route.schedule.boarding}</p>
                                                    </div> 
                                                    <div className='box_horario_list'>
                                                        <label>Salida</label>
                                                        <p>{route.schedule.departure}</p>
                                                    </div>
                                                    <div className='box_horario_list'>
                                                        <label>Duración</label>
                                                        <p>{route.schedule.duration}</p>
                                                    </div>
                                                </div>
                                            );
                                        }
                                    return null;
                                })}
                                */}
                                <div className='content_horarios_list'>
                                    {routesData[selectedDayName] && routesData[selectedDayName].length > 0 ? (
                                        routesData[selectedDayName].map((route, index) => {
                                            const normalizedRouteName = route.routeName.trim().toLowerCase();
                                            const normalizedSelectedOption = selectedOption.trim().toLowerCase();
                                            if (normalizedRouteName === normalizedSelectedOption) {
                                                return (
                                                    <div key={index}>
                                                        <div className='box_horario_list'>
                                                            <label>Embarque</label>
                                                            <p>{route.schedule.boarding}</p>
                                                        </div>
                                                        <div className='box_horario_list'>
                                                            <label>Salida</label>
                                                            <p>{route.schedule.departure}</p>
                                                        </div>
                                                        <div className='box_horario_list'>
                                                            <label>Duración</label>
                                                            <p>{route.schedule.duration}</p>
                                                        </div>
                                                    </div>
                                                );
                                            }else{
                                                if(index === 0){
                                                    <p>No hay viajes disponibles para este día.</p>
                                                }
                                            }
                                            return null;
                                        })
                                    ) : (
                                        <p>No hay viajes disponibles para este día.</p>
                                    )}
                                </div>                                                                                                                                                                                                                           
                            </div>                 
                        </div>
                        <div className='box_content_mapa_ruta_horario'>
                            {/*<img src={MapaRuta} alt='Mapa de rutas Baja Ferries' />*/}
                            {rutaImagen && <img src={rutaImagen} alt="Imagen de la ruta seleccionada" />}
                        </div>
                    </div>
                    <div className='info_ruta_services'>
                        <h3 onClick={handleMostrarServicios} className='mostrar_servicios'>Servicios de esta ruta </h3>
                        <div>
                            {mostrarServicios && 
                                    <div className='list_ruta_services'>
                                        <div className='icons_ruta_services'>
                                            <div className='icon_ruta_services'>
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconAlimentos} alt='' />
                                                </div>                                                         
                                                <p>Alimentos y bebidas</p>
                                            </div>
                                            <div className='icon_ruta_services'>
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconVehiculo} alt='' />
                                                </div>                                                          
                                                <p>Viaja con tu vehiculo</p>
                                            </div>
                                            <div className='icon_ruta_services'>
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconCabina} alt='' />
                                                </div>                                                           
                                                <p>Viaja en cabina</p>
                                            </div>                                                                                                                                                            
                                        </div>
                                        <div className='icons_ruta_services'>
                                            <div className='icon_ruta_services'>                                                         
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconEquipaje} alt='' />
                                                </div> 
                                                <p>Equipaje documentada</p>
                                            </div>
                                            <div className='icon_ruta_services'>                                                         
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconCarga} alt='' />
                                                </div> 
                                                <p>Viaja con carga</p>
                                            </div>
                                            <div className='icon_ruta_services'>
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconAsientos} alt='' />
                                                </div>                                                          
                                                <p>Alimentos y bebidas</p>
                                            </div> 
                                            <div className='icon_ruta_services'>
                                                <div className='icon_ruta_services_img'>
                                                    <img src={iconMascota} alt='' />
                                                </div>                                                            
                                                <p>Viaja con tu mascota</p>
                                            </div>   
                                            
                                        </div>
                                    </div>
                                    }  
                            </div>
                                                                
                    </div>
                </div>
           
            </div>
        </section>
                
        <section className='callToAction'>
            <div className="container">
            <div>
                <h3>¿Listo para reservar?</h3>
            </div>
            <Row>
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Consulta nuestras tarifas</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Reservar ahora</Link>                                      
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Col>   
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Preguntas frecuentes</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Consultar </Link>                                      
                                </div>
                            </div>                                  
                        </div>
                    </div>
                </Col>      
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Envianos un WhatsApp</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Te responderemos lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Enviar WhatsApp </Link>                                  
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>        
                    
            </Row>    
            </div>
           
        </section>
        <Outlet />
        <Footer />
                                    
    </>
   
  )
}
function getTodayDate() {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
  }



