import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Estilo del editor
import '../css/noticias_form.css'
import axios from 'axios';
import Quill, {Delta} from 'quill';

//flag ->

export default function NoticiasForm() {

    const [editing, setEditing] = useState (false);
    const [noticia, setNoticia] = useState({
        photo_route_header:'',
        title: '',
        description:'',
        photo_route_footer: '',
        category: {
            Marítimo: false,
            RSE: false,
            Bajaferries: false,
            Aviso: false,
            // Agrega más checkboxes
        },
        creation_date: '',
        modification_date:'',
        user_manager: '',
        creation_time:'',
        visible: true,
    })
    //const [file, setFile] = useState(null);
    //const [uploadedFileHeader, setUploadedFileHeader] = useState(null);
    //const [uploadedFileFooter, setUploadedFileFooter] = useState(null);
    const [descriptionValue, setDescriptionValue] = useState(null);
    const [isDescriptionLoaded, setIsDescriptionLoaded] = useState(true);
    const navigate = useNavigate();
    const params = useParams();

    const handleChange = (e) =>{
        setNoticia({...noticia, [e.target.name]: e.target.value});
        //console.log("Noticia: "+noticia);

        const { name, checked } = e.target;
            setNoticia((prevNoticia) => ({
                ...prevNoticia,
                category: { ...prevNoticia.category, [name]: checked },
            }));
       
        }
        
        const loadTask = async (id) => {
            /*
            const response = await fetch(`http://localhost:3004/noticias/${id}`);
            const data = await response.json();
            console.log("Data: "+data);
            const descriptionHTML = data.description ? `<p>${data.description}</p>` : '';

            setNoticia({photo_route_header: data.photo_route_header, title: data.title, description: descriptionHTML, photo_route_footer: data.photo_route_footer, category: data.category, creation_date: data.creation_date, modification_date: data.modification_date, user_manager: data.user_manager, creation_time: data.creation_time, visible: data.visible});

            console.log("Noticia: "+ data.photo_route_header,  data.title,  descriptionHTML,  data.photo_route_footer,  data.category,  data.creation_date,  data.modification_date,  data.user_manager,  data.creation_time,  data.visible);
            console.log("description: "+ data.description);
            console.log("descriptionHTML: "+ descriptionHTML);

            setEditing(true);*/
            /*
            try {
                const response = await fetch(`http://localhost:3004/noticias/${id}`);
                const data = await response.json();
            
                console.log('Data from API:', data);
            
                const photoRouteHeader = Array.isArray(data.photo_route_header) ? data.photo_route_header[0] : '';
                const descriptionHTML = Array.isArray(data.description) ? `<p>${data.description.join('')}</p>` : '';
                const photoRouteFooter = Array.isArray(data.photo_route_footer) ? data.photo_route_footer[0] : '';
            
                console.log('Photo Route Header:', photoRouteHeader);
                console.log('Description HTML:', descriptionHTML);
                console.log('Photo Route Footer:', photoRouteFooter);
                        
                setNoticia({
                    ...data, // Set other fields from data directly
                    photo_route_header: data.photo_route_header, 
                    title: data.title,
                    photo_route_footer: data.photo_route_footer, 
                    category: data.category, 
                    creation_date: data.creation_date, 
                    modification_date: data.modification_date, 
                    user_manager: data.user_manager, 
                    creation_time: data.creation_time, 
                    visible: data.visible

                });
            
                console.log('State after setting:', noticia);
            
                setEditing(true);
            } catch (error) {
            console.error('Error loading task', error);
            }*/

            try {
                const response = await fetch(`http://localhost:3001/noticias/${id}`);
                const data = await response.json();
                console.log('Noticia:', noticia);
                /*
                if (data.description && data.description.ops) {
                    const descriptionHTML = convertDeltaToHTML(data.description);
                    setNoticia({
                      ...data,
                      description: descriptionHTML,
                    });
                } else {
                    setNoticia(data);
                }
                */

                const descriptionHTML = data.description ? `<p>${data.description}</p>` : '';
                /*const descriptionText = data.description || '';
                const descriptionHTML = !descriptionText.startsWith('<p>') && !descriptionText.endsWith('</p>') ? `<p>${descriptionText}</p>` : descriptionText;*/
                setDescriptionValue(descriptionHTML);
                console.log("descriptionHTML: ", descriptionHTML);

                setNoticia({
                    photo_route_header: data.photo_route_header, 
                    title: data.title,                    
                    photo_route_footer: data.photo_route_footer, 
                    category: {
                        Marítimo: data.category.includes('Marítimo'),
                        RSE: data.category.includes('RSE'),
                        Bajaferries: data.category.includes('Bajaferries'),
                        Aviso: data.category.includes('Aviso'),
                    },
                    creation_date: data.creation_date, 
                    modification_date: data.modification_date, 
                    user_manager: data.user_manager, 
                    creation_time: data.creation_time, 
                    visible: data.visible
                });                

                console.log('1:', noticia);
                //setUploadedFileHeader(data.photo_route_header);
                setEditing(true);

            } catch (error) {
                console.error('Error loading task', error);              
            }
            console.log('2:', noticia);

        }

        const convertDeltaToHTML = (delta) => {
            const container = document.createElement('div');
            const quill = new Quill(container);
            quill.setContents(delta);
            return container.innerHTML;
        }

        const handleUploadFooter = async (e) => {
            e.preventDefault();
            //setUploadedFileFooter(e.target.files[0]);
            const formData = new FormData();
            formData.append('photo_route_footer',  e.target.files[0]);
          
            try {
              const response = await axios.post('http://localhost:3003/upload', formData);
              //console.log('Imagen (photo_route_footer) subida correctamente '+ response);

              const photoRouteFooter = response.data.photoRouteFooter;

              //setUploadedFileFooter(response.data.photo_route_footer);

              //console.log("response.data.filename: "+photoRouteFooter);

              setNoticia({ ...noticia, photo_route_footer: photoRouteFooter });
            } catch (error) {
              console.error('Error al subir la imagen', error);
            }
        };

        useEffect(() =>{
            if(params.id){
                loadTask(params.id);
            }

        },[params.id]); 

        useEffect(() => {
            if ((descriptionValue != null) && isDescriptionLoaded ) {
                setNoticia((prevNoticia) => ({
                    ...prevNoticia,
                    description: descriptionValue,
                }));

                setIsDescriptionLoaded(false);
                console.log("descriptionHTML5 Assignment: " + descriptionValue);
                console.log("noticia.description: " + noticia.description);
            }

        }, [descriptionValue, isDescriptionLoaded]);
        


            
            

        const handleUpload = async (e) => {
            e.preventDefault(); 
            
            const formData = new FormData();
            formData.append('photo_route_header',  e.target.files[0]);

            try {
                const response = await axios.post('http://localhost:3001/upload', formData);
                //console.log('Imagen (photo_route_header) subida correctamente '+ response);
                
                const photoRouteHeader = response.data.photoRouteHeader;
                
                // Asignar el valor de uploadedFileHeader a photo_route_header
                setNoticia({ ...noticia, photo_route_header: photoRouteHeader });                                

            } catch (error) {
                console.error('Error al subir la imagen', error);
                console.log('Error response:', error.response); // Agrega esta línea para obtener más información sobre el error de respuesta.
                console.log('Full error object:', error); // Muestra el objeto de error completo para obtener más detalles               
            }                        
        };

        const handleSubmit = async (e) => {
            e.preventDefault();

            const checkboxesArray = Object.keys(noticia.category).filter(checkbox => noticia.category[checkbox]);

            const noticiaToSend = {
                ...noticia,
                category: checkboxesArray,
                description: noticia.description, // Ya es contenido HTML                          
            };

            /*           
            if (noticia.description) {
                const deltaDescription = Quill.delta(JSON.parse(noticia.description));
                setNoticia({ ...noticia, description: deltaDescription });
            }
            */

            //console.log("noticia: " + noticia); 
            if(editing){
                const response =await fetch(`http://localhost:3001/noticias/${params.id}`,{
                    method:'PUT',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify(noticiaToSend),
                });
                const data = await response.json();
                console.log("Editando.." + data);
            }else{
                await fetch('http://localhost:3001/noticias',{
                    method:'POST',
                    body: JSON.stringify(noticiaToSend),
                    headers: {'Content-Type': 'application/json'},
                });

                //const data = await res.json();
            }

            //console.log(data);
            navigate("/noticialist");

        }

     

  return (

    <>
    <section className='container'>
        <p>Administrador de noticias</p>
        <hr />
    </section>

    <section className='container'>
        <div className='noticias_form_content'>
            

        
    

            <form onSubmit={handleSubmit} encType="multipart/form-data">
                <div className='form_input_noticias visible_switch'>
                    <label>Visible:</label>
                    <div className='switch'>
                        <input 
                            type='checkbox' 
                            id='visibleSwitch' 
                            name='visible' 
                            checked={noticia.visible}                                                         
                            onChange={(e) => setNoticia({...noticia, visible: e.target.checked})}
                        />
                        <label htmlFor='visibleSwitch'></label>
                    </div>
                </div>
                <div className='form_input_noticias'>
                    <label>Imagen de Encabezado:</label>
                    <label for="file" class="file-style">
                        <i class="fi fi-ss-picture"></i>
                        <input type="file" id="file" name='photo_route_header' accept="image/*" onChange={handleUpload} />
                        <p>Introduce la imagen de encabezado</p>
                    </label>                                        
                </div>
                {noticia.photo_route_header && (
                    <div className='img_uploaded'>                        
                        <img src={`../../images/noticias/${noticia.photo_route_header}`} alt="Noticia header" />  
                    </div>                      
                )}
                <div className='form_input_noticias'>
                    <label>Título:</label>
                    <input type='text' placeholder='Introduce un título' name='title' value={noticia.title} onChange={handleChange} />
                </div>
                {/*
                <div className='form_input_noticias'>
                    <label>Descripción:</label>
                    <input type='textarea' name='description' value={noticia.description} onChange={handleChange}/>
                </div>
                */}
                 {/*
                <div className='form_input_noticias'>
                <ReactQuill name='description' value={noticia.description} onChange={(content) => 
                    setNoticia({ ...noticia, description: content })}         modules={{
                        toolbar: [
                            ['bold', 'italic', 'underline', 'strike'],
                            [{ list: 'ordered' }, { list: 'bullet' }],
                            ['link', 'image'],
                            ['clean'],
                        ],
                    }}
                    //dangerouslySetInnerHTML={{ __html: noticia.description }}
            />
                </div>
            */}
                <div className='form_input_noticias'>
                    <label>Descripción:</label>
                    <ReactQuill name='description' value={noticia.description} onChange={(content) => 
                            setNoticia({ ...noticia, description: content })
                        } modules={{
                            toolbar: [
                                ['bold', 'italic', 'underline', 'strike'],
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                ['link', 'image'],
                                ['clean'],
                            ],
                        }
                    }/>

                </div>

                <div className='form_input_noticias'>
                    <label>Imagen al pie:</label>
                    <label for="fileFooter" class="file-style">
                        <i class="fi fi-ss-picture"></i>
                        <input type="file" id="fileFooter" name='photo_route_footer' accept="image/*" onChange={handleUploadFooter} />
                        <p>Introduce la imagen </p>
                    </label>                                        
                </div>
                {noticia.photo_route_footer && (
                    <div className='img_uploaded'>                        
                        <img src={`../../images/noticias/${noticia.photo_route_footer}`} alt="Noticia footer" />  
                    </div>                      
                )}
                 
                <div className='form_input_noticias'>
                    <label>Categorías:</label>
                    <div className='form_box_noticias'>
                        <label htmlFor='Marítimo'>
                        <input
                            type="checkbox"
                            name='Marítimo'
                            checked={noticia.category.Marítimo}
                            onChange={handleChange}
                        />

                            Marítimo
                        </label>
                        <label htmlFor='RSE'>
                            <input
                                type="checkbox"
                                name='RSE'
                                checked={noticia.category.RSE}
                                onChange={handleChange}
                            />
                            RSE
                        </label>
                    </div>
                    <div className='form_box_noticias'>
                        <label htmlFor='Bajaferries'>
                        <input
                                type="checkbox"
                                name='Bajaferries'
                                checked={noticia.category.Bajaferries}
                                onChange={handleChange}
                            />
                           
                            Baja ferries
                        </label>
                        <label htmlFor='Aviso'>
                        <input
                                type="checkbox"
                                name='Aviso'
                                checked={noticia.category.Aviso}
                                onChange={handleChange}
                            />
                           
                            Aviso
                        </label>
                    </div>                                        
                </div>          

                
                <div className='form_input_noticias'>
                {/* 
                <button disabled={!noticia.title || !noticia.description} >
                    Enviar
                </button>
                */}

                <button type='submit'>
                    Enviar
                </button>

                </div>
            </form>
        </div>
        
    </section>
    </>
  )
}
