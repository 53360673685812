import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/faqs.css'

import Contactanos from '../contacto/Contactanos';
import Footer from '../Footer'

/**/
import bookingIcon from '../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../assets/images/iconsPreguntas/barco-de-carga.png'
import avisos from '../../assets/images//iconsPreguntas/megafono.png'
import documentacion from '../../assets/images/iconsPreguntas/documentacion.png'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
const questions = [
    {
        id: 1,
        text: '¿Cuáles son las formas de pago?',
        answer: ( 
            <ul>
                <li>En los puntos de venta, aceptamos pagos con tarjeta de débito, tarjeta de crédito y en efectivo. En el Contact Center, aceptamos pagos mediante depósito, transferencia bancaria, y tarjeta de débito o crédito. En nuestra página web, aceptamos pagos solamente con tarjeta de débito o crédito.</li>                
            </ul>
        ),
    },
    {
        id: 2,
        text: '¿Puedo facturar mi compra?',
        answer: ( 
            <ul>
                {/*<li>Si, la facturación de tus boletos se deberá solicitar al momento de la compra brindando todos los datos actualizados.</li>   
                <li>En caso de no solicitarla en ese momento, en las próximas 48 horas ingresando al portal de facturación de nuestra página web en el apartado "facturación"</li>                */}
                <li>Puedes solicitar la factura de tu compra enviando un correo a facturas@bajaferries.com.mx, adjuntando la constancia de situación fiscal actualizada y tu número de reservación.</li>
                <li>Si solicitas la factura el mismo día de tu compra, puedes llamar al contact center, indicar tus datos de facturación, y te la enviarán en el transcurso del día.</li>                
            </ul>
        ),
    },
    
    {
        id: 4,
        text: '¿Cómo puedo obtener el recibo de pago?',
        answer: ( 
            <ul>
                <li>La confirmación de tu reserva es tu recibo de pago y debe llegar de manera automática al correo que proporcionaste al momento de realizar tu pago.</li>
                <li>En caso de no haberlo recibido puedes comunicarte a nuestro Contact Center para solicitar el reenvío. </li>            
            </ul>
        ),
    },
   {/*} {
        id: 5,
        text: '¿Cómo obtengo mi factura?',
        answer: ( 
            <ul>
                <li>Puedes obtener tu factura en nuestro portal de facturación dentro de nuestra página web. Podrás descargarla siempre y cuando la solicites dentre del mes de tu compra.</li>
                
            </ul>
        ),
    },
    */}
   

];



const Pagos_facturas = ({ faqs, handleClick }) => {
  return (
    <> 
        <section>
            <div className='header_faqs'>
                <div className='container header_faqs_box'>
                    <div className='header_faqs_title'>
                        <h3>Preguntas frecuentes</h3>
                        <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                    </div>                
                </div>
            </div>
        </section>
 
        <section className='container'>
            <div className='box_titulo_faqs '>
                <div className='lista_preguntas'>
                    <h3>Pagos y facturas</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-pagos-facturas/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
                <div className='lista_temas'>
                    <h3>Otros temas frecuentes</h3>
                    <div className='box_lista_temas'>
                        <ul>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={bookingIcon} alt='antesViajar' />
                                    <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                                </div>
                            </li>
                            <li> 
                                <div className='content_lista_temas'>
                                    <img src={documentacion} alt='antesViajar' />
                                    <Link to="/documentacion">Documentación</Link> 
                                </div>
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={reservar} alt='antesViajar' />
                                    <Link to="/pagos-facturas">Reservar</Link> 
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={carga} alt='antesViajar' />
                                    <Link to="/carga">Carga</Link>  
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={avisos} alt='antesViajar' />
                                    <Link to="/automovil">Automóvil</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es   " target="_blank" rel="noopener noreferrer">Contactar</a>
                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
        <Footer />
        
    </>
  )
}
export default Pagos_facturas;