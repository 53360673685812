import React, { useState, useRef, useEffect  } from 'react'
import '../css/integracionLogistica.css'
import Navbar from '../components/Navbar';
import reduccion_costos from '../assets/images/soluciones/maritimo/reduccion.png'
import mejor_servicio from '../assets/images/soluciones/maritimo/servicio.png'
import almacen from '../assets/images/soluciones/integracion/almacen.png'
import inventario from '../assets/images/soluciones/integracion/lista.png'
import proceso from '../assets/images/soluciones/integracion/proceso.png'
import contractLogistics from '../assets/images/soluciones/integracion/contract-logistics.png'
import freight_forwarding from '../assets/images/carga/terrestre/freight-forwarding.webp'
import stock from '../assets/images/stock.png'
import { Outlet, Link } from "react-router-dom";
import ferry_carga from '../assets/images/soluciones/maritimo/ferry_carga.jpg'
import FooterCarga from '../components/FooterCarga'
import video from '../assets/video/integracionLogistica.mp4';
import solucioneslogisitcas from '../assets/images/soluciones/video-solucioneslogisitcas.png'

import VideoIntegracionLogistica from '../components/frames/VideoIntegracionLogistica';

export default function IntegracionLogistica() {
    const [isPlaying, setIsPlaying] = useState(false);
    const [isButtonVisible, setIsButtonVisible] = useState(true);
    const videoRef = useRef(null);

    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    useEffect(() => {
        if (isButtonVisible) {
            const timer = setTimeout(() => {
                setIsButtonVisible(false);
            }, 2000);
            return () => clearTimeout(timer);
        }
    }, [isButtonVisible]);

 

  return (
    <>
    <Navbar />
   
    <section className='header_carga integracion'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>Integración Logística</h2>
                    <p>Nos encargamos de cada etapa del proceso para llevar tu carga a su destino</p>
                </div>
            </div>
        </div>
    </section>

    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box chartering text'>
                    <p></p>
                    <p>Ofrecemos soluciones personalizadas para optimizar y gestionar de manera eficiente todas las etapas de tu cadena de suministro. Nuestro objetivo es facilitar el movimiento de tus mercancías desde el punto de origen hasta el destino final, asegurando la máxima eficiencia, reducción de costos y sostenibilidad en cada proceso.</p>
                    {/*<p>Nuestra logística representa un nivel avanzado de integración y gestión logística, utilizando tecnología de punta y un enfoque estratégico para ofrecer soluciones logísticas completas, eficientes y sostenibles.</p>
                    <p>A diferencia de los otros modelos de logística que se enfocan en aspectos específicos como el transporte, almacenamiento, y gestión de operaciones, un proveedor 5PL mira la cadena de suministro desde una perspectiva más amplia y estratégica.</p>*/}
                    <div className='btn_servicio_transporte'>
                        <a href="mailto:norma.munguia@baajferries.com.mx" rel="noopener noreferrer">Contactar</a>                                              
                    </div>
                </div>
                <div className='servicio_carga_box ilvideo img'>
                    <video ref={videoRef} loop width="100%" height="500" poster={solucioneslogisitcas}>
                        <source src={video} type="video/mp4" />
                    </video>
                    <div className='btn-controls-video'>
                        <button className={`play-pause-button ${isButtonVisible ? 'button-visible' : 'button-hidden'}`} onClick={handlePlayPause}>
                            {isPlaying ? (<i class="fi fi-ss-pause"></i>):(<i class="fi fi-ss-play"></i>)}
                        </button>
                    </div>                                      
                </div>
            </div>
        </div>
    </section>    
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
                <div className='servicio_carga_box img'>
                    <img src={contractLogistics} alt='servicio terrestre baja ferries' />
                </div>               
                <div className='servicio_carga_box feedering text'>
                    <h2>Contract Logistics </h2>
                    <p>Nuestro servicio esta diseñado para optimizar y gestionar de manera integral tu cadena de suministro. Nos encargamos de todas las etapas de tu proceso logístico, desde la planeación y almacenamiento, hasta el transporte y distribución permitiéndote enfocarte en el crecimiento de tu negocio.</p>
                    <div className='btn_servicio_transporte'>                        
                    <a href="mailto:norma.munguia@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>                                                              
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box proyecto text'>
                    <h2>Freight Forwarding</h2>
                    <p>Trabajamos junto a ti para desarrollar soluciones de transporte personalizadas y proponerte la mejor ruta enfocada en optimizar tus costos. Coordinamos todos los aspectos del proceso de envío, incluida la planificación, reserva de transporte, documentación aduanera, seguro de carga y seguimiento del envío, garantizándote  una eficiente entrega de la carga.</p>
                    <div className='btn_servicio_transporte'>                        
                    <a href="mailto:norma.munguia@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>                                                             
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={freight_forwarding} alt='servicio terrestre baja ferries' />
                </div>
            </div>
        </div>
    </section>
{/*
    <section className='beneficios integracion_logistica'>
        <div className='container'>
            <div className='beneficios_container integracion_logistica'>
                <div className='beneficios_content integracion_logistica'>
                    
                    <div className='beneficios_box integracion_logistica'>
                        <div className='beneficios_box integracion_logistica'>
                            <img src={almacen} alt='' />
                        </div>
                        <div>                        
                            <h3>Gestión de transporte:</h3>
                            <p>Incluye la selección de modos de transporte, la optimización de rutas y la consolidación de cargas para garantizar entregas puntuales y reducir costos.</p>
                        </div>
                    </div>
               </div>
               <div className='beneficios_content integracion_logistica'>
                    
                    <div className='beneficios_box integracion_logistica'>
                        <div className='beneficios_box integracion_logistica'>
                            <img src={almacen} alt='' />
                        </div>
                        <div>
                            <h3>Gestión de almacenes:</h3>
                            <p>El almacenamiento, manejo de inventarios, preparación de pedidos, asegura que los productos estén disponibles cuando y donde se necesiten.</p>
                        </div>
                    </div>
                </div>
               
               <div className='beneficios_content integracion_logistica'>
                    
                    <div className='beneficios_box integracion_logistica'>
                        <div className='beneficios_box integracion_logistica'>
                            <img src={inventario} alt='' />
                        </div>
                        <div>
                            <h3>Gestión de inventarios:</h3>
                            <p>Busca mantener el equilibrio adecuado entre demanda y oferta, minimizando costos asociados con el exceso de inventario o la falta de productos.</p>
                        </div>
                    </div>
               </div>
                           
            </div>
            <div className='beneficios_container integracion_logistica'>
                <div className='beneficios_content integracion_logistica'>
                    
                    <div className='beneficios_box integracion_logistica'>
                        <div className='beneficios_box integracion_logistica'>
                            <img src={proceso} alt='' />
                        </div>
                        <div>                        
                            <h3>Procesamiento de pedidos</h3>
                            <p>Desde la recepción hasta la ejecución del pedido, este componente asegura una experiencia de compra fluida para el cliente.</p>
                        </div>
                    </div>
               </div>
               <div className='beneficios_content integracion_logistica'>
                   
                    <div className='beneficios_box integracion_logistica'>
                        <div className='beneficios_box integracion_logistica'>
                            <img src={mejor_servicio} alt='' />
                        </div>
                        <div>
                            <h3>Servicio al cliente:</h3>
                            <p>Nos enfocamos en cumplir o superar las expectativas de los clientes, soporte posventa y comunicación efectiva.</p>
                        </div>
                    </div>
                </div>
               
               <div className='beneficios_content integracion_logistica'>
                    
                    <div className='beneficios_box integracion_logistica'>
                        <div className='beneficios_box integracion_logistica'>
                            <img src={reduccion_costos} alt='' />
                        </div>
                        <div>
                            <h3>Integración de la cadena de suministro:</h3>
                            <p>La capacidad de coordinar y sincronizar todas las actividades logísticas, desde proveedores hasta clientes finales.</p>
                        </div>
                    </div>
               </div>
            </div>
        </div>
        
    </section>
    */}


    <FooterCarga />
    <Outlet />
    
    </>
  )
}
