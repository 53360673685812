import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/faqs.css'

import Contactanos from '../contacto/Contactanos';
import Footer from '../Footer'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import '../../css/accordion.css'
/**/

//import carga  from "../../assets/images/carga.svg";
import bookingIcon from '../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import documentacion from '../../assets/images/iconsPreguntas/documentacion.png'
import avisos from '../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../assets/images/iconsPreguntas/factura.png'

const questions = [
    
    {
        id: 1,
        text: '¿La tarifa del camión es en viaje redondo?',
        answer: 'La tarifa publicada es para un viaje sencillo. Si deseas consultar la tarifa para un viaje redondo, con gusto te podemos proporcionar una cotización.',
    },
    {
        id: 2,
        text: '¿El peso influye en el costo? ',
        answer: 'Sí el peso de la carga supera las 70 toneladas o si tiene medidas sobredimensionadas en largo, ancho o alto, se aplicará la tarifa correspondiente al peso y dimensiones.',
    },
    {
        id: 3,
        text: '¿Cómo puedo registrarme en ASIPONA?',
        answer: 'Si es tu primera vez viajando en nuestra ruta Topolobampo – La Paz, debes realizar un registro en la página web https://www.puertointeligenteseguro.com.mx/#/login con al menos 72 horas de anticipación.',
    },
    {
        id: 4,
        text: '¿La tarifa de la unidad incluye al operador?',
        answer: 'Sí, las tarifas publicadas para las unidades de carga incluyen al operador, una cabina compartida para él y una comida de cortesía.',
    },
    {
        id: 5,
        text: '¿Se ofrece servicio de comida?',
        answer: 'Sí, contamos con un restaurante y un bar a bordo donde puedes comprar alimentos y bebidas. Además, el boleto incluye una comida de cortesía al embarcar.',
    },
    {
        id: 6,
        text: '¿Cuánto dura la travesía?',
        answer: 'La travesía en la ruta Mazatlán dura aproximadamente 14 horas y en la ruta Topolobampo, aproximadamente 9 horas.',
    },
    {
        id: 7,
        text: '¿La tarifa es la misma si el camión va cargado o vacío?',
        answer: 'Sí, la tarifa es la misma si la unidad va cargada o vacía.',
    },
    {
        id: 8,
        text: '¿Pueden ingresar los ayudantes a la cabina?',
        answer: 'El ayudante del operador no puede ingresar en la cabina.',
    },
    {
        id: 9,
        text: '¿Dónde viajan los ayudantes?',
        answer: 'El o los ayudantes del operador viajan en un asiento asignado dentro del salón durante la travesía.',
    },
    {
        id: 10,
        text: '¿Dónde puedo obtener la carta porte?',
        answer: 'Si tu unidad viaja cargada, debes solicitar la carta porte en el SAT. Este documento es indispensable, ya que las autoridades portuarias lo solicitan al ingreso al puerto.',
    },
    {
        id: 11,
        text: '¿Cuál es el tiempo límite para llegar al puerto?',
        answer: 'Solicitamos que todas las unidades de carga se presenten en la terminal portuaria con al menos 5 horas de anticipación.',
    },
    
];

const Carga = ({ faqs, handleClick }) => {
      
 

  return (
    <> 
        <section>
            <div className='header_faqs'>
                <div className='container header_faqs_box'>
                    <div className='header_faqs_title'>
                        <h3>Preguntas frecuentes</h3>
                        <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                    </div>                
                </div>
            </div>
        </section>

        <section className='container'>
            <div className='box_titulo_faqs '>
                <div className='lista_preguntas'>
                    <h3>Carga</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-carga/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
                <div className='lista_temas'>
                    <h3>Otros temas frecuentes</h3>
                    <div className='box_lista_temas'>
                        <ul>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={bookingIcon} alt='antesViajar' />
                                    <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                                </div>
                            </li>
                            <li> 
                                <div className='content_lista_temas'>
                                    <img src={documentacion} alt='antesViajar' />
                                    <Link to="/documentacion">Documentación</Link> 
                                </div>
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={pagos_facturas} alt='antesViajar' />
                                    <Link to="/pagos-facturas">Pagos y facturas</Link> 
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={reservar} alt='antesViajar' />
                                    <Link to="/reserva">Reservar</Link>  
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={avisos} alt='antesViajar' />
                                    <Link to="/automovil">Automóvil</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es   " target="_blank" rel="noopener noreferrer">Contactar</a>
                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
        <Footer />
        
    </>
  )
}

export default Carga;