import React, {useState} from 'react'
import '../../css/reserva_mobile.css'
import { DatePicker, Space } from 'antd';
import dayjs from 'dayjs';
import { ConfigProvider } from 'antd';
import es_ES from 'antd/locale/es_ES';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const disabledDate = (current) => {
    // Can not select days before today and today
    return current && current < dayjs().startOf('day');
  };


export default function ReservaMobile() {

    //FormData
	const [formData, setFormData] = useState({
		origen: '',
		destino: '',
		travelType: 'RETURN', // Establece el valor predeterminado a "RETURN"
		fechaIda: '',
		fechaVuelta: '',
	});


    const [modal, setModal] = useState(false);
    const [selectedTravelType, setSelectedTravelType] = useState('RETURN'); // Nuevo estado
    
    const [activeButton, setActiveButton] = useState('button1');
    const [btnCupon, setBtnCupon] = useState(false);
    const [boxPasajero, setBoxPasajero] = useState(false);
    const [adultoCount, setAdultoCount] = useState(1);
	const [infanteCount, setInfanteCount] = useState(0);
	const [menorCount, setMenorCount] = useState(0);
	const [mayorCount, setMayorCount] = useState(0);

    const handleOptionChange = e => {
        //setSelectedOption(event.target.value);
        const name = e.target.name;
		const value = e.target.value;

		if (name === 'travelType') {
			setSelectedTravelType(value);
		}

		//console.log(e.target.name, e.target.value);
		setFormData({...formData,[name]: value});

        
    };
    const handleClick = (button) => {
        setActiveButton(button);
    };
	const handleBtnCupon = (button) => {
        setBtnCupon(!btnCupon);
    };
    const toggleModal = () => {
        setModal(!modal)
    }
    const handleBoxPasajero = () => {
		setBoxPasajero(!boxPasajero);
	}
    	// Manejadores para sumar
	const handleAdultoCount = () => {
		setAdultoCount(adultoCount + 1);		
	};
	const handleInfanteCount = () => {
		setInfanteCount(infanteCount + 1);		
	};
	const handleMenorCount = () => {
		setMenorCount(menorCount + 1);		
	};
	const handleMayorCount = () => {
		setMayorCount(mayorCount + 1);		
	};

	// Manejadores para restar
    const handleAdultoCountDecrement = () => {
        setAdultoCount(adultoCount > 0 ? adultoCount - 1 : 0);
    };

    const handleInfanteCountDecrement = () => {
        setInfanteCount(infanteCount > 0 ? infanteCount - 1 : 0);
    };

    const handleMenorCountDecrement = () => {
        setMenorCount(menorCount > 0 ? menorCount - 1 : 0);
    };

    const handleMayorCountDecrement = () => {
        setMayorCount(mayorCount > 0 ? mayorCount - 1 : 0);
    };

    const handleDateChangeReturn = (dates, dateStrings) => {
		const [fechaIda, fechaVuelta] = dateStrings;
		console.log('Fechas seleccionadas:', dateStrings);
		setFormData({
		  ...formData,
		  fechaIda,
		  fechaVuelta,
		});
	};	

    

    // Suma de los clics de ambos botones
	const totalClicks = adultoCount + infanteCount + menorCount + mayorCount;

    
    
	const [mostrarCamposFecha, setMostrarCamposFecha] = useState(false);
	const [mostrarDatepicker, setMostrarDatepicker] = useState(false);
    const [selectedVehiculo, setSelectedVehiculo] = useState('');

	const handleChangeReservation = e => {		
		const name = e.target.name;
		const value = e.target.value;

		if (name === 'travelType') {
			setSelectedTravelType(value);
		}

		//console.log(e.target.name, e.target.value);
		setFormData({...formData,[name]: value});
	};

    const opcionesRuta = [
		
		{ value: '', label: '' },
		{ value: 'P~PIC', label: 'La Paz' },
		{ value: 'P~MZT', label: 'Mazatlán' },
		{ value: 'P~TPB', label: 'Topolobampo' },
		// Agrega más opciones de destino según sea necesario
	];

    const rutasNoPermitidas = {
		'P~MZT': ['P~TPB'],
		'P~TPB': ['P~MZT'],
	  };

    const getOpcionesFiltradas = (opciones, seleccionado, noPermitidos) => {
		if (rutasNoPermitidas[seleccionado]) {
		  return opciones.filter((opcion) => !noPermitidos.includes(opcion.value) && opcion.value !== seleccionado);
		}
		return opciones.filter((opcion) => opcion.value !== seleccionado);
	  };
      const handleDateChange = (date, dateString, type) => {
		console.log('Fecha seleccionada: ', dateString);
		setFormData({
		  ...formData,
		  [type]: dateString,
		});
	};


    const reservation = (e) => {
		
		e.preventDefault();		
		console.log("formData movil: ", formData);
		


		if (selectedTravelType === 'RETURN') {

			// Acciones específicas para el tipo de viaje de "RETURN"
			window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&	
				cw_journeysearch_j2_passengers[0][ctg]=ADL&
				cw_journeysearch_j2_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j2_passengers[1][ctg]=CHD&
				cw_journeysearch_j2_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j2_passengers[2][ctg]=INF&
				cw_journeysearch_j2_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j2_passengers[3][ctg]=RET&
				cw_journeysearch_j2_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j2_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j2_vehicles[0][qty]=1&	
				bookingFlow=RETURN&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&
				cw_journeysearch_j1_route=${formData.origen}-${formData.destino}&
				cw_journeysearch_j2_date=${formData.fechaVuelta}T00:00:00&
				cw_journeysearch_j2_route=${formData.destino}-${formData.origen }
				`,'_blank'
			);

		} else if (selectedTravelType === 'ONEWAY') {

			// Acciones específicas para el tipo de viaje de "ONEWAY"
			window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&
				cw_journeysearch_j1_passengers[0][ctg]=ADL&
				cw_journeysearch_j1_passengers[0][qty]=${adultoCount}&
				cw_journeysearch_j1_passengers[1][ctg]=CHD&
				cw_journeysearch_j1_passengers[1][qty]=${menorCount}&
				cw_journeysearch_j1_passengers[2][ctg]=INF&
				cw_journeysearch_j1_passengers[2][qty]=${infanteCount}&
				cw_journeysearch_j1_passengers[3][ctg]=RET&
				cw_journeysearch_j1_passengers[3][qty]=${mayorCount}&
				cw_journeysearch_j1_vehicles[0][ctg]=${selectedVehiculo}&
				cw_journeysearch_j1_vehicles[0][qty]=1&				
				bookingFlow=ONEWAY&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}`,'_blank'					
			);
		}			
		
	};

  return (
    <>
    
        <section className='reserva_mobile'>
            <div className='container'>
                <div className='content_reserva_mobile'>
                    <div className='box_reserva_mobile'>
                        <div className='box_reserva_mobile_radioButons'>
                            <label>
                                <input
                                type="radio"
                                value="RETURN"
                                name="travelType"
                                checked={selectedTravelType === "RETURN"}
                                onChange={handleOptionChange}
                                />
                                Ida y vuelta
                            </label>
                            <span>|</span>
                            <label>
                                <input
                                type="radio"
                                name='travelType'
                                value="ONEWAY"
                                checked={selectedTravelType === "ONEWAY"}
                                onChange={handleOptionChange}
                                />
                                Solo ida
                            </label>                    
                        </div>
                    </div>
                    <div className='box_reserva_mobile'>
                        <div className='box_reserva_mobile_select'>
                            <label>Origen:</label>
                            <select name="origen" value={formData.origen} onChange={handleChangeReservation}>
                                <option value=""></option>
                                {getOpcionesFiltradas(opcionesRuta, formData.destino, rutasNoPermitidas[formData.destino] || []).map((opcion) => (
                                    <option key={opcion.value} value={opcion.value}>
                                    {opcion.label}
                                    </option>
                                ))}
                            </select>	
                        </div>
                        <div className='box_reserva_mobile_select'>
                            <label>Destino:</label>
                            <select name="destino" value={formData.destino} onChange={handleChangeReservation}>
                                <option value=""></option>
                                {getOpcionesFiltradas(opcionesRuta, formData.origen, rutasNoPermitidas[formData.origen] || []).map((opcion) => (
                                    <option key={opcion.value} value={opcion.value}>
                                    {opcion.label}
                                    </option>
                                ))}
                            </select>	
                        </div>
                    </div>
                    <div className='box_reserva_mobile'>
                        <div className='box_reserva_mobile_button'>
                            <button onClick={toggleModal}>Reserva ahora</button>                    
                        </div>                    
                    </div>
                    <div className='box_reserva_mobile'>
                        <div className='cupon_reserva_mobile'>
                            <label onClick={handleBtnCupon}>¿Tienes un código promocional? <i class="fi fi-rr-add"></i></label> 
                        </div>
                        {btnCupon && (
                        <div className='box_ruta'>  
                            <input type='text' />                                                    
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </section>
        {modal && (
            <form onSubmit={reservation}>
                <section className='modal_content'>
                
                    <div className='container'>
                        <div className='btn_cerrar_modal_reservar'>
                            <label onClick={toggleModal}> <i class="fi fi-br-cross"></i></label>
                        </div>
                        <div className='title_modal_reservar'>
                            <h3>Reserva tu viaje</h3>                
                        </div>
                        <div className='reservacion_modal'>   
                                                
                            <div className='box_reserva_mobile_radioButons'>
                                <label>
                                    <input
                                    type="radio"
                                    value="RETURN"
                                    name="travelType"
                                    checked={selectedTravelType === "RETURN"}
                                    onChange={handleOptionChange}
                                    />
                                    Ida y vuelta
                                </label>
                                <span>|</span>
                                <label>
                                    <input
                                    type="radio"
                                    name="travelType"
                                    value="ONEWAY"
                                    checked={selectedTravelType === "ONEWAY"}
                                    onChange={handleOptionChange}
                                    />
                                    Solo ida
                                </label>
                            </div>
                            
                            {selectedTravelType === "RETURN" ? (
                                <>
                                
                                <div className='box_modal'>
                                    <div className='box_ruta'>
                                        <label>Origen:</label>
                                        <div>                                        
                                        <select name="origen" value={formData.origen} onChange={handleChangeReservation}>
                                            <option value=""></option>
                                            {getOpcionesFiltradas(opcionesRuta, formData.destino, rutasNoPermitidas[formData.destino] || []).map((opcion) => (
                                                <option key={opcion.value} value={opcion.value}>
                                                {opcion.label}
                                                </option>
                                            ))}
                                            </select>	
                                        </div>                                              
                                    </div>                            
                                </div>
                                <div className='box_modal'>
                                    <div className='box_ruta'>  
                                        <label>Destino:</label>                          
                                        <div>
                                            <select name="destino" value={formData.destino} onChange={handleChangeReservation}>
                                                <option value=""></option>
                                                {getOpcionesFiltradas(opcionesRuta, formData.origen, rutasNoPermitidas[formData.origen] || []).map((opcion) => (
                                                    <option key={opcion.value} value={opcion.value}>
                                                    {opcion.label}
                                                    </option>
                                                ))}
                                            </select>	
                                        </div>                        
                                    </div>
                                </div>
                            
                                <div className='box_modal'>
                                    <div className='box_ruta'>
                                        <label>Fecha salida</label>
                                        <div>                                        
                                        <ConfigProvider locale={es_ES}>  
                                            <Space direction="vertical" size={12} className="custom-datepicker">
                                                <RangePicker disabledDate={disabledDate}  onChange={handleDateChangeReturn}/>						
                                            </Space>					
                                        </ConfigProvider>
                                        </div>                                              
                                    </div>
                                </div>

                                
                                <div className='box_modal'>
                                    <div className='box_ruta'>                                                           
                                            <div style={{display:'grid'}} onClick={handleBoxPasajero}>
                                                <label>Personas</label>
                                                <label>{totalClicks} <i class="fi fi-rr-angle-small-down"></i> </label>
                                            </div>
                                            {boxPasajero && (
                                            <div className='modalPasajeroMobile'>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Adulto:</label>
                                                        <label>De 12 a 99</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {adultoCount} </label>
                                                        <div onClick={handleAdultoCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleAdultoCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>														
                                                </div>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Infante:</label>
                                                        <label>De 0 a 2 años de edad</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {infanteCount} </label>
                                                        <div onClick={handleInfanteCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleInfanteCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>
                                                </div>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Menor:</label>
                                                        <label>De 3 a 11 años</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {menorCount} </label>
                                                        <div onClick={handleMenorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleMenorCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>														
                                                </div>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Adulto mayor:</label>
                                                        <label>A partir de 60 años</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {mayorCount} </label>
                                                        <div onClick={handleMayorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleMayorCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>
                                                </div>
                                                <button onClick={handleBoxPasajero}>Aceptar</button>
                                            </div>
                                            )}
                                                                
                                    </div>
                                </div>
                                <div className='box_modal'>
                                    <div className='box_ruta'>  
                                        <label>Vehículo</label>                          
                                        <div>
                                            <select>
                                                <option value={''}>Sin vehículo</option>
                                                <option value={'MC'}>Motocicleta </option>
                                                <option value={'CAR'}>Automovíl/Pickup</option>
                                                <option value={'MHO'}>Motorhome/RV</option>
                                            </select>
                                        </div>                        
                                    </div>
                                </div>                        
                                <div className='box_ruta'>
                                    <button type='submit' className=''> Reservar </button>
                                </div>
                                <div>
                                    <div className='cupon_reserva_vertical'>
                                        <label onClick={handleBtnCupon}>¿Tienes un código promocional? <i class="fi fi-rr-add"></i></label> 
                                    </div>
                                    {btnCupon && (
                                        <div className='box_ruta'>  
                                            <input type='text' />                                                    
                                        </div>
                                    )}
                                </div>
                            


                                </>                   
                            ) : (
                                <>
                                <div>
                                    <div className='box_modal'>
                                        <div className='box_ruta'>
                                            <label>Origen</label>
                                            <div>                                        
                                                <select name="origen" value={formData.origen} onChange={handleChangeReservation}>
                                                    <option value=""></option>
                                                    {getOpcionesFiltradas(opcionesRuta, formData.destino, rutasNoPermitidas[formData.destino] || []).map((opcion) => (
                                                        <option key={opcion.value} value={opcion.value}>
                                                        {opcion.label}
                                                        </option>
                                                    ))}
                                                </select>	
                                            </div>                                              
                                        </div>
                                    </div>
                                    <div className='box_modal'>
                                        <div className='box_ruta'>  
                                            <label>Destino</label>                          
                                            <div>
                                                <select name="destino" value={formData.destino} onChange={handleChangeReservation}>
                                                    <option value=""></option>
                                                    {getOpcionesFiltradas(opcionesRuta, formData.origen, rutasNoPermitidas[formData.origen] || []).map((opcion) => (
                                                        <option key={opcion.value} value={opcion.value}>
                                                        {opcion.label}
                                                        </option>
                                                    ))}
                                                </select>
                                            </div>                        
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <div className='box_modal'>
                                        <div className='box_ruta'>
                                            <label>Fecha salida</label>
                                            <div>                                        
                                                <ConfigProvider locale={es_ES}>  
                                                    <Space direction="vertical" size={12} className="custom-datepicker">
                                                        <DatePicker disabledDate={disabledDate} name="fechaIda"  onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')}/>						
                                                    </Space>					
                                                </ConfigProvider>	
                                            </div>                                              
                                        </div>
                                    </div>                           
                                </div>
                                <div className='box_modal'>
                                    <div className='box_ruta'>                                                           
                                            <div style={{display:'grid'}} onClick={handleBoxPasajero}>
                                                <label>Personas</label>
                                                <label>{totalClicks} <i class="fi fi-rr-angle-small-down"></i> </label>
                                            </div>
                                            {boxPasajero && (
                                            <div className='modalPasajeroMobile'>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Adulto:</label>
                                                        <label>De 12 a 99</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {adultoCount} </label>
                                                        <div onClick={handleAdultoCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleAdultoCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>														
                                                </div>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Infante:</label>
                                                        <label>De 0 a 2 años de edad</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {infanteCount} </label>
                                                        <div onClick={handleInfanteCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleInfanteCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>
                                                </div>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Menor:</label>
                                                        <label>De 3 a 11 años</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {menorCount} </label>
                                                        <div onClick={handleMenorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleMenorCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>														
                                                </div>
                                                <div className='content_cant_pasajero'>
                                                    <div className='box_cant_pasajero'>
                                                        <label>Adulto mayor:</label>
                                                        <label>A partir de 60 años</label>
                                                    </div>
                                                    <div className='box_cant_pasajero'>
                                                        <label> {mayorCount} </label>
                                                        <div onClick={handleMayorCountDecrement}><i class="fi fi-sr-square-minus"></i></div>
                                                        <div  onClick={handleMayorCount}><i class="fi fi-sr-square-plus"></i></div>
                                                    </div>
                                                </div>
                                                <button onClick={handleBoxPasajero}>Aceptar</button>
                                            </div>
                                            )}
                                                                
                                    </div>
                                </div>
                                <div className='box_modal'>
                                    <div className='box_ruta'>  
                                        <label>Vehículo</label>                          
                                        <div>
                                            <select>
                                                <option value={''}>Sin vehículo</option>
                                                <option value={'MC'}>Motocicleta </option>
                                                <option value={'CAR'}>Automovíl/Pickup</option>
                                                <option value={'MHO'}>Motorhome/RV</option>
                                            </select>
                                        </div>                        
                                    </div>
                                </div>
                                <div>
                                    <div className='cupon_reserva_vertical'>
                                        <label onClick={handleBtnCupon}>Aplicar cupón <i class="fi fi-rr-add"></i></label> 
                                    </div>
                                    {btnCupon && (
                                        <div className='box_ruta'>  
                                            <input type='text' />                                                    
                                        </div>
                                    )}
                                </div>
                                <div className='box_ruta'>
                                    <button type='submit' className=''> Reservar </button>
                                </div>

                                </>                  
                            )}                  

                        </div>
                    </div>
                </section>
            </form>
        )}
    
    </>
  )
}
