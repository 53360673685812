import React from 'react';

const MapsContacto = ({ locationUrl }) => {
    return (
        <div className="map-container">
            <iframe
                title="ubicación"
                width="100%"
                height="400px"
                frameBorder="0"
                style={{ border: 0 }}
                src={locationUrl}
                allowFullScreen
                zoom={5}
            ></iframe>
        </div>
    );
};

export default MapsContacto;
