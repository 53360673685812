import React, { useState, useEffect } from 'react';
import { Outlet, Link } from "react-router-dom";


export default function Navbar() {
    const [isAviso, setIsAviso] = useState(false);
  const [scroll, setScroll] = useState(false);
  const [isSubmenu, setIsSubmenu] = useState(null);
  const [isMenu, setIsMenu] = useState(false);


  useEffect(() => {
    const handleScroll = () => {
      const isScrolled = window.scrollY > 20; // Cambia este valor según tus necesidades
      if (isScrolled !== scroll) {
        setScroll(isScrolled);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
    }, [scroll]);

    const toggleMenu = () => {
        setIsMenu(!isMenu);
    };
    const toggleSubmenu = (index) => {
        setIsSubmenu((prevState) => (prevState === index ? null : index));
    };

  return (
    <>
    {isAviso &&(
        <section className='avisoHeader'>
            <div className='container'>
              
                <div className='avisoHeader_content'>
                    {/*<i class="fi fi-br-cloud-showers-heavy"></i>*/}
                    {/*<i class="fi fi-rs-megaphone"></i>*/}
                    
                    <p>  <i class="fi fi-ss-triangle-warning"></i> ¡Atención!: Sigue la trayectoria de la tormenta tropical "Ileana" dando clic aquí  </p>
                    <label><a className="btn_aviso_cintillo" href="https://smn.conagua.gob.mx/es/animacion-imagenes-de-satelite?satelite=GOES%20Este&nombre=Pac%C3%ADfico%20-%20Sur&tipo=Tope%20de%20Nubes" target="_blank" rel="noopener noreferrer">Ir ahora</a></label>
                    
                </div>
            </div>
        </section>
    )}
       <nav className={scroll ? 'menucarga scrolled' : 'menucarga'}>
       
        <div className='container'>
            <div className='nav-menu-carga'>
                <div className='logo_carga'>
                    <Link to="/soluciones-logisticas" className="menu-item" >
                        <div className='menu_img_show_carga'></div>
                    </Link>
                </div>
                <div className='menu_medio'>
                    <ul>
                        
                        <li><Link to="/maritimo">Marítimo {/*<i class="fi fi-rr-caret-down"></i>*/}</Link>
                            {/*<ul className='submenu_carga'>
                                <li><a href="#maritimo">Chartering </a></li>
                                <li><a href="#maritimo">Feedering nacional </a></li>
                                <li><a href="#maritimo">Carga proyecto </a></li>
                            </ul>*/}
                        </li>
                        <li><Link to="/terrestre">Terrestre  {/*<i class="fi fi-rr-caret-down"></i>*/}</Link>
                           {/*<ul className='submenu_carga'>
                                <li><a href="#terrestre">Contract Logistics </a></li>
                                <li><a href="#terrestre">Freight Forwarding </a></li>
                                <li><a href="#terrestre">Agente Naviero </a></li>
                            </ul>*/}
                        </li>
                        <li><Link to="/multimodal">Multimodal  {/*<i class="fi fi-rr-caret-down"></i>*/}</Link>
                            {/*<ul className='submenu_carga'>
                                <li><a href="#multimodal">Servicio Multimodal</a></li>
                                <li><a href="#multimodal">Circuito Intermodal </a></li>
                            </ul>*/}
                        </li>
                        <li><Link to="/integracion-logistica" >Integración logística </Link></li>
                        {/*<li><Link to="/tarifas-carga" >Contacto </Link></li>*/}

                        {/*<li className="navbar-item">
                            <Link to="/sostenibilidad">Sostenibilidad  {/*<i class="fi fi-rr-caret-down"></i>*</Link>
                        </li>
                        <li><Link to="/viaje-en-curso" >Viaje en curso </Link></li>*/}
                       
                    </ul>
                </div>
                <div className='menu_derecho'>
                    <ul>
                        <li style={{ padding: '.5rem .3rem', background: '#545454'}}><Link to="/" style={{color:'#fff'}}><i class="fi fi-ss-ship" style={{marginRight:'5px'}}></i> Turismo</Link></li>
                        <li><a  className="navbar-link" href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">Proveedores</a> </li>
                        <li><a href="#services"><i class="fi fi-rr-globe"></i> ES {/*<i class="fi fi-rr-caret-down"></i>*/}</a> </li>
                    </ul>
                </div>                
            </div>            
        </div>               
     </nav>
     <nav className='menu_responsive_carga'>
        <div className='container'>
            <div className='menu_responsive_carga_content'>
                <div>
                    <Link to="/soluciones-logisticas"><div class="menu_img_show_carga"></div></Link>
                </div>
                <div className='btn_menu_responsive_carga'>
                    <button onClick={toggleMenu}><i class="fi fi-br-menu-burger"></i></button>
                </div>
            </div>            
        </div>        
     </nav>
     {isMenu &&(
                <div className='menu_carga_activo'>
                   
                   <div className='container'>
                   <div className='menu_responsive_carga_content'>
                        <div>
                            <Link href="/"><div class="menu_img_show_carga"></div></Link>
                        </div>
                        <div className='btn_menu_responsive_carga'>
                            <button onClick={toggleMenu}><i class="fi fi-br-cross"></i></button>
                        </div>
                    </div>
                  
                   <div className='submenu_list_carga'>
                        <ul>
                       
                            {/*<li><Link  onClick={() => toggleSubmenu(1)}>Marítimo <i class="fi fi-rr-caret-down"></i></Link>*/}
                            <li><Link to='/maritimo'>Marítimo </Link>
                            {isSubmenu === 1 &&(
                                <ul className='submenu_item'>
                                    <li>Chartering</li>
                                    <li>Feedering nacional</li>
                                    <li>Carga proyecto</li>
                                </ul>
                            )}
                            </li>
                            {/*<li><Link  onClick={() => toggleSubmenu(2)}>Terrestre <i class="fi fi-rr-caret-down"></i></Link>*/}
                            <li><Link to='/terrestre' >Terrestre</Link>
                            {isSubmenu === 2 &&(
                                <ul className='submenu_item'>
                                    <li>Contract Logistics</li>
                                    <li>Freight Forwarding</li>
                                    <li>Agente Naviero</li>
                                </ul>
                            )}
                            </li>
                            {/*<li><Link  onClick={() => toggleSubmenu(3)}>Multimodal <i class="fi fi-rr-caret-down"></i></Link>*/}
                            <li><Link to='/multimodal'>Multimodal </Link>
                            {isSubmenu === 3 &&(
                                <ul className='submenu_item'>
                                    <li>Servicio Multimodal</li>
                                    <li>Circuito Intermodal</li>
                                </ul>
                            )}
                            </li>
                            <li><Link to='/integracion-logistica'>Integración logística </Link></li>
                           {/* <li><Link to="/tarifas-carga" >Tarifas </Link></li>
                            <li>
                                <Link to="/sostenibilidad">Sostenibilidad <i class="fi fi-rr-caret-down"></i></Link>                                                            
                            </li>
                            <li><Link to="/itinerario" >Itinerario </Link></li>*/}
                            
                            <li><a  href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">Proveedor</a> </li>
                        </ul>
                   </div>
                   <div className='regresarHome'>                    
                        <li className=''><Link to="/" >Turismo </Link></li>
                   </div>

                   </div>
                </div>                
            )}
     <Outlet />
    
    </>
  )
}
