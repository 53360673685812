import React from 'react'
import '../css/login_gobernanza.css'
import Login from '../components/form/Login'
export default function LoginGobernanza() {
  return (
    <>
    <section className='gobernanza_login_content'>
      <div className='login_gobernanza'>
          <div className='container'>
              <Login />
          </div>
      </div>
      <div className='lateral_box_gobernanza'>

      </div>
    </section>



    </>
  )
}
