import React from 'react'
import logo from '../../assets/images/logo.png'

export default function Login() {
  return (
    <div className='content_form_gobernanza'>
        <form>
            <div className='box_form_gobernanza title'>
                <div className='box_form_gobernanza logo'>
                    <img src={logo} alt='login gobernanza baja ferries' />
                </div>
            </div>
            <div className='boxes_form_gobernanza'>                
                <div className='form_login_title title'>
                    <p>Inicia sesión con tu cuenta</p>
                </div>
                <div className='box_form_gobernanza'>
                    <label>Usuario o correo</label>
                    <input type='text' className="custom-input" />                
                </div>
                <div className='box_form_gobernanza'>
                    <div className='box_form_gobernanza_text'>
                        <label>Contraseña</label>
                        <label>Restablecer contraseña</label> 
                    </div>
                    <input type='text' className="custom-input" />   
                </div>
                <div className='box_form_gobernanza_btn'>                 
                    <button>Iniciar sesión</button>    
                </div>
                
            </div>
        </form>
    </div>
  )
}
