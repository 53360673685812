    import React from 'react';
    import '../css/timeline.css'; // Importa tu archivo CSS para el estilo

    /* Imagenes */
    import img01 from '../assets/images/timeline/2003.webp'
    import img02 from '../assets/images/timeline/2005.png'
    import img03 from '../assets/images/timeline/2006.png'
    import img04 from '../assets/images/timeline/2008.png'
    import img05 from '../assets/images/timeline/2013.png'
    import img06 from '../assets/images/timeline/2015.png'
    import img07 from '../assets/images/timeline/2016.png'
    import img08 from '../assets/images/timeline/2017.png'
    import img09 from '../assets/images/timeline/2018.webp'
    import img10 from '../assets/images/timeline/2019.webp'
    import img11 from '../assets/images/timeline/2020.png'
    import img12 from '../assets/images/timeline/2021.webp'
    import img13 from '../assets/images/timeline/2022.png'
    import img14 from '../assets/images/timeline/2023.webp'



    // Componente para representar un evento en la línea de tiempo
    const TimelineEvent = ({ event, isLeft }) => {
    return (
        <div className={`timeline-event ${isLeft ? 'left' : 'right'}`}>
            <div className='timeline-content'>
               <div className='timeline-box'>
                <div className='timeline-text'>
                        <div className="event-date"><h3>{event.date}</h3></div>
                        <div className="event-date"><p>{event.title}</p></div>
                    </div>
                    <div className="event-details">
                        <img src={event.image} alt={event.title} />
                    </div>
               </div>
            </div>
        </div>
    );
    };

    // Componente para la línea de tiempo
    const Timeline = ({ events }) => {
    return (
        <div className="timeline">
        <div className="line"></div>
        <div className="events-container">
            {events.map((event, index) => (
                <TimelineEvent key={index} event={event} isLeft={index % 2 === 0} />
            ))}
        </div>
        </div>
    );
    };

    // Ejemplo de datos de la línea de tiempo
    const timelineEvents = [
    { date: "2003", title: "Iniciamos operaciones el 05 de marzo Innovando el servicio marítimo con nuestro buque “California Star”  transportando pasajeros y carga en la ruta Topolobampo- La Paz.", description: "Descripción del Evento 1", image: img01 },
    { date: "2005", title: "Se integra el buque “Sinaloa Star” para comenzar operaciones en la ruta Mazatlán- La Paz ofreciendo más alternativas a nuestros pasajeros y al mercado de carga. ", image: img02 },
    { date: "2006", title: "Los buques “Colima Star” y “Sonora Star” navegan entre los puertos de Manzanillo, La Paz y Guaymas transportando cemento y material de construcción respecitvamente. Este año se construyeron las oficinas corporativas en La Paz, B.C.S.", description: "Descripción del Evento 3", image: img03 },
    { date: "2008", title: "Añadimos a nuestra flota el “Chihuahua Star” en la ruta Mazatlán-La Paz reemplazando el “Sinaloa Star” para aumentar la capacidad de carga y duplicar el pasaje.   ", description: "Descripción del Evento 4", image: img04 },
    { date: "2013", title: "“La Paz Star” entra a nuestra flota para sustituir buque “Mazatlán Star” con la finalidad de tener más capacidad de carga en la ruta de Mazatlán. ", description: "Descripción del Evento 5", image: img05 },
    { date: "2015", title: "Innovamos nuestros servicios con el buque “Baja Star” fortaleciendo el turismo en la región del Mar de Cortés. Obtuvimos la certificación ISO 9001-2008 y la primera licencia para operar un ferry en la ruta Estados Unidos- Cuba.", description: "Descripción del Evento 6", image: img06 },
    { date: "2016", title: "Adquirimos el buque Cabo Star lo que representó para la empresa una importante apuesta en innovación; obtuvimos la Certificación de Empresa Socialmente Responsable.", description: "Descripción del Evento 7", image: img07 },
    { date: "2017", title: "Añadimos a nuestra flota el “Balandra Star”. Fuimos el primer grupo logístico naviero en recibir la certificación Distintivo H.", description: "Descripción del Evento 8", image: img08 },
    { date: "2018", title: "Adquirimos el “México Star”, el buque más grande de Latinoamérica en su tipo. ", description: "Descripción del Evento 9", image: img09 },  

    { date: "2019", title: "Lanzamos el servicio Baja Ferries Multimodal.  El “Mini Star” deja de ser parte de nuestra flota y llegó el buque multipropósito “Oaxaca Star” para operar en el Pacífico mexicano.", description: "Descripción del Evento 7", image: img10 },
    { date: "2020", title: "Obtuvimos la Certificación Prime, Punto Limpio, el Distintivo de Seguridad Sanitaria y fuimos la primera empresa en B.C.S en obtener el Sello Safe Travels otorgado por el Consejo Mundial de Viajes y Turismo (WTTC, por sus siglas en inglés).", description: "Descripción del Evento 8", image: img11 },
    { date: "2021", title: "Adquirimos un nuevo buque multipropósito, “Chiapas Star”, nos certificarnos con el estándar de calidad ISO 14001, fuimos incluidos en el primer informe del Pacto Mundial México de las 50 empresas privadas, ONG´s y organismos gremiales que trabajan por la agenda 2030 de las Naciones Unidas.", description: "Descripción del Evento 9", image: img12 },  
    
    { date: "2022", title: "Recibimos por primera vez el distintivo de inclusión Social por parte de CEMEFI y formamos parte de las 100 Mejores Empresas mexicanas por nuestro alto nivel de desempeño en gestión de negocios. ", description: "Descripción del Evento 8", image: img13 },
    { date: "2023", title: "Se añade a la flota el buque “California Star”. Recibimos por parte de CEMEFI el reconocimiento a Mejores Prácticas de Responsabilidad Social y el Premio Ética y Valores por parte de la Concamin.", description: "Descripción del Evento 9", image: img14 },  
    // Agrega más eventos aquí según sea necesario
    ];

    // Componente principal
    function TimeLine() {
    return (
        <div>
        <h2>Nuestra historia</h2>
        <Timeline events={timelineEvents} />
        </div>
    );
    }

    export default TimeLine;
