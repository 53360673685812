import React, {useEffect, useState} from 'react'
import { useNavigate,  } from 'react-router-dom';

export default function TaskList() {
    const [noticias, setTasks] = useState([]);
    const navigate = useNavigate();
 
    const handleDelete = async (id) => {

    try {
        await fetch(`http://localhost:3001/noticias/${id}`,{
            method: 'DELETE',
        })

        setTasks(noticias.filter(noticias => noticias.id !== id));
        } catch (error) {
            console.log(error)
        }

    }

    const loadTasks = async () => {
        const response = await fetch('http://localhost:3001/noticias');
        const data = await response.json();
        console.log("Data: ",data);
        setTasks(data);
    }

    useEffect(() =>{
        loadTasks();
    },[])

    const handleNew = () => {
        navigate("/noticias/new");

    };
      

    console.log("Noticia: ",noticias);
    return (
        <>
        <div>
            <h2>Noticias</h2>
            <div>
                <button onClick={() => handleNew()}>Nueva noticia</button>
            </div>
        </div>
        
        {
    
        <table>
            <thead>
            <tr>
                <th>Foto header</th>
                <th>Título</th>
                <th>Descripción</th>
                <th>Foto footer</th>
                <th>category</th>
                <th>Fecha creación</th>
                <th>Modificatión</th>
                <th>Usuario</th>
                <th>Hora</th>
                <th>Visible</th>
                <th>Editar</th>
                <th>Eliminar</th>
            </tr>
            </thead>
            <tbody>
            {noticias.map((task) => (

                <tr key={task.id}>                
                <td>{task.photo_route_header}</td>
                <td>{task.title}</td>
                <td>{task.description}</td>
                <td>{task.photo_route_footer}</td>
                <td>{task.category.join(', ')}</td>
                <td>{task.creation_date}</td>
                <td>{task.modification_date}</td>
                <td>{task.user_manager}</td>
                <td>{task.creation_time}</td>
                <td>{task.visible}</td>
                <td>
                    <button onClick={() => navigate(`/noticias/${task.id}/edit`) }>Editar</button>
                </td>
                <td>
                    <button onClick={() => handleDelete(task.id)}>Eliminar</button>
                </td>

                </tr>
            ))}
            </tbody>
        </table>
        
        }
        
        </>
        
    )
}
