import React from 'react';
import { WhatsappShareButton, EmailShareButton } from 'react-share';

const ShareButton = ({ url, title }) => {
  return (
    <div className='share_options'>
      {/* Botón de compartir en WhatsApp */}
      <div>
        <WhatsappShareButton url={url} title={title}>
            WhatsApp
        </WhatsappShareButton>
      </div>
      
      
      {/* Botón de compartir por correo electrónico */}
      <div>
        <EmailShareButton url={url} subject={title}>
            Correo
        </EmailShareButton>

      </div>
    </div>
  );
};

export default ShareButton;
