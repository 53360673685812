import React, { useState, useEffect } from 'react'
import '../css/tarifario.css'
import { Col, Row,  } from 'antd';
import { Outlet, Link } from "react-router-dom";
import Booking from '../components/form/Booking';
import ReservaMobile from '../components/form/ReservaMobile';
import Footer from '../components/Footer';
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'


export default function Tarifario() {
    const [selectElegirRuta, setSelectElegirRuta] = useState(false);
    const [nameOption, setNameOption] = useState('La Paz - Topolobampo');

    const chooseRutasVisible = () => {
        setSelectElegirRuta(!selectElegirRuta);
    };
    const rutaSelected = (option) => {
        setNameOption(option);            
        setSelectElegirRuta(!selectElegirRuta);
    };


  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Tarifas</h3>
                    <p>Consulta nuestras tarifas y encuentra la mejor opción para tu viaje. </p>
                </div>                
            </div>
        </div>
    </section>
 
    <section className='tarifario'>
        <div className='d-none d-sm-none d-md-block'>
            <Booking />
        </div>

        <div class="d-block d-sm-block d-md-none">                                       
            <ReservaMobile />                               
        </div>
        <div className='select_tarifario'>
            <div className='container'>
                <div>           
                    <div className='box_select_ruta_horario' onClick={chooseRutasVisible} >
                        <div style={{width: 'fit-content'}}  >                        
                            <div className='select_ruta'>
                                <label>Selecciona una ruta</label>
                                <label>{nameOption} <i class="fi fi-br-angle-small-down"></i></label>
                            </div>
                        </div>    
                        <div>
                            {selectElegirRuta && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                            </div>
                            }
                        </div>
                    </div>      
                </div>
            </div>
        </div>
        <div className='container'>
        {nameOption === 'La Paz - Topolobampo' || nameOption === 'Topolobampo - La Paz' ? (
            <>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                        <tr>
                            <th>Pasajero</th>
                            <th>Tarifa</th>
                        </tr>
                        <tr>
                            <td>Adulto <br /> <p>Derecho a embarque</p></td>
                            <td>$1,850</td>
                        </tr>
                        <tr>
                            <td>Infante <br /> <p>De 0 a 2 años</p></td>
                            <td>sin costo</td>
                        </tr>
                        <tr>
                            <td>Niños <br /> <p>De 3 a 11 años</p></td>
                            <td>$925</td>
                        </tr>
                        <tr>
                            <td>Adulto Mayor <br /> <p>De 3 a 11 años</p></td>
                            <td>$925</td>
                        </tr>
                        </tbody>
                    </table>            
                </div>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Vehículo</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td>Motocicleta <br /> <p>Hasta 2 mts.</p></td>
                                <td>$3,125</td>
                            </tr>
                            <tr>
                                <td>Automóvil <br /> <p>Hasta 6 mts.</p></td>
                                <td>$5,610</td>
                            </tr>
                            <tr>
                                <td>Auto con remolque <br /> <p>Hasta 11 mts.</p></td>
                                <td>$12,120</td>
                            </tr>
                            <tr>
                                <td>Motorhome <br /> <p>Hasta 11 mts.</p></td>
                                <td>$12,120</td>
                            </tr>
                            <tr>
                                <td>Autobús <br /> <p>Hasta 13 mts.</p></td>
                                <td>$16,040</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Cabinas</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td>Exterior TV 4 Pax <br /> <p>2 literas</p><p>TV, Baño completo</p></td>
                                <td>$2,400</td>
                            </tr>
                                                                        
                        </tbody>
                    </table>           
                </div>
                <div className='tabla-tarifa'>
                    
                    <div className='tabla_grid'>
                        <div className=''>
                            <label>Explora nuestra sección de carga donde encontraras las tarifas y un formulario de contacto</label>
                            <div className='tabla_grid_button'>
                                <Link to="/tarifas-carga">Ver tarifas de carga <i class="fi fi-rr-arrow-small-right"></i> </Link> 
                            </div>                                    
                        </div>                        
                    </div>                                    
                   
                </div>
            </div>
            </>
        ): (
            <>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                        <tr>
                            <th>Pasajero</th>
                            <th>Desde/Hasta</th>
                        </tr>
                        <tr>
                            <td>Adulto <br /> <p>Derecho a embarque</p></td>
                            <td>$1,650 / $2,500</td>
                        </tr>
                        <tr>
                            <td>Niños <br /> <p>De 3 a 11 años</p></td>
                            <td>$825 / $1,250</td>
                        </tr>
                        <tr>
                            <td>Adulto Mayor <br /> <p>De 3 a 11 años</p></td>
                            <td>$1,600 / $2,450</td>
                        </tr>
                        </tbody>
                    </table>            
                </div>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Vehículo</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td>Motocicleta <br /> <p>Hasta 2 mts.</p></td>
                                <td>$3,650</td>
                            </tr>
                            <tr>
                                <td>Automóvil <br /> <p>Hasta 6 mts.</p></td>
                                <td>$6,365</td>
                            </tr>
                            <tr>
                                <td>Auto con remolque <br /> <p>Hasta 11 mts.</p></td>
                                <td>$16,980</td>
                            </tr>
                            <tr>
                                <td>Motorhome <br /> <p>Hasta 11 mts.</p></td>
                                <td>$16,980</td>
                            </tr>
                            <tr>
                                <td>Autobús <br /> <p>Hasta 13 mts.</p></td>
                                <td>$24,435</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Cabinas</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td>Exterior TV 2 Pax <br /> <p>2 camas individuales</p><p>TV, Baño completo</p></td>
                                <td>$2,400</td>
                            </tr>
                            <tr>
                                <td>Exterior TV 4 Pax <br /> <p>2 literas</p><p>TV, Baño completo</p></td>
                                <td>$2,400</td>
                            </tr>                                               
                        </tbody>
                    </table>           
                </div>
                <div className='tabla-tarifa'>
                    
                    <div className='tabla_grid'>
                        <div className=''>
                            <label>Explora nuestra sección de carga donde encontraras las tarifas y un formulario de contacto</label>
                            <div className='tabla_grid_button'>
                                <Link to="/tarifas-carga">Ver tarifas de carga <i class="fi fi-rr-arrow-small-right"></i> </Link> 
                            </div>                                    
                        </div>                        
                    </div>                                    
                   
                </div>
            </div>
            </>
        )}
        </div>
    </section>
    <section className='callToAction'>
            <div className="container">
            <div>
                <h3>¿Listo para reservar?</h3>
            </div>
            <Row>
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Consulta rutas y horarios</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                    Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Reservar ahora</Link>                                      
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Col>   
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Preguntas frecuentes</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                    Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. 
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Consultar </Link>                                      
                                </div>
                            </div>                                  
                        </div>
                    </div>
                </Col>      
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Envianos un WhatsApp</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                    Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                                                
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>        
                    
            </Row>    
            </div>
            <Outlet />
    </section>
    <Outlet />
    <Footer />
    </>
  )
}

