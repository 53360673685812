import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/faqs.css'
import SearchBar from '../SearchBar/SearchBar';
import SearchResults from '../SearchBar/SearchResults';
import Contactanos from '../contacto/Contactanos';
import Footer from '../Footer'


//import bookingIcon from '../../assets/images/booking.svg'
import documentacion from '../../assets/images/iconsPreguntas/documentacion.png'
import reservar  from "../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../assets/images/iconsPreguntas/barco-de-carga.png'
import avisos from '../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../assets/images/iconsPreguntas/factura.png'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
const questions = [
    {
        id: 1,
        text: '¿Cuánto cuesta el boleto?',
        answer: 'El precio de los boletos varía según la edad del pasajero y la temporalidad en la que realices tu viaje. Te invitamos a visitar nuestra sección de Tarifas para encontrar la mejor opción para tu viaje. ',
    },
    
    {
        id: 2,
        text: '¿Puedo dejar mi boleto abierto?',
        answer: 'Si es posible, en caso de que el pasajero no pueda viajar en su reservación original puede comunicarse al Contact center (800) 337 74 37 y solicitar dejar su boleto abierto, es importante considerar que este tendrá una vigencia de doce meses y que estos boletos requieren una confirmación 48 horas antes de la salida; en todos los casos un boleto abierto siempre está sujeto a disponibilidad de espacio.            Posteriormente cuando el cliente decida hacer uso de él, deberá cubrir un cargo por el cambio de la fecha de $580 pesos por cada boleto y en caso de existir una diferencia en la tarifa, deberá cubrirla.',
    },
    {
        id: 3,
        text: '¿Puedo cambiar la ruta de mi boleto?',
        answer: 'Es posible solicitar el cambio de ruta en la reservación cubriendo la diferencia en tarifa (en caso de existir) y pagando un cargo de $580 pesos por el cambio en el boleto.',
    },
    {
        id: 4,
        text: '¿Mi boleto incluye cabina?',
        answer: 'La compra de tu boleto no inlcuye cabina, puedes agregar una a tu reservación al momento de tu compra o bien adquirirla antes de tu viaje. Contamos con cabinas limitadas, por lo que recomendamos reservar con anticipación. ',
    },
    {
        id: 5,
        text: '¿Voy a compartir cabina con otros pasajeros?',
        answer: 'Las cabinas son para el uso exclusivo de quién la reserva, por lo tanto solo la compartes con las personas que viajan contigo. Nuestras cabinas tienen capacidad para cuatro pasajeros y no se incluyen en la compara de los boletos, estas tienen un costo adicional.',
    },
    {
        id: 6,
        text: '¿Los menores pagan boleto regular?',
        answer: 'Los menores de 3 a 11 años pagan la mitad del boleto. Infantes menores a 3 años no pagan boleto',
    },
    {
        id: 7,
        text: '¿Qué servicios están incluidos en mi reservación?',
        answer: 'Cada pasajero puede documentar hasta cinco maletas de 25 kg cada una. Abordo, te incluye una rica comida de cortesía, así como café, pan y un asiento en área de salón (sujeto a disponibilidad).  Conoce más sobre nuestros servicios abordo.',
    },
    {
        id: 8,
        text: '¿En qué horario se sirven los alimentos?',
        answer: 'La venta de alimentos y snacks en el restaurante y en el bar se encuentra abierta en horarios estipulados dentro del barco.',
    },
  

];



const Reservar = ({ faqs, handleClick }) => {
      
    
  return (
    <> 
        <section>
            <div className='header_faqs'>
                <div className='container header_faqs_box'>
                    <div className='header_faqs_title'>
                        <h3>Preguntas frecuentes</h3>
                        <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                    </div>                
                </div>
            </div>
        </section>
        <section className='container'>
            <div className='box_titulo_faqs '>
                <div className='lista_preguntas'>
                    <h3>Reserva</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-reserva/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
                <div className='lista_temas'>
                    <h3>Otros temas frecuentes</h3>
                    <div className='box_lista_temas'>
                        <ul>                                                                         
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={reservar} alt='antesViajar' />
                                    <Link to="/reserva">Reserva</Link>
                                </div>
                            </li>
                            <li> 
                                <div className='content_lista_temas'>
                                    <img src={documentacion} alt='antesViajar' />
                                    <Link to="/documentacion">Documentación</Link> 
                                </div>
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={pagos_facturas} alt='antesViajar' />
                                    <Link to="/pagos-facturas">Pagos y facturas </Link> 
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={carga} alt='antesViajar' />
                                    <Link to="/carga">Carga </Link>  
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={avisos} alt='antesViajar' />
                                    <Link to="/automovil">Automóvil</Link>
                                </div>
                            </li>
                        </ul>
                    </div>                
                </div>
            </div>
        </section>
        <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es   " target="_blank" rel="noopener noreferrer">Contactar</a>
                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
        <Footer />
        
    </>
  )
}

export default Reservar;