import React from 'react';
import { Link } from 'react-router-dom';

const questions = [
  { id: 1, text: '¿Cuál es la capital de Francia?', answer: 'París' },
  { id: 2, text: '¿Cuál es el océano más grande?', answer: 'Océano Pacífico' },
  { id: 3, text: '¿Quién escribió "Don Quijote de la Mancha"?', answer: 'Miguel de Cervantes' }
];

const FAQList = ({ faqs, handleClick }) => {
  return (
    <>
         <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Preguntas frecuentes</h3>
                        <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                    </div>                
                </div>
            </div>
        </section>
        
        
    <div>
      <h1>Lista de Preguntas</h1>
      <ul>
        {questions.map(question => (
          <li key={question.id}>
            <Link to={`/question/${question.id}`}>{question.text}</Link>
          </li>
        ))}
      </ul>
    </div>
    </>
  );
};

export default FAQList;
