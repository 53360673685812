import React from 'react'
import Navbar from '../components/Navbar';
import '../css/promociones_carga.css'
import promo1 from '../assets/images/flota/california-star.webp'
import { Outlet, Link } from "react-router-dom";
import FooterCarga from '../components/FooterCarga'
import california_star from '../assets/images/flota/california-star.webp'
export default function PromocionesCarga() {
  return (
    <>
    <Navbar />
    <section>
        <div className='header_section carga'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones</h3>
                    <p>Aprovecha nuestras ofertas y ¡planea tu próximo viaje hoy mismo!</p>
                </div>                
            </div>
        </div>
    </section>

    <section className='promociones-carga'>
        <div className='container'>
            <div className='promociones-carga-container'>
                <div className='promociones-carga-content'><img src={promo1}  alt='promo a bordo y al volante'/></div>
                <div className='promociones-carga-content'>
                    <h3>¡Atención, amigo operador de carga!</h3>
                    <p>Con Baja Ferries, además de mover tu carga, ¡puedes ganar un auto sedán y premios mensuales!</p>
                    <p>Participa en A bordo & Al Volante siguiendo estos sencillos pasos:</p>
                    <ul>
                        <li>1. Transporta tu carga con nosotros y encuentra la urna de la promoción en el ferri.</li>
                        <li>2. Calcula cuántos camiones hay en ella y envía tu respuesta junto con una foto de tu pase de abordar de pasajero y carga por WhatsApp.</li>
                        <li>3. ¡Podrías ganar si tu cálculo es el más cercano al número real! </li>
                    </ul>
                    <p>¿Qué esperas? ¡Participa hoy en A bordo & Al Volante! </p>
                </div>
                <div className='promociones-carga-content'>
                    <div className='btns_flota'>
                        <Link to="/rutas_horario" className='flota_reserva'>Ruta y horarios</Link>
                        <Link to="/promo-abordo/tyc" className='flota_ver_mas'>Términos y condiciones </Link>  
                    </div>
                </div>
            </div>
            <div className='content_fota'>
                <div className='content_img_fota'>
                    <img src={california_star} alt='california star'/>
                </div>
                <div className='promociones-carga-content'>
                    <h3>¡Atención, amigo operador de carga!</h3>
                    <p>Con Baja Ferries, además de mover tu carga, ¡puedes ganar un auto sedán y premios mensuales!</p>
                    <p>Participa en A bordo & Al Volante siguiendo estos sencillos pasos:</p>
                    <ul>
                        <li>1. Transporta tu carga con nosotros y encuentra la urna de la promoción en el ferri.</li>
                        <li>2. Calcula cuántos camiones hay en ella y envía tu respuesta junto con una foto de tu pase de abordar de pasajero y carga por WhatsApp.</li>
                        <li>3. ¡Podrías ganar si tu cálculo es el más cercano al número real! </li>
                    </ul>
                    <p>¿Qué esperas? ¡Participa hoy en A bordo & Al Volante! </p>
                </div>
                <div className='btns_flota'>
                    <Link to="/rutas_horario" className='flota_reserva'>Ruta y horarios</Link>                
                    <Link to="/servicios-california-star" className='flota_ver_mas'>Ver más </Link>  
                </div>
            </div>
        </div>        
    </section>
 
    <Outlet />
    <FooterCarga />
    
    
    </>
  )
}
