import React, { useState } from 'react';
import '../css/antes_viajar.css'
import { Outlet, Link } from "react-router-dom";
import Footer from '../components/Footer';
import promociones from '../assets/images/cta/promociones.svg'
import reservar from '../assets/images/cta/reservacion.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'


const Tab = ({ label, onClick, active, icon }) => {
    return (
      <div className={`tab_viajar ${active ? 'active' : ''}`} onClick={onClick}> 
        <p>{label}</p>
      </div>
    );
};




export default function Antes_de_viajar() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

   
  return (
    <>
    <section id="hero-section">
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Antes de tu viaje</h3>
                    <p>Consulta los requisitos para viajar y evita contratiempos</p>
                </div>                
            </div>
        </div>                                 
    </section>
 
   <sections className='info_antes_de_viajar'>
   <div className="container">
        <div className='tabs_viajar'>
            <div className="tab-container">
            <div className="tab-boxes">
            
                <Tab
                label="Compra de boletos"
                onClick={() => handleTabClick(0)}
                active={activeTab === 0}                
                />
                <Tab
                label="Tarifas especiales"
                onClick={() => handleTabClick(1)}
                active={activeTab === 1}
                icon={<i class="fi fi-rs-file-invoice-dollar"></i>}
                />
                <Tab
                label="Mujeres embarazadas"
                onClick={() => handleTabClick(2)}
                active={activeTab === 2}                
                />
                <Tab
                label="Pasajeros con movilidad reducida"
                onClick={() => handleTabClick(3)}
                active={activeTab === 3}                
                />
                
                <Tab
                label="Documentación para viajar"
                onClick={() => handleTabClick(4)}
                active={activeTab === 4}            
                />
                <Tab
                label="Mascotas"
                onClick={() => handleTabClick(5)}
                active={activeTab === 5}                
                />
                <Tab
                label="Viaja con tu auto"
                onClick={() => handleTabClick(6)}
                active={activeTab === 6}            
                />
                <Tab
                label="Viaja en grupo"
                onClick={() => handleTabClick(7)}
                active={activeTab === 7}
                />
                 <Tab
                label="Pasajeros con motocicleta"
                onClick={() => handleTabClick(8)}
                active={activeTab === 8}
                />
            </div>
            </div>
            <div className="content-container">
            
                {activeTab === 0 && 
                <div className='info_antes_viaje'>
                    <h4>Compra de boletos</h4>
                    <p>La compra de boletos la puede realizar de distintas formas:</p>
                    <ul>
                        <li>1. Desde la página principal de nuestra página web </li>
                        <li>2. Acudiendo directamente a ventanilla en nuestros <label><Link to="/contacto">puntos de venta</Link></label>  </li>
                        <li>3. Llamando a nuestro Contact Center <a href="tel:8003377437" rel="noopener noreferrer"><span >(800-337-7437)</span></a></li>
                        <li>4. Enviando un mensaje de  <label><a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">WhatsApp</a> </label> </li>
                    </ul>
                    <p>Al terminar el proceso de compra, recibirás tu confirmación al correo que registraste en tu reservación. El boleto es personal e intransferible, no será válido si presenta tachaduras, enmendaduras o alteraciones. </p>
                    <p>Adicionalmente te recomendamos adquirir tus boletos con anticipación para asegurar tu viaje. </p>
                    <p>Conoce nuestras <label><Link to="/tarifas">tarifas especiales</Link></label>  y <label><Link to="/promociones">promociones </Link></label>.</p>
                    <p>Reserva tu viaje</p>
                    <p> <strong>Boleto abierto:</strong>  Si por alguna razón no pudiste completar tu viaje el día planeado, tienes 12 meses a partir de la fecha de compra para hacer uso de tu boleto, solo debes tomar en cuenta las siguientes consideraciones:</p>                                                               
                    <ul>
                        <li>1. Debes notificar a nuestro equipo 48 horas antes de tu nueva fecha de viaje.</li>
                        <li>2. Durante los 12 meses, tienes oportunidad de cambiar tu boleto tres veces cubriendo la  cantidad de $580.00 pesos más diferencia de tarifa (en caso de existir) en cada ocasión. </li>
                        <li>3. El cambio de tu boleto siempre estará sujeto a disponibilidad de espacio. Consulta nuestras <label><Link to="/rutas_horario">rutas y horarios</Link> y <Link to="/tarifas">tarifas</Link></label> </li>
                        <li>4. El boleto es personal e intransferible.</li>
                    </ul>
                
                    <p><label><Link to="/aviso-de-privacidad">Aviso de privacidad</Link>/<Link to="/condiciones-de-servicio">condiciones de servicio</Link></label> </p>
                    <p>
                        <strong>Boleto extraviado/Caducidad:</strong> Si extraviaste tu boleto impreso, su reimpresión tiene un cargo adicional de $580.00 ( quinientos ochenta pesos 00/100 M.N.) por boleto por tramo de viaje. Debes presentarte con tu idenfiticación oficial en el mostrador del punto de venta en el puerto con mayor antelación a los tiempos señalados para embarcar, ahí localizarán tu reserva y te reimprimiran tu boleto.  <label><Link to="/contacto">Contact Center </Link> </label>.
                    </p>
                    <p>Este cargo no es reembolsable en caso de que localices tu boleto original previo al embarque. </p>

                </div>}
                {activeTab === 1 && 
                <div className='info_antes_viaje'>
                    <h4>Tarifas especiales </h4>
                    <p><strong>Adultos mayores:</strong> Al presentar la credencial de INAPAM se otorga una tarifa de promoción sobre el boleto publicada en el tarifario vigente.  </p>
                    <p><strong>Menores de 3 años:</strong> Los infantes menores de tres años cumplidos están exentos de pago. Esta excepción aplica para un máximo de dos menores por familia. En caso de exceder el máximo establecido, el tercer menor pagarán el 50% del precio vigente de la tarifa de adulto. </p>
                    <p><strong>Menores de 3 a 11 años:</strong> Los menores de tres a once años cumplidos pagan el 50% del precio vigente de la tarifa de adulto.</p>
                </div>}
                {activeTab === 2 && 
                <div className='info_antes_viaje'>                           
                        <h4>Mujeres embarazadas </h4>
                        <p>Si estás embarazada o viajas con alguien en gestación, debes informar al personal de la empresa sobre dicha condición al comprar tu boleto y presentar un certificado médico expedido máximo 7 días antes de tu fecha de viaje que debe indicar lo siguiente:  </p>
                        <ul>
                            <li>a) Tus datos personales (nombre completo y edad).</li>
                            <li>b) Semanas de gestación y tipo de embarazo (simple o múltiple).</li>
                            <li>c) Fecha probable de parto.</li>
                            <li>d) La aprobación médica que conste te encuentras en condiciones óptimas para viajar el/los días señalados.</li>
                            <li>e) El periodo de validez del certificado.</li>
                            <li>f) El nombre, número de cédula, firma y datos de contacto del médico que lo emite. Antes de embarcar nuestro médico abordo puede realizar una valoración, para determinar la viabilidad o no, de permitir su embarque. Por seguridad, si el tiempo de gestación es igual o mayor a 31 semanas (7 meses) no se permite viajar.</li>
                        </ul>
                        <p>En caso de no declararlo será responsabilidad del pasajero, cualquier complicación que se pudiese presentar durante la travesía, lo mismo para personas con alguna situación de salud critica o limitaciones físicas no declaradas. Por seguridad, no se permite viajar a mujeres embarazadas a partir del séptimo mes de embarazo.</p>
                    </div>}
                {activeTab === 3 && <div className='info_antes_viaje'>
                    <h4>Pasajeros con movilidad reducida</h4>
                                        <p>Si tú o alguna persona que viaja contigo tiene alguna limitación de movilidad debido a la edad, enfermedad, accidente o alguna otra condición, debes informar al personal de la empresa al momento de comprar el boleto. De esta manera, estaremos preparados para brindarte la atención y la información que necesitas para tu viaje con nosotros.</p>
                                        <p>Si llegas al día de tu viaje sin habernos informado previamente sobre dicha condición y el buque no está equipado para atenderte adecuadamente, lamentablemente deberemos reprogramar tu salida. Tu seguridad y comodidad son nuestra prioridad, por lo que es importante que nos notifiques con anticipación para que podamos ofrecerte la mejor experiencia de viaje posible. </p>
                                        <p><label><Link to="/aviso-de-privacidad">Aviso de privacidad</Link>/<Link to="/condiciones-de-servicio">Condiciones de servicio</Link></label> </p>
                                        </div>}
                {activeTab === 4 && <div className='info_antes_viaje'> <h4>Documentación para viajar </h4>
                                        <p><strong>Adultos:</strong> Para poder embarcar, cada pasajero debe presentar su pase de abordar original, así como una identificación oficial con fotografía (INE, licencia de conducir, pasaporte, cartilla militar, cédula profesional).</p>
                                        <p>Tu pase de abordar no será válido si presenta tachaduras, enmendaduras o alteraciones. Sin estos requisitos no podrás viajar.</p>
                                        <p><strong>Menores de edad:</strong> Los pasajeros menores de 18 años deben presentar alguna de las siguientes identificaciones oficiales: pasaporte nacional o internacional, acta de nacimiento o CURP para corroborar su identidad.</p>
                                        <p>En caso de que el menor de edad no vaya acompañado por alguno de sus padres, el adulto a cargo deberá presentar una carta de consentimiento firmada junto con una identificación oficial del padre o madre donde estos autorizan que el menor pueda viajar.</p>
                                        <p> <strong>Extranjeros:</strong> Para viajar con nosotros solo necesitas presentar tu pasaporte y boleto. Sin embargo, en el puerto se encuentran autoridades de migración quienes pueden pedirte tu <label><a href='https://www.gob.mx/tramites/ficha/internacion-al-territorio-nacional-de-personas-extranjeras-titulares-de-una-visa-ordinaria-valida-y-vigente-expedida-por-el-gobierno-mexicano/INM619' target="_blank" rel='noopener noreferrer' >Forma Migratoria Múltiple (FMM)</a></label>, o tu visa de residente permanente o temporal. </p>                                
                                        <p> <strong>Mascotas:</strong> Debido a los requisitos de las diferentes autoridades que regulan el transporte marítimo, se recomienda llevar: </p>
                                        <ul>
                                            <li>1. Original y copia del certificado de vacunación (rabia, parvovirus, moquillo, etc.) </li>
                                            <li>2. Certificado de salud (estado de salud y desparasitación) expedido por Médico Veterinario con cédula profesional donde consten sus datos de contacto, con fecha de expedición menor a 6 días antes de la fecha de viaje. </li>
                                            <li>3. Si tu mascota se encuentra en periodo de gestación, debes presentar una nota o constancia, expedida por un médico veterinario, en el que se evalúe el estado general de salud y recomiende que puede realizar viaje. </li>
                                            <li>4. Si tu mascota es menor de 8 semanas, debes presentar una nota o constancia expedida por un médico veterinario en el que se evalúe el estado general de salud y recomiende su transporte.</li>
                                            <li>5. Si viajas con animales de servicio, debes mostrar su certificado médico y la certificación original y oficial de adiestramiento para animales de servicio.</li>
                                        </ul>
                                        <p> <strong>Vehículos:</strong> Para que tu vehículo viaje con nosotros solo es necesario contar y proporcionar la información principal del auto al momento de realizar la compra del boleto: </p>
                                        <ul>
                                            <li>Modelo</li>
                                            <li>Marca</li>
                                            <li>Placa</li>
                                        </ul>
                                        <p>Es importante destacar que la operación de los puertos en los que estamos presentes es independiente a la empresa. Cada puerto tiene sus propios procedimientos y controles de acceso, además operan distintas autoridades (Aduana, SEMAR, SEDENA) quienes cuentan con controles de seguridad propios. Por lo tanto, es fundamental que estés preparado y cuentes con la siguiente documentación antes de ingresar:</p>
                                        <ul>
                                            <li>Copia de la factura de compra del auto</li>
                                            <li>Tarjeta de circulación</li>
                                            <li>Licencia de conducir vigente</li>
                                            <li>Póliza del seguro del auto en vigente</li>
                                        </ul>
                                        <p>En caso de que tu vehículo sea fronterizo o tenga placas de otro país, necesitarás tramitar el permiso de “Importación Temporal”, el cual puedes obtener en las instalaciones de Banjercito. Puedes consultar las tarifas y documentos requeridos en su <label><a href='https://www.banjercito.com.mx/registroVehiculos/' target="_blank" rel='noopener noreferrer' >página web.</a> </label></p>

                                    </div>}
                {activeTab === 5 && 
                <div className='info_antes_viaje'>
                    <h4>Mascotas </h4>
                        <p><strong>Boletos:</strong> Cada pasajero puede llevar máximo dos mascotas por viaje. Su boleto tiene costo adicional de $650.00 (quinientos noventa pesos 00/100 M.N.) por mascota y en viaje sencillo.</p>                                
                        <p><strong>Transporte:</strong> Para que tu mascota pueda embarcar, es indispensable contar con jaula transportadora con espacio suficiente que le permita moverse libremente en su interior y recostarse en una posición natural. No se permite viajar mascotas de especies distintas en la misma jaula. Se recomiendan las siguientes medidas:</p>                                
                        
                        <table class="table table-bordered mascota-tabla">
                            <tbody>            
                                <tr>                                    
                                    <th colSpan={2} className="">Tipo de mascota	</th>
                                    <th colSpan={3} className="">Dimensión mínima de la jaula</th>                                    
                                </tr>
                                <tr>
                                    <td>Gatos</td>
                                    <td className="">Perros</td>
                                    <td className="">Ancho</td>
                                    <td className="">Largo</td>
                                    <td className="">Altura</td>
                                </tr>
                                <tr>
                                    <td>Chicos</td>
                                    <td className="">Muy chicos</td>
                                    <td className="">30 cm</td>
                                    <td className="">50 cm</td>
                                    <td className="">37 cm</td>
                                </tr>
                                <tr>
                                    <td>Grandes</td>
                                    <td className="">Chicos</td>
                                    <td className="">42 cm</td>
                                    <td className="">60 cm</td>
                                    <td className="">30 cm</td>			        					      
                                </tr>	
                                <tr>
                                    <td></td>
                                    <td className="">Medianos</td>
                                    <td className="">52 cm</td>
                                    <td className="">75 cm</td>
                                    <td className="">50 cm</td>	
                                </tr>	

                                <tr>
                                    <td></td>
                                    <td className="">Grandes</td>
                                    <td className="">57 cm</td>
                                    <td className="">87 cm</td>
                                    <td className="">65 cm</td>	
                                </tr>
                                <tr>
                                    <td></td>
                                    <td className="">Muy grandes</td>
                                    <td className="">65 cm</td>
                                    <td className="">1.10 cm</td>
                                    <td className="">75 cm</td>	
                                </tr>
                                			                                            
                            </tbody>
                        </table>
                        
                        <p>Las mascotas viajan en un área especial del barco y podrás visitarla durante la travesía con previo aviso y acompañada por un miembro de la tripulación o personal de seguridad.  Algunos barcos de nuestra flota cuentan con servicio de cabinas petfriendly, al reservarla, puedes llevar tu mascota contigo durante la travesía. Sin embargo, por la seguridad de los pasajeros, tripulación y de tu mascota, no está permitido retirarlas de su jaula o de la cabina durante el viaje.</p>
                        <p>No se ofrece servicio de traslado de mascotas sin pasajero a bordo. Si viajas con auto, tu mascota no puede quedarse dentro de éste ya que representa un peligro para su salud. El número de mascotas abordo está sujeto a disponibilidad. </p>
                        <p>Los animales permitidos para transportarse en el área de mascotas son: perros, gatos, aves (excepto de rapiña), peces, reptiles (no mayor a un metro de largo), roedores, anfibios, insectos, crustáceos, hurones, primates pequeños (no más de 20 kg) </p>
                        <p>El servicio de transporte de mascotas se encuentra sujeto a condiciones especiales que debes solicitar y aceptar de forma virtual por internet o ante personal en ventanilla sin lo cual no será posible brindarte el servicio</p>
                        <p><strong>Animales de servicio: </strong>Los animales de servicio (entendiéndose por éstos exclusivamente aquellos que han sido entrenados en un centro de adiestramiento para dar servicio y asistencia a las personas con alguna discapacidad, con el fin de mejorar su calidad de vida y autonomía personal, y el que a su vez requiere de éste para su subsistencia) quedan exentos del uso de jaula y podrán desplazarse junto a ti durante la travesía exceptuando las áreas restringidas indicadas por la tripulación. </p>
                        <p>Consulta la <label  onClick={() => handleTabClick(4)} style={{color:'#0075C9', cursor: 'pointer', textDecoration: 'underline'}}>documentación</label> que tu mascota necesita para viajar.</p>                                                                
                </div>}


                {activeTab === 6 && <div className='info_antes_viaje'>
                    <h4>Viaja con tu auto </h4>
                    <p>Cómo vehículos se consideran aquellos autos de uso personal o familiares. El costo del embarque del vehículo dependerá del tamaño, tipo de vehículo y de la ruta que hayas elegido para viajar. </p>
                    <p>Una gran ventaja de llevar tu auto es que dentro del mismo puedes llevar todo el equipaje que requieras. </p>
                    <p>Consulta nuestras <label><Link to="/tarifas">tarifas</Link></label> y la <label onClick={() => handleTabClick(4)} style={{color:'#0075C9', cursor: 'pointer', textDecoration: 'underline'}}>documentación</label> necesaria para que viajes con tu auto. </p>                                    
                </div>}
                {activeTab === 7 && <div className='info_antes_viaje'>
                    <h4>Viaja en grupo </h4>
                    <p>Si estas planeando un viaje en ferry ¡hacerlo en grupo tiene múltiples ventajas! Para poder realizar una reserva de grupo, es necesario que este conformado por al menos 20 personas. </p>
                    <p>Para solicitar una cotización solo tienes que llenar nuestro <label><Link to="/viaje-en-grupo">formulario</Link> </label> y nuestra área de Atención a Grupos se pondrá en contacto contigo. </p>
                    </div>}
                {activeTab === 8 && <div className='info_antes_viaje'>
                <h4>Pasajeros con motocicleta</h4>
                <p>Para garantizar un viaje seguro y cómodo tanto para nuestros pasajeros como para sus motocicletas, por favor tome en cuenta las siguientes indicaciones antes de embarcar: </p>
                <p><strong>Cinchos de seguridad:</strong> Es necesario que cada motocicleta sea asegurada adecuadamente durante el transporte. Para ello, se requiere que los pasajeros cuenten con al menos tres cinchos de buena calidad. Estos se utilizarán para fijar la motocicleta en tres puntos diferentes, de esta manera, viajara de forma segura durante la travesía evitando cualquier movimiento que pueda dañarla o causar inconvenientes.</p>
                <p>* No es posible trasladar motocicletas sin conductor.</p>
                </div>
                }
                
            </div>
        </div>
    </div>
   </sections>


   <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Promociones y Descuentos</h4>
                                </div>
                                <div>
                                    <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/promociones">Ver más</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={reservar} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Listo para reservar?</h4>
                                </div>
                                <div>
                                    <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>        
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Rutas y Horarios</h4>
                                </div>
                                <div>
                                    <p>Explora nuestras rutas y encuentra el horario que mejor se ajuste a tus planes de viaje.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/rutas_horario">Ir ahora</Link>                  
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Outlet />
    <Footer />
    
    </>
  )
}
