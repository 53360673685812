import React, {useState} from 'react'
import '../css/destinos.css'
import { Outlet, Link } from "react-router-dom";
import pichilingue from '../assets/images/destinos/pichilingue.webp';
import mazatlan  from '../assets/images/destinos/mazatlan.png';
import mochis from '../assets/images/destinos/mochis.webp';
import Footer from '../components/Footer'
import { Col, Row,  } from 'antd';
import reservacion from '../assets/images/cta/reservacion.svg'
import viajeGrupo from '../assets/images/cta/viajegrupo.svg'
import reserva from '../assets/images/cta/reservacion.svg'
import Booking from '../components/form/Booking';
import Mapa from '../components/maps/Mapa'
export default function Destinos() {

    const [isHovered, setIsHovered] = useState(null);


  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Destinos </h3>
                    <p>Nosotros te llevamos y tú decides qué deseas explorar de estos increíbles lugares. </p>
                </div>                
            </div>
        </div>
    </section>
    <section>
        <Booking />
    </section>

    <section className='d-none d-sm-none d-md-block'>
        <div className='container'>
            <div className='destinos_content_general'>
                <div className='box_content_destinos'>
                <Link to="/destino-la-paz">
                    <div className='box_group_card_destino' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${pichilingue})` }} onMouseEnter={() => setIsHovered(1)} onMouseLeave={() => setIsHovered(null)}>
                        <div className='boxes_content'>
                            <div className='box_content_title'>
                                <h2>La Paz, Baja California Sur.</h2>
                            </div>
                            <div className='box_content_subtext'>
                                
                                {isHovered === 1 && <p>La puerta de entrada al Mar de Cortés, ideal para unas vacaciones rodeadas de naturaleza, aventura y gastronomía.</p>}
                            </div>
                           
                        </div>
                    </div>
                    </Link>
                </div>
                             
                    <div className='box_content_destinos'> 
                    <Link to="/destino-mazatlan">
                        <div className='box_group_card_destino ' style={{  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${mazatlan})` }} onMouseEnter={() => setIsHovered(2)}
      onMouseLeave={() => setIsHovered(null)}>
                            <div className='boxes_content'>
                                <div className='box_content_title'>
                                    <h2>Mazatlán, Sinaloa.</h2>
                                </div>
                                <div className='box_content_subtext'>
                                    {isHovered === 2 && <p> Conocida como “La Perla del Pacífico” es de los destinos más visitados en México por turistas nacionales e internacionales. </p>}
                                </div>
                               
                            </div>
                        </div>
                        </Link>
                    </div>                
                    <div className='box_content_destinos'>  
                    <Link to="/destino-los-mochis">
                        <div className='box_group_card_destino' style={{  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${mochis})` }}  onMouseEnter={() => setIsHovered(3)}
      onMouseLeave={() => setIsHovered(null)}>
                            <div className='boxes_content'>
                                <div className='box_content_title'>
                                    <h2> Los Mochis, Sinaloa.</h2>
                                </div>
                                <div className='box_content_subtext'>
                                   {isHovered === 3 && <p> La primera parada del ferrocarril Chihuahua-Pacífico, es la base perfecta para iniciar la expedición hacia las Barrancas del Cobre.</p>}
                                </div>                               
                            </div>
                        </div>
                    </Link>
                    </div>
                
            </div>
        </div>
    </section>

    <section className='d-block d-sm-block d-md-none'>
    <div className='container'>
            <div className='destinos_content_general'>
                <div className='box_content_destinos'>
                <Link to="/destino-la-paz">
                    <div className='box_group_card_destino' style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${pichilingue})`, height: '170px' }}>
                        <div className='boxes_content'>
                            <div className='box_content_title'>
                                <h2>La Paz, Baja California Sur</h2>
                            </div>
                            {
                                /*
                            <div className='box_content_subtext'>
                                <p>La puerta de entrada al Mar de Cortés, ideal para unas vacaciones rodeadas de naturaleza, aventura y gastronomía.</p>
                            </div>
                             */
                        }
                           
                        </div>
                    </div>
                    </Link>
                </div>
                             
                    <div className='box_content_destinos'> 
                    <Link to="/destino-mazatlan">
                        <div className='box_group_card_destino ' style={{  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${mazatlan})`, height: '170px'  }}>
                            <div className='boxes_content'>
                                <div className='box_content_title'>
                                    <h2>Mazatlán, Sinaloa.</h2>
                                </div>
                                {
                                /*
                                <div className='box_content_subtext'>
                                    <p>
                                    Conocida como “La Perla del Pacífico” es de los destinos más visitados en México por turistas nacionales e internacionales.                                    
                                    </p>
                                </div>
                                */ }
                               
                            </div>
                        </div>
                        </Link>
                    </div>                
                    <div className='box_content_destinos'>  
                    <Link to="/destino-los-mochis">
                        <div className='box_group_card_destino' style={{  backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(${mochis})`, height: '170px'  }}>
                            <div className='boxes_content'>
                                <div className='box_content_title'>
                                    <h2> Los Mochis, Sinaloa.</h2>
                                </div>
                                {
                                /*
                                <div className='box_content_subtext'>
                                    <p>
                                    La primera parada del ferrocarril Chihuahua-Pacífico, es la base perfecta para iniciar la expedición hacia las Barrancas del Cobre.                                
                                    </p>
                                </div>     
                                 */ }                          
                            </div>
                        </div>
                    </Link>
                    </div>
                
            </div>
        </div>
    </section>
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reserva} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Rutas y horarios</h4>
                            </div>
                            <div>
                                <p>Ahora que conoces nuestros destinos, encuentra la ruta y el horario que más se ajuste a tus planes de viaje.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas_horario">Ir ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={viajeGrupo} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Viaja en grupo</h4>
                            </div>
                            <div>
                                <p>Si viajas en grupo de 20 personas o más, solicita tu cotización y accede a tarifas y servicios especiales.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/viaje-en-grupo">Cotizar ahora</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                       
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>
        <Outlet />
    <Footer />
        
    
    
    </>
  )
}
