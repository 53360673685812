import React from 'react'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import '../css/certificaciones.css'
import lrqa9001 from '../assets/images/certificaciones/9001.jpg'
import lrqa1401 from '../assets/images/certificaciones/14001.jpg'
import distintivoH from '../assets/images/certificaciones/distintivo-h.png'
import esr from '../assets/images/certificaciones/esr.png'
import esr_inclusion from '../assets/images/certificaciones/esr-inclusion.png'
import prime from '../assets/images/certificaciones/prime.png'
import puntolimpio from '../assets/images/certificaciones/punto-limpio.png'
import safe_travel from '../assets/images/certificaciones/safe-travel.png'
import etica_valores from '../assets/images/certificaciones/etica-valores.png'
import imss from '../assets/images/certificaciones/imss.png'

import mejores_empresas from '../assets/images/certificaciones/mejores-empresas.png'
import mejores_practicas from '../assets/images/certificaciones/mejores-practicas.jpg'
import empresas_excepcionales from '../assets/images/certificaciones/empresas-excepcionales.png'

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import Footer from '../components/Footer'

export default function Certificaciones() {
  return (
    <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Certificaciones</h3>
                    <p style={{textAlign:'center'}}>Conoce las certificaciones y distintivos que avalan nuestro compromiso con el medio ambiente, <br /> la seguridad marítima y la excelencia en el servicio.</p>
                </div>                
            </div>
        </div>
    </section>


    <section className='certificaciones'>

        <section className='container'>
            <div className='certificaciones_list'>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={lrqa9001} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>ISO 9001 </label>
                    <p>La norma ISO 9001 certifica a las empresas por implementar un correcto Sistema de Gestión de Calidad.</p>
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={lrqa1401} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>ISO 14001</label>
                    <p>Se otorga a las organizaciones que demuestran tener implementado,mantenido y mejorado, un Sistema de Gestión Ambiental.</p>
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={prime} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Distintivo PRIME</label>
                     <p>La certificación PRIME se otorga a empresas por la implementación de planes estratégicos que les permitan cumplir con estándares de gobierno corporativo.</p>
                </div>                
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={esr} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>ESR</label>
                    <p>Este distintivo reconoce a las empresas que han asumido el compromiso público de integrar los lineamientos en ética empresarial, calidad de vida en la empresa, su vinculación con la comunidad y el cuidado y preservación del medio ambiente.</p>
                     {/*<p>Otorgado por: Centro Mexicano para la Filantropía (CEMEFI) y la Alianza por la Responsabilidad Social Empresarial (AliaRSE)</p>*/}
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={esr_inclusion} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>ESR Inclusión Social</label>
                    <p>Este distintivo reconoce a las empresas que han asumido el compromiso público de integrar los lineamientos en ética empresarial, calidad de vida en la empresa, su vinculación con la comunidad y el cuidado y preservación del medio ambiente.</p>
                    {/*<p>Otorgado por: Centro Mexicano para la Filantropía (CEMEFI) y la Alianza por la Responsabilidad Social Empresarial (AliaRSE)</p>*/}
                </div>
 
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={distintivoH} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Distintivo "H"</label>
                    <p>Reconocimiento que otorgan la Secretaría de Turismo y la Secretaría de Salud a aquellos establecimientos fijos de alimentos y bebidas por cumplir con los estándares de higiene que marca la Norma Mexicana NMX-F-605-NORMEX-2018.</p>
                    {/*<p>Otorgado por: Secretaría de Turismo en colaboración con la Secretaría de Salud del Gobierno Federal</p>*/}
                </div>
                
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={safe_travel} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Safe Travel</label>
                    <p>Califica empresas o lugares turísticos que cumplen con los protocolos de sanidad correspondientes ante la pandemia por COVID-19.</p>
                    {/*<p>Otorgado por: entidades gubernamentales y/o organizaciones turísticas nacionales en colaboración con el Consejo Mundial de Viajes y Turismo (WTTC)</p>*/}
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={mejores_practicas} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Mejores Prácticas RSE</label>
                    <p>Este reconocimiento de CEMEFI distingue a las empresas que implementan iniciativas y programas que demuestran contribuir a la sustentabilidad social, económica y ambiental de la empresa y sus grupos de interés.</p>
                    {/*<p>Otorgado por: Secretaría de Turismo, avalado por la Secretaría de Salud y la Secretaría del Trabajo y Previsión Social</p>*/}
                
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={etica_valores} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Etica y Valores</label>
                    <p>Premio que otorga la CONCAMIN a las organizaciones que están aplicando los principios éticos en su operación así como la responsabilidad social y el desarrollo sostenible.</p>
                    {/*<p>Otorgado por: Secretaría de Turismo en colaboración con la Secretaría de Salud del Gobierno Federal</p>*/}
                </div>
                
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={imss} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>IMSS</label>
                    <p>Este distintivo se otorga por las buenas prácticas sanitarias implementadas en las instalaciones de la empresa y áreas de trabajo.</p>
                    {/*<p>Otorgado por: entidades gubernamentales y/o organizaciones turísticas nacionales en colaboración con el Consejo Mundial de Viajes y Turismo (WTTC)</p>*/}
                </div>
                
              
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={mejores_empresas} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Mejores Empresas Mexicanas</label>
                    <p>Reconocimiento otorgado por Citibanamex, Deloitte y el Tecnológico de Monterrey a aquellas empresas que se destacan por su alto desempeño en la gestión de los negocios.</p>
                    {/*<p>Otorgado por: Secretaría de Turismo, avalado por la Secretaría de Salud y la Secretaría del Trabajo y Previsión Social</p>*/}
                
                </div>
                <div className='certificaciones_box'>
                    <div className='certificaciones_box_img'>
                        <img src={empresas_excepcionales} alt='certificacion iso 9001 y 14001'/>
                    </div>
                    <label>Empresas Excepcionales</label>
                    <p>Este reconocimiento lo entrega el CCE, Consejo de la Comunicación y el Instituto para el fomento a la Calidad a empresas que fomentan y desarrollan la dimesión social de las empresas.</p>
                    {/*<p>Otorgado por: Secretaría de Turismo en colaboración con la Secretaría de Salud del Gobierno Federal</p>*/}
                </div>

            </div>
         </section>
    </section>
    <section className='callToAction'>
            <div className="container" id="content">
            <div className='title_callToAction'>
                <h3>¿Listo para reservar?</h3>
            </div>
        <Row>
        <Col span={8}>
                <div className='box_group-card'>
                    <div className='box_content-card contact-box-card'>
                        <div className='box_icon-card'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='boxes_content'>
                            <div className='box_content-title'>
                                <h2>Consulta nuestras tarifas</h2>
                            </div>
                            <div className='box_content-subtext'>
                                <p>
                                    Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                </p>
                            </div>
                            <div className='box_content-btn'>
                                <Link to="?">Reservar ahora</Link>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </Col>   
            <Col span={8}>
                <div className='box_group-card'>
                    <div className='box_content-card contact-box-card'>
                        <div className='box_icon-card'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='boxes_content'>
                            <div className='box_content-title'>
                                <h2>Preguntas frecuentes</h2>
                            </div>
                            <div className='box_content-subtext'>
                                <p>
                                    Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                </p>
                            </div>
                            <div className='box_content-btn'>
                                <Link to="?">Consultar </Link>                                      
                            </div>
                        </div>
                    </div>
                </div>
            </Col>      
            <Col span={8}>
                <div className='box_group-card'>
                    <div className='box_content-card contact-box-card'>
                        <div className='box_icon-card'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='boxes_content'>
                            <div className='box_content-title'>
                                <h2>Envianos un WhatsApp</h2>
                            </div>
                            <div className='box_content-subtext'>
                                <p>
                                    Te responderemos lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                </p>
                            </div>
                            <div className='box_content-btn'>
                                <Link to="?">Enviar WhatsApp </Link>                                  
                            </div>
                        </div>

                    </div>
                </div>
            </Col>        
                  
        </Row>    
        </div>
    <Outlet />
        </section>
    <Footer />
    </>
  )
}
