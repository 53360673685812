import React from 'react'

export default function Ganadores() {
  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Abordo y al volante  </h3>
                    <p></p>
                </div>                
            </div>
        </div>
    </section>
        
        
        
        </>
  )
}
