import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
export default function NewsletterForm() {
    const [editing, setEditing] = useState (false);
    const [task, setTask] = useState({
        email:'',
        fecha_alta: '',
        fecha_baja:'',
    })

    const navigate = useNavigate();
    const params = useParams();

    const handleChange = (e) =>
        setTask({...task, [e.target.name]: e.target.value});
        //console.log(e.target.name, e.target.value);

    const loadTask = async (id) => {
        const response = await fetch(`http://localhost:3004/newsletter/${id}`);
        const data = await response.json();
        //console.log(data);
        setTask({email: data.email, fecha_alta: data.fecha_alta, fecha_baja: data.fecha_baja});
        setEditing(true);
    }

    useEffect(() =>{
        if(params.id){
            loadTask(params.id);
        }
        
    },[params.id]);
   
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log("task: "+task); 
        if(editing){
            const response =await fetch(`http://localhost:3004/newsletter/${params.id}`,{
                method:'PUT',                
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(task),
            });
            const data = await response.json();
            //console.log("Editando.." + data);
        }else{
            await fetch('http://localhost:3004/newsletter',{
                method:'POST',
                body: JSON.stringify(task),
                headers: {'Content-Type': 'application/json'},
            });
        
           // const data = await res.json();
        }

        //console.log(data);
        navigate("/newsletterlist");

        //Redirigir al usuario a la página de inicio (por ejemplo, '/')
        //history.push('/');
        //history.push('/home.js');
        //window.location.href = '/';
    }
  return (
    <section className='container'>
        <form onSubmit={handleSubmit}>
            <div>
                <label>Email</label>
                <input type='email' name='email' value={task.email} onChange={handleChange}/>
            </div>
            <div>
                <label>Fecha alta</label>
                <input type='date' name='fecha_alta' value={task.fecha_alta} onChange={handleChange}/>
            </div>
            <div>
                <label>Fecha baja</label>
                <input type='date' name='fecha_baja' value={task.fecha_baja} onChange={handleChange} />
            </div>
            
            <div>
            {/* 
            <button disabled={!task.title || !task.description} >
                Enviar
            </button>
            */}

            <button type='submit'>
                Enviar
            </button>
            
            </div>
        </form>

    </section>
   
  )
}
