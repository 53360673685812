import React from 'react'
import Navbar from '../components/Navbar';
import '../css/multimodal.css'
import multimodal from '../assets/images/carga/multimodal/multimodal.webp'
import intermodal from '../assets/images/carga/multimodal/intermodal.webp'
import CargaForm from '../components/form/CargaForm';
import FooterCarga from '../components/FooterCarga'
import { Outlet, Link } from "react-router-dom";
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'

export default function Multimodal() {
  return (
    <> 
    <Navbar />
   
    <section className='header_carga multimodal'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>Soluciones Multimodales</h2>
                    <p>Combinamos diferentes modos de transporte para optimizar tiempos y costos, garantizando la entrega puntual y segura de tus mercancías.</p>
                </div>
            </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
            <div className='servicio_carga_box'>
                    <img src={intermodal} alt='servicio multimodal baja ferries' />
                </div>
                <div className='servicio_carga_box feedering'>
                    <h2>Multimodal </h2>
                    <p>Baja Ferries Multimodal es una opción más verde y eficiente para el transporte de mercancías puerta a puerta desde el centro de producción o distribución hacia Baja California Sur, integrando los servicios de transporte marítimo, autotransporte y ferroviario. Utilizando nuestro servicio, garantizamos la flexibilidad necesaria para adaptarnos a las necesidades específicas de cada envío, todo bajo un mismo documento de embarque.</p>
                    <div className='btn_servicio_transporte'>                        
                    <a href="mailto:contacto.comercial@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>                                                          
                    </div>
                </div>
                
            </div>
        </div>
    </section>
    {/*
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box proyecto'>
                    <h2>Intermodal  </h2>
                    <p>El Circuito Intermodal se refiere a la combinación de diferentes servicios de transporte por separado para mover mercancías desde el punto de origen hasta el destino final. En este enfoque, las mercancías pueden ser transportadas inicialmente por un modo de transporte (por ejemplo, camión) desde el punto de origen hasta un centro de carga intermodal (como un puerto o una terminal ferroviaria), donde son transferidas a otro modo de transporte (por ejemplo, tren o barco) para el tramo final del viaje. </p>
                    <p>El circuito intermodal implica la coordinación de servicios de transporte independientes pero complementarios para lograr una entrega eficiente de las mercancías. Este enfoque puede ser parte de una solución multimodal más amplia en la que se integran y coordinan múltiples modos de transporte para satisfacer las necesidades específicas de la cadena de suministro.</p>
                    <div className='btn_servicio_transporte'>                        

                    </div>
                </div>
                <div className='servicio_carga_box'>
                    <img src={multimodal} alt='servicio multimodal baja ferries' />
                </div>
            </div>
        </div>
    </section>
    */}
    <section className="callToAction carga">
        <div className='container'>

            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Transporte de carga rodada</h4>
                            </div>
                            <div>
                                <p>Contamos con servicio  de ferry con frecuencia diaria entre los puertos de Mazatlán, Topolobampo y La Paz para llevar tu carga desde o hacia B.C.S.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario-carga">Ver horario</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Soluciones terrestres</h4>
                            </div>
                            <div>
                                <p>Creamos soluciones eficaces para el transporte de tus mercancías por carretera.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/terrestre">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Tienes un proyecto especial?</h4>
                            </div>
                            <div>
                                <p>Si necesitas transportar mercancía de grandes dimensiones y peso, trabajamos contigo para gestionar tu proyecto especial.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Contactar </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>
    <FooterCarga />
    
    <Outlet />
    
    </>
  )
}
