import React from 'react'
import '../../css/destino.css'
import { Outlet, Link } from "react-router-dom";
import promociones from '../../assets/images/cta/promociones.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/pregutas.png'
import ImageSlider from '../sliders/SliderDestinos';
import Footer from '../Footer'
import {img1Mochis, img2Mochis, img3Mochis} from '../../assets/base64'
export default function Mochis() {
    const images = [

        {
            image: img1Mochis,
            title: 'Pueblo Mágico El Fuerte',
            text: 'Este poblado ofrece grandes atractivos naturales, históricos, arquitectónicos y culturales además de arraigadas tradiciones indígenas yoremes. ',
            subtext: 'Los Mochis, Sinaloa.',
        },
        {
            image: img2Mochis,
            title: 'El Chepe',
            text: 'A bordo del ferrocarril “El Chepe” podrás apreciar los increíbles paisajes de la sierra tarahumara. Su ruta de 653 kilómetros atraviesa las “Barrancas del Cobre”, una serie de cañones únicos en su tipo. ',
            subtext: 'Los Mochis, Sinaloa.',
        },
        {
            image: img3Mochis,
            title: 'Jardín Botánico Benjamín Francís Johnston',
            text: 'Es el pulmón ecológico de Los Mochis, que además de dotar a la ciudad de una belleza escénica, resguarda colecciones documentadas de plantas con propósitos de estudio científico, conservación, exhibición y educación.',
            subtext: 'Los Mochis, Sinaloa.',
        },
        // Agrega más imágenes según sea necesario
      ];
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Los Mochis, Sialoa.</h3>
                        <p>Conoce Los Mochis como destino</p>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <ImageSlider images={images}/>
        </section>
        <section>            
            <div className='container'>
                <div className='destino_info_util'>
                    <div className='content_destinos_info'>
                        <div className='box_destinos_info'>
                            <h3>Geografía y Clima</h3>
                            <ul>
                                <li><strong>Ubicación: </strong>Situada en el noroeste de México, en el municipio de Ahome, cerca del Golfo de California.</li>
                                <li><strong>Fundación: </strong>Fundada el 5 de junio de 1903 por Benjamin F. Johnston, un empresario estadounidense.</li>
                                <li><strong>Población: </strong>Aproximadamente 300,000 habitantes.</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>Clima</h3>
                            <ul>
                                <li><strong>Tipo de Clima: </strong>Semitropical y cálido.</li>
                                <li><strong>Temperaturas: </strong> Varían entre 15°C en invierno y 40°C en verano.</li>
                                <li><strong>Temporada de Lluvias: </strong>De junio a octubre, con precipitaciones más intensas en agosto y septiembre.</li>
                            </ul>
                        </div>
                    
                        <div className='box_destinos_info'>
                            <h3>Economía</h3>
                            <label>Sectores Principales:</label>
                            <ul>
                                <li><strong>Agricultura: </strong>Principal motor económico, con cultivos de maíz, trigo, sorgo, frijol, y hortalizas.</li>
                                <li><strong>Industria Alimentaria: </strong>Procesamiento de productos agrícolas y marinos.</li>
                                <li><strong>Comercio y Servicios: </strong>Sector en crecimiento, apoyando tanto a la población local como a la economía agrícola.</li>
                                <li><strong>Pesca: </strong>Importante actividad económica, especialmente en el puerto de Topolobampo.</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>Transporte</h3>
                            <ul>
                                <li><strong>Aeropuerto Internacional de Los Mochis (LMM) </strong>Principal aeropuerto que conecta Los Mochis con otras ciudades de México.</li>
                                <li><strong>Ferrocarril Chihuahua al Pacífico (Chepe): </strong>Línea ferroviaria que une Los Mochis con Chihuahua, atravesando las espectaculares Barrancas del Cobre.</li>
                                <li><strong>Puerto de Topolobampo: </strong> Importante puerto comercial y de pasajeros, con ferris que conectan a Baja California Sur.</li>                       
                            </ul>
                        </div>

                    </div>
                    <div className='content_destinos_info'>
                       
                        <div className='box_destinos_info'>
                            <h3>Turismo</h3>
                            <label>Principales Atractivos:</label>
                            <ul>
                                <li><strong>El Fuerte: </strong>Pueblo Mágico cercano, conocido por su arquitectura colonial y como punto de partida del tren Chepe hacia las Barrancas del Cobre.</li>
                                <li><strong>Parque Sinaloa: </strong>Jardín botánico con una amplia variedad de flora, ideal para caminatas y recreación al aire libre.</li>
                                <li><strong>Museo Regional del Valle del Fuerte: </strong>Exhibiciones sobre la historia y cultura de la región.</li>
                                <li><strong>Bahía de Topolobampo: </strong>Lugar ideal para la pesca, paseos en barco, y actividades acuáticas.</li>                            
                                <li><strong>Cerro de la Memoria: </strong>Mirador que ofrece vistas panorámicas de Los Mochis y sus alrededores.</li>
                            </ul>
                        </div>
                        
                        <div className='box_destinos_info'>
                            <h3>Naturaleza y Actividades al Aire Libre</h3>
                            <label>Parques Nacionales y Reservas:</label>
                            <ul>
                                <li><strong>Playa Olas Altas: </strong>Popular entre surfistas.</li>
                                <li><strong>Playa Norte: </strong>Amplia y concurrida, ideal para nadar y tomar el sol.</li>
                                <li><strong>Playa Cerritos: </strong>Más tranquila y adecuada para familias.</li>                       
                            </ul>
                            <label>Actividades:</label>
                            <ul>
                                <li>Pesca deportiva, avistamiento de ballenas, surf, kayak, y golf en campos de categoría mundial.</li>                   
                            </ul>
                        </div>

                    </div>
                </div>
            </div>
        </section>
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Promociones y Descuentos</h4>
                            </div>
                            <div>
                                <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/promociones">Ir ahora</Link>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
        <Footer />
    
    
    </>
  )
}
