import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { format } from 'date-fns';
import { es } from 'date-fns/locale';
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import '../css/itinerario.css'

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import Footer from '../components/Footer';

const calcularDosHorasAntes = (horaOriginal) => {
    const [hora, minutos] = horaOriginal.split(':');

    //Calcula 3 horas antes
    let horasAntes = parseInt(hora, 10) - 3;

    if (horasAntes < 0) {
        horasAntes += 24; // Ajustar al rango de 0 a 23
    }

    // Formatear el resultado
    const resultado = `${String(horasAntes).padStart(2, '0')}:${minutos}`;
    return resultado;
};

const convertirNombreCompleto = (nombreAbreviado) => {
    switch (nombreAbreviado) {
        case 'PIC-MZT':
            return 'La Paz - Mazatlán';
        case 'MZT-PIC':
            return 'Mazatlán - La Paz';
        case 'TPB-PIC':
            return 'Topolobampo - La Paz';
        case 'PIC-TPB':
            return 'La Paz - Topolobampo';
        default:
            return nombreAbreviado;
    }
};
const capitalize = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

export default function Itinerarioprueba() {
    const [fecha, setFecha] = useState(new Date());

    useEffect(() => {
        const intervalo = setInterval(() => {
          setFecha(new Date());
        }, 1000);
        return () => clearInterval(intervalo);
      }, []);
  
    // Formatear la fecha en español
    const fechaFormateada = capitalize(format(fecha, "EEEE dd 'de' MMMM  yyyy", { locale: es }));

    /* Eta */
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //http://etaservice.bajaferries.net:9005/services/ETAService.asmx/Get_Recent_Trips
        //axios.get('http://187.141.138.29:9002/Services/ETAService.asmx/Get_Recent_Trips')
       //axios.get('http://192.168.100.54:9002/services/ETAService.asmx/Get_Recent_Trips')
       axios.get('https://bajaferries.com.mx:444/Services/EtaService.asmx/Get_Recent_Trips')
            .then(response => {
                const responseData = JSON.parse(response.data);
                const parsedData = JSON.parse(responseData.data);
                setData(parsedData);
                setLoading(false);
                console.log("parsedData: ",parsedData);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
                setLoading(false);
            });
    }, []);


return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Viaje en curso </h3>
                    <p>Conoce la hora estimada de llegada para tu viaje</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='d-none d-sm-none d-md-block'>
        <div className='itinerario'>
            <div className='container'>
                <div className=''>
                    
                    <div className='itinerario_name_ruta'>
                        <label>La Paz </label><label><i class="fi fi-rr-exchange-alt"></i></label><label>Topolobampo</label><span></span>
                        <label>{fechaFormateada} <i class="fi fi-rr-calendar-days"></i></label>
                    </div>
                </div>
            <div className='content-itinerario'>
                
                    <div>
                        <div className='itinerario_tabla'>
                            {loading ? (
                                <p>Cargando...</p>
                            ) : (
                                <div className='container'>
                                    {data && data.length > 0 ? (
                                        <>
                                            <div className="trip-blocks">                                
                                                <table className='table_itinerario'>
                                                    <thead>
                                                        <tr>
                                                            <th>Origen - Destino</th>
                                                            <th>Embarque</th>
                                                            <th>Salida</th>
                                                            <th>Llegada</th>
                                                            <th>Buque</th>
                                                            <th>Estatus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.map((trip, index) => {
                                                            console.log("trip12: ",trip);                                                            
                                                            const rutaCompleta = convertirNombreCompleto(trip[1]);
                                                            if (rutaCompleta === 'La Paz - Topolobampo' || rutaCompleta === 'Topolobampo - La Paz') {
                                                                const horaDosHorasAntes = calcularDosHorasAntes(trip[0].split(' ')[1]);
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{rutaCompleta}</td>
                                                                        <td>{horaDosHorasAntes}</td>
                                                                        <td>{trip[0].split(' ')[1]}</td>
                                                                        <td>{trip[2].split(' ')[1]}</td>
                                                                        <td>{trip[3]}</td>
                                                                        <td>A tiempo</td>
                                                                    </tr>
                                                                );
                                                            }else{
                                                                if ((rutaCompleta === 'La Paz - Mazatlán' || rutaCompleta === 'Mazatlán - La Paz') &&
                                                                (rutaCompleta !== 'La Paz - Topolobampo' || rutaCompleta !== 'Topolobampo - La Paz')){                                                                        
                                                                
                                                                return (
                                                                  
                                                                    <tr>
                                                                        <td colspan="6" style={{textAlign:'center'}}>No hay viaje programado</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            }                                                      
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>                            
                                        </>
                                    ) : (
                                        <div>
                                            <table className='tabla_itinerario_sin_vijes'>
                                                <thead>
                                                    <tr>
                                                        <th>Origen - Destino</th>
                                                        <th>Embarque</th>
                                                        <th>Salida</th>
                                                        <th>Llegada</th>
                                                        <th>Buque</th>
                                                        <th>Estatus</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colSpan="6" style={{textAlign:'center'}}>No hay viaje programado</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='box_second_itinerario'>
                        <div className='itinerario_name_ruta'>
                            <label>La Paz </label><label><i class="fi fi-rr-exchange-alt"></i></label><label>Mazatlán</label><span></span>
                            <label>{fechaFormateada} <i class="fi fi-rr-calendar-days"></i></label>
                        </div>
                    </div>
                <div className='content-itinerario'>

                    <div>
                        <div className='itinerario_tabla'>
                            {loading ? (
                                <p>Cargando...</p>
                            ) : (
                                <div className='container'>
                                    {data && data.length > 0 ? (
                                        <>
                                            <div className="trip-blocks">                                
                                                <table className='table_itinerario'>
                                                    <thead>
                                                        <tr>
                                                            <th>Origen - Destino</th>
                                                            <th>Embarque</th>
                                                            <th>Salida</th>
                                                            <th>Llegada</th>
                                                            <th>Buque</th>
                                                            <th>Estatus</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                  

                                                        {data.map((trip, index) => {
                                                            const rutaCompleta = convertirNombreCompleto(trip[1]);
                                                            console.log("rutaCompleta: ", rutaCompleta);
                                                            if (rutaCompleta === 'La Paz - Mazatlán' || rutaCompleta === 'Mazatlán - La Paz') {
                                                                const horaDosHorasAntes = calcularDosHorasAntes(trip[0].split(' ')[1]);
                                                                return (
                                                                    <tr key={index}>
                                                                        <td>{rutaCompleta}</td>
                                                                        <td>{horaDosHorasAntes}</td>
                                                                        <td>{trip[0].split(' ')[1]}</td>
                                                                        <td>{trip[2].split(' ')[1]}</td>
                                                                        <td>{trip[3]}</td>
                                                                        <td>A tiempo</td>
                                                                    </tr>
                                                                );
                                                            }else{
                                                                if ((rutaCompleta === 'La Paz - Topolobampo' || rutaCompleta === 'Topolobampo - La Paz') &&
                                                                (rutaCompleta !== 'La Paz - Mazatlán' || rutaCompleta !== 'Mazatlán - La Paz')){                                                                        
                                                                
                                                                return (
                                                                  
                                                                    <tr>
                                                                        <td colspan="6" style={{textAlign:'center'}}>No hay viaje programado</td>
                                                                    </tr>
                                                                );
                                                            }
                                                            }
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            <table className='tabla_itinerario_sin_vijes'>
                                                <thead>
                                                    <tr>
                                                        <th>Origen - Destino</th>
                                                        <th>Embarque</th>
                                                        <th>Salida</th>
                                                        <th>Llegada</th>
                                                        <th>Buque</th>
                                                        <th>Estatus</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td colspan="6" style={{textAlign:'center'}}>No hay viaje programado</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className='notas_itinerario'>
                    <ul>
                        <li>• El itinerario se muestra en horario local, sujeto a cambios sin previo aviso.</li>
                        <li>• Si vas a recoger un vehículo, te pedimos que llegues con al menos 30 minutos de antelación.</li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <section className="d-block d-sm-block d-md-none">
       <div className='itinerairo_phone'>
       <div className='itinerario'>
            <div className='container'>
                <div className=''>
                    <div className='itinerario_name_ruta '>                        
                        <label>La Paz </label><label><i class="fi fi-rr-exchange-alt"></i></label><label>Topolobampo</label>
                    </div>      
                    <div className='itinerario_name_ruta'>                    
                        <label>{fechaFormateada} <i class="fi fi-rr-calendar-days"></i></label>
                    </div>               
                </div>
                <div className='itinerario_tabla'>
                            {loading ? (
                                <p>Cargando...</p>
                            ) : (
                                <div className=''>
                                    {data && data.length > 0 ? (
                                        <>
                                        {data.map((trip, index) => {
                                            if (!trip[0]) {
                                                return null;
                                            }
                                            const rutaCompleta = convertirNombreCompleto(trip[1]);
                                            if (rutaCompleta === 'La Paz - Topolobampo' || rutaCompleta === 'Topolobampo - La Paz') {
                                                const horaDosHorasAntes = calcularDosHorasAntes(trip[0].split(' ')[1]);
                                                return (
                                                    <div key={index}>

                                                   
                                                    <div className='itinerario_content'>
                                                        <div className='itinerario_box'>
                                                            <div>
                                                                <h3>Embarque</h3>
                                                                <label>{horaDosHorasAntes}</label>
                                                            </div>
                                                            <div>
                                                                <h3>Salida</h3>
                                                                <label>{trip[0].split(' ')[1]}</label>
                                                            </div>
                                                            <div>
                                                                <h3>Llegada</h3>
                                                                <label>{trip[2].split(' ')[1]}</label>
                                                            </div>
                                                        </div>
                                                        <div className='itinerario_box'>
                                                            <div>
                                                                <h3>Buque</h3>
                                                                <label>{trip[3]}</label>
                                                            </div>
                                                            <div>
                                                                <h3>Ruta</h3>
                                                                <label>LP - TP</label>
                                                            </div>
                                                            <div>
                                                                <h3>Estatus</h3>
                                                                <label style={{color:'#28A745'}}>A tiempo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                );
                                            }
                                           
                                        })}
                                        
                                                                     
                                        </>
                                    ) : (
                                        <div className='itinerario_content'>
                                        <div style={{textAlign:'center'}}>
                                            <td colspan="6">No hay viajes para mostrar hoy</td>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            )}                            
                </div>
            </div>
        </div>

        <div className='itinerario'>
            <div className='container'>
                <div className=''>
                    <div className='itinerario_name_ruta '>                        
                        <label>La Paz </label><label><i class="fi fi-rr-exchange-alt"></i></label><label>Mazatlán</label>
                    </div>      
                    <div className='itinerario_name_ruta'>                    
                        <label>{fechaFormateada} <i class="fi fi-rr-calendar-days"></i></label>
                    </div>               
                </div>
                <div className='itinerario_tabla'>
                            {loading ? (
                                <p>Cargando...</p>
                            ) : (
                                <div className=''>
                                    {data && data.length > 0 ? (
                                        <>
                                        {data.map((trip, index) => {
                                            if (!trip[0]) {
                                                return null;
                                            }
                                            const rutaCompleta = convertirNombreCompleto(trip[1]);
                                            if (rutaCompleta === 'La Paz - Mazatlán' || rutaCompleta === 'Mazatlán - La Paz') {
                                                const horaDosHorasAntes = calcularDosHorasAntes(trip[0].split(' ')[1]);
                                                return (
                                                    <div key={index}>

                                                   
                                                    <div className='itinerario_content'>
                                                        <div className='itinerario_box'>
                                                            <div>
                                                                <h3>Embarque</h3>
                                                                <label>{horaDosHorasAntes}</label>
                                                            </div>
                                                            <div>
                                                                <h3>Salida</h3>
                                                                <label>{trip[0].split(' ')[1]}</label>
                                                            </div>
                                                            <div>
                                                                <h3>Llegada</h3>
                                                                <label>{trip[2].split(' ')[1]}</label>
                                                            </div>
                                                        </div>
                                                        <div className='itinerario_box'>
                                                            <div>
                                                                <h3>Buque</h3>
                                                                <label>{trip[3]}</label>
                                                            </div>
                                                            <div>
                                                                <h3>Ruta</h3>
                                                                <label>LP - MZ</label>
                                                            </div>
                                                            <div>
                                                                <h3>Estatus</h3>
                                                                <label style={{color:'#28A745'}}>A tiempo</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                );
                                            }
                                            
                                        })}
                                        
                                                                     
                                        </>
                                    ) : (
                                        <div className='itinerario_content'>
                                        <div style={{textAlign:'center'}}>
                                            <td colspan="6">No hay viajes para mostrar hoy</td>
                                        </div>
                                    </div>
                                    )}
                                </div>
                            )}
                </div>
            </div>
        </div>
        <div className='container'>
            <div className='notas_itinerario'>
                <ul>
                    <li>• El itinerario se muestra en horario local, sujeto a cambios sin previo aviso.</li>
                    <li>• Si vas a recoger un vehículo, te pedimos que llegues con al menos 30 minutos de antelación.</li>
                </ul>
            </div>
        </div>

       </div>
    </section>


    <section className='callToAction'>
            <div className="container">
            <div>
                <h3>¿Listo para reservar?</h3>
            </div>
            <Row>
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Consulta nuestras tarifas</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Reservar ahora</Link>                                      
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Col>   
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Preguntas frecuentes</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Nos pondremos en contacto lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Consultar </Link>                                      
                                </div>
                            </div>                                  
                        </div>
                    </div>
                </Col>      
                <Col span={8}>
                    <div className='box_group-card'>
                        <div className='box_content-card contact-box-card'>
                            <div className='box_icon-card'>
                                <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                            </div>
                            <div className='boxes_content'>
                                <div className='box_content-title'>
                                    <h2>Envianos un WhatsApp</h2>
                                </div>
                                <div className='box_content-subtext'>
                                    <p>
                                        Te responderemos lo más pronto posible, nuestro horario de atención es de lunes a sábado de 8:00 am a 6:00 pm.
                                    </p>
                                </div>
                                <div className='box_content-btn'>
                                    <Link to="?">Enviar WhatsApp </Link>                                  
                                </div>
                            </div>

                        </div>
                    </div>
                </Col>        
                    
            </Row>    
            </div>
            <Outlet />
    </section>

    <Footer />
    <Outlet />
    </>
)
}
