import React from 'react'
import '../css/condiciones.css'
import Footer from '../components/Footer'
export default function Condiciones() {
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Condiciones de servicio </h3>
                        <p></p>
                    </div>                
                </div>
            </div>
        </section>
    
    <section className='condiciones_servicios'>
        <div className='container'>
            <div>
                <h3>1.Objetivo</h3>
                <p>Establecer las condiciones de servicio que deberán consultarse, publicarse, leerse y aplicarse en los puntos de venta, terminales y agencias autorizadas, estas estarán disponibles en los porta boletos o página Web www.bajaferries.com de acuerdo al servicio requerido.</p>
            </div>
            <div>
                <h3>2.Condiciones de Servicio</h3>
                <p>Se entiende por BAJA FERRIES S.A.P.I. DE C.V. la expresión “La Empresa” o viceversa.</p>
                <p>Las condiciones descritas forman parte de los acuerdos nacionales e internacionales para el transporte marítimo de carga y pasaje.</p>
                <p>Los pasajeros deberán presentarse al embarque en la terminal de origen con una antelación de una y media (1 ½) hora(s) antes del viaje. Para pasajeros con vehículos turísticos dos (2) horas antes de la salida y para camiones y vehículos de carga se requiere presentarse tres (3) horas antes. </p>

                <p>El pasajero acepta las condiciones del servicio desde el momento que adquiere el boleto, siendo las siguientes:</p>
                <p><strong>1. Boletos.</strong> El valor del boleto incluye impuestos sobre valor agregado, el espacio a bordo para el pasajero y/o el vehículo, mascotas, bicicletas y cabinas según se especifique en el propio boleto, así como un seguro de responsabilidad civil. El boleto incluye un servicio de alimentos por persona, en los horarios que se designen a bordo. El boleto es personal e intransferible, no será válido si presenta tachaduras, enmendaduras o alteraciones.</p>

                <p>El pasajero deberá presentar su boleto original, así como una identificación oficial con fotografía, requisitos sin los cuales no podrá abordar.</p>

                <p><strong>Boletos abiertos (OPEN):</strong> Estos boletos requieren confirmación 48 horas antes de la salida; en todos los casos un boleto abierto siempre está sujeto a disponibilidad de espacio.</p>

                <p>Promociones y/o paquetes:</p>
                <p>Las promociones especiales y/o paquetes estarán sujetos a sus propias reglas específicas de oferta, de cancelación y cambio de fecha las que aplicarán por encima de las presentes condiciones, mismas que serán puestas en conocimiento del cliente con la referida oferta.</p>

                <p>La empresa se reserva el derecho de aplicar cambios en las condiciones de servicio y horarios descritos en cada una de las promociones.</p>

                <p><strong>Caducidad:</strong> El boleto tendrá una vigencia de doce meses contados a partir de la fecha inicial de compra.</p>
                <p><strong>2.Cancelaciones: LA EMPRESA </strong> se reserva el derecho de proporcionar el servicio sin que esto implique el derecho del usuario de algún reclamo, demanda, pago o cualquier otra responsabilidad civil o mercantil, en los casos siguientes: </p>






                <p>a) Cuando existan condiciones climatológicas y/o de tráfico en puertos y/o caso fortuito y/o fuerza mayor y/o cualquier otro que pongan en peligro la seguridad de la embarcación y/o de la tripulación y/o de los pasajeros, y que por consecuencia retrasen el zarpe y/o arribo y/o que impidan la realización del viaje.</p>

                <p>b) Por no presentarse el usuario ante <strong>LA EMPRESA</strong> con la antelación indicada, en las condiciones y con la documentación necesaria para llevar a cabo el embarque.</p>

                <p>c) Cuando el pasajero, en la fecha de prestación del servicio, presente un estado de salud o padecimiento que pudiera implicar un riesgo para sí mismo, para la tripulación, para el resto de los pasajeros y/o para la propia embarcación. El médico de la empresa podrá evaluar al pasajero previo al embarque y emitirá su opinión al Capitán del buque; éste último será el único facultado para determinar la viabilidad o no de que se proporcione el servicio al pasajero de que se trate.</p>

                <p>d) Tratándose de pasajeros que viajen como operadores de un vehículo, sea cual fuere su tipo, cuando por causas imputables al operador del vehículo sea imposible embarcar el vehículo en el lugar asignado, cualesquiera que sean estas, señalándose de manera enunciativa más no limitativa, por las dimensiones del vehículo, por exceso de carga, por problemas mecánicos en el vehículo o por cualquier otra circunstancia que, a juicio de <strong>LA EMPRESA</strong>, ponga en riesgo la seguridad de la embarcación, de la tripulación, el medio ambiente y/o del resto de los pasajeros y/o vehículos. </p>

                <p>e) Si el operador del vehículo o el pasajero se presenta bajo los efectos del alcohol y/o alguna sustancia tóxica o prohibida.</p>

                <p>f) Si el operador del vehículo o el pasajero no acata las instrucciones del personal de <strong>LA EMPRESA</strong>.</p>

                <p>g) Por la inadecuada estiba de la carga del vehículo, en caso de que éste transporte carga.</p>

                <p>h) Si se pretende utilizar o utiliza un boleto que no corresponda al pasajero y/o el vehículo y/o que sea objeto de la reventa, se encuentre alterado o bien, por el extravío del boleto sin que se haya realizado el procedimiento señalado en las presentes condiciones para obtener el servicio en caso de extravío. </p>

                <p>i) <strong>LA EMPRESA</strong>, de acuerdo a las necesidades del servicio, podrá modificar y/o cancelar y/o cambiar su itinerario de viaje con respecto a horarios y fechas impresas en el boleto, debiendo notificar de tal situación al Cliente. Para tales efectos, el Cliente deberá proporcionar todos sus datos de contacto, tales como teléfono, correo electrónico y/o medio alguno de comunicación donde se le pueda informar dichos cambios, siendo responsabilidad del pasajero proporcionar correctamente los datos de referencia con el fin de que la empresa se encuentre en posibilidades de darle aviso oportunamente. En caso de que el pasajero no dé respuesta a la notificación de cambio de itinerario, <strong>LA EMPRESA</strong> no se hace responsable por los compromisos que hubiese tenido el pasajero en base a su fecha original, por lo cual no se pagará el servicio de hoteles, alimentos, transportación y demás gastos adicionales que pudiera reclamar el pasajero. </p>
                
                <p>Si el pasajero no se presenta a abordar el día y la hora que se le indiquen y no da aviso por lo menos 48 horas previas al zarpe, perderá el derecho de uso del boleto. </p>
                <p>Si el cliente notifica con antelación de 48 horas que no realizará su viaje, el boleto quedará en estatus <strong>ABIERTO (OPEN)</strong>, por lo que podrá ser utilizado por el pasajero en un plazo máximo de doce meses contados a partir de la fecha inicial de compra del mismo. Para utilizar un boleto en estatus ABIERTO (OPEN) el pasajero deberá realizar el pago de $580.00 (quinientos ochenta pesos 00/100 M.N.) por cambio de fecha, así como la diferencia entre la tarifa que se pagó y la que se encuentre vigente al momento de solicitar el servicio en caso de existir. Un boleto en estatus ABIERTO (OPEN) solo podrá modificarse hasta en tres ocasiones, debiendo realizarse el pago correspondiente con cada cambio. En todo caso un boleto en estatus Abierto siempre se encontrará sujeto a disponibilidad de viaje en la fecha que el pasajero pretenda utilizar el servicio. </p>

                <p>3. <strong>Demoras en zarpe, atraque y en travesía:</strong>  Cuando exista una demora en zarpe de más de cuatro horas, y la empresa no esté en posibilidades de notificar al cliente con la antelación pertinente, es decir, antes del horario establecido para la presentación de los clientes en la terminal de embarque, la empresa proporcionará a EL CLIENTE, a elección del cliente, una de las siguientes opciones: </p>

                <p>I.- El reembolso de la proporción del precio del boleto que corresponda a la parte no realizada del viaje. </p>

                <p>II.- El boleto podrá ser utilizado por el pasajero en un plazo máximo doce meses contados a partir de la fecha de afectación del viaje, sin penalización por cambios de fecha, reimpresiones de boleto, y si cargo adicional de tarifa vigente. </p>

                <p>En caso de que no sea posible a <strong>LA EMPRESA</strong> prestar el servicio en la fecha establecida, proporcionará a <strong>EL CLIENTE</strong>, una de las siguientes opciones:</p>

                <p>I.- El reembolso del precio del boleto que corresponda a la parte no realizada del viaje.</p>
                <p>II.- Proporcionar el servicio en el primer viaje inmediato disponible, proporcionando alimentos en el tiempo de espera y hospedaje cuando se requiera pernocta, así como transporte terrestre desde y hacia terminal de embarque, en caso de ser necesario. </p>

                <p>Para el caso de la fracción II, los alimentos, hospedaje y transporte será proporcionado por LA EMPRESA exclusivamente a los clientes que así lo soliciten, siempre y cuando éstos acrediten tener su domicilio y lugar de residencia fuera de la Ciudad en que ocurra la afectación.</p>

                <p>La empresa no se hará responsable del pago de servicios ligados a itinerarios del pasajero, como consecuencia de cambios en itinerario o cancelación de salidas o llegadas o de cualquier cambio en el horario de salida de los publicados, o de las ocurridas por causas fortuitas, fuerza mayor, actos de la naturaleza o imponderables. </p>

                <p><strong>4. Reembolso y cambios de fecha y/o ruta:</strong> El reembolso sólo procederá cuando el cliente realice la solicitud correspondiente dentro de los cinco (5) días hábiles posteriores a la compra del boleto, siempre y cuando la prestación del servicio no se encuentre dentro del plazo de once (11) días hábiles o menos, de la fecha de la adquisición del boleto. O bien, en los supuestos establecidos en las presentes condiciones de servicio. Todo reembolso deberá ser solicitado mediante carta membretada (de preferencia), dirigida al departamento de tesorería, debiendo remitirse al domicilio ubicado en Ignacio Allende 1025, Colonia Centro, en La Paz, B.C.S., anexando la ficha de depósito original o copia de la transferencia electrónica realizada, copia de identificación oficial. Detallar en el escrito el monto(s) pagado(s), numero(s) de boleto(s) y/o reservación(es) realizada(s) e información bancaria: Nombre del banco, sucursal, titular de la cuenta y número de cuenta en formato CLABE (18 dígitos) o 16 dígitos en caso de tarjeta bancaria, debiendo ser ésta, a nombre de la persona/empresa de quien provino deposito. Para el caso de personas morales, el escrito deberá de ser firmado por el Representante legal y acreditarse sus facultades de representación. Así mismo, se deberá señalar número de teléfono y correo electrónico de contacto para cualquier duda o aclaración. El plazo para el reembolso del saldo será de quince días naturales a partir de que la información requerida se proporcione completa.</p>

                <p>Fuera de dichos supuestos, si el boleto no es utilizado, el monto pagado no será reembolsado ni transferido a otra persona y/o vehículo. El cliente tendrá derecho a cambio de fecha y/o ruta cubriendo el pago de $580.00 (quinientos ochenta pesos 00/100 M.N.) por cada boleto por segmento; en caso de que exista diferencia entre la tarifa de la ruta pagada y la tarifa de la ruta a la que se pretende cambiar el boleto, o bien, en la tarifa vigente a la fecha en que se vaya a realizar el viaje, el cliente deberá cubrir dicha diferencia de tarifa. </p>

                <p><strong>5. Cargos: LA EMPRESA</strong>, aplicará los siguientes cargos:</p>

                <p>a) Si el vehículo no es desembarcado en el momento en el que se requiera, se le cargará el cincuenta por ciento (50%) del valor del boleto, independientemente del cargo que se cause por las maniobras. En caso de que no sea posible su desembarque en un lapso de 2 horas, la unidad será regresada a bordo del Buque, generándose un cargo del 100%  del valor del traslado de la unidad.</p>

                <p>b) Una pena equivalente a cien (100) días de UMA si el vehículo obstruye rampas en los procesos de embarque o desembarque; además del cargo anterior, se cargarán los costos que resulten por demoras, maniobras, daños ocasionados por el usuario a bordo de la embarcación o dentro de las instalaciones. </p>

                <p>c) Pensión por estancia de los vehículos en instalaciones de <strong>LA EMPRESA</strong> o a su cargo.</p>

                <p>d) Cambio de fecha y/o ruta sobre reservación generada tendrán un cargo de $580.00 (quinientos ochenta pesos 00/100 M.N.) por segmento, sujeto a disponibilidad de espacio y la diferencia de tarifa vigentes por el cambio de ruta. </p>

                <p>6. <strong>LA EMPRESA</strong> se reserva el derecho de sustituir una embarcación por otra, por causas de fuerza mayor y por necesidades de servicio a demanda de éste. </p>

                <p><strong>7. LA RESPONSABILIDAD DE LA EMPRESA</strong> empezará una vez que el pasajero este a bordo del buque y terminará cuando desembarque en el puerto destino. En el caso de vehículos, la responsabilidad de la empresa inicia cuando el vehículo esté a bordo, trincado y con el motor apagado y terminará al destrinque del mismo en el puerto de destino, salvo en los casos de que un daño sea generado derivado de las condiciones eléctricas y/o mecánicas del propio vehículo, los cuales bajo ninguna circunstancia serán imputables a LA EMPRESA. LA EMPRESA será responsable de los daños o averías que sufran los bienes transportados cuando le sean imputables. EL CLIENTE deberá notificar de inmediato y previo al abandono de la terminal de desembarque cualquier daño o desperfecto que presente su vehículo y/o maletas y que pueda presumirse imputable a <strong>LA EMPRESA</strong>. <strong>LA EMPRESA</strong> no será responsable de daños que no sean reportados previo a la salida del vehículo y/o maletas de la terminal de desembarque. Queda estrictamente prohibido el transporte de mercancías peligrosas en vehículos de carga sin previo consentimiento por escrito de Baja Ferries, S.A.P.I. de C.V. y de la autoridad respectiva. </p>

                <p>En todo caso, la responsabilidad de LA EMPRESA se encontrará limitada a lo establecido en la Ley de Navegación y Comercio Marítimo</p>

                <p><strong>8. Restricciones.</strong></p>
                <p><strong>A) En servicio de pasajeros:</strong> En caso de necesitar mayor información respecto a cualquier asistencia o al rango de servicios que podemos prestar a nuestros Clientes o solicitantes del servicio con cualquier capacidad distinta, adulto mayor u otra condición que deba considerarse, <strong>EL CLIENTE</strong> podrá comunicarse  al teléfono 800- FERRIES (3377437) en México y en el extranjero a través de la página web: www.bajaferries.com; no obstante  lo anterior, se establecen, de forma enunciativa más no limitativa, las siguientes restricciones para la protección de la vida, salud y seguridad del <strong>EL PASAJERO</strong> por servicios que pudieran ser considerados peligrosos o nocivos y con el fin de proveer la mayor seguridad y bienestar en su viaje, declarando lo siguiente: </p>

                <p><strong>Las siguientes personas deben viajar con asistencia o compañía obligatoria </strong> de una persona mayor de 18 años  comprobables con identificación oficial vigente, quien se haga responsable del pasajero: </p>

                <p><strong>i</strong> Que no pueda satisfacer sus necesidades primarias y/o presenten condiciones físicas o mentales que le impidan valerse por sí mismo. </p>

                <p><strong>ii</strong> Que requiera de  atención  médica  especializada,  ingerir  y/o  aplicarse  cualquier  medicamento de prescripción y no pueda hacerlo personalmente, considerando que el personal de abordo no tiene permiso de administrar o dar cualquier tipo de medicamento que no sea en casos específicos de emergencia;</p>

                <p><strong>iii</strong> Cuando el pasajero sea menor de dieciocho (18) años.</p>

                <p>Para efectos del último supuesto, la única excepción será cuando el servicio sea solicitado por familias constituidas por menores de edad y/o emancipados.</p>

                <p>Los pasajeros menores de 18 años, deben presentar alguna de las siguientes identificaciones oficiales: pasaporte nacional o internacional, acta de nacimiento o CURP para corroborar su identidad.</p>

                <p>En caso de que el menor de edad no vaya acompañado por alguno de sus padres o tutores, el adulto a cargo deberá presentar una carta de consentimiento firmada junto con una identificación oficial del padre, madre, o tutor, donde estos autorizan que el menor pueda viajar con ellos.</p>

                <p>Por cuestiones de seguridad de nuestros pasajeros, no se permite viajar a mujeres embarazadas a partir del séptimo mes de embarazo.</p>

                <p>Mujeres embarazadas deben informar al personal de la empresa sobre tu condición al comprar tu boleto y presentar un certificado médico expedido máximo 7 días antes de tu fecha de viaje que indique lo siguiente:</p>

                <p>a) Tus datos personales (nombre completo y edad).</p>

                <p>b) Semanas de gestación y tipo de embarazo (simple o múltiple).</p>

                <p>c) Fecha probable de parto.</p>
                <p>d) La aprobación médica que conste te encuentras en condiciones óptimas para viajar el/los días señalados.</p>
                <p>e) El periodo de validez del certificado.</p>
                <p>f) El nombre, número de cédula, firma y datos de contacto del médico que lo emite.</p>

                <p>Antes de embarcar nuestro médico abordo puede realizar una valoración de su estado para determinar la viabilidad o no de permitir su embarque Por tu seguridad, si tu embarazo es igual o mayor a 31 semanas (7 meses) no podrás realizar el viaje.</p>

                <p>En caso de no declararlo será responsabilidad del pasajero, cualquier complicación que se pudiese presentar durante la travesía, lo mismo para personas con alguna situación de salud critica o limitaciones físicas no declaradas.</p>

                <p>Las personas mayores a 70 años deberán presentar un certificado medico expedido máximo 7 días antes de tu fecha de viaje que indique el pasajero está en condiciones de tomar el viaje.</p>

                <p><strong>B) Para el transporte de mascotas:</strong> El servicio de transporte de mascotas se encuentra sujeto a condiciones especiales que debe solicitar y aceptar el solicitante del servicio, de forma virtual por internet o ante personal en ventanilla, sin lo cual no será posible la prestación de dicho servicio.</p>

                <p>El servicio de transporte de mascotas se encuentra sujeto a disponibilidad de espacio y <strong>EL PASAJERO</strong> deberá cubrir la tarifa vigente por adquirir este servicio. Las mascotas deben viajar en el área asignada para mascotas y es obligatorio que la mascota viaje en su jaula transportadora de material rígido y seguro, de tamaño adecuado, permitido solo una mascota por jaula que permitan su adecuada manipulación sin riesgo de daños o lesiones a otros pasajeros, personal de transporte o tripulación. </p>

                <p>La única excepción para este caso serán los perros guía o animales de servicio, entendiéndose por éstos exclusivamente aquellos que han sido adiestrados en un centro de adiestramiento certificado, para dar servicio y asistencia a las personas con alguna discapacidad, con el fin de mejorar su calidad de vida y autonomía personal, y el que a su vez requiere de éste para su subsistencia. Para estos efectos, se señala a manera limitativa los perros guía, perros señal o de alerta de sonidos, perros de servicio, perros de aviso o alerta médica, perros para personas con trastornos del espectro autista, perros de rehabilitación o terapia y otros animales de servicio que cuenten con el adiestramiento requerido para realizar alguna de las funciones señaladas en líneas anteriores. Las condiciones para la prestación de este servicio se encuentran detalladas en las condiciones especiales para el transporte de perros guía o animales de servicio, mismas que el cliente deberá solicitar y aceptar para la prestación de este servicio. </p>
                <p>Animales exóticos y/o protegidos quedaran sujeto su traslado a la disposición de las autoridades competentes, SAGARPA.</p>

                <p><strong>C) Personas inmovilizadas</strong> o solicitantes de servicio para viajar en camilla.- <strong>LA EMPRESA</strong> considerará la viabilidad de prestar el servicio a las personas inmovilizadas o que requieran condiciones especiales de viaje, atendiendo a la disponibilidad de condiciones óptimas y seguras para la prestación del mismo en la embarcación de que se trate. En caso de que una persona ubicada bajo el presente supuesto haya adquirido un boleto para viajar y la empresa no disponga de las condiciones adecuadas para prestarle el servicio especializado que requiera en la fecha solicitada, el viaje podrá ser reprogramado en un periodo máximo de doce meses a partir de la fecha inicial de compra del mismo, sin que sea imputable a la empresa penalidad alguna por tal cambio. </p>

                <p>9. <strong>Tarifas especiales. LOS PASAJEROS</strong> especificados a continuación, gozarán de las tarifas especiales que se describen: </p>

                <p>Con credenciales del INSEN/INAPAM se otorgará una tarifa de promoción en clase Salón,  sujeta a disponibilidad de espacio, misma que será publicada en el tarifario vigente.</p>
                <p>Están exentos de pago los niños menores de tres años cumplidos, limitando tal excepción a un máximo de dos niños por familia, cuando estos excedan del máximo establecido, pagarán el 50% del precio vigente de la tarifa de adulto. </p>
                <p>Niños de 3 a 11 años de edad cumplidos pagarán el 50% del precio vigente de la tarifa de adulto.</p> 
                <p>La Empresa se reserva el derecho de modificar estas condiciones de tarifas especiales sin previo aviso. </p>

                <p><strong>10. Equipaje.</strong> El Equipaje para considerarse como tal debe estar contenido en maletas u otros recipientes adecuados para garantizar el transporte seguro con un cuidado razonable en su manipulación. El equipaje se documentará previa revisión de las autoridades correspondientes, entregándose un cupón que lo ampare.<strong>LA EMPRESA</strong> no se hace responsable de los daños al equipaje que no se encuentre amparado por un cupón de equipaje proporcionado al momento de su documentación, objetos de valor en dinero, efectos negociables, metales preciosos, joyería, ornamentos, obras de arte y/u objetos de valor equivalentes, ya que no se reciben tales objetos ni formales ni tácitamente en custodia. Cada pasajero tiene derecho a documentar 5 maletas de 25 kilogramos por viaje Tenga en cuenta que si trae vehículo todo el equipaje que se lleva a bordo del mismo es bajo su propia responsabilidad y/o riesgo. </p>

                <p><strong>LA EMPRESA</strong> no es responsable por la pérdida de dinero u objetos de valor dejados en los espacios comunes de la embarcación o en las cabinas. Para cualquier pérdida hay que informar a la recepción a bordo durante el viaje. En caso de pérdida de su equipaje por responsabilidad del pasajero, la empresa realizará las investigaciones correspondientes, dándose una respuesta al pasajero en un plazo de cinco (5) días hábiles. No se permite equipaje que contenga mercancías peligrosas o ilícitas.  <strong>LA EMPRESA</strong> se reserva el derecho, pero no la obligación, de verificar en la presencia de <strong>EL PASAJERO</strong> el contenido de su equipaje, y, para abrir y examinar el equipaje, en caso de sospecha de que se transporten mercancías que pongan en riesgo la seguridad de la embarcación y/o de los pasajeros y/o de la tripulación con o sin la presencia del pasajero. En caso de pérdida de su equipaje por responsabilidad de la empresa, esta realizará las investigaciones conducentes, debiendo dar respuesta al pasajero en un plazo de cinco (5) días hábiles para proceder a la reclamación del mismo y en caso de no recuperar esta pérdida, se pagará el equivalente a veinticinco (25) veces el salario mínimo general vigente. No procederá ningún reclamo sobre pérdida o daño de equipaje posterior al abandono de la terminal por parte del pasajero.  </p>
                <p>Al comprar el boleto <strong>EL PASAJERO</strong> puede llevar equipaje de mano y documentado.</p>

                <p><strong>Equipaje de mano:</strong> podrá llevar en todo momento equipaje y un artículo personal que en conjunto pesen hasta 10 kilos, maletas con o sin ruedas cuyas dimensiones no rebasen los 55 cm *40 cm * 25 cm alto, considerados artículos personales: maletines, bolsos, computadoras, estos deberán caber debajo de los asientos de salón. </p>

                <p><strong>Equipaje documentado:</strong>Se permite llevar hasta 5 piezas de 25 kg por pasajero adulto cuya superficie lineal máxima en conjunto sea de 158 cm</p>

                <p><strong>Equipaje especial:</strong></p>
                <p><strong>Artículos deportivos:</strong> una pieza de estos artículos se podrá intercambiar por una pieza de equipaje documentado sin costo y sin causar exceso de equipaje siempre y cuando no exceda el peso y la longitud máxima permitida, </p>

                <p><strong>Hieleras:</strong> solo se aceptan hieleras rígidas de plástico (no unicel) en caso de contener líquidos, alimentos perecederos, estos deberán ir congelados ,selladas y emplayadas  para evitar derrames de líquidos y contaminación que afecte a equipaje de los pasajeros,  la empresa no se hace responsable por el estado de conservación de su contenido quedando sujeto a revisión y traslado de las autoridades competentes. </p>

                <p><strong>Instrumentos musicales:</strong> se podrá transportar un instrumento musical a bordo por pasajero siempre y cuando no exceda las dimensiones establecidas superficie lineal establecida Largo + alto + ancho 1.15 mt, se podrá intercambiar un instrumento musical sin costo adicional por una pieza de equipaje documentado, siempre y cuando no excedan las dimensiones establecidas. </p>

                <p><strong>Cenizas:</strong> Se acepta la trasportación de cenizas humanas y de mascotas dentro del equipaje documentado y de mano, se debe presentar al momento de la documentación y en filtros de seguridad lo siguiente: acta de defunción original y copia (únicamente para cenizas humanas) Documento de cremación, exhumación en original y copia, el contenedor de deberá ser metal, madera o plástico herméticamente sellado. </p>
                <p><strong>Cobertores, mantas, edredones y ropa de cama: </strong> por regulación sanitaria en contra de la propagación de la garrapata los cobertores, mantas, edredones y lo considerado como ropa de cama queda prohibido subirlo a las áreas publicas y de pasaje. Este tipo de artículos deberán viajar en el área de equipaje documentado. </p>

                <p><strong>11. Paquetería. LA EMPRESA</strong> no realiza el servicio de transporte de paquetería, por lo que cualquier “paquete” del pasajero deberá ser transportado en calidad de equipaje. No se considera equipaje y no está sujeto a las reglas de este, de forma enunciativa más no limitativa el morral, envuelto, amarre o cualesquiera otro que contenga herramienta agrícola manual o mecánica, misma que será totalmente responsabilidad del pasajero. </p>

                <p>Los artículos frágiles o perecederos, musicales, equipamiento y/o material deportivo, artículos para el hogar, herramientas, dinero, joyas, platería, documentos negociables, valores u otros objetos de valor no se aceptarán en nuestro servicio de equipajes ni se reconoce como formal ni tácitamente recibidos en custodia, por lo que en todo momento se encontrarán bajo la custodia y responsabilidad del pasajero y/o cliente. </p>

                <p><strong>12. Documentación.</strong> El pasajero y/o cliente será el único responsable de proporcionar a <strong>LA EMPRESA</strong> datos ciertos y correctos de nombre, domicilio, R.F.C. y demás que sean requeridos por ésta, así como que el contenido declarado del vehículo para su documentación sea cierto y cumpla con las normas que disponen las diversas autoridades.</p>

                <p><strong>13. La Administración Portuaria Integral (API)</strong>cobra una serie de derechos e impuestos por el uso de sus instalaciones y servicios, montos que son ajenos a <strong>LA EMPRESA</strong>, por lo que no están integrados a la tarifa aplicable por <strong>LA EMPRESA</strong>.</p>

                <p><strong>14. Jurisdicción aplicable.</strong> En caso de surgir alguna controversia derivada de las presentes condiciones de servicio, así como para todo aquello que no esté expresamente estipulado en las mismas, las partes se someten a la jurisdicción de los tribunales competentes de la ciudad de La Paz, Baja California Sur, México; por lo tanto, el pasajero renuncia al fuero que pudiere corresponderle por razón de su domicilio presente o futuro o cualquier otra causa. </p>

                <p><strong>15. Interpretación y/o Cumplimiento del Contrato.</strong> Se establece que, a efectos de que se suscite alguna     controversia sobre la interpretación o cumplimiento del contrato, la   autoridad administrativa competente       para resolverla será la Procuraduría Federal de Protección al Consumidor. </p>

                <p><strong>16. Autoridades.</strong> En los puertos operan diferentes autoridades, señalándose a manera enunciativa más no limitativa: Procuraduría General de la República, Servicio de Administración Tributaria, Aduana, Ejército Mexicano, Secretaría de Marina, entre otras, las cuales, de acuerdo a sus actividades y  funciones solicitan documentos específicos, situación que resulta ajena a nuestra operación, por lo que cualquier demora o cancelación derivado de requerimientos realizados por las autoridades ajenas a <strong>LA EMPRESA</strong>, es responsabilidad del Cliente. </p>

                <p><strong>17. Facturación.</strong> Si el cliente requiere factura deberá solicitarla al momento de su compra, no se expedirán facturas con fecha posterior a la compra. En caso de requerir algún cambio deberá ser solicitado dentro de las 48 horas posteriores a la expedición de la factura. Los datos proporcionados por parte del Cliente, así como nombre y/o razón social, domicilio y demás datos, son responsabilidad de él mismo. </p>

                <p><strong>18. Polizones.</strong> . El ingreso a una embarcación de personas no autorizadas constituye un atentado en contra de la seguridad de la embarcación, de su tripulación y sus pasajeros, aunado a que constituye un fraude al disponer de un servicio sin cubrir su costo. En caso de detectarse algún Polizón a bordo de la embarcación, este será consignado a las autoridades competentes, así como toda aquella persona que facilite dicha conducta ilícita.  En todo caso, todo Polizón, así como aquella persona que facilite dicha conducta ilícita, serán responsables solidariamente de cubrir  a <strong>LA EMPRESA  </strong>el importe que se genere derivado de los servicios que se deben proporcionar al Polizón, señalándose a manera enunciativa más no limitativa: costo de derecho de embarque, costo de cabina para custodia, custodia personal, servicio médico, alimentación y todos aquellos que se generen derivado de su localización a bordo de la embarcación, de acuerdo a las tarifas que se encuentren vigentes en dicho momento. </p>

                <p><strong>19. Protección al medio ambiente.</strong>Todos los pasajeros y operadores deberán respetar las medidas que se tienen para el cuidado y protección del medio ambiente que se tienen dentro del buque. Todo vehículo propiedad de los clientes, deberá presentarse en óptimas condiciones y libre de fugas, goteos o derrames</p>
            </div>

        </div>
    </section>
    <Footer />
    </>
  )
}

