import React, { useState, useEffect } from "react";
import '../css/rutas_horario.css'
import { Outlet, Link } from "react-router-dom";
import Carousel from '../components/Carousel'
import Footer from '../components/Footer';
import tarifas from '../assets/images/cta/tarifas.svg'
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import preguntas from '../assets/images/pregutas.png'
import buque_cali from '../assets/images/iconRutasHorario/california-star.svg'
import buque_mexico from '../assets/images/iconRutasHorario/mexico-star.svg'
import MapaCali from '../components/frames/MapRoutes'
import Booking from "../components/form/Booking";
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import MapaTopo from '../components/maps/MapaTopo'
import MapaMaza from '../components/maps/MapaMaza'
import MapaDefault from '../components/maps/Mapa'
// Token de acceso de Mapbox
mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kaHJhIiwiYSI6ImNsbDJnZTJjbzJnMHAzZW15YnhhOWJkOGEifQ.pP3n_58agJfYHp7fUzrUbw';


const servicios = [ 
    '../images/iconRutasHorario/alimentos.svg',
    '../images/iconRutasHorario/asientos.svg',
    '../images/iconRutasHorario/cabina.svg',
    '../images/iconRutasHorario/camioncito.svg',
    '../images/iconRutasHorario/maleta.svg',
    '../images/iconRutasHorario/mascota.svg',
    '../images/iconRutasHorario/vehiculo.svg',

    // Añade más logos aquí...
  ];

export default function Rutas_horario() {
    const [isRutaVisible, setIsRutaVisible] = useState(false);
    const [selectedOption, setSelectedOption] = useState('La Paz - Topolobampo');
    const [cambiarMapa, setCambiarMapa] = useState(true);
    

    const toggleIsRutaVisible = () => {
        setIsRutaVisible(!isRutaVisible)
    };
    
    
    //Sin auto
    const itinerario_LP_TP = [
        {
          dia: 'Lunes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Martes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '20:30 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '21:30 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viaje programado',
        }
    ];
    const itinerario_TP_LP = [
        {
          dia: 'Lunes',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Martes',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '21:30 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',

        }
    ];
    const itinerario_LP_MZ = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miercoles',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Jueves',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Sábado',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        }
    ];
    const itinerario_MZ_LP = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Miercoles',        
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS', 
        },
        {
          dia: 'Jueves',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Viernes',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS', 
        },
        {
          dia: 'Sábado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '08:00 HRS', 
        }
    ];


    //Con auto
    const itinerario_LP_TP_AUTO = [
        {
          dia: 'Lunes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Martes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '12:00 HRS',
          salida: '13:30 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '21:30 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viaje programado',
        }
    ];
    const itinerario_TP_LP_AUTO = [
        {
          dia: 'Lunes',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Martes',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Miércoles',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Jueves',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '22:30 HRS',
          salida: '23:59 HRS',
          duracion: '09:00 HRS',
        },
        {
          dia: 'Sabado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '21:30 HRS',
          salida: '23:00 HRS',
          duracion: '09:00 HRS',

        }
    ];
    const itinerario_LP_MZ_AUTO = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '15:00 HRS',
        },
        {
          dia: 'Miercoles',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Jueves',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '15:00 HRS',
        },
        {
          dia: 'Viernes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Sábado',
          embarque: '17:30 HRS',
          salida: '19:00 HRS',
          duracion: '15:00 HRS',
        },
        {
          dia: 'Domingo',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        }
    ];
    const itinerario_MZ_LP_AUTO = [
        {
          dia: 'Lunes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Martes',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Miercoles',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '15:00 HRS',      
        },
        {
          dia: 'Jueves',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Viernes',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '15:00 HRS',
        },
        {
          dia: 'Sábado',
          embarque: '',
          salida: '',
          duracion: '',
          sinViaje: 'No hay viajes programado',
        },
        {
          dia: 'Domingo',
          embarque: '14:30 HRS',
          salida: '16:00 HRS',
          duracion: '15:00 HRS',
        }
    ];

    // Manejar la selección del usuario
    const handleRouteSelection = (route) => {        
        setSelectedOption(route);
        setIsRutaVisible(!isRutaVisible);        
    };

 

  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Rutas y Horarios</h3>
                        <p>Planifica tu próximo viaje con nosotros ¡Elige el día y reserva ahora!</p>
                    </div>                
                </div>
            </div>
        </section>
        <section style={{background: '#E8E8E8'}}>
            <Booking />
        </section>
        <section className="d-none d-sm-none d-md-block">
            <div className="rutas_horarios">
                <div className="container">  
                    <div className="rutas_horario_tabs">
                        <div className='select_route_ruta_horario'>           
                            <label>Selecciona una ruta</label>
                            <div className='box_select_route' onClick={toggleIsRutaVisible} >                    
                                <div className='select_ruta__horario'>                               
                                        <label>{selectedOption} </label>
                                        <i class="fi fi-br-angle-small-down"></i>
                                    </div>                                                      
                                <div>
                                    {isRutaVisible && 
                                    <div className='optionListRoutes'>
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                        <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                                    </div>
                                    }
                                </div>
                            </div>                              
                        </div>                                                    
                    </div>
                   
                    <div className="container_rutas_horarios">
                        <div className="content_rutas_horarios">
                            
                            <div className="box_rutas_horarios">
                                <div className="header_rutas_horarios">
                                    
                                    <div className="box_selected_rutas_horarios">
                                      
                                        
                                    </div>
                                    {/*                            
                                    <div className="select_rutas_horarios">
                                        <div className="box_select_rutas_horarios"> 
                                        <label style={{fontSize:'14px'}}>Ruta:</label> 
                                        <p>{selectedOption}</p>             
                                            <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                                                <div className='select_ruta_horario'>
                                                    <label>{selectedOption} </label>
                                                    <i class="fi fi-br-angle-small-down"></i>
                                                </div>      
                                            </div>
                                            {isRutaVisible && 
                                            <div className='optionListRoutes'>
                                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                                <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                                <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                                            </div>
                                            }                                                                                                                                                                                                                
                                        </div>
                                    </div>
                                    */}
                                    
                                </div>
                                <div className="body_rutas_horarios">
                                    {selectedOption === 'La Paz - Topolobampo' && (
                                    <>                                
                                        <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th></th>
                                                <th className="tb_tyh_edit">REGISTRO </th>
                                                <th className="tb_tyh_edit">SALIDA </th>
                                                <th className="tb_tyh_edit">LLEGADA</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">LUNES A VIERNES</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>Sin vehículo</td>
                                                <td className="tb_tyh_edit">12:00 HRS</td>
                                                <td className="tb_tyh_edit">13:30 HRS</td>
                                                <td className="tb_tyh_edit">20:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>Con vehículo</td>
                                                <td className="tb_tyh_edit">11:30 HRS</td>
                                                <td className="tb_tyh_edit">13:30 HRS</td>
                                                <td className="tb_tyh_edit">20:30 HRS</td>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">SÁBADO</td>
                                            </tr>
                                            
                                            <tr>
                                                <td>Sin vehículo</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>					        					      
                                            </tr>	
                                            <tr>
                                                <td>Con vehículo</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>	
                                            </tr>	
                                            <tr>
                                                <td  className="dia_semana">DOMINGO</td>
                                                <td colSpan={3}  className="tb_tyh_edit">No hay viaje programado</td>  
                                            </tr>			    

                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                    {selectedOption === 'Topolobampo - La Paz' && (
                                    <>                                
                                        <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th></th>
                                                <th className="tb_tyh_edit">REGISTRO </th>
                                                <th className="tb_tyh_edit">SALIDA </th>
                                                <th className="tb_tyh_edit">LLEGADA</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">LUNES A VIERNES</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>Sin vehículo</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:59 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>Con vehículo</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:59 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">SÁBADO</td>
                                                <td colSpan={3}  className="tb_tyh_edit">No hay viaje programado</td> 
                                            </tr>
                                            <tr>
                                                <td  className="dia_semana">DOMINGO</td>                                                
                                            </tr>
                                            <tr>
                                                <td>Sin vehículo</td>
                                                <td className="tb_tyh_edit">21:30 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>					        					      
                                            </tr>	
                                            <tr>
                                                <td>Con vehículo</td>
                                                <td className="tb_tyh_edit">21:00 HRS</td>
                                                <td className="tb_tyh_edit">23:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>	
                                            			    

                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                    {selectedOption === 'La Paz - Mazatlan' && (
                                    <>                                
                                          <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th></th>
                                                <th className="tb_tyh_edit">REGISTRO </th>
                                                <th className="tb_tyh_edit">SALIDA </th>
                                                <th className="tb_tyh_edit">LLEGADA</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">MARTES, JUEVES Y SÁBADO</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>Sin vehículo</td>
                                                <td className="tb_tyh_edit">17:30 HRS</td>
                                                <td className="tb_tyh_edit">19:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>Con vehículo</td>
                                                <td className="tb_tyh_edit">17:00 HRS</td>
                                                <td className="tb_tyh_edit">19:00 HRS</td>
                                                <td className="tb_tyh_edit">09:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">LUNES, MIÉRCOLES, VIERNES Y DOMINGO</td>
                                                <td colSpan={3}  className="tb_tyh_edit">No hay viaje programado</td>  
                                            </tr>	    
                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                    {selectedOption === 'Mazatlan - La Paz' && (
                                    <>                                
                                        
                                        <table class="table table-bordered tb_tyh">
                                            <tbody>
                                                
                                            <tr>
                                                <th></th>
                                                <th className="tb_tyh_edit">REGISTRO </th>
                                                <th className="tb_tyh_edit">SALIDA </th>
                                                <th className="tb_tyh_edit">LLEGADA</th>
                                            </tr>
                                            <tr>
                                                <td colSpan={4} className="dia_semana">MIÉRCOLES, VIERNES Y DOMINGO</td>                                                      
                                            </tr>
                                            <tr>
                                                <td>Sin vehículo</td>
                                                <td className="tb_tyh_edit">14:30 HRS</td>
                                                <td className="tb_tyh_edit">16:00 HRS</td>
                                                <td className="tb_tyh_edit">08:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td>Con vehículo</td>
                                                <td className="tb_tyh_edit">14:00 HRS</td>
                                                <td className="tb_tyh_edit">16:00 HRS</td>
                                                <td className="tb_tyh_edit">08:00 HRS</td>
                                            </tr>
                                            <tr>
                                                <td className="dia_semana">LUNES, MARTES, JUEVES, Y SÁBADO</td>
                                                <td colSpan={3}  className="tb_tyh_edit">No hay viaje programado</td> 
                                            </tr>
                                            
                                            
                                           			    

                                                                    
                                            </tbody>
                                        </table>
                                    </>
                                    )}
                                </div>
                            </div>
                            
                        
                            <div className="box_servicios_itinerario">                            
                                <div className="available_services">                                
                                    <h3>Servicios disponibles</h3>
                                    <Carousel logos={servicios} />                                                                                                     
                                </div>
                                <div className="box_buque_itinerario">
                                    <div className="available_services_buque">
                                        <div><p>Esta ruta generalmente será a bordo del</p></div>
                                        {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ?(
                                        <>
                                            <div className="box_available_services">
                                                <img src={buque_cali} alt="buque california star" />
                                                <h4>CALIFORNIA STAR</h4>
                                            </div>
                                            <div><p>Conoce más de <Link to="/servicios-california-star">nuestro buque</Link></p></div>
                                        </>):(<>
                                            <div className="box_available_services"><img src={buque_mexico} alt="buque california star" /><h4>MÉXICO STAR</h4></div>
                                            <div><p>Conoce más de <Link to="/servicios-mexico-star">nuestro buque</Link></p></div>
                                        </> )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="content_mapa_rutas_horarios">
                            {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ? (
                                
                                <MapaTopo />
                                
                            ):(
                                <MapaMaza/>
                            )}
                           
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
     
    </section>

        <section className="d-block d-sm-block d-md-none">
            <div className="container-rutas-horario">
                <div className="container">
                    
                    <div className="box-select-ruta">
                        <div className="box_select_rutas_horarios phone">               
                            <div className='select_ruta_horario_box_content' onClick={toggleIsRutaVisible} >                                                        
                                <div className='select_ruta_horario phone'>                    
                                    <label>{selectedOption} </label>
                                    <i class="fi fi-br-angle-small-down"></i>
                                </div>      
                            </div>
                            {isRutaVisible && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => handleRouteSelection('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                <div className='optionRoutes' onClick={() => handleRouteSelection('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => handleRouteSelection('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                            </div>
                            }                                                                                                                                                                                                                
                        </div>
                    </div>

                    <div className="content-rutas-horario">
                        <div className="body-rutas-horario">
                            {selectedOption === 'La Paz - Topolobampo' &&(
                            <>                                                                                    
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES A VIERNES</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>SÁBADO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                                
                                {itinerario_LP_TP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>DOMINGO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>                                                             
                                    </div>
                                ))}
                            </>
                            )}
                            {selectedOption === 'Topolobampo - La Paz' &&(
                            <>                                
                                                    
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES A VIERNES</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Sabado')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>SÁBADO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                                {itinerario_TP_LP
                                .filter(viaje => viaje.dia === 'Domingo')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>DOMINGO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                       
                                    </div>
                                ))}
                            </>
                            )}
                            {selectedOption === 'La Paz - Mazatlan' &&(
                            <>                                
                                                    
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Martes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>MARTES, JUEVES Y SÁBADO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_LP_MZ
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES, MIÉRCOLES, VIERNES Y DOMINGO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                
                               
                            </>
                            )}
                            {selectedOption === 'Mazatlan - La Paz' &&(
                            <>                                
                                                    
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Viernes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>MIÉRCOLES, VIERNES Y DOMINGO</h3>
                                        <div className="item-hora-itinerario"><label>Registro: </label><label> {viaje.embarque}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Salida: </label><label>{viaje.salida}</label></div>
                                        
                                        <div className="item-hora-itinerario"><label>Llegada: </label><label>{viaje.duracion}</label> </div>                                                        
                                    </div>
                                ))}
                            
                                {itinerario_MZ_LP
                                .filter(viaje => viaje.dia === 'Lunes')
                                .map((viaje, index) => (
                                    <div key={index} className="items-box-itinerario">
                                        <h3>LUNES, MARTES, JUEVES Y SÁBADO</h3>
                                        <div className="item-itinerario no-viaje">{viaje.sinViaje || ''}</div>  
                                                                                               
                                    </div>
                                ))}
                                                               
                            </>
                            )}
                       
                            <div>
                                <p>• Itinerario sujeto a cambios sin previo aviso.</p>
                            </div>
                        </div>                                                                        
                    </div>
                </div>
                <div className="box-servicio-horario">
                    <div className="available-services">                                
                        <h3>Servicios disponibles</h3>
                        <Carousel logos={servicios} />                                                                                                     
                    </div>
                </div>
                <div>
                <div className="content_mapa">
                    {selectedOption === 'La Paz - Topolobampo' || selectedOption === 'Topolobampo - La Paz' ? (
                                
                        <MapaTopo />
                        
                    ):(
                        <MapaMaza/>
                    )}
                </div>
                </div>
               {/*
                <div className="container">
                    <div className="content-rutas-horario">
                        <div className="content_mapa">
                            <MapaCali />
                        </div>
                    </div>                    
                </div>
                */}
            </div>
        </section>
       

        <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={tarifas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Consulta nuestras tarifas</h4>
                                </div>
                                <div>
                                    <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/tarifas">Ver tarifas</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Preguntas frecuentes</h4>
                                </div>
                                <div>
                                    <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">Más información</Link>     
                            </div>
                        </div>
                    </div>
                   {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envianos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </section>

        <Outlet />
        <Footer />
            
        
        </>
  )
}
