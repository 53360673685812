import React from 'react';
import '../../css/slider_destinos.css'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 8000,
};

const ImageSlider = ({images}) => {

  return (
    <div className='container'>
        <div className="slider-destino">
            <Slider {...settings}>
                {images.map((slide, index) => (
                    <div key={index} className='destino_slider_container'>
                        <img src={slide.image} alt={`Slide ${index + 1}`} />
                        <div className="slide-title">{slide.title}</div>
                        <div className="slide-text">{slide.text}</div>
                        <div className="slide-subtext">{slide.subtext}</div>
                    </div>
                ))}
            </Slider>
        </div>
    </div>
   
  );
};

export default ImageSlider;


