import React, {useState} from 'react'

import '../css/responsive.css'

import logo from '../assets/images/logo.png';

export default function Menu() {
    const [isNavExpanded, setIsNavExpanded] = useState(false)
 
  return (

    <> 


    {
        //Celular
    }
    <section className='menu_mobile'>
        <div className='container'>
            <nav className='navbar'>
                <div className='menu_logo'>
                    <img src={logo} alt="logo" />
                </div>
                <div className='menu_btn'>
                    <button className="hamburger" onClick={() => { setIsNavExpanded(!isNavExpanded) }} >
                        {/* hamburger svg code... */}
                        <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="h-5 w-5"
                        viewBox="0 0 20 20"
                        fill="red"
                        >
                        <path
                            fillRule="evenodd"
                            d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM9 15a1 1 0 011-1h6a1 1 0 110 2h-6a1 1 0 01-1-1z"
                            clipRule="evenodd"
                        />
                        </svg>
                    </button>
                </div>               
            </nav>
            <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu" }>
                <ul>
                    <li>Item</li>
                    <li>Item</li>
                    <li>Item</li>
                    <li>Item</li>
                    <li>Item</li>
                </ul>
            </div> 
        </div>
    </section>

    </>

        
  
  )
}
