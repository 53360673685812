import React, { useState, useEffect } from 'react';
import '../css/headerprueba.css'
import facebook_icon from '../assets/images/grises/facebook-gris.svg'
import instagram_icon from '../assets/images/grises/instagram-alt-circle-gris.svg'
import spotify_icon from '../assets/images/grises/spotify-gris.svg'
import twitter_icon from '../assets/images/grises/twitter-alt-circle-gris.svg'
import whatsapp_icon from '../assets/images/grises/whatsapp-grises.svg'
import youtube_icon from '../assets/images/grises/youtube-alt-circle-blanco.svg'
import '../css/pruebas.css';
import { Outlet, Link } from "react-router-dom";

const Headerprueba = () => {
    const [isAviso, setIsAviso] = useState(false);
    const [isMenuOpenRight, setIsMenuOpenRight] = useState(false);
    const [isMenuOpenLeft, setIsMenuOpenLeft] = useState(false);
    const [isActive, setActive] = useState(false)
    const [isSubmenu, setIsSubmenu] = useState(false)
    const [isSubmenuLeft, setIsSubmenuLeft] = useState(false)

    const toggleMenuRight = () => {
        setIsMenuOpenRight(!isMenuOpenRight);    
    };

    const toggleMenuLeft = () => {
        setIsMenuOpenLeft(!isMenuOpenLeft);
    };

    const toggleMenuExpanded = () => {
        setActive(!isActive);  
    };
    const toggleSubmenu = (index) => {
        setIsSubmenu((prevState) => (prevState === index ? null : index));
    };
    const toggleSubmenuLeft = (index) => {
        setIsSubmenuLeft((prevState) => (prevState === index ? null : index));
    };

    /* Noticia */
    
    const [noticias, setNoticias] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedNoticiaId, setSelectedNoticiaId] = useState(114); // Cambia 7 por el ID que desees mostrar

// Tu código para cargar las noticias desde la base de datos
useEffect(() => {
    const loadNoticias = async () => {
        try {
            //const response = await fetch('http://localhost:3004/noticias');
            const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias');
            const data = await response.json();
            console.log('Data: ', data);
            setNoticias(data);
        } catch (error) {
            console.error('Error fetching noticias:', error);
        } finally {
            setLoading(false); // Cuando se completa la carga o hay un error, detenemos la carga
        }
    };
    loadNoticias();
}, []);

    
if (loading) {
    // Muestra el mensaje de carga
    return <p>Loading...</p>;
} else {
    // Si hay noticias, toma la primera noticia como primeraNoticia, de lo contrario, muestra la noticia predeterminada
    //const primeraNoticia = noticias.length > 0 ? noticias[0] : null;
    const noticiaSeleccionada = noticias.find(noticia => noticia.id === selectedNoticiaId);

    // Filtra solo las noticias visibles
    /*const noticiasVisibles = noticias.filter(noticia => noticia.visible);

    if (noticiasVisibles.length === 0) {
    return <p>No hay noticias visibles</p>;
    }*/
    
  
return (
    <>
    {isAviso &&(
        <>
        {/*<section className='avisoHeader'>
            <div className='container'>
                <div className='avisoHeader_content'>
                    <p>  <i class="fi fi-ss-triangle-warning"></i>¡Atención!: Sigue la trayectoria de la tormenta tropical "Ileana" </p>
                    <label>
                    <a class="btn_aviso_cintillo" href="https://smn.conagua.gob.mx/es/animacion-imagenes-de-satelite?satelite=GOES%20Este&amp;nombre=Pac%C3%ADfico%20-%20Sur&amp;tipo=Tope%20de%20Nubes" target="_blank" rel="noopener noreferrer">Ir ahora</a>
                    </label>
                </div>

            </div>
        </section>*/}
         <section className='avisoHeader' style={{ background: '#007bff', color: '#fff'}}>
         <div className='container'>
             <div className='avisoHeader_content'>                 
                 <p style={{color:'#fff'}}>Consulta el ajuste de itinerario </p>
                 <label> <Link to="/aviso" className="btn_aviso_cintillo" style={{color: '#fff', border: '2px solid #fff'}}>Ver más</Link> </label>
                 
             </div>
             
         </div>
     </section>
     </>
    )}
    
    <section className={`header ${isActive ? 'overlay' : ''}`}>
    <nav className="navbar">
        <div className='container'>            
            <div className='navbar-content'>
                    <div className="navbar-brand">
                        <div className='box_menu_left'>
                            <button className="navbar-toggler" onClick={toggleMenuLeft}>
                                <i class="fi fi-rr-menu-dots"></i>
                            </button>
                        </div>
                        <div className='box_menu_img'>
                            <Link to="/">
                                <div className='menu_img_show'></div>
                            </Link>
                        </div>
                        <div className='box_menu_right'>
                            <button className="navbar-toggler" onClick={toggleMenuRight}>
                                <i className="fi fi-rr-bars-staggered"></i>   
                            </button>
                        </div>                
                    </div>
                
                    <ul className={`navbar-menu menu_items ${isMenuOpenRight ? 'navbar-menu-open' : ''}`}>

                        <div className='opc_menu_right'>
                            <div className='menu_img_show'></div>           
                                <div onClick={toggleMenuRight} className='menu_cerrar_option'> <div className='idioma_option'><label>ES</label> <i class="fi fi-rr-globe"></i>{/*<i class="fi fi-rr-caret-down"></i>*/}</div>
                                <div className='btn_cerrar_option'><i class="fi fi-br-cross"></i></div>
                            </div>
                        </div>
                        <div className='main_menu'>
                        
                            <li className="navbar-item ">
                                
                                <Link to="/rutas_horario" className="navbar-link" onClick={toggleMenuRight}>Rutas y Horarios</Link>                                        
                                {/*{isSubmenu === 1 &&(
                                <ul className={`submenu-item ${isSubmenu ? 'submenu-item-open' : ''}`}>
                                    <li><Link to="/rutas_horario" onClick={toggleMenuRight}>Rutas y Horarios </Link></li>
                                    <li><Link to="/tarifas" onClick={toggleMenuRight}>Tarifas </Link></li>
                                    <li><Link to="/destinos" onClick={toggleMenuRight}>Destinos </Link></li>
                                    <li><Link to="/promociones" onClick={toggleMenuRight}>Descuentos y promociones </Link>  </li>
                                    <li><Link to="/formulario-de-contacto?viajoEnGrupo=true" onClick={toggleMenuRight}>Viaja en grupo </Link> </li>
                                </ul>
                                )}
                                */}
                            </li>
                            <li className="navbar-item">
                                <Link to="/tarifas" className="navbar-link"  onClick={toggleMenuRight}>Tarifas </Link>                        
                                {/*<ul className='submenu-item'>
                                
                                    <li><Link to="/modelo" onClick={toggleSubmenu}>Modelo </Link></li>
                                    <li><Link to="/voluntariado" onClick={toggleSubmenu}>Voluntariado </Link></li>
                                    <li><Link to="/fundacion" onClick={toggleSubmenu}>Fundación </Link></li>
                                    
                                    <li><a  className="navbar-link" href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/" target="_blank" rel="noopener noreferrer">Portal de ética</a>   </li>
                                                        
                                </ul>  */}   
                            </li>
                            <li className="navbar-item">
                                <Link to="/promociones" className="navbar-link" onClick={toggleMenuRight}>Promociones </Link>                        
                                {/*<ul className='submenu-item'>
                                
                                    <li><Link to="/modelo" onClick={toggleSubmenu}>Modelo </Link></li>
                                    <li><Link to="/voluntariado" onClick={toggleSubmenu}>Voluntariado </Link></li>
                                    <li><Link to="/fundacion" onClick={toggleSubmenu}>Fundación </Link></li>
                                
                                    <li><a  className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Portal de ética</a>   </li>
                                                            
                                </ul>    */}
                            </li>
                            <li className="navbar-item ">
                                
                                <Link className="navbar-link" onClick={() => toggleSubmenu(1)}>Viaja <i class="fi fi-rr-caret-down"></i></Link>                                        
                                {isSubmenu === 1 &&(
                                <ul className={`submenu-item ${isSubmenu ? 'submenu-item-open' : ''}`}>
                                    <li><Link to="/destinos" onClick={toggleMenuRight}>Destinos </Link></li>
                                    <li><Link to="/viaje-en-grupo" onClick={toggleMenuRight}>Viaja en grupo </Link> </li>
                                </ul>
                                )}
                            </li>
                            {/*}
                            <li className="navbar-item">                        
                                <a  className="navbar-link" href="https://eweb-test-bafc.carus.com/booking_bajaferries_com_mx_es/customer-login/" target="_blank" rel="noopener noreferrer">Reservaciones</a>                                                              
                            </li>
                            */}
                            <li className="navbar-item">                    
                                <Link className="navbar-link" onClick={() => toggleSubmenu(2)}>Información <i class="fi fi-rr-caret-down"></i></Link>                                         
                                {isSubmenu === 2 &&(
                                <ul className='submenu-item'>
                                    <li><Link to="/prepara-tu-viaje" onClick={toggleMenuRight}>Prepara tu viaje </Link></li>
                                    <li><Link to="/preguntas-frecuentes" onClick={toggleMenuRight}>Preguntas frecuentes </Link></li>
                                    <li><Link to="/flota" onClick={toggleMenuRight}>Nuestra flota </Link></li>
                                    <li><Link to="/contacto" onClick={toggleMenuRight}>Contacto </Link>  </li>                        
                                </ul>  
                                )}
                            </li>
            
                    
                        
                                
                                            
                            {/*
                            <li className="navbar-item">                    
                                <Link to="/viaje-en-curso"  className="navbar-link"  onClick={toggleSubmenu}>Viaje en curso </Link>                    
                            </li>
                            
                            <li className="navbar-item">                    
                                <Link to="/itinerarioprueba"  className="navbar-link"  onClick={toggleSubmenu}>itinerario </Link>                    
                            </li>*/}
                            
                            {/*
                            <li className="navbar-item">
                                <Link to="/">BF Rewards </Link>                                
                            </li>
                            */}
                            
                            <div className='botton_opcion_menu'>
                                <li className="navbar-item">                    
                                    <Link to="/soluciones-logisticas" style={{ border: '1px solid #225981', background: '#225981', padding: '5px 5px'}}  onClick={toggleSubmenu}> Carga </Link>                   
                                </li>
                                {/*<li className="navbar-item">                    
                                    <a  className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Facturación</a>                                   
                                </li>*/}
                    
                            </div>

                        </div>                                        
                    </ul>
                    <div className='content_menu_right menu_items'>
                            <li className="navbar-item">                        
                                <Link to="/soluciones-logisticas"   style={{ border: '1px solid #225981', background: '#225981', padding: '5px 5px'}}   onClick={toggleSubmenu}> Carga </Link>                                          
                            </li>
                            {/*<li className="navbar-item">                        
                                <a  className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Facturación</a>                  
                            </li>*/}
                            <li className="navbar-item">                        
                                <a  className="navbar-link" href="https://facturacion.bajaferries.com" target="_blank" rel="noopener noreferrer">Facturación</a>                  
                            </li>
                            {/*}
                            <li className="navbar-item">
                                <Link to="/">Login </Link>                                
                            </li>
                            */}
                            
                            
                            <li className="navbar-item">                    
                                <Link to="/" className=""><i class="fi fi-rr-globe"></i> ES {/*<i class="fi fi-rr-caret-down"></i>*/}</Link>                                                
                                {/*<ul className='submenu-item'>
                                
                                    <li><Link to="/promociones" onClick={toggleSubmenu}>EN </Link></li>
                                    <li><Link to="/promociones" onClick={toggleSubmenu}>FR </Link></li>                      
                                </ul> */}
                            </li>
                            <li className="">
                                <button className="navbar-link" onClick={toggleMenuExpanded}><i class="fi fi-rr-bars-staggered" style={{fontSize: '20px', display: 'flex'}}></i></button>                    
                            </li>
                        </div>

                    <ul className={`navbar-menu-left ${isMenuOpenLeft ? 'navbar-menu-open-left' : ''}`}>                

                        <div className='opc_menu_right'>
                            <div className='menu_img_show_left'></div>           
                                <div onClick={toggleMenuLeft} className='menu_cerrar_option'> <div className='idioma_option_left'><label>ES</label> <i class="fi fi-rr-globe"></i>{/*<i class="fi fi-rr-caret-down"></i>*/}</div>
                                <div className='btn_cerrar_option_left'><i class="fi fi-br-cross"></i></div>
                            </div>
                        </div>
                
                        <li className="navbar-item-left">
                            <Link className=""  onClick={() => toggleSubmenuLeft(1)}>Acerca de nosotros<i class="fi fi-rr-caret-down"></i></Link>
                                                                                            
                                {isSubmenuLeft === 1 &&(
                                <ul className=''>
                                    <li><Link to="/nosotros" onClick={toggleMenuRight}>Conócenos </Link></li>
                                    <li><Link to="/flota" onClick={toggleMenuRight}>Nuestra flota </Link></li>
                                    <li><Link to="/certificaciones" onClick={toggleMenuRight}>Certificaciones </Link></li>
                                </ul>
                                )}
                        </li>
                        {/*<li className="navbar-item-left">
                            <Link to="/sostenibilidad" onClick={toggleMenuLeft}  className="navbar-link">SRE </Link>                                        
                        </li>*/}
                        <li className="navbar-item-left">                    
                            
                            <Link className=""  onClick={() => toggleSubmenuLeft(2)}>Carga <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenuLeft === 2 &&(
                                <ul className=''>
                                    <li><Link to="/soluciones-logisticas" onClick={toggleMenuRight}>Transporte de carga rodada </Link></li>
                                    
                                </ul>
                                )}
                        </li>
                        <li className="navbar-item-left">                                  
                            <Link className=""  onClick={() => toggleSubmenuLeft(3)}>Atención al cliente <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenuLeft === 3 &&(
                                <ul className=''>
                                    <li><Link to="/contacto" onClick={toggleMenuRight}>Contacto </Link></li>
                                    <li><Link to="/preguntas-frecuentes" onClick={toggleMenuRight}>Preguntas frecuentes </Link></li>
                                    
                                    <li className="navbar-item-left">             
                                        <a  className="navbar-link" href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&amp;pass=e3f391ec274cd03488365c20827762e3dc893500&amp;nif=COLR039&amp;centro=7888&amp;campanya=6711&amp;oleada=100982&amp;idm=es " target="_blank" rel="noopener noreferrer">Buzón de quejas y sugerencias</a>                                
                                    </li>
                                </ul>
                                )}
                            
                        </li>
                        <li className="navbar-item-left">                                    
                            <a  className="navbar-link" href="https://facturacion.bajaferries.com" target="_blank" rel="noopener noreferrer">Facturación</a>                    
                        </li>
                        <li className="navbar-item-left">                    
                            <Link to="/noticias" className="navbar-link" onClick={toggleMenuLeft}>Blog </Link>
                            
                        </li>
                        <li className="navbar-item-left">             
                            <a  className="navbar-link" href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/" target="_blank" rel="noopener noreferrer">Portal de ética</a>    
                            
                        </li>
                        <li className="navbar-item-left">             
                            <a  className="navbar-link" href="https://www.eticabajaferries.com.mx/denuncias/" target="_blank" rel="noopener noreferrer">Portal de denuncias</a>                    
                        </li>
                        <li className="navbar-item-left">                                    
                            <a  className="navbar-link" href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">Proveedor</a>                    
                        </li>
                        {/*<li className="navbar-item-left">
                            <Link to="/login-gobernanza" className="navbar-link" onClick={toggleMenuLeft}>Gobernanza </Link>                    
                        </li>*/}                 
                    </ul>            
            </div>
        </div>
    </nav>


    {/* Menu lateral DESKTOP*/}
    <aside className={`sidebar ${isActive ? "show" : null}`}>
        <div className='boxSidebar'>
            <div className='boxAsidebar'>
                <div className='btnCerrar'> 
                    <button onClick={toggleMenuExpanded}  className="navbar-toggle " id="toggleButton">                            
                        {/*<i className="fi fi-rr-cross-circle"></i>*/}
                        <i class="fi fi-br-cross"></i>
                    </button>
                </div>
                <div className='blockAsidebar_first'>
                    <ul>                                       
                        <li className='block'><Link to="/nosotros">Acerca de nosotros </Link>
                            <ul>
                                <li><Link to="/nosotros" onClick={toggleMenuExpanded}>Conócenos </Link></li>
                                {/*<li><Link to="/puertos">Puertos </Link></li>*/}
                                <li><Link to="/flota" onClick={toggleMenuExpanded}>Nuestra flota </Link></li>                                              
                                <li><Link to="/certificaciones" onClick={toggleMenuExpanded}>Nuestras certificaciones </Link></li>                                       
                                {/*<li><Link to="/itinerarioprueba" onClick={toggleMenuExpanded}>Viaje en curso </Link></li> */}
                            </ul>
                        </li>  
                        <li className='block'><Link to="/soluciones-logisticas">Carga</Link>
                            <ul>
                                <li><Link to="/soluciones-logisticas" onClick={toggleMenuExpanded}>Transporte de carga rodada</Link></li>
                               {/*} <li><Link to="/maritimo" onClick={toggleMenuExpanded}>Marítimo </Link></li>
                                <li><Link to="/terrestre" onClick={toggleMenuExpanded}>Terrestre </Link></li>
                                <li><Link to="/multimodal" onClick={toggleMenuExpanded}>Multimodal </Link></li>
                                <li><Link to="/integración-logística" onClick={toggleMenuExpanded}>Integración Logística </Link></li>*/}
                            </ul>
                        </li>  
                        {/* 
                        <li className='block'><a href="?">Soluciones digitales</a>
                            <ul>
                                <li><a href='?'>Marketplace</a></li>
                                <li><a href='?'>Rastreo</a></li>                                                    
                            </ul>
                        </li> 
                        */}
                        <li className='block'><a href="https://booking.bajaferries.com.mx/es/cuenta/" target="_blank" rel="noopener noreferrer">Blog</a>
                            <ul>
                                <li><Link to="/noticias" onClick={toggleMenuExpanded}>Noticias y Avisos</Link></li>
                            </ul>
                        </li>
                        <li className='block'><a href="?">Cuenta</a>
                            <ul>
                                <li> <a href="https://booking.bajaferries.com.mx/es/cuenta/" target="_blank" rel="noopener noreferrer">Accede a tu cuenta</a></li>                                
                            </ul>
                        </li>
                        {/*
                   
                        <li className='block'><a href="?">Prensa</a>
                            <ul>
                                <li><Link to="/kit-de-comunicacion" onClick={toggleMenuExpanded}>Contacto de agencia</Link></li>
                            </ul>
                        </li>*/}
                    </ul>
                </div>
                <div className='blockAsidebar_second'>
                    <ul>
                        {/*
                    <li className='block'><a href="?">RSE</a>
                        <ul>
                            <li><a href='?'>Modelo de sustentabilidad</a></li>
                            <li><a href='?'>Programas</a></li>
                            <li><a href='?'>Voluntariado</a></li>
                        </ul>
                    </li>  
                    */}
                    <li className='block'><a href='?'>Atención al cliente </a>                                           
                        <ul>
                            <li><Link to="/contacto" onClick={toggleMenuExpanded}>Contacto </Link></li>  
                            <li><Link to="/preguntas-frecuentes" onClick={toggleMenuExpanded}>Preguntas frecuentes </Link></li>                              
                            <li><a href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">Buzón de quejas y sugerencias</a></li>
                        </ul>                                     
                    </li>
                    <li className='block'><a href="?">Ética </a>                      
                        <ul>                            
                            <li><a href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/" target="_blank" rel="noopener noreferrer">Portal de ética</a>   </li>
                            <li><a href="https://www.eticabajaferries.com.mx/denuncias/" target="_blank" rel="noopener noreferrer">Portal de denuncias</a>   </li>                                                                                                               
                                                                                                              
                        </ul>
                    </li>
                    
                    {/*<li className='block'><Link to="/login-gobernanza" onClick={toggleMenuExpanded}>Gobernanza </Link>
                        <ul>
                            <li>
                                <Link to="/login-gobernanza" onClick={toggleMenuExpanded}>Login</Link>
                            </li>                                                                                    
                        </ul>
                    </li>*/}
                    <li className='block'><Link to="/login-gobernanza" onClick={toggleMenuExpanded}>Portales </Link>
                        <ul>
                            <li>
                                <a href="https://facturacion.bajaferries.com/" target="_blank" rel="noopener noreferrer">Facturación</a>    
                            </li>  
                            <li>
                                <a href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">Proveedor</a>    
                            </li>                                                                                    
                        </ul>
                    </li>

                        <div className='asidebar_icons_socials'>    
                        <a href="https://www.facebook.com/bajaferriesoficial/" target="_blank" rel="noopener noreferrer"><img src={facebook_icon}  alt='facebook baja ferries'/></a> 
                        <a href="https://www.instagram.com/bajaferriesoficial/" target="_blank" rel="noopener noreferrer"><img src={instagram_icon}  alt='instagram baja ferries'/></a> 
                        <a href="https://open.spotify.com/user/in0rh2go0af0k6asr46bzxun7" target="_blank" rel="noopener noreferrer"><img src={spotify_icon}  alt='spotify baja ferries'/></a> 
                        <a href="https://x.com/bajaferries" target="_blank" rel="noopener noreferrer"><img src={twitter_icon}  alt='twitter baja ferries'/></a> 
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer"><img src={whatsapp_icon}  alt='whatsapp baja ferries'/></a> 
                        <a href="https://www.youtube.com/@bajaferries2914" target="_blank" rel="noopener noreferrer"><img src={youtube_icon}  alt='youtube baja ferries'/></a>                                                                                
                                                                                                                                            
                        </div>
                    </ul>                                        
                    
                </div>
            
            </div>
                        
            <div className='asidebarNoticia'>
                    {/* Si hay una primera noticia, muestra los detalles de esa noticia */}
                    {noticiaSeleccionada && (
                        <Link to={`/noticia/${noticiaSeleccionada.id}`}>
                            <div className='noticia_box_asidebar'>
                                <div className='asidebar_noticia_box'>
                                    <div>
                                        <p><i className="fi fi-sr-bookmark"></i> {noticiaSeleccionada.category.join(', ')}</p>
                                        <h3>{noticiaSeleccionada.title}</h3>
                                    </div>
                                    <div className='img_box_asidebar' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(./images/noticias/${noticiaSeleccionada.photo_route_header})` }}></div>
                                </div>
                            </div>
                        </Link>
                    )}
                    {/* Si no hay una primera noticia, muestra el contenido de noticia_default */}
                    {!noticiaSeleccionada && (
                        <div className='noticia_default'>
                            {/* Aquí puedes colocar el contenido de la noticia predeterminada */}                           
                            <div className='noticia_box_asidebar'>
                                <div className='asidebar_noticia_box'>
                                    <div>
                                        <p><i className="fi fi-sr-bookmark"></i> Bajaferries</p>
                                        <h3>Baja Ferries nombra a Nino Liaño Egozcue como nuevo Director General</h3>
                                    </div>
                                    <div className='img_box_asidebar' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(./images/noticias/1707321989123.png)` }}></div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                
            
        </div>                                                    
    </aside>
    </section>
    <Outlet />
    </> 
);
}
};


export default Headerprueba;