import React, {  useState } from 'react';
import '../css/durante_viaje.css'
import { CSSTransition } from "react-transition-group";
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import Footer from '../components/Footer';
import info_util from '../assets/images/durante-viaje/durante-viaje.png'
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'


const Tab = ({ label, onClick, active, icon }) => {
    return (
      <div className={`tab_viajar ${active ? 'active' : ''}`} onClick={onClick}>       
        <p>{label}</p>
      </div>
    );
};





export default function Despues_viaje() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

  
  

  return (
    <>
    <section id="hero-section">
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Al llegar a tu destino</h3>
                    <p>Consulta la información e indicaciones para desembarcar</p>
                </div>                
            </div>
        </div>                                 
    </section>
  
   <sections className='info_durante_viajar'>
   <div className="container">
        <div className='tabs_viajar'>
           <div className='tab-container'>
            <div className="tab-boxes">
                    <Tab
                    label="Desembarque con automóvil"
                    onClick={() => handleTabClick(0)}
                    active={activeTab === 0}
                    
                    />
                    <Tab
                    label="Desembarque de pasajeros"
                    onClick={() => handleTabClick(1)}
                    active={activeTab === 1}
                    
                    />
                    <Tab
                    label="Recepción de equipaje"
                    onClick={() => handleTabClick(2)}
                    active={activeTab === 2}
                    
                    />
                    <Tab
                    label="Desembarque de las unidades de carga"
                    onClick={() => handleTabClick(3)}
                    active={activeTab === 3}
                    
                    />
                
                </div>
           </div>
            <div className="content-container">
            
                {activeTab === 0 && 
                <div className='info_antes_viaje'>
                    <h4>Desembarque con automóvil</h4>
                    <p>Minutos antes de arribar al puerto, se les recomienda a los pasajeros estar atentos al orden del desembarque, esta indicación se escucha en los altavoces, es importante considerar que solo el conductor podrá desembarcar en el vehículo siguiendo las indicaciones proporcionadas por el área de operaciones, acompañantes desembarcarán con pasajeros en general, esto, por motivos de revisión en los puertos.  </p>                    
                    <p>El tiempo de desembarque de los automóviles puede variar de 30 minutos a 1 hora, dependiendo del acomodo dentro del buque de su vehículo, el área de operaciones va a dar las indicaciones a bordo.  </p>
                    
                </div>}
                {activeTab === 1 && 
                <div className='info_antes_viaje'>
                    <h4>Desembarque de pasajeros </h4>
                    <p>Los pasajeros que viajan sin automóvil deberán esperar la indicación para desembarcar de forma segura y ordenada. El personal de hotelería dará inicio al desembarque y este será por medio de un autobús proporcionado por la empresa que los llevará hasta la entrada a la terminal.</p>
                    <p>El tiempo de desembarque puede variar de 30 minutos a 1 hora, dependiendo de las indicaciones proporcionadas a bordo. </p>
                    
                </div>}
                {activeTab === 2 && 
                <div className='info_antes_viaje'>                           
                        <h4>Recepción de equipaje </h4>
                        <p>Una vez los pasajeros estén en la terminal, deben caminar hacia la zona de maletas donde el personal de Baja Ferries les va a solicitar su boleto y les hará entrega de sus maletas. </p>
                        <p>En caso de tener alguna duda sobre el proceso, puedes acercarte a nuestro personal con chaleco amarillo.</p>
                    </div>}
                {activeTab === 3 && 
                    <div className='info_antes_viaje'>
                        <h4>Desembarque de las unidades de carga </h4>
                        <p>El orden del desembarque de las unidades de carga varía dependiendo en que cubierta del buque quedo acomodada la unidad, en el boleto que tiene el operador viene la letra de la cubierta que les corresponde. Minutos previos por arribar al puerto se les indica en el altavoz la letra de la cubierta con los operadores de carga que deben estar listos para bajar del barco.</p>
                        <p>Es importante que consideren que una vez abajo del buque deben seguir las indicaciones para salir del puerto, y considerar que hay una última revisión ajena a Baja Ferries.</p>
                        
                    </div>}                                         

            </div>
        </div>
    </div>
   </sections>



   <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Comparte tu opinión</h4>
                                </div>
                                <div>
                                    <p>Tus comentarios son muy importantes para continuar ofreciondote el mejor servicio.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                                                                
                                <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es" target="_blank" rel="noopener noreferrer">Más información </a> 
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Necesitas hacer cambios en tu reservación?</h4>
                                </div>
                                <div>
                                    <p>Envíanos un WhatsApp. Nuestro equipo está listo para ayudarte. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                                
                                <a href="https://wa.me/5218003377437?text=Necesito%20cambiar%20una%20mi%20reservacón" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Siguenos en redes sociales</h4>
                                </div>
                                <div>
                                    <p>Mantente al día con nuestras últimas noticias, promociones y actualizaciones. ¡Únete a nuestra comunidad online!</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <a href="https://www.facebook.com/bajaferriesoficial/" target="_blank" rel="noopener noreferrer">Ir ahora </a>                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Outlet />
    <Footer />
    
    </>
  )
}
