import React from 'react'
import '../css/gobernanza.css'
import informes from '../assets/images/gobernanza/periodico.png'
import reportes from '../assets/images/gobernanza/portapapeles.png'
import bannerDownload from '../assets/images/banner-logo-download.webp'
import imagotipo from '../assets/images/logosKit/BF_Imagotipo.png'
import logoBlack from '../assets/images/logosKit/media-kit-logo-bla.png'

import logoBF from '../assets/images/logosKit/bf-logo-menu.png'

export default function Gobernanza() {
  return (
    <>
        <section>
            <div className='header_gobernanza'>
                <div className='container'>
                    <div className='header_title_gobernanza'>
                        <div className='title_gobernanza'>
                            <h1>Gobernanza</h1>
                        </div>
                        <div className='description_gobernanza'>
                            <p>
                                Con 4 modernas embarcaciones, ofrecemos soluciones logísticas integrales y multimodales en el Mar de Cortés, el Pacifico mexicano y Medio Oriente, para el transporte de materias primas y bienes de consumo,                             
                                así como una experiencia de viaje inolvidable a nuestros pasajeros.
                            </p>
                        </div>
                        <div className='icons_gobernanza'>                      
                            <div className='icon_gobernanza'>                                
                                <img src={informes} alt='informes bajaferries'/>
                                <label>Informes integrados</label>
                            </div>
                            <div className='icon_gobernanza'>
                                <img src={reportes} alt='reportes bajaferries' />
                                <label>Reportes financieros</label>
                            </div>
                            <div className='icon_gobernanza'>
                                <img src={reportes} alt='reportes bajaferries' />
                                <label>Reportes sostenibilidad</label>
                            </div>                       
                        </div>
                    </div>                               
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className='box_group_gobernanza'>
                    <div className='box_content_gobernanza'>                        
                        <div>
                            <div className='title_informes_gobernanza'>
                                <h3>Archivos / 2023 / infrme anual</h3>
                            </div>
                            <div className='box_content_informes' >
                                <div className='informes_list'>
                                    <div className='informe_list'><p>1. Lorem ipsum odor amet, consectetuer</p> <button><i class="fi fi-rr-download"></i></button></div>
                                    <div className='informe_list'><p>2. Lorem ipsum odor amet, consectetuer</p> <button><i class="fi fi-rr-download"></i></button></div>
                                    <div className='informe_list'><p>3. Lorem ipsum odor amet, consectetuer</p> <button><i class="fi fi-rr-download"></i></button></div>
                                </div>
                                <div className='informes_list'>
                                    <div className='informe_list'><p>1. Lorem ipsum odor amet, consectetuer</p> <button><i class="fi fi-rr-download"></i></button></div>
                                    <div className='informe_list'><p>2. Lorem ipsum odor amet, consectetuer</p> <button><i class="fi fi-rr-download"></i></button></div>
                                    <div className='informe_list'><p>3. Lorem ipsum odor amet, consectetuer</p> <button><i class="fi fi-rr-download"></i></button></div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className='banner-kit-descarga'>
                    <div className='banner-descarga'>
                        <img src={bannerDownload} alt='descargar logos de bajaferries' />
                        <div className='box_msg_descarga'>
                            <h3>Logos</h3>
                            <p>Tu solicitud para descargar el logo se revisará y si es aprobada se enviara al correo que ingresaste.</p>
                        </div>                        
                    </div>            
                </div>
            </div>        
        </section>
        <section>
            <div className='container'>
                <div className=''>
                    <div className='informe_year'>
                        <h2>2024</h2>
                    </div>
                    <div className='informe_list'>
                        <p>
                            Informe anual de responsabilidad social empresarial
                        </p>
                        <div className='informe_list_icon'>
                            <i class="fi fi-br-download"></i>
                        </div>
                    </div>
                    <div className='informe_list'>
                        <p>
                            Informe anual de responsabilidad social empresarial
                        </p>
                        <div className='informe_list_icon'>
                            <i class="fi fi-br-download"></i>
                        </div>
                    </div>
                    
                    <div className='informe_year'>
                        <h2>2023</h2>
                    </div>
                    <div className='informe_list'>
                        <p>
                            Informe anual de responsabilidad social empresarial
                        </p>
                        <div className='informe_list_icon'>
                            <i class="fi fi-br-download"></i>
                        </div>
                    </div>
                    <div className='informe_list'>
                        <p>
                            Informe anual de responsabilidad social empresarial
                        </p>
                        <div className='informe_list_icon'>
                            <i class="fi fi-br-download"></i>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <div className='container'>
                <div className='banner-kit-descarga'>
                    <div className='banner-descarga'>
                        <img src={bannerDownload} alt='descargar logos de bajaferries' />
                        <div className='box_msg_descarga'>
                            <h3>Reportes sostenibilidad</h3>
                            <p>Tu solicitud para descargar el logo se revisará y si es aprobada se enviara al correo que ingresaste.</p>
                        </div>                        
                    </div>            
                </div>
            </div>        
        </section>
        <div className='container'>
            <div className='logosList'>
                <div className='itemList_logo_informe'>
                    <div className='box_content_logo'>
                        <div className='box_logo'>
                            <img src={logoBF} alt='Logo Baja Ferries' />
                        </div>
                        <div  className='box_text_logo_informe'>
                            <label>Informe anual de responsabilidad social 2024</label>
                            <i class="fi fi-bs-download"></i>
                        </div>
                    </div>
                </div>
                <div className='itemList_logo_informe'>
                    <div className='box_content_logo'>
                        <div className='box_logo'>
                            <img src={imagotipo} alt='Logo Baja Ferries' />
                        </div>
                        <div  className='box_text_logo_informe'>
                            <label>Reporte de responsabilidad social 2023</label>
                            <i class="fi fi-bs-download"></i>
                        </div>
                    </div>
                </div>
                <div className='itemList_logo_informe'>
                    <div className='box_content_logo'>
                        <div className='box_logo'>
                            <img src={logoBlack} alt='Logo Baja Ferries' />
                        </div>
                        <div className='box_text_logo_informe'>
                            <label>Reporte de responsabilidad social 2022</label>
                            <i class="fi fi-bs-download"></i>
                        </div>
                    </div>
                </div>               
            </div>
        </div>
        <section>
            <div className='container'>
                <div className='banner-kit-descarga'>
                    <div className='banner-descarga'>
                        <img src={bannerDownload} alt='descargar logos de bajaferries' />
                        <div className='box_msg_descarga'>
                            <h3>Logos</h3>
                            <p>Tu solicitud para descargar el logo se revisará y si es aprobada se enviara al correo que ingresaste.</p>
                        </div>                        
                    </div>            
                </div>
            </div>        
        </section>
    </>
  )
}
