import React, { useState, useEffect } from 'react';
import '../../css/slideNosotros.css'; // Estilos CSS

const SliderNosotros = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentSlide((prevSlide) =>
        prevSlide === slides.length - 1 ? 0 : prevSlide + 1
      );
    }, 3000); // Cambia 3000 por el tiempo en milisegundos que deseas para cada slide

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="slider">
      {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide_nosotros ${index === currentSlide ? 'active' : ''}`}
        >
          {/* Contenido del slide */}
          {slide}
        </div>
      ))}
    </div>
  );
};

export default SliderNosotros;
