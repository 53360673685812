import React, { useState } from 'react';
import '../../css/reserva.css';
import '../../css/reserva_horizontal.css'

const ReservaHorizontal = () => {
	const [selectedTravelType, setSelectedTravelType] = useState('RETURN'); // Nuevo estado
	const fechaToday = new Date();
	
	//console.log(fechaToday.toLocaleDateString());
	
	//FormData

	const [formData, setFormData] = useState({
		origen: '',
		destino: '',
		travelType: 'RETURN', // Establece el valor predeterminado a "RETURN"
		fechaIda: '',
		fechaVuelta: '',		
	});

	const handleChangeReservation = e => {		
		const name = e.target.name;
		const value = e.target.value;

		if (name === 'travelType') {
			setSelectedTravelType(value);
		}

		//console.log(e.target.name, e.target.value);
		setFormData({...formData,[name]: value});
	};

	// Intercambia los valores de origen y destino
	const handleIntercambiar = () => {		
		setFormData({
			...formData,
			origen: formData.destino,
			destino: formData.origen ,
		});		
		
	};

	const opcionesRuta = [
		{ value: 'P~PIC', label: 'Pichilingue' },
		{ value: 'P~MZT', label: 'Mazatlán' },
		{ value: 'P~TPB', label: 'Topolobampo' },
		// Agrega más opciones de destino según sea necesario
	  ];
	
	const reservation = (e) => {
		e.preventDefault();
		// Realiza aquí la acción que desees con los datos del formulario
		console.log(formData);
		if (selectedTravelType === 'RETURN') {

			// Acciones específicas para el tipo de viaje de "RETURN"
			window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&cw_journeysearch_j1_passengers[1][ctg]=ADL&cw_journeysearch_j1_passengers[1][qty]=1&cw_journeysearch_j2_passengers[1][ctg]=ADL&cw_journeysearch_j2_passengers[1][qty]=1&bookingFlow=RETURN&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}&cw_journeysearch_j2_date=${formData.fechaVuelta}T00:00:00&cw_journeysearch_j2_route=${formData.origen}-${formData.destino}`	
			);

		} else if (selectedTravelType === 'ONEWAY') {

			// Acciones específicas para el tipo de viaje de "ONEWAY"
			window.open(
				`https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform&redirect=true&cw_journeysearch_j1_passengers[1][ctg]=ADL&cw_journeysearch_j1_passengers[1][qty]=1&cw_journeysearch_j2_passengers[1][ctg]=ADL&cw_journeysearch_j2_passengers[1][qty]=1&bookingFlow=ONEWAY&cw_journeysearch_j1_date=${formData.fechaIda}T00:00:00&cw_journeysearch_j1_route=${formData.origen}-${formData.destino}`	
			);
		}


		/*
			`/formulario?origen=${formData.origen}&destino=${formData.destino}`

			https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform
			&redirect=true
			&cw_journeysearch_j1_passengers[1][ctg]=ADULT
			&cw_journeysearch_j1_passengers[1][qty]=1
			&cw_journeysearch_j2_passengers[1][ctg]=ADULT
			&cw_journeysearch_j2_passengers[1][qty]=1
			&bookingFlow=RETURN
			&cw_journeysearch_j1_date=2023-09-20T00:00:00
			&cw_journeysearch_j1_route=P~PIC-P~MZT
			&cw_journeysearch_j2_date=2023-09-22T00:00:00
			&cw_journeysearch_j2_route=P~MZT-P~PIC
		*/
		/*

		window.open(

			'https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch?cw_ajax=dobookingform',
			'&redirect=true',
			'&cw_journeysearch_j1_passengers[0][ctg]=ADL',
			'&cw_journeysearch_j1_passengers[0][qty]=1',

			'&cw_journeysearch_j2_passengers[0][ctg]=ADLT',
			'&cw_journeysearch_j2_passengers[0][qty]=1',

			'&bookingFlow=RETURN',	
			'&cw_journeysearch_j1_date=',
			'&cw_journeysearch_j1_route=P~PIC-P~MZT',
			'&cw_journeysearch_j2_date=2023-10-22T00:00:00',
			'&cw_journeysearch_j2_route=P~MZT-P~PIC'
		);
		*/						
	};

	const SvgArrowRightLeft = (props) => (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
			<path d="m9.293 18.707 1.414-1.414L8.414 15H20v-2H3.586l5.707 5.707zM14.707 5.293l-1.414 1.414L15.586 9H4v2h16.414l-5.707-5.707z"
			 style={{ fill: "#fff" }}/>
		</svg>
	);

	const SvgArrowRight = (props) => (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
			<path d="M5 12h13M12 5l7 7-7 7"/>
		</svg>
	);

	

  return (
    <div  className='booking_horizontal'>
        <div className='container'>
            <form className='formBooking_horizontal' onSubmit={reservation}> 
                <div className='horizontal_form'>
                    {/* Selects Origen  */}
                    <div className='contentBooking_horizontal'>
                        <div className='box_formOrigen_horizontal'>						
                            <select name="origen" value={formData.origen} onChange={handleChangeReservation}>
								<option value="" disabled defaultValue>
									Origen
								</option>
								{opcionesRuta.map((opcion) => (											
									<option key={opcion.value} value={opcion.value} disabled={opcion.value === formData.destino}>										
										{opcion.label}
									</option>
								))}
                            </select>					
                        </div>
                        {/* Botón para intercambiar valores entre Origen y Destino */}
                        <div className='box_formButton_horizontal'>
                            <button type="button"  onClick={handleIntercambiar}>
								<SvgArrowRightLeft width={50} height={50} />  
							</button>
                        </div>
                        {/* Selects Destino */}
                        <div className='box_formDestino_horizontal'>							
                            <select name="destino" value={formData.destino} onChange={handleChangeReservation}>
								<option value="" disabled defaultValue>
									Destino
								</option>
								{opcionesRuta.map((opcion) => (
                                <option key={opcion.value} value={opcion.value} disabled={opcion.value === formData.origen}>
                                    {opcion.label}
                                </option>
                                ))}
                            </select>					
                        </div>  		
                    </div>	

                    {/* Inputs Fecha */}
                    {/* mostrarCamposFecha && */ formData.travelType ==='RETURN' && (
                    <div className='contentBooking_horizontal'>
                        <div className='box_fechaSalida_horizontal'>
                            {/*<label>Ida</label>	*/}				
                            <input type="date" name="fechaIda" min={getTodayDate()} onChange={handleChangeReservation}  />
                        </div>
                        <div className='box_fechaRegreso_horizontal'>
                            {/*<label>Regreso</label>*/}
                            <input type="date" name="fechaVuelta" min={getTodayDate()} onChange={handleChangeReservation}  />
                        </div>          
                    </div>
                    )}

                    {/* mostrarCamposFecha && */ formData.travelType === 'ONEWAY' && (
                    <div className='contentBooking_horizontal'>
                        <div className='box_fechaSoloIda_horizontal'>
                            {/*<label>Salida</label> */}
                            <input type="date" name="fechaIda" min={getTodayDate()} onChange={handleChangeReservation}/>
                        </div>

                    </div>        
                    )}

					{/* switch */}					
					<div className='switchBooking'>
						<label className="switch">
							<input
							type="checkbox"
							className="checkbox_type"
							checked={selectedTravelType === 'RETURN'}
							onChange={(e) =>
								handleChangeReservation({
								target: { name: 'travelType', value: e.target.checked ? 'RETURN' : 'ONEWAY' },
								})
							}
							/>
							<div className="slider_switch"></div>
						</label>
							{formData.travelType === 'RETURN' && (
								<p>Ida y vuelta</p>
							)}
							{formData.travelType === 'ONEWAY' && (
								<p>Solo ida</p>
							)}
					</div>

                    <div className='contentBooking_horizontal btnBuscar_horizontal'>																
                        <button type='submit' className='btnBuscarViaje'>  
                            <div className='textPlaceholder_horizontal'>
                                <span className="textBuscar">Reservar </span> 
                                <SvgArrowRight width={25} height={25} />   
                            </div>	
                        </button>																															
                    </div> 
                </div>
            </form>
        </div>
    </div>	

  );
};

function getTodayDate() {
	const today = new Date();
	const year = today.getFullYear();
	const month = String(today.getMonth() + 1).padStart(2, '0'); // Los meses comienzan desde 0
	const day = String(today.getDate()).padStart(2, '0');
	return `${year}-${month}-${day}`;
  }

export default ReservaHorizontal;