import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/faqs.css'
import Contactanos from '../contacto/Contactanos';
import Footer from '../Footer'
/**/
import bookingIcon from '../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../assets/images/iconsPreguntas/barco-de-carga.png'
import documentacion from '../../assets/images/iconsPreguntas/documentacion.png'
import pagos_facturas from '../../assets/images/iconsPreguntas/factura.png'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
const questions = [
    {
        id: 1,
        text: '¿La tarifa del automovil incluye operador?',
        answer: 'La tarifa del automóvil no incluye el operador, son tarifas separadas, por lo tanto, se deberá pagar el boleto del automóvil y del adulto.',
    },
    {
        id: 2,
        text: '¿Puedo viajar dentro de mi auto durante la travesía? ',
        answer: 'Para nosotros lo más importante es tu seguridad. Por lo tanto, una vez que inicia la travesía, ninguna persona deberá permanecer en las cubiertas de carga. Es necesario permanecer en las áreas de pasaje, incluyendo mascotas.',
    },
    {
        id: 3,
        text: '¿Puede viajar mi automóvil solo?',
        answer: 'Si, es posible enviar el automóvil solo, en esos casos, solicitamos designar un adulto con identificación oficial responsable de embarcarlo, y desembarcarlo, tambien solicitamos estar en el puerto en los horarios designados para evitar contratiempos en la operación.',
    },
    {
        id: 4,
        text: '¿Qué pasa si no bajo mi automóvil en el horario que me indicaron?',
        answer: 'El personal de Baja Ferries lo bajará para no obstruir la operación, sin embargo, habrá un cargo de $600 pesos mexicanos por este desembarque, adicionalmente si el vehículo permanece ahí en el puerto deberá pagar la cuota de estadía a API.',
    },
    {
        id: 5,
        text: '¿Puedo enviar la motocicleta sola en el barco?',
        answer: 'Es obligatorio que las motocicletas viajen con su conductor, mismo que será responsable de su embarque y desembarque.',
    },

];
export default function Automovil() {
      

  return (
    <> 
        <section>
            <div className='header_faqs'>
                <div className='container header_faqs_box'>
                    <div className='header_faqs_title'>
                        <h3>Preguntas frecuentes</h3>
                        <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                    </div>                
                </div>
            </div>
        </section>
        <section className='container'>
            <div className='box_titulo_faqs'>
                <div className='lista_preguntas'>
                    <h3>Automóvil</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-automovil/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
                <div className='lista_temas'>
                    <h3>Otros temas frecuentes</h3>
                    <div className='box_lista_temas'>
                        <ul>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={bookingIcon} alt='antesViajar' />
                                    <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                                </div>
                            </li>
                            <li> 
                                <div className='content_lista_temas'>
                                    <img src={documentacion} alt='antesViajar' />
                                    <Link to="/documentacion">Documentación</Link> 
                                </div>
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={pagos_facturas} alt='antesViajar' />
                                    <Link to="/pagos-facturas">Pagos y facturas</Link> 
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={carga} alt='antesViajar' />
                                    <Link to="/carga">Carga</Link>  
                                </div>  
                            </li>
                            <li>
                                <div className='content_lista_temas'>
                                    <img src={reservar} alt='antesViajar' />
                                    <Link to="/reserva">Reservar</Link>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es" target="_blank" rel="noopener noreferrer">Contactar</a>                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
        <Footer />
        
    </>
  )
}
