    import React, { useState, useEffect } from 'react';
    import '../css/pruebas.css';
    import { Outlet, Link } from "react-router-dom";

    const Pruebas = () => {
        const [isMenuOpenRight, setIsMenuOpenRight] = useState(false);
        const [isMenuOpenLeft, setIsMenuOpenLeft] = useState(false);
        const [isActive, setActive] = useState(false)
        const [isSubmenu, setIsSubmenu] = useState(false)
        const [isSubmenuLeft, setIsSubmenuLeft] = useState(false)
    
        const toggleMenuRight = () => {
            setIsMenuOpenRight(!isMenuOpenRight);    
        };

        const toggleMenuLeft = () => {
            setIsMenuOpenLeft(!isMenuOpenLeft);
        };

        const toggleMenuExpanded = () => {
            setActive(!isActive);  
        };
        const toggleSubmenu = (index) => {
            setIsSubmenu((prevState) => (prevState === index ? null : index));
        };
        const toggleSubmenuLeft = (index) => {
            setIsSubmenuLeft((prevState) => (prevState === index ? null : index));
        };

        /* Noticia */
        
        const [noticias, setNoticias] = useState([]);
        const [loading, setLoading] = useState(true);

    // Tu código para cargar las noticias desde la base de datos
    useEffect(() => {
        const loadNoticias = async () => {
            try {
                //const response = await fetch('http://localhost:3004/noticias');
                const response = await fetch('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/noticias');
                const data = await response.json();
                console.log('Data: ', data);
                setNoticias(data);
            } catch (error) {
                console.error('Error fetching noticias:', error);
            } finally {
                setLoading(false); // Cuando se completa la carga o hay un error, detenemos la carga
            }
        };
        loadNoticias();
    }, []);

        
    if (loading) {
        // Muestra el mensaje de carga
        return <p>Loading...</p>;
    } else {
        // Si hay noticias, toma la primera noticia como primeraNoticia, de lo contrario, muestra la noticia predeterminada
        const primeraNoticia = noticias.length > 0 ? noticias[0] : null;
            // Filtra solo las noticias visibles
        /*const noticiasVisibles = noticias.filter(noticia => noticia.visible);

        if (noticiasVisibles.length === 0) {
        return <p>No hay noticias visibles</p>;
        }*/
        
        
    return (
        <>
        <section className={`header ${isActive ? 'overlay' : ''}`}>
        <nav className="navbar">
        <div className='container'>
            
       <div className='navbar-content'>
            <div className="navbar-brand">
                <div className='box_menu_left'>
                    <button className="navbar-toggler" onClick={toggleMenuLeft}>
                        <i class="fi fi-rr-menu-dots"></i>
                    </button>
                </div>
                <div className='box_menu_img'>
                    <Link to="/">
                        <div className='menu_img_show'></div>
                    </Link>
                </div>
                <div className='box_menu_right'>
                    <button className="navbar-toggler" onClick={toggleMenuRight}>
                        <i className="fi fi-rr-bars-staggered"></i>   
                    </button>
                </div>                
            </div>
        
            <ul className={`navbar-menu menu_items ${isMenuOpenRight ? 'navbar-menu-open' : ''}`}>

                <div className='opc_menu_right'>
                    <div className='menu_img_show'></div>           
                        <div onClick={toggleMenuRight} className='menu_cerrar_option'> <div className='idioma_option'><label>ES</label> <i class="fi fi-rr-globe"></i><i class="fi fi-rr-caret-down"></i></div>
                        <div className='btn_cerrar_option'><i class="fi fi-br-cross"></i></div>
                    </div>
                </div>
                <div className='main_menu'>
                   
                    <li className="navbar-item ">
                        
                        <Link className="navbar-link" onClick={() => toggleSubmenu(1)}>Viaja <i class="fi fi-rr-caret-down"></i></Link>                                        
                        {isSubmenu === 1 &&(
                        <ul className={`submenu-item ${isSubmenu ? 'submenu-item-open' : ''}`}>
                            <li><Link to="/rutas_horario" onClick={toggleMenuRight}>Rutas y Horarios </Link></li>
                            <li><Link to="/tarifas" onClick={toggleMenuRight}>Tarifas </Link></li>
                            <li><Link to="/destinos" onClick={toggleMenuRight}>Destinos </Link></li>
                            <li><Link to="/promociones" onClick={toggleMenuRight}>Descuentos y promociones </Link>  </li>
                            <li><Link to="/viaje-en-grupo" onClick={toggleMenuRight}>Viaja en grupo </Link> </li>
                        </ul>
                         )}
                    </li>
                    {/*}
                    <li className="navbar-item">                        
                        <a  className="navbar-link" href="https://eweb-test-bafc.carus.com/booking_bajaferries_com_mx_es/customer-login/" target="_blank" rel="noopener noreferrer">Reservaciones</a>                                                              
                    </li>
                    */}
                    <li className="navbar-item">                    
                        <Link className="navbar-link" onClick={() => toggleSubmenu(2)}>Información <i class="fi fi-rr-caret-down"></i></Link>                                         
                        {isSubmenu === 2 &&(
                        <ul className='submenu-item'>
                            <li><Link to="/prepara-tu-viaje" onClick={toggleMenuRight}>Prepara tu viaje </Link></li>
                            <li><Link to="/preguntas-frecuentes" onClick={toggleMenuRight}>Preguntas frecuentes </Link></li>
                            <li><Link to="/flota" onClick={toggleMenuRight}>Nuestra flota </Link></li>
                            <li><Link to="/contacto" onClick={toggleMenuRight}>Contacto </Link>  </li>                        
                        </ul>  
                        )}
                    </li>
                         
                    <li className="navbar-item">
                        <Link to="/sostenibilidad" className="navbar-link">Sostenibilidad <i class="fi fi-rr-caret-down"></i></Link>                        
                        <ul className='submenu-item'>
                            {/*
                            <li><Link to="/modelo" onClick={toggleSubmenu}>Modelo </Link></li>
                            <li><Link to="/voluntariado" onClick={toggleSubmenu}>Voluntariado </Link></li>
                            <li><Link to="/fundacion" onClick={toggleSubmenu}>Fundación </Link></li>
                            */}
                            <li><a  className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Portal de ética</a>   </li>
                                                    
                        </ul>  
                    </li>
                    <li><Link to="/contacto" onClick={toggleMenuRight}>Contacto </Link>  </li>  
                    {/*
                    <li className="navbar-item">                    
                        <Link to="/viaje-en-curso"  className="navbar-link"  onClick={toggleSubmenu}>Viaje en curso </Link>                    
                    </li>
                    
                    <li className="navbar-item">                    
                        <Link to="/itinerarioprueba"  className="navbar-link"  onClick={toggleSubmenu}>itinerario </Link>                    
                    </li>*/}
                    
                    {/*
                    <li className="navbar-item">
                        <Link to="/">BF Rewards </Link>                                
                    </li>
                    */}
                    
                    <div className='botton_opcion_menu'>
                    <li className="navbar-item">                    
                        <Link to="/soluciones-logisticas" style={{ border: '1px solid #225981', background: '#225981', padding: '5px 5px'}}  onClick={toggleSubmenu}> Carga </Link>                   
                    </li>
                    <li className="navbar-item">                    
                        <a  className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Facturación</a>                                   
                    </li>
        
                    </div>

                </div>                                        
            </ul>
            <div className='content_menu_right menu_items'>
                    <li className="navbar-item">                        
                        <Link to="/soluciones-logisticas"   style={{ border: '1px solid #225981', background: '#225981', padding: '5px 5px'}}   onClick={toggleSubmenu}> Carga </Link>                                          
                    </li>
                    <li className="navbar-item">                        
                        <a  className="navbar-link" href="https://bajaferries.com.mx/facturacion/" target="_blank" rel="noopener noreferrer">Facturación</a>                  
                    </li>
                    {/*}
                    <li className="navbar-item">
                        <Link to="/">Login </Link>                                
                    </li>
                    */}
                    
                    
                    <li className="navbar-item">                    
                        <Link to="/" className=""><i class="fi fi-rr-globe"></i> ES <i class="fi fi-rr-caret-down"></i></Link>                                                
                        <ul className='submenu-item'>
                            <li><Link to="/promociones" onClick={toggleSubmenu}>EN </Link></li>
                            <li><Link to="/promociones" onClick={toggleSubmenu}>FR </Link></li>                      
                        </ul> 
                    </li>
                    <li className="">
                        <button className="navbar-link" onClick={toggleMenuExpanded}><i class="fi fi-rr-bars-staggered" style={{fontSize: '20px', display: 'flex'}}></i></button>                    
                    </li>
                </div>

            <ul className={`navbar-menu-left ${isMenuOpenLeft ? 'navbar-menu-open-left' : ''}`}>                

                <div className='opc_menu_right'>
                    <div className='menu_img_show_left'></div>           
                        <div onClick={toggleMenuLeft} className='menu_cerrar_option'> <div className='idioma_option_left'><label>ES</label> <i class="fi fi-rr-globe"></i><i class="fi fi-rr-caret-down"></i></div>
                        <div className='btn_cerrar_option_left'><i class="fi fi-br-cross"></i></div>
                    </div>
                </div>
        
                <li className="navbar-item-left">
                    <Link className=""  onClick={() => toggleSubmenuLeft(1)}>Acerca de nosotros<i class="fi fi-rr-caret-down"></i></Link>
                                                                                    
                        {isSubmenuLeft === 1 &&(
                        <ul className=''>
                            <li><Link to="/nosotros" onClick={toggleMenuRight}>Conócenos </Link></li>
                            <li><Link to="/flota" onClick={toggleMenuRight}>Nuestra flota </Link></li>
                            <li><Link to="/certificaciones" onClick={toggleMenuRight}>Certificaciones </Link></li>
                        </ul>
                         )}
                </li>
                <li className="navbar-item-left">
                    <Link to="/sostenibilidad" onClick={toggleMenuLeft}  className="navbar-link">SRE </Link>                                        
                </li>
                <li className="navbar-item-left">                    
                    
                    <Link className=""  onClick={() => toggleSubmenuLeft(2)}>Soluciones logísticas <i class="fi fi-rr-caret-down"></i></Link>
                    {isSubmenuLeft === 2 &&(
                        <ul className=''>
                            <li><Link to="/rutas_horario" onClick={toggleMenuRight}>Rutas y Horarios </Link></li>
                            <li><Link to="/tarifas" onClick={toggleMenuRight}>Tarifas </Link></li>
                            <li><Link to="/destinos" onClick={toggleMenuRight}>Destinos </Link></li>
                            <li><Link to="/promociones" onClick={toggleMenuRight}>Descuentos y promociones </Link>  </li>
                            <li><Link to="/viaje-en-grupo" onClick={toggleMenuRight}>Viaja en grupo </Link> </li>
                        </ul>
                         )}
                </li>
                <li className="navbar-item-left">                                  
                    <Link className=""  onClick={() => toggleSubmenuLeft(3)}>Atención al cliente <i class="fi fi-rr-caret-down"></i></Link>
                    {isSubmenuLeft === 3 &&(
                        <ul className=''>
                            <li><Link to="/contacto" onClick={toggleMenuRight}>Contacto </Link></li>
                            <li><Link to="/preguntas-frecuentes" onClick={toggleMenuRight}>Preguntas frecuentes </Link></li>
                        </ul>
                         )}
                    
                </li>
                <li className="navbar-item-left">                    
                    <Link to="/noticias" className="navbar-link" onClick={toggleMenuLeft}>Blog </Link>
                    
                </li>
                <li className="navbar-item-left">             
                    <a  className="navbar-link" href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/ " target="_blank" rel="noopener noreferrer">Portal de ética</a>    
                    
                </li>
                <li className="navbar-item-left">                    
                    <Link to="/" className="navbar-link" onClick={toggleMenuLeft}>Proveedor </Link>
                    
                </li>
                <li className="navbar-item-left">
                    <Link to="/login-gobernanza" className="navbar-link" onClick={toggleMenuLeft}>Gobernanza </Link>                    
                </li>                        
            </ul>            
       </div>
        </div>
        </nav>
        <aside className={`sidebar ${isActive ? "show" : null}`}>
            <div className='boxSidebar'>
                <div className='boxAsidebar'>
                    <div className='btnCerrar'> 
                        <button onClick={toggleMenuExpanded}  className="navbar-toggle " id="toggleButton">                            
                            {/*<i className="fi fi-rr-cross-circle"></i>*/}
                            <i class="fi fi-br-cross"></i>
                        </button>
                    </div>
                    <div className='blockAsidebar_first'>
                        <ul>                                       
                            <li className='block'><Link to="/nosotros">Acerca de nosotros </Link>
                                <ul>
                                    <li><Link to="/nosotros" onClick={toggleMenuExpanded}>Conócenos </Link></li>
                                    {/*<li><Link to="/puertos">Puertos </Link></li>*/}
                                    <li><Link to="/flota" onClick={toggleMenuExpanded}>Nuestra flota </Link></li>                                              
                                    <li><Link to="/certificaciones" onClick={toggleMenuExpanded}>Nuestras certificaciones </Link></li>                                       
                                    <li><Link to="/itinerarioprueba" onClick={toggleMenuExpanded}>Viaje en curso </Link></li>                                       
                                </ul>
                            </li>  
                            <li className='block'><Link to="/soluciones-logisticas">Soluciones logísticas</Link>
                                <ul>
                                    <li><a href='#maritimo' >Soluciones Marítimas</a></li>
                                    <li><a href='?'>Soluciones Terrestres</a></li>
                                    <li><a href='?'>Soluciones Multimodal</a></li>
                                    <li><Link to="/tarifas-carga" onClick={toggleMenuExpanded}>Tarifas </Link></li>
                                </ul>
                            </li>  
                            {/* 
                            <li className='block'><a href="?">Soluciones digitales</a>
                                <ul>
                                    <li><a href='?'>Marketplace</a></li>
                                    <li><a href='?'>Rastreo</a></li>                                                    
                                </ul>
                            </li> 
                            */}
                            <li className='block'><a href="?">Blog</a>
                                <ul>
                                    <li><Link to="/noticias" onClick={toggleMenuExpanded}>Noticias y Avisos</Link></li>
                                </ul>
                            </li>
                            <li className='block'><a href="?">Prensa</a>
                                <ul>
                                    <li><Link to="/kit-de-comunicacion" onClick={toggleMenuExpanded}>Contacto de agencia</Link></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className='blockAsidebar_second'>
                        <ul>
                        <li className='block'><a href="?">RSE</a>
                            <ul>
                                <li><a href='?'>Modelo de sustentabilidad</a></li>
                                {/*<li><a href='?'>Programas</a></li>*/}
                                <li><a href='?'>Voluntariado</a></li>
                            </ul>
                        </li>  
                        <li className='block'><a href='?'>Atención al cliente </a>                                           
                            <ul>
                                <li><Link to="/contacto" onClick={toggleMenuExpanded}>Contacto </Link></li>  
                                <li><Link to="/preguntas-frecuentes" onClick={toggleMenuExpanded}>Preguntas frecuentes </Link></li>  
                            </ul>                                     
                        </li>
                        <li className='block'><a href="?">Portal de ética</a>                      
                            <ul>
                                <li>
                                    <Link to="/nosotros" onClick={toggleMenuExpanded}>Código de ética </Link>
                                </li>                                                                                    
                            </ul>
                        </li>
                        <li className='block'><a href="?">Proveedor</a></li>
                        <li className='block'><Link to="/login-gobernanza" onClick={toggleMenuExpanded}>Gobernanza </Link>
                            <ul>
                                <li>
                                    <Link to="/login-gobernanza" onClick={toggleMenuExpanded}>Login</Link>
                                </li>                                                                                    
                            </ul>
                        </li>
                        </ul>                                        
                        
                    </div>
                
                </div>
                            
                <div className='asidebarNoticia'>
                        {/* Si hay una primera noticia, muestra los detalles de esa noticia */}
                        {primeraNoticia && (
                            <Link to={`/noticia/${primeraNoticia.id}`}>
                                <div className='noticia_box_asidebar'>
                                    <div className='asidebar_noticia_box'>
                                        <div>
                                            <p><i className="fi fi-sr-bookmark"></i> {primeraNoticia.category.join(', ')}</p>
                                            <h3>{primeraNoticia.title}</h3>
                                        </div>
                                        <div className='img_box_asidebar' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(./images/noticias/${primeraNoticia.photo_route_header})` }}></div>
                                    </div>
                                </div>
                            </Link>
                        )}
                        {/* Si no hay una primera noticia, muestra el contenido de noticia_default */}
                        {!primeraNoticia && (
                            <div className='noticia_default'>
                                {/* Aquí puedes colocar el contenido de la noticia predeterminada */}                           
                                <div className='noticia_box_asidebar'>
                                    <div className='asidebar_noticia_box'>
                                        <div>
                                            <p><i className="fi fi-sr-bookmark"></i> Bajaferries</p>
                                            <h3>Baja Ferries nombra a Nino Liaño Egozcue como nuevo Director General</h3>
                                        </div>
                                        <div className='img_box_asidebar' style={{ backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), url(./images/noticias/1707321989123.png)` }}></div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                    
                
            </div>                                                    
        </aside>
        </section>
        <Outlet />
        </> 
    );
    }
};


    export default Pruebas;