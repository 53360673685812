import React, {useState, useEffect} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../css/styles.css'
import '../css/header.css';
import '../App.css';
import '../css/responsive.css'
import logo from '../assets/images/logo.png';
import iso_logo from '../assets/images/bf-iso-blanco.png'
import carga from '../assets/images/carga.png';
import ReservaHorizontal from './form/ReservaHorizontal';

import { Squash as Hamburger } from 'hamburger-react'
import { Col, Row } from 'antd';
import {
    DownOutlined, MenuOutlined, CloseOutlined,
  } from '@ant-design/icons';
export default function Header() {
    
    const [isActive, setActive] = useState(false)
    const [isScrolled, setIsScrolled] = useState(false);
    const [isOpen, setOpen] = useState(false)

    const handleToggle = () => {
        setActive(!isActive);    
    };
    
    // eslint-disable-next-line no-undef
    useEffect(() => {
        const scrollPosition = window.scrollY;
        const contentSection = document.getElementById('content');
        console.log(scrollPosition);
        console.log(contentSection);
/*
        const handleScroll = () => {
          if (window.scrollY > 0 && (contentSection && scrollPosition > contentSection.offsetTop)) {
            setIsScrolled(true);            
            
          } else {
            setIsScrolled(false);
          }
        };
    window.addEventListener('scroll', handleScroll);

    return () => {
        window.removeEventListener('scroll', handleScroll);
        };

        */
    }, []);
   
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const [subMenuActivo, setSubMenuActivo] = useState(false);

    const Asidebar_icon = (props) => (
        <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" {...props}>
            <path d="M12 2a10 10 0 1 0 10 10A10.011 10.011 0 0 0 12 2zm0 18a8 8 0 1 1 8-8 8.009 8.009 0 0 1-8 8z" />
            <path d="M11 7h2v7h-2zM11 15h2v2h-2z" />
        </svg>
    );
      
  return (

    <> 
    {
        
    <section className={`menu_form_horizontal ${isScrolled ? "scrolled" : null}`} >
        <ReservaHorizontal />
    </section>
        
    }
    <section className={`header ${isActive ? 'overlay' : ''}`}>
        {
            /*
            <div className='menu_icon_responsive'>
                <MenuOutlined />
                <CloseOutlined />
            </div>

            */
        }
        
        <nav className='menu'>            
            <div className='container'>
                <Row>
                    <Col span={17}>
                        <div className='menuOptions'>
                            <div className="logo">
                                <Link to="/" className="menu-item" >
                                    <img src={logo} alt="logo" />
                                </Link>
                            </div>                 
                            <ul className="menu_items">
                                <li><Link to="/" className="menu-item" >Viaja con nosotros<DownOutlined /></Link>  
                                    <ul className='submenu-item'>
                                        <li><Link to="/rutas_horario">Rutas y Horarios </Link></li>
                                        <li><Link to="/tarifas">Tarifas </Link></li>
                                        <li><Link to="/destinos">Destinos </Link> </li>
                                        <li><Link to="/promociones">Descuentos y promociones </Link>  </li>
                                        <li><Link to="/viaje-en-grupo">Viaja en grupo </Link> </li>
                                        {
                                            /*<li><Link to="?">Cotiza tu viaje </Link>  </li>*/
                                        }
                                    </ul>
                                </li>
                                <li><Link to="/prepararViajeOpc" className="menu-item" >Mis reservaciones </Link> </li>

                                <li><Link to="?" className="menu-item">Información<DownOutlined /></Link> 
                                    <ul className='submenu-item'>
                                        <li><Link to="/prepara-tu-viaje">Prepara tu viaje  </Link></li>
                                        <li><Link to="/preguntas-frecuentes">Preguntas frecuentes </Link> </li>
                                        <li> <Link to="/flota">Nuestra flota </Link> </li>
                                        <li><Link to="/contacto">Contacto </Link> </li>
                                        {
                                        /*  
                                            <li><a href='?'>Prepara tu viaje</a></li>
                                            <li><a href='?'>Atención al cliente </a></li>                                                                                                         
                                            <li><a href='?'>Servicios abordo</a></li>
                                            <li><a href='?'>Seguridad a bordo</a></li>
                                        */  
                                        }
                                    </ul>
                                </li> 
                                <li><Link to="/sostenibilidad" className="menu-item">Sostenibilidad<DownOutlined /> </Link>
                                    <ul className='submenu-item'>
                                        <li><Link to="/modelo">Modelo </Link> </li>
                                        <li><Link to="/voluntariado">Voluntariado </Link> </li>
                                        <li><Link to="/fundacion">Fundación </Link> </li>     
                                        <li><Link to="/fundacion">Portal de ética</Link> </li>                                                      
                                    </ul>
                                </li>	
                                <li><Link to="/Itinerario" className="menu-item">Itinerario </Link></li>	                                                        
                            </ul>                    
                        </div>                       
                    </Col>
                    <Col span={7} >                        
                        <div className="menuIcons">
                            <ul className="menu_icons">
                                <li><Link to="/soluciones-logisticas" className="menu-item cargaOpc"> 
                                <img src={carga} alt="carga" className='imgCarga'/>Carga
                                </Link></li>
                                
                                <li><a className="menu-item" href='http://proveedores.bajaferries.com/ProveedoresLogin.aspx' target="_blank" rel='noopener noreferrer' >Proveedores</a> </li>
                                {
                                    /*
                                     <li>
                                        <Link to="/tasks/new">TaskForm </Link>
                                    </li>
                                    <li>
                                        <Link to="/tasklist">TaskList </Link> 
                                    </li>                                                                         
                                    
                                    */
                                     
                                }
                                                     
                                <li id='btnMenu'>                               
                                    <button onClick={handleToggle}  className="navbar-toggle" id="toggleButton">
                                        {/*<span className="toggle-icon"></span>  */}
                                        <i className="fi fi-rr-bars-staggered"></i>      
                                                             
                                    </button>                                                         
                                </li>  
                                {
                                    /*
                                    <li>
                                        <Hamburger toggled={isOpen} toggle={setOpen} color="#FFFFFF"/>
                                    </li>  
                                    */
                                }
                                                               					
                            </ul>
                        </div> 
                        
                        <aside className={`sidebar ${isActive ? "show" : null}`}>
                            <div className='boxSidebar'>
                                <div className='boxAsidebar'>
                                    <div className='btnCerrar'> 
                                        <button onClick={handleToggle}  className="navbar-toggle " id="toggleButton">
                                            
                                            <i className="fi fi-rr-cross-circle"></i>
                                        </button>
                                    </div>
                                    <div className='blockAsidebar_first'>
                                        <ul>                                       

                                            <li className='block'><a href="?">Acerca de nosotros</a>
                                                <ul>
                                                    <li><Link to="/nosotros">Conócenos </Link></li>
                                                    <li><Link to="/puertos">Puertos </Link></li>
                                                    <li><Link to="/nosotros">Nuestra flota </Link></li>                                                  
                                                    <li><Link to="/nosotros">Código de ética </Link></li>
                                                </ul>
                                            </li>  
                                            <li className='block'><a href="?">Soluciones logísticas</a>
                                                <ul>
                                                    <li><a href='?'>Soluciones Marítimas</a></li>
                                                    <li><a href='?'>Soluciones Terrestres</a></li>
                                                    <li><a href='?'>Soluciones Multimodal</a></li>
                                                </ul>
                                            </li>  
                                            <li className='block'><a href="?">Soluciones digitales</a>
                                                <ul>
                                                    <li><a href='?'>Marketplace</a></li>
                                                    <li><a href='?'>Rastreo</a></li>                                                    
                                                </ul>
                                            </li> 
                                            <li className='block'><a href="?">Blog</a>
                                                <ul>
                                                    <li><Link to="/noticias">Noticias </Link></li>
                
                                                </ul>
                                            </li>
                                            <li className='block'><a href="?">Prensa</a>
                                                <ul>
                                                    <li><Link to="/kit-de-comunicacion">Kit de comunicación </Link></li>
       
                                                </ul>
                                            </li>

                                        </ul>
                                    </div>
                                    <div className='blockAsidebar_second'>
                                        <ul>
                                        <li className='block'><a href="?">RSE</a>
                                            <ul>
                                                <li><a href='?'>Modelo de sustentabilidad</a></li>
                                                <li><a href='?'>Programas</a></li>
                                                <li><a href='?'>Voluntariado</a></li>
                                            </ul>
                                        </li>  
                                        <li className='block'><a href='?'>Atención al cliente </a>                                           
                                            <ul>
                                                <li><Link to="/contacto">Contacto </Link></li>  
                                                <li><Link to="/preguntas-frecuentes">Preguntas frecuentes </Link></li>  
                                            </ul>                                     
                                        </li>
                                        <li className='block'><a href="?">Portal de ética</a></li>
                                        <li className='block'><a href="?">Proveedor</a></li>
                                        <li className='block'><Link to="/login-gobernanza">Gobernanza </Link></li>
                                        </ul>                                        
                                        
                                    </div>
                                
                                </div>

                                <div className='asidebarNoticia'>
                                    <div className='asidebarNoticia_box'>
                                        <h2>Llega el buque “California Star”, el más grande de américa latina</h2>
                                        <p>El buque “California Star” llegó este fin de semana a territorio mexicano y se incorpora a la ruta de navegación por el Mar de Cortés, entre Sinaloa y Baja California Sur.</p>
                                        <button>Descubrir más</button>
                                    </div>
                                    <div className='asidebarNoticia_icons'>
                                        <Asidebar_icon width={100} height={100}/>
                                    </div>
                                </div>
                            </div>                                                    
                        </aside>                   
                    </Col>
                </Row> 
            </div>                      
        </nav>

    </section>
    {
        //Celular
    }
    <section className='menu_mobile'>  
        <nav className='navbar'>
            <div className='menu_logo'>
                <Link to="/" className="menu-item" >
                    <img src={iso_logo} alt="logo" />
                </Link>                
            </div>
            <div className='menu_btn'>
                <button className="hamburger" onClick={() => { setIsNavExpanded(!isNavExpanded) }} >
                    {/*{ isNavExpanded &&(
                        <i className="fi fi-rr-cross-circle"></i>
                    )
                    } {!isNavExpanded &&(                        
                        <i className="fi fi-sr-menu-burger"></i>
                    )}*/} 
                       { isNavExpanded ? (
                    <i className="fi fi-rr-cross-circle"></i>
                    ) : (                        
                        <i className="fi fi-sr-menu-burger"></i>
                    )}                   
                </button>
            </div>               
        </nav>
        <div className={isNavExpanded ? "navigation-menu expanded" : "navigation-menu" }>
            <ul className='menu_mobile_list'>
                <li className="menu-item" onClick={() => { setSubMenuActivo(!subMenuActivo) }}>Viaja con nosotros<DownOutlined />
                    <ul className={subMenuActivo ? "submenu-item active_submenu" : "submenu-item " } >
                        <li><Link to="/rutas_horario">Rutas y Horarios </Link></li>
                        <li><Link to="tarifas">Tarifas </Link></li>
                        <li><Link to="/#destinos">Destinos </Link> </li>
                        <li><Link to="/promociones#promociones">Descuentos y promociones </Link>  </li>
                        <li><Link to="/viaje-en-grupo">Viaja en grupo </Link> </li>
                    </ul>
                </li>
                <li className="menu-item" onClick={() => { setSubMenuActivo(!subMenuActivo) }}>Mis reservaciones <DownOutlined />

                    <ul className={subMenuActivo ? "submenu-item active_submenu" : "submenu-item " } >
                        <li><Link to="/rutas_horario">Rutas y Horarios </Link></li>
                    </ul>
                </li>
                <li className="menu-item" onClick={() => { setSubMenuActivo(!subMenuActivo) }}>Información<DownOutlined />
                    <ul className={subMenuActivo ? "submenu-item active_submenu" : "submenu-item " } >
                        <li><Link to="/rutas_horario">Prepara tu viaje  </Link></li>
                        <li><Link to="tarifas">Preguntas frecuentes  </Link></li>
                        <li><Link to="/#destinos">Flota  </Link> </li>
                        <li><Link to="/promociones#promociones">Contacto  </Link>  </li>
                    </ul>
                </li>
                <li className="menu-item" onClick={() => { setSubMenuActivo(!subMenuActivo) }}>Sostenibilidad<DownOutlined />
                    <ul className={subMenuActivo ? "submenu-item active_submenu" : "submenu-item " } >
                        <li><Link to="/rutas_horario">Prepara tu viaje  </Link></li>
                        <li><Link to="tarifas">Preguntas frecuentes  </Link></li>
                        <li><Link to="/#destinos">Flota  </Link> </li>
                        <li><Link to="/promociones#promociones">Contacto  </Link>  </li>
                    </ul>
                </li>
                <li className="menu-item" onClick={() => { setSubMenuActivo(!subMenuActivo) }}>Fundación <DownOutlined />
                    <ul className={subMenuActivo ? "submenu-item active_submenu" : "submenu-item " } >
                        <li><Link to="/rutas_horario">Prepara tu viaje  </Link></li>
                        <li><Link to="tarifas">Preguntas frecuentes  </Link></li>
                        <li><Link to="/#destinos">Flota  </Link> </li>
                    </ul>
                </li>
                <li className="menu-item" onClick={() => { setSubMenuActivo(!subMenuActivo) }}>Itinerario  <DownOutlined />
                    <ul className={subMenuActivo ? "submenu-item active_submenu" : "submenu-item " } >

                    </ul>
                </li>

            </ul>
        </div>     
    </section>
    <Outlet />
    </>

        
  
  )
}
