import React, {  useState } from 'react';
import '../css/durante_viaje.css'
import { CSSTransition } from "react-transition-group";
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import Footer from '../components/Footer';
import info_util from '../assets/images/durante-viaje/durante-viaje.png'
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/pregutas.png'


const Tab = ({ label, onClick, active, icon }) => {
    return (
      <div className={`tab_viajar ${active ? 'active' : ''}`} onClick={onClick}>       
        <p>{label}</p>
      </div>
    );
};





export default function Durante_viaje() {
    const [activeTab, setActiveTab] = useState(0);

    const handleTabClick = (index) => {
        setActiveTab(index);
    };

  
  

  return (
    <>
    <section id="hero-section">
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Durante tu viaje</h3>
                    <p>Consulta los requisitos para viajar y evita contratiempos</p>
                </div>                
            </div>
        </div>                                 
    </section>
  
   <sections className='info_durante_viajar'>
 

   <div className="container">
        <div className='tabs_viajar'>
            <div className="tab-container">
            <div className="tab-boxes">
                <Tab
                label="Cabinas"
                onClick={() => handleTabClick(0)}
                active={activeTab === 0}
                
                />
                <Tab
                label="Área de salón"
                onClick={() => handleTabClick(1)}
                active={activeTab === 1}
                
                />
                <Tab
                label="Salón ejecutivo "
                onClick={() => handleTabClick(2)}
                active={activeTab === 2}
                
                />
                <Tab
                label="Restaurante"
                onClick={() => handleTabClick(3)}
                active={activeTab === 3}
                
                />
                <Tab
                label="Bar"
                onClick={() => handleTabClick(4)}
                active={activeTab === 4}
                
                />               
            </div>
            </div>
            <div className="content-container">
            
                {activeTab === 0 && 
                <div className='info_antes_viaje'>
                    <h4>Cabinas</h4>
                    <p>A bordo de nuestros buques, contamos con cabinas cuádruples que ofrecen un espacio cómodo para descansar durante todo el viaje. Estas cabinas cuentan con camas tipo litera, televisión, baño completo y tienen capacidad para hasta cuatro personas. </p>                    
                    <p>Además, algunos de nuestros buques tienen cabinas pet-friendly, así que puedes llevar a tu mascota contigo. Estas cabinas incluyen una cama y un sobre de alimento para tu mascota, que deberá permanecer dentro de la cabina durante todo el viaje. Te recomendamos reservar tu cabina al comprar tu boleto para asegurarte de que haya disponibilidad.</p>
                    
                </div>}
                {activeTab === 1 && 
                <div className='info_antes_viaje'>
                    <h4>Área de salón </h4>
                    <p>Nuestra área de salón cuenta con asientos confortables y semi-reclinables, aire acondicionado y televisión. A cada pasajero se le asigna un asiento, con excepción de aquellos que viajan en cabinas. </p>
                    
                </div>}
                {activeTab === 2 && 
                <div className='info_antes_viaje'>                           
                        <h4>Salón ejecutivo </h4>
                        <p>Si buscas una experiencia de viaje más exclusiva, en la ruta Topolobampo te ofrecemos la opción de viajar en nuestra clase ejecutiva por un costo adicional. Esta área cuenta con asientos reclinables espacios con reposa pies. </p>
                        
                    </div>}
                {activeTab === 3 && 
                    <div className='info_antes_viaje'>
                        <h4>Restaurante</h4>
                        <p>Disfruta de una variedad de alimentos, snacks, postres y bebidas en nuestro restaurante. </p>
                        <p>La venta de alimentos en el restaurante se realiza en el horario estipulado a bordo, es importante destacar que el pago a bordo se realiza únicamente en efectivo. Te sugerimos planificar tus consumos y disfrutar de una experiencia placentera a bordo de nuestros buques.</p>
                        
                    </div>}
                {activeTab === 4 && 
                    <div className='info_antes_viaje'> 
                        <h4>Bar </h4>
                        <p>El bar ofrece a los pasajeros un espacio agradable para disfrutar una variedad de bebidas y snacks durante la travesía. </p>
                        <p>La venta de bebidas en el bar se realiza en el horario estipulado a bordo, es importante destacar que el pago a bordo se realiza únicamente en efectivo. Te sugerimos planificar tus consumos y disfrutar de una experiencia placentera a bordo de nuestros buques.</p>
                    
                    </div>}                               

            </div>
        </div>
    </div>
   </sections>
   <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Nuestra flota</h4>
                                </div>
                                <div>
                                    <p>Conoce más acerca de nuestros buques y los servicios que cada uno ofrece abordo ¡Elige en cúal será tu próximo viaje! </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/flota">Ver más</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Aún tienes dudas?</h4>
                                </div>
                                <div>
                                    <p>Visita nuestra sección de preguntas frecuentes, donde encontrarás más información para evitar un viaje sin contratiempos.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/preguntas-frecuentes">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Explora tu destino</h4>
                                </div>
                                <div>
                                    <p>Te platicamos sobre algunos de los increíbles lugares que puedes conocer a tu llegada. </p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <Link to="/destinos">Ir ahora</Link>                      
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    <Outlet />
    <Footer />
    
    </>
  )
}
