import React from 'react'
import '../../css/footer.css';
import logo from '../../assets/images/logo.png';

import { Outlet, Link } from "react-router-dom";
export default function Footerpruebas() {
  return (
    <footer >
        
     
    <div className="container">      
        <div className="links">
            <div className="links_columna">                
                <Link to="/nosotros" className="links_menu">Conócenos </Link>
                <Link to="/puertos" className="links_menu">Puertos </Link>
                <Link to="/soluciones-logisticas" className="links_menu">Servicios de carga </Link>
                
                <Link to="/flota" className="links_menu">Flota </Link>
                <a className="links_menu" href='http://proveedores.bajaferries.com/ProveedoresLogin.aspx' target="_blank" rel='noopener noreferrer' >Proveedores</a>
            </div>
            <div className="links_columna">
                {/*<Link to="/sostenibilidad" className="links_menu">Sostenibilidad </Link>*/}
                <a className="links_menu" href="https://eticabajaferries.lineaetica.com.mx/" rel='noopener noreferrer' target="_blank">Código de ética</a>
                <Link to="/preguntas-frecuentes" className="links_menu">Preguntas frecuentes </Link>
                <a className="links_menu" href="?" title="">Blog </a>
                
            </div>
            <div className="links_columna">
                <a className="links_menu" href="?" title="" target="_blank" rel="">Noticias y avisos</a>
                <Link to="/contacto" className="links_menu">Contacto </Link>
                <a className="links_menu" href="?" title=" ">Promociones </a>                
                <Link to="/inversionistas" className="links_menu">Inversionistas </Link>            
                <Link to="/kit-de-comunicacion " className="links_menu">Media Kit </Link>                         
            </div>
            <div className="links_columna">
                <a className="links_menu" href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&amp;pass=e3f391ec274cd03488365c20827762e3dc893500&amp;nif=COLR039&amp;centro=7888&amp;campanya=6711&amp;oleada=100982&amp;idm=es" title="" target="_blank" rel='noopener noreferrer'>Buzón de quejas y sugerencias </a>                
                <a className="links_menu" href="https://eticabajaferries.lineaetica.com.mx/" rel='noopener noreferrer' target="_blank">Portal de denuncias </a>
                <Link to="/condiciones-de-servicio" className="links_menu">Condiciones de Servicio </Link>                
                <Link to="/aviso-de-privacidad" className="links_menu">Aviso de Privacidad </Link>                
                <a className="links_menu" href="?" title=" ">Política de cookies</a>
                {/*<a className="links_menu" href="?" title=" " target="_blank" rel=" ">Política del sistema de gestión integrado</a>*/}
            </div>
        </div>  


        <div className="copyright">
            <div className='copy'>
                Copyright © 
                <a className="text-reset fw-bold" href="https://bajaferries.com.mx"> BAJA FERRIES S.A.P.I. DE C.V. 2023.</a>
            </div>

            <div className='logoFooter'>
                <img src={logo} alt="logo" /> 
            </div>
            
            <div className='copy2'>
                Todo los derechos reservados.
            </div>
            
        </div>
    </div>
    <Outlet />
</footer>
  )
}
