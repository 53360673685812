import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

mapboxgl.accessToken = 'pk.eyJ1IjoiYW5kaHJhIiwiYSI6ImNsbDJnZTJjbzJnMHAzZW15YnhhOWJkOGEifQ.pP3n_58agJfYHp7fUzrUbw';

const MapaTopo = () => {
  const mapContainerRef = useRef(null);
  const route = [
    [-110.327473, 24.275435],
    [-110.327650, 24.274789],
    [-110.327678, 24.273909],
    [-110.327487, 24.267109],
    [-110.327510, 24.259804],
    [-110.328043, 24.257397],
    [-110.336970, 24.246213],
    [-110.338420, 24.245689],
    [-110.341144, 24.246395],
    [-110.345243, 24.250474],
    [-110.353965, 24.266607],
    [-110.357039, 24.289822],
    [-110.359388, 24.309069],
    [-110.350219, 24.354059],

    [-110.348830, 24.358184],
    [-110.344600, 24.363167],
    [-110.340006, 24.366954],
    [-110.328264, 24.373530],
    [-110.166521, 24.480128],
    [-110.076424, 24.571724],
    [-109.968686, 24.686899],
    [-109.191069, 25.508345],
    [-109.166063, 25.527144],
    [-109.134978, 25.551045],
    [-109.131329, 25.553301],
    [-109.128356, 25.554276],
    [-109.115381, 25.555861],
    [-109.095109, 25.558970],
    [-109.062628, 25.575868],
    [-109.056119, 25.579721],
    [-109.055033, 25.582511],
    [-109.054471, 25.585714],
    [-109.054762, 25.588164],
    [-109.054936, 25.588794],
    [-109.055790, 25.589529],
    [-109.058662, 25.590877],
  ];

  useEffect(() => {
    if (mapContainerRef.current) {
      const map = new mapboxgl.Map({
        container: mapContainerRef.current,
        style: 'mapbox://styles/mapbox/streets-v11', // Estilo del mapa
        center: [-108.931, 25.177],
        zoom: 5.5
      });

      // Agregar la ruta al mapa
      map.on('load', () => {
        map.addSource('route', {
          type: 'geojson',
          data: {
            type: 'Feature',
            properties: {},
            geometry: {
              type: 'LineString',
              coordinates: route,
            },
          },
        });

        map.addLayer({
          id: 'route',
          type: 'line',
          source: 'route',
          layout: {
            'line-join': 'round',
            'line-cap': 'round',
          },
          paint: {
            'line-color': '#888',
            'line-width': 8,
          },
        });

        // Añadir marcadores al inicio y final de la ruta
        new mapboxgl.Marker()
        .setLngLat(route[0])
        .addTo(map);

        new mapboxgl.Marker()
        .setLngLat(route[route.length - 1])
        .addTo(map);

      });

      return () => map.remove();
    }
  });

  return <div ref={mapContainerRef} style={{ width: '100%', height: '100%' }} />;
};

export default MapaTopo;