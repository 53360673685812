import React from 'react'
import Navbar from '../components/Navbar';
import '../css/terrestre.css'
import terrestre from '../assets/images/pichilingue.webp'
import CargaForm from '../components/form/CargaForm';
import FooterCarga from '../components/FooterCarga';
import transporte_carretera from '../assets/images/carga/terrestre/transporte-carretera.webp'
import agente_naviero from '../assets/images/carga/terrestre/agente-maritimo.webp'

import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import { Outlet, Link } from "react-router-dom";
export default function Terrestre() {
  return (
    <> 
    <Navbar />
    {/*
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Soluciones terrestre</h3>
                    <p>Consulte la información respecto a las terminales portuarias y las autoridades que operan en estas instalaciones.</p>
                </div>                
            </div>
        </div>
    </section>
    */}
    <section className='header_carga terrestre'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>Soluciones Terrestres</h2>
                    <p>Creamos soluciones eficaces para el transporte de tus mercancías por carretera.</p>
                </div>
            </div>
        </div>
    </section>
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content' style={{marginTop:'4rem'}}>                
                <div className='servicio_carga_box'>
                    <h2>Transporte por Carretera</h2>
                    <p>De la mano de nuestra filial GEOS, el operador logístico líder en la zona noroeste del país, brindamos servicio de última milla para transportar tus mercancías hasta su destino final de manera segura y eficiente desde o hacia Baja California Sur.</p>
                    <div className='btn_servicio_transporte'>                        
                        <a href="https://www.transportadorageos.com.mx/" target="_blank" rel="noopener noreferrer">Más información <i class="fi fi-rs-arrow-up-right-from-square"></i></a>  
                        <a href="mailto:rodolfo.vela@tgeos.mx" rel="noopener noreferrer">Contactar</a>      
                                                                            
                    </div>
                </div>
                <div className='servicio_carga_box'>
                    <img src={transporte_carretera} alt='servicio terrestre baja ferries' />
                </div>
            </div>
        </div>
    </section>


   

    <section className="callToAction carga">
        <div className='container'>
            
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Transporte de carga rodada</h4>
                            </div>
                            <div>
                                <p>Contamos con servicio  de ferrey con frecuencia diaria entre los puertos de Mazatlán, Topolobampo y La Paz para llevar tu carga desde o hacia B.C.S</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas-horario-carga">Ver horarios</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Tienes un proyecto especial?</h4>
                            </div>
                            <div>
                                <p>Si necesitas transportar mercancía de grandes dimensiones y peso, trabajamos contigo para gestionar tu proyecto especial.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>          
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Contacta un asesor</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros; nuestro equipo de expertos esta listo para brindarte la mejor asesoria para tu proyecto.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>                           
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />
    {/*        
        <CargaForm />
    */}
    <FooterCarga />
    
    </>
  )
}
