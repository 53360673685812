import React, {useEffect, useState} from 'react'
import { useNavigate,  } from 'react-router-dom';

export default function TaskList() {
    const [newsletter, setTasks] = useState([]);
    const navigate = useNavigate();
 
    const handleDelete = async (id) => {

    try {
        await fetch(`http://localhost:3004/newsletter/${id}`,{
            method: 'DELETE',
        })

        setTasks(newsletter.filter(newsletter => newsletter.id !== id));
        } catch (error) {
            console.log(error)
        }

    }

    const loadTasks = async () => {
        const response = await fetch('http://localhost:3004/newsletter');
        const data = await response.json();
        console.log("Data: ",data);
        setTasks(data);
    }

    useEffect(() =>{
        loadTasks();
    },[])

    const handleNew = () => {
        navigate("/newsletter/new");

    };
      

    console.log("Noticia: ",newsletter);
    return (
        <>
        <div>
            <h2>Newsletter</h2>
            <div>
                <button onClick={() => handleNew()}>Nuevo suscriptor</button>
            </div>
        </div>
        
        {
    
        <table>
            <thead>
            <tr>
                <th>Email</th>
                <th>Fecha alta</th>
                <th>Fecha baja</th>                

                <th>Editar</th>
                <th>Eliminar</th>
            </tr>
            </thead>
            <tbody>
            {newsletter.map((task) => (

                <tr key={task.id}>                
                <td>{task.email}</td>
                <td>{task.fecha_alta}</td>
                <td>{task.fecha_baja}</td>
                
                <td>
                    <button onClick={() => navigate(`/newsletter/${task.id}/edit`) }>Editar</button>
                </td>
                <td>
                    <button onClick={() => handleDelete(task.id)}>Eliminar</button>
                </td>

                </tr>
            ))}
            </tbody>
        </table>
        
        }
        
        </>
        
    )
}
