import React, {useState, useEffect} from 'react'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import whatsapp from '../../assets/images/chat.svg'
import tarifas from '../../assets/images/cta/tarifas.svg'
import preguntas from '../../assets/images/pregutas.png'
import '../../css/contact_form.css'
import { useLocation } from 'react-router-dom';
import Footer from '../Footer';
import axios from 'axios';

const ContactForm = () => {

    const [asunto, setAsunto] = useState('');
    const [nombre, setNombre] = useState('');
    const [apellido, setApellido] = useState('');
    const [correo, setCorreo] = useState('');
    const [isChecked, setIsChecked] = useState(true);
    const [telefono, setTelefono] = useState('');
    const [mensaje, setMensaje] = useState('');
    const [cant_viaja_grupo, setCant_viaja_grupo] = useState('');
    const location = useLocation();
    const [viajoEnGrupo, setViajoEnGrupo] = useState(false);


    // Manejador de cambios para actualizar el estado cuando se selecciona una opción
    const handleSelectChange = (event) => {
        setAsunto(event.target.value);        
    };

    const handleCant_viaja_grupo = (event) => {
        setCant_viaja_grupo(event.target.value);
    };

    // Manejador de cambio para el checkbox
    const checkboxChange = () => {
        // Cambia el valor del estado cuando se presiona el checkbox
        setIsChecked(!isChecked);
    };

    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const viajoEnGrupoParam = queryParams.get('viajoEnGrupo');
    
        if (viajoEnGrupoParam === 'true') {
          setViajoEnGrupo(true);
        }
        }, [location.search]);

        const handleCheckboxChange = (e) => {
            setViajoEnGrupo(e.target.checked);
    };

  const enviarCorreo = async () => {
    try {
        // Cambia el valor de isChecked a "si" o "no"
        const autorizaLlamada = isChecked ? "si" : "no";
        
        await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', {
            asunto,
            nombre,
            apellido,
            correo,
            autorizaLlamada,
            telefono,
            viajoEnGrupo,
            cant_viaja_grupo,
            mensaje,
            formulario: "viajaEnGrupo"
        
        }, {
            headers: {
                'Content-Type': 'application/json',
                // Agrega otros encabezados si son necesarios
            },
        });
        
        // Limpiar campos después de enviar el correo
        setAsunto('');
        setNombre('');
        setApellido('');
        setCorreo('');
        setTelefono('');
        setCant_viaja_grupo('');
        setMensaje('');
        
        alert('Correo enviado correctamente');
    } catch (error) {
        console.error('Error al enviar el correo:', error);
        alert('Error al enviar el correo');
    }
};
  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Viaja en grupo</h3>
                    <p>{/*Ofrecemos tarifas especiales y servicios personalizados para grupos grandes y pequeños*/} ¡Planifica tu próximo viaje con amigos, familia o colegas! </p>
                </div>                
            </div>
        </div>
    </section>
    <section className='box_form_grupo'>
        <div className='container'>
            <div className='formulario_contacto'>
                <div className='formulario_contacto_title'>
                    <div className='formulario_contacto_text'>
                        <h3>Formulario</h3>
                        <p>Ofrecemos tarifas especiales y servicios personalizados para grupos grandes y pequeños. Comparte con nosotros la siguiente información, nos contactaremos contigo. </p>                 
                    </div>
                </div>
                <div className='form_contact'>
                    
                        <div className='contentbox_contactform'>
                            <div className='box_contactform'>
                                {/*
                                <div className='boxes_contactform'>
                                    <label>Asunto del correo:</label>
                                    <select name='asunto' value={asunto}  required  onChange={handleSelectChange}>
                                        <option>Selecciona un tema</option>
                                        <option value="Tarifas">Tarifas</option>
                                        <option value="Rutas y Horarios">Rutas y Horarios</option>
                                        <option value="Descuenos y promociones">Descuenos y promociones</option>
                                        <option value="Viaje en grupo">Viaje en grupo</option>
                                        <option value="Otro">Otro</option>
                                    </select>
                                </div>  
                                */}                                                      
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Nombre:</label>
                                    <input type='text' value={nombre} onChange={(e) => setNombre(e.target.value)} placeholder='Introduce tu nombre' className="custom-input" />
                                </div>
                                <div className='boxes_contactform'>
                                    <label>Apellidos:</label>
                                    <input type='text' value={apellido} onChange={(e) => setApellido(e.target.value)} placeholder='Introduce tus apellidos'className="custom-input" />
                                </div>
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Correo:</label>
                                    <input type='text' value={correo} onChange={(e) => setCorreo(e.target.value)} placeholder='Introduce tu correo' className="custom-input" />
                                </div>
                                <div className='boxes_contactform'>
                                    <div className='checkbox_telefono'>
                                        <input type="checkbox" checked={isChecked} onChange={checkboxChange}/> <div> Teléfono: <label> (Autorizo que se me contacte por llamada)</label>    </div>                         
                                    </div>
                                    <div>
                                        <input type='text' value={telefono} onChange={(e) => setTelefono(e.target.value)}  min={10} max={10} placeholder='Teléfono (10 dígitos)' className="custom-input" />
                                    </div>                                                                                    
                                </div>
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <div className='checkbox_telefono'>
                                        <input type="checkbox" id="" name="" value="viajoEnGrupo" checked={viajoEnGrupo} onChange={handleCheckboxChange}/>Viajo en grupo                                    
                                    </div>
                                    <hr />
                                    {viajoEnGrupo &&                                 
                                        <div>
                                            <p>Tarifa fija preferencial a partir de 20 personas adultas pagadas (adicionales al coordinador y conductor en caso de trasladar alguna unidad)</p>
                                            <label>Viajo con un grupo de:</label> <br />
                                            <select name='cant_viaja_grupo' value={cant_viaja_grupo}  onChange={handleCant_viaja_grupo}>
                                                <option>Selecciona cantidad</option>
                                                <option value="20">20 personas</option>
                                                <option value="21">21 personas</option>
                                                <option value="22">22 personas</option>
                                                <option value="23">23 personas</option>
                                                <option value="24">24 personas</option>
                                                <option value="25">25 personas</option>

                                                <option value="26">26 personas</option>
                                                <option value="27">27 personas</option>
                                                <option value="28">28 personas</option>
                                                <option value="29">29 personas</option>
                                                <option value="30">30 personas</option>
                                                <option value="31">31 personas</option>

                                                <option value="32">32 personas</option>
                                                <option value="33">33 personas</option>
                                                <option value="34">34 personas</option>
                                                <option value="35">35 personas</option>
                                                <option value="36">36 personas</option>
                                                <option value="37">37 personas</option>

                                                <option value="38">38 personas</option>
                                                <option value="39">39 personas</option>
                                                <option value="40">40 personas</option>
                                                <option value="41">41 personas</option>
                                                <option value="42">42 personas</option>
                                                <option value="43">43 personas</option>

                                                <option value="44">44 personas</option>
                                                <option value="45">45 personas</option>
                                                <option value="46">46 personas</option>
                                                <option value="47">47 personas</option>
                                                <option value="48">48 personas</option>
                                                <option value="49">49 personas</option>

                                                <option value="50">50 personas</option>
                                                <option value="51">51 personas</option>
                                                <option value="52">52 personas</option>
                                                <option value="53">53 personas</option>
                                                <option value="54">54 personas</option>
                                                <option value="55">55 personas</option>

                                                <option value="56">56 personas</option>
                                                <option value="57">57 personas</option>
                                                <option value="58">58 personas</option>
                                                <option value="59">59 personas</option>
                                                <option value="60">60 personas</option>
                                                <option value="61">61 personas</option>

                                                <option value="62">62 personas</option>
                                                <option value="63">63 personas</option>
                                                <option value="64">64 personas</option>
                                                <option value="65">65 personas</option>
                                                <option value="66">66 personas</option>
                                                <option value="67">67 personas</option>

                                                <option value="68">68 personas</option>
                                                <option value="69">69 personas</option>
                                                <option value="70">70 personas</option>
                                                <option value="71">71 personas</option>
                                                <option value="72">72 personas</option>
                                                <option value="73">73 personas</option>

                                                <option value="74">74 personas</option>
                                                <option value="75">75 personas</option>
                                                <option value="76">76 personas</option>
                                                <option value="77">77 personas</option>
                                                <option value="78">78 personas</option>
                                                <option value="79">79 personas</option>

                                                <option value="80">80 personas</option>
                                                <option value="81">81 personas</option>
                                                <option value="82">82 personas</option>
                                                <option value="83">83 personas</option>
                                                <option value="84">84 personas</option>
                                                <option value="85">85 personas</option>
                                                <option value="86">86 personas</option>
                                                <option value="87">87 personas</option>
                                                <option value="88">88 personas</option>
                                                <option value="89">89 personas</option>
                                                <option value="90">90 personas</option>
                                                <option value="91">91 personas</option>

                                                <option value="92">92 personas</option>
                                                <option value="93">93 personas</option>
                                                <option value="94">94 personas</option>
                                                <option value="95">95 personas</option>
                                                <option value="96">96 personas</option>
                                                <option value="97">97 personas</option>

                                                <option value="98">98 personas</option>
                                                <option value="99">99 personas</option>
                                                <option value="100">100 personas</option>
                                                <option value="101">101 personas</option>
                                                <option value="102">102 personas</option>
                                                <option value="103">103 personas</option>

                                                <option value="104">104 personas</option>
                                                <option value="105">105 personas</option>
                                                <option value="106">106 personas</option>
                                                <option value="107">107 personas</option>
                                                <option value="108">108 personas</option>
                                                <option value="109">109 personas</option>

                                                <option value="110">110 personas</option>
                                                <option value="111">111 personas</option>
                                                <option value="112">112 personas</option>
                                                <option value="113">113 personas</option>
                                                <option value="114">114 personas</option>
                                                <option value="115">115 personas</option>

                                                <option value="116">116 personas</option>
                                                <option value="117">117 personas</option>
                                                <option value="118">118 personas</option>
                                                <option value="119">119 personas</option>
                                                <option value="120">120 personas</option>
                                                <option value="121">121 personas</option>

                                                <option value="122">122 personas</option>
                                                <option value="123">123 personas</option>
                                                <option value="124">124 personas</option>
                                                <option value="125">125 personas</option>
                                                <option value="126">126 personas</option>
                                                <option value="127">127 personas</option>
                                                <option value="128">128 personas</option>
                                                <option value="129">129 personas</option>
                                                <option value="130">130 personas</option>

                                                <option value="131">131 personas</option>
                                                <option value="132">132 personas</option>
                                                <option value="133">133 personas</option>
                                                <option value="134">134 personas</option>
                                                <option value="135">135 personas</option>
                                                <option value="136">136 personas</option>
                                                <option value="137">137 personas</option>
                                                <option value="138">138 personas</option>
                                                <option value="139">139 personas</option>

                                                <option value="140">140 personas</option>
                                                <option value="141">141 personas</option>
                                                <option value="142">142 personas</option>
                                                <option value="143">143 personas</option>
                                                <option value="144">144 personas</option>
                                                <option value="145">145 personas</option>
                                                <option value="146">146 personas</option>
                                                <option value="147">147 personas</option>
                                                <option value="148">148 personas</option>
                                                <option value="149">149 personas</option>
                                                <option value="150">150 personas</option>
                                                <option value="151">151 personas</option>

                                                <option value="152">152 personas</option>
                                                <option value="153">153 personas</option>
                                                <option value="154">154 personas</option>
                                                <option value="155">155 personas</option>
                                                <option value="156">156 personas</option>
                                                <option value="157">157 personas</option>
                                                <option value="158">158 personas</option>
                                                <option value="159">159 personas</option>
                                                <option value="160">160 personas</option>
                                                <option value="161">161 personas</option>
                                                <option value="162">162 personas</option>
                                                <option value="163">163 personas</option>

                                                <option value="164">164 personas</option>
                                                <option value="165">165 personas</option>
                                                <option value="166">166 personas</option>
                                                <option value="167">167 personas</option>
                                                <option value="168">168 personas</option>
                                                <option value="169">169 personas</option>
                                                <option value="170">170 personas</option>
                                                <option value="171">171 personas</option>
                                                <option value="172">172 personas</option>
                                                <option value="173">173 personas</option>
                                                <option value="174">174 personas</option>
                                                <option value="175">175 personas</option>

                                                <option value="176">176 personas</option>
                                                <option value="177">177 personas</option>
                                                <option value="178">178 personas</option>
                                                <option value="179">179 personas</option>
                                                <option value="180">180 personas</option>
                                                <option value="181">181 personas</option>
                                                <option value="182">182 personas</option>
                                                <option value="183">183 personas</option>
                                                <option value="184">184 personas</option>
                                                <option value="185">185 personas</option>
                                                <option value="186">186 personas</option>
                                                <option value="187">187 personas</option>

                                                <option value="188">188 personas</option>
                                                <option value="189">189 personas</option>
                                                <option value="190">190 personas</option>
                                                <option value="191">191 personas</option>
                                                <option value="192">192 personas</option>
                                                <option value="193">193 personas</option>
                                                <option value="194">194 personas</option>
                                                <option value="195">195 personas</option>
                                                <option value="196">196 personas</option>
                                                <option value="197">197 personas</option>
                                                <option value="198">198 personas</option>
                                                <option value="199">199 personas</option>
                                                <option value="200">200 personas</option>
                                                
                                            </select>
                                            <hr />
                                        </div>                                                                        
                                    }                                                                               
                                </div>                            
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Mensaje (Opcional):</label>
                                    <textarea rows={4} cols={40} placeholder=' Introduce el mensaje...' value={mensaje} onChange={(e) => setMensaje(e.target.value)} >
                                    
                                    </textarea>
                                </div>
                            </div>
                            <div className='box_content-btn'>                         
                                <button onClick={enviarCorreo}>Enviar</button>                              
                            </div>                            
                        </div>                                                  
                    
                    <div className='localizar_punto'>
                        {/*<div>
                            <div className='box_group-card'>
                                <div className='box_content-card contact-box-card'>
                                    <div className='box_icon-card'>
                                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                                    </div>
                                    <div className='boxes_content'>
                                        <div className='box_content-title'>
                                            <h2>Encuentra el punto de ventas más cercano</h2>
                                        </div>
                                        <div className='box_content-subtext'>
                                            <p>
                                                Utiliza nuestro buscador para encontrar el punto de ventas más cercano. 
                                            </p>
                                        </div>
                                        <div className='box_content_busqueda_punto'>
                                            <div className='busqueda'>
                                                <input type='text' placeholder='Ingresa tu estado' />
                                                <button>Buscar</button>                                            
                                            </div>                                        
                                        </div>
                                    </div>                                
                                </div>
                            </div>
                        </div>*/}
                    </div>
                    
                </div>
            </div>              
        </div>
    </section>
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={tarifas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
    <Footer />
</>
  )
}
export default ContactForm;

