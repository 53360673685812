import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import { Col, Row,  } from 'antd';
import '../css/flota.css'
import ContadorFlota from '../components/ContadorFlota'
import bgFlota01 from '../assets/images/bg-flota.jpg'
import reloj from '../assets/images/flota/reloj_reloj-g.svg'
import users_usuarios from '../assets/images/flota/users_usuarios-g.svg'
import ferry from '../assets/images/flota/ferry_ferry icon-g.svg'
import reservacion from '../assets/images/cta/reservacion.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import california_star from '../assets/images/flota/california-star.webp'
import mexico_star from '../assets/images/flota/mexicoStar01.webp'
import mapa from '../assets/images/pregutas.png'

import Footer from '../components/Footer'

export default function Flota() {
    const [selectedOption, setSelectedOption] = useState('mexico'); // Valor inicial

    // Define las listas de imágenes para desierto y playa
    const mexico = [
      'bgFlota01.jpg',
      'bgFlota02.jpg',
      'bgFlota03.jpg',
      'bgFlota04.jpg',
      // Agrega aquí más imágenes de desierto
    ];
  
    const california = [
        'baja_star1.jpg',
        'baja_star2.jpg',
        'baja_star3.jpg',
        'baja_star4.jpg',
      // Agrega aquí más imágenes de playa
    ];
  
    // Función para cambiar la selección del usuario
    const handleSelectChange = (event) => {
      setSelectedOption(event.target.value);
    };
  
    // Obtiene la lista de imágenes en función de la selección del usuario
    const selectedImages = selectedOption === 'mexico' ? mexico : california;

  return (
    <> 
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Nuestra flota</h3>
                    <p>Calidad, Innovación y Responsabilidad</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='flota'>
        <div className='container'>
            <div className='content_fota'>
                <div className='content_img_fota'>
                    <img src={california_star} alt='california star'/>
                </div>
                <div>
                    <p className='title_buque_flota'>Buque</p>
                    <p className='name_buque_flota'>California Star</p>
                    <p className='routes_buque_flota'>Ruta: Topolobampo <i class="fi fi-rr-exchange-alt"></i> La Paz</p>
                </div>
                <div className='content_info_fota'>
                    <p><i><img src={reloj} alt='flota california star' /></i>21 Nudos (38.89km)</p>
                    <p><i><img src={users_usuarios} alt='flota california star' /></i>399 Pasajeros</p>
                    <p><i><img src={ferry} alt='flota california star' /></i>2,750 Metros Lineales</p>
                    <p><i class="fi fi-ts-ruler-horizontal"></i>193 Metros de Eslora (largo)</p>
                    <p><i class="fi fi-ts-ruler-vertical"></i>25.5 Metros de Manga (ancho)</p>
                </div>
                <div className='btns_flota'>
                    <Link to="/rutas_horario" className='flota_reserva'>Ruta y horarios</Link>                
                    <Link to="/servicios-california-star" className='flota_ver_mas'>Ver más </Link>  
                </div>
            </div>
            <div className='content_fota'>
                <div className='content_img_fota'>
                    <img src={mexico_star} alt='california star'/>
                </div>
                <div>
                    <p className='title_buque_flota'>Buque</p>
                    <p className='name_buque_flota'>México Star</p>
                    <p className='routes_buque_flota'>Ruta: Mazatlán <i class="fi fi-rr-exchange-alt"></i> La Paz</p>
                </div>
                <div className='content_info_fota'>
                    <p><i><img src={reloj} alt='flota california star' /></i>22 Nudos (40.74km)</p>
                    <p><i><img src={users_usuarios} alt='flota california star' /></i>348 Pasajeros</p>
                    <p><i><img src={ferry} alt='flota california star' /></i>2,715 Metros Lineales</p>
                    <p><i class="fi fi-ts-ruler-horizontal"></i>182.6 Metros de Eslora (largo)</p>
                    <p><i class="fi fi-ts-ruler-vertical"></i>25.5 Metros de Manga (ancho)</p>
                </div>
                <div className='btns_flota'>
                    
                    <Link to="/rutas_horario" className='flota_reserva'>Ruta y horarios</Link>    
                    <Link to="/servicios-mexico-star" className='flota_ver_mas'>Ver más </Link>                 
                </div>
            </div>
        </div>
    </section>

{/* 
    <section className='container'>
        <div className='flota'>
            <div className='flota_text'>
                <h2>Conoce nuestra flota</h2>
                <p>Nos hemos consolidado como el engranaje clave de la cadena logística y flujo de pasaje presente en el Mar de Cortés.</p>      
                <p>Conectamos a Baja California Sur, con el resto del país a través de dos rutas en el Mar de Cortés, Topolobampo-La Paz y Mazatlán-La Paz, movilizando más de 2 millones de toneladas de mercancías y 300 mil pasajeros al año, ofreciendo a nuestros clientes una amplia gama de servicios a bordo.</p>   
            </div>
            <div className='flota_img'>
                <img src={bgFlota01} alt='flota'/>
            </div>
        </div>
    </section>
    */}
{/*}
    <section className='container'>
        <ContadorFlota />
  </section>*/}

{/* 
    <section className='container'>
        <div className='box_selection_flota'>

            <select id="imageType" value={selectedOption} onChange={handleSelectChange}>
            <option value="mexico">México Star</option>
            <option value="california">California Star</option>
            </select>
        </div>

        <div className='flotaBox'>
        {
            selectedImages.map((image, index) => (
                <div className='content_box_flota'>
                    <img key={index} src={`./images/${image}`} alt={`Imagen ${index}`} />

                    { index === 0 && selectedOption === 'mexico' &&                                                                   
                    <div className='flota_text_info'>  
                        <h3>Ruta</h3>                      
                        <ul>
                            <li>Nos hemos consolidado</li>                           
                        </ul>                                               
                    </div>                                            
                    }
                    { index === 1 && selectedOption === 'mexico' &&                                                                   
                        <div className='flota_text_info'>     
                            <h3>Capacidad de carga</h3>                     
                            <ul>
                                <li>Nos hemos consolidado</li>
                                <li>Nos hemos consolidado</li>                                
                            </ul>                                               
                        </div>                                               
                    }
                    { index === 2 && selectedOption === 'mexico' &&                                                                   
                        <div className='flota_text_info'>      
                            <h3>Servicios</h3>                    
                            <ul>
                                <li>Nos hemos consolidado</li>
                                <li>Nos hemos consolidado</li>
                                <li>Nos hemos consolidado</li>
                            </ul>                                               
                        </div>                                               
                    }
                    { index === 3 && selectedOption === 'mexico' &&                                                                   
                        <div className='flota_text_info'>    
                            <h3>Pasajeros</h3>                      
                            <ul>
                                <li>Nos hemos consolidado</li>
                                <li>Nos hemos consolidado</li>
                                <li>Nos hemos consolidado</li>
                                <li>Nos hemos consolidado</li>
                            </ul>                                               
                        </div>                                               
                    }







                    { index === 0 && selectedOption === 'california' &&
                        
                        <div className='flota_text_info'>        
                            <h3>Ruta</h3>                   
                            <ul>
                                <li>Nos hemos consolidado</li>
                                
                            </ul>                                               
                        </div>   
                                            
                        }
                        { index === 1 && selectedOption === 'california' &&                                                                   
                            <div className='flota_text_info'>    
                                <h3>Capacidad de carga</h3>                    
                                <ul>
                                    <li>Nos hemos consolidado</li>
                                    <li>Nos hemos consolidado</li>
                                    
                                </ul>                                               
                            </div>                                               
                        }
                        { index === 2 && selectedOption === 'california' &&                                                                   
                            <div className='flota_text_info'>   
                                <h3>Servicios</h3>                     
                                <ul>
                                    <li>Nos hemos consolidado</li>
                                    <li>Nos hemos consolidado</li>
                                    <li>Nos hemos consolidado</li>
                                </ul>                                               
                            </div>                                               
                        }
                        { index === 3 && selectedOption === 'california' &&                                                                   
                            <div className='flota_text_info'>                        
                                <h3>Pasajeros</h3>
                                <ul>
                                    <li>Nos hemos consolidado</li>
                                    <li>Nos hemos consolidado</li>
                                    <li>Nos hemos consolidado</li>
                                    <li>Nos hemos consolidado</li>
                                </ul>                                               
                            </div>                                               
                        }
                                                                  
                </div>
            ))
        }
        </div>

    </section>

*/}


    
    <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>¿Listo para reservar?</h4>
                                </div>
                                <div>
                                    <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>                                                               
                                <a href="https://booking.bajaferries.com.mx/es/book/journey-booking/journeySearch/" target="_blank" rel="noopener noreferrer">Reservar</a>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Nuestras certificaciones</h4>
                                </div>
                                <div>
                                    <p>Conoce las certificaciones y distintivos que avalan la seguridad y la calidad de viajar con nosotros.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/certificaciones">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Comunícate con nosotros</h4>
                                </div>
                                <div>
                                    <p>Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Envíar mensaje </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
    </section>
    <Outlet />
    <Footer />
    
</>
  )
}

