import React, { useState } from 'react';
import axios from 'axios';

const EnviarCorreo = () => {
    const [destinatario, setDestinatario] = useState('');
    const [asunto, setAsunto] = useState('');
    const [cuerpo, setCuerpo] = useState('');

    const enviarCorreo = async () => {
        try {
            await axios.post('http://localhost:3001/enviar-correo', {
                destinatario,
                asunto,
                cuerpo,
            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });
    
            // Limpiar campos después de enviar el correo
            setDestinatario('');
            setAsunto('');
            setCuerpo('');
    
            alert('Correo enviado correctamente');
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };

    return (
        <div>
            <input
                type="email"
                placeholder="Destinatario"
                value={destinatario}
                onChange={(e) => setDestinatario(e.target.value)}
            />
            <input
                type="text"
                placeholder="Asunto"
                value={asunto}
                onChange={(e) => setAsunto(e.target.value)}
            />
            <textarea
                placeholder="Cuerpo del correo"
                value={cuerpo}
                onChange={(e) => setCuerpo(e.target.value)}
            />
            <button onClick={enviarCorreo}>Enviar Correo</button>
        </div>
    );
};

export default EnviarCorreo;