import React, {useState} from 'react'
import '../css/footer.css'
import logo from '../assets/images/LOGO-BF-BLANCO.png'
import spotify_logo from '../assets/images/spotify/spotify.svg';
import facebook from '../assets/images/spotify/facebook.svg';
import twitter from '../assets/images/spotify/twitter.svg';
import instagram from '../assets/images/spotify/instagram.svg';
import youtube from '../assets/images/spotify/youtube.svg';
import { Outlet, Link } from "react-router-dom";
export default function FooterPrueba() {
    const [isSubmenu, setIsSubmenu] = useState(null);

    const toggleSubmenu = (index) => {
        setIsSubmenu((prevState) => (prevState === index ? null : index));
    };

  return (
    <>
        <section className='d-none d-sm-none d-md-block'>
            <div className='footer'>
                <div className='container'>                
                    <div className='footer_links'>
                        <div className='box_links'>
                            <div className='box_links_logo' style={{marginBottom: '4.5rem'}}><img src={logo} alt='' /></div>                            
                            <div>
                                <div className='form-newsletter'>
                                    <label>NEWSLETTER</label>
                                    <input type='email' placeholder='E-Mail'/>
                                    <button>Suscribirme</button>
                                </div>
                            </div>
                           
                        </div>
                        <div className='box_links'>
                            <ul>
                                <li><Link to="/nosotros">Conócenos</Link> </li>
                                <li><Link to="/flota">Nuestra flota</Link></li>
                                <li><Link to="/certificaciones">Certificaciones</Link></li>
                                <li><Link to="/soluciones-logisticas">Servicios de carga</Link></li>
                                <li><Link to="/rutas_horario">Rutas y horarios</Link></li>
                                <li><Link to="/tarifas">Tarifas</Link></li>
                                <li><Link to="/viaje-en-grupo">Viaje en grupo</Link></li>
                                <li><Link to="/promociones">Promociones</Link></li>                                
                            </ul>
                        </div>
                        <div className='box_links'>
                            <ul>        
                                <li><Link to="/prepara-tu-viaje">Prepara tu viaje</Link></li>                                                    
                                <li><Link to="/noticias">Noticias y avisos</Link></li>
                                {/*<li><Link to="/sostenibilidad">Sostenibilidad</Link></li> */}
                                <li><Link to="/flota">Nuestra flota</Link></li>
                                <li><Link to="/certificaciones">Certificaciones</Link></li>
                                {/*<li><Link to="/kit-de-comunicacion">Prensa</Link></li>*/}
                                
                                <li><a className="navbar-link" href="http://proveedores.bajaferries.com/ProveedoresLogin.aspx" target="_blank" rel="noopener noreferrer">Proveedores</a> </li>
                                                             
                            </ul>
                        </div>
                        <div className='box_links'>
                            <ul>
                                <li><Link to="/contacto">Atención al cliente</Link></li>    
                                <li><Link to="/preguntas-frecuentes">Preguntas frecuentes</Link></li>
                                <li><a className="navbar-link" href="https://www.eticabajaferries.com.mx/c%C3%B3digo-de-%C3%A9tica/" target="_blank" rel="noopener noreferrer">Código de ética</a></li>
                                <li><a className="navbar-link" href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es" target="_blank" rel="noopener noreferrer">Buzón de quejas y sugerencias</a></li>
                                <li><a className="navbar-link" href="https://www.eticabajaferries.com.mx/denuncias/" target="_blank" rel="noopener noreferrer">Portal de denuncias</a></li>
                                <li><Link to="/condiciones-de-servicio">Condiciones de servicio</Link></li>
                                <li><Link to="/aviso-de-privacidad">Aviso de privacidad</Link></li>                                                                
                                <div className='box_links_socials'>
                                
                                <div className='box_link_social'>
                                    <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                                </div>
                                <div className='box_link_social'>                                        
                                    <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://www.youtube.com/@bajaferries2914" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://open.spotify.com/user/in0rh2go0af0k6asr46bzxun7" rel='noopener noreferrer' target="_blank"><img src={spotify_logo} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </div>
                                
                            </div>                        
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='footer_copyright'>
                    <div className='conteiner'>
                        <label>Copyright © BAJA FERRIES S.A.P.I. DE C.V. 2024. </label>
                        <label> Todo los derechos reservados.</label>
                    </div>
                </div>
            </div>
        </section>
        <section className='d-block d-sm-block d-md-none'>
           <div className='footer phone'>
                <div className='container'>
                    <div className='footer_boxes'>
                        <div className='box_socials'>
                            <div>
                                <label>Síguenos</label>
                            </div>
                            <div className='box_socials_links'>
                                <div className='box_link_social'>
                                    <a href="https://www.facebook.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={facebook} alt='facebook oficial bajaferries' className='bg_spotify' /> </a>                                                                            
                                </div>
                                <div className='box_link_social'>                                        
                                    <a href="https://x.com/bajaferries" rel='noopener noreferrer' target="_blank"><img src={twitter} alt='twitter oficial bajaferries' className='bg_spotify' /> </a>                                      
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://www.instagram.com/bajaferriesoficial/" rel='noopener noreferrer' target="_blank"><img src={instagram} alt='instagram oficial bajaferries' className='bg_spotify' /> </a>                                                                                
                                </div>
                                <div className='box_link_social'>
                                    <a href="https://www.youtube.com/@bajaferries2914" rel='noopener noreferrer' target="_blank"><img src={youtube} alt='youtube oficial bajaferries' className='bg_spotify' /></a>                                                                                
                                </div>                            
                            </div>
                        </div>
                        
                        <div className='form-newsletter phone'>
                            <label>NEWSLETTER</label>
                            <input type='email' placeholder='E-mail'/>
                            <button>Suscribirme</button>
                        </div>
                        <div className='menu_list_footer phone'>
                        <ul>
                            <li><Link  onClick={() => toggleSubmenu(1)}>Viajar <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 1 &&(
                                <ul className='submenu_item'>                                                                                                          
                                    <li>Rutas y horarios</li>
                                    <li>Tarifas</li>
                                    <li>Viaje en grupo</li>
                                    <li>Promociones</li>
                                </ul>
                                )}
                            </li>
                            <li><Link  onClick={() => toggleSubmenu(2)}>Acerca de nosotros <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 2 &&(
                                <ul className='submenu_item'>
                                    <li>Conocenos</li>
                                    <li>Certificaciones</li>                                    
                                    <li>Nuestra flota</li>                                                                        
                                </ul>
                            )}
                            </li>
                            <li><Link  onClick={() => toggleSubmenu(3)}>Servicio de carga<i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 3 &&(
                                <ul className='submenu_item'>
                                    <li>Maritímo</li>
                                    <li>Terrestre </li>
                                    <li>Multimodal </li>
                                    <li>Tarifas</li>
                                </ul>
                            )}
                            </li>
                            <li><Link  onClick={() => toggleSubmenu(4)}>Atención al cliente <i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 4 &&(
                                <ul className='submenu_item'>
                                    <li>Contacto</li>
                                    <li>Preguntas frecuentes</li>
                                </ul>
                            )}
                            </li>
                            <li><Link to="/tarifas-carga" >Facturación</Link></li>
                            <li>
                                <Link to="/sostenibilidad">Noticias y avisos</Link>                                                            
                            </li>
                            <li>
                                <Link to="/sostenibilidad">Código de ética</Link>                                                            
                            </li>
                            <li><Link  onClick={() => toggleSubmenu(5)}>Legal<i class="fi fi-rr-caret-down"></i></Link>
                            {isSubmenu === 5 &&(
                                <ul className='submenu_item'>
                                    <li>Buzón de quejas y sugerencias</li>
                                    <li>Portal de denuncias</li>
                                    <li>Condiciones de servicio</li>
                                    <li>Aviso de privacidad</li>                                    
                                </ul>
                            )}
                            </li>
                            
                        </ul>

                        </div>
                        
                        <div className='box_links_logo phone'><img src={logo} alt='' /></div> 
                        
                        
                    </div>
                    
                </div>               
           </div>
           <div className='footer_copyright'>
                    <div className='conteiner'>
                        <div className='footer_copyright_text'>
                            <label>Copyright © BAJA FERRIES S.A.P.I. DE C.V. 2024.</label>
                            <label> Todo los derechos reservados.</label>
                        </div>
                    </div>
                </div>
        </section>
        <Outlet />
    </>
  )
}
