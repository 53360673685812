import React from 'react'
import Navbar from '../components/Navbar';
import '../css/maritimo.css'
import chartering from '../assets/images/soluciones/maritimo/chartering.webp'
import feedering from '../assets/images/soluciones/maritimo/feedering.webp'
import whatsapp from '../assets/images/chat.svg'
import formulario from '../assets/images/formulario-de-contacto.svg'
import mapa from '../assets/images/mapa.svg'
import carga_proyecto from '../assets/images/carga/maritima/CARGA-PROYECTO.webp'
import ferry_carga from '../assets/images/soluciones/maritimo/ferry_carga.jpg'
/*import agente_naviero from '../assets/images/carga/terrestre/agente-maritimo.webp'*/
import agentenaviero from '../assets/images/soluciones/integracion/agentenaviero.png'
import FooterCarga from '../components/FooterCarga'
import { Outlet, Link } from "react-router-dom";


export default function Maritimo() {
  return (
    <> 
    <Navbar />
    {/*
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Soluciones marítimas</h3>
                    <p>Consulte la información respecto a las terminales portuarias y las autoridades que operan en estas instalaciones.</p>
                </div>                
            </div>
        </div>
    </section>
  */}
    <section className='header_carga maritimo'>
        <div className='container'>
            <div className='servicio_carga_header'>
                <div className='servicio_carga_box'>
                    <h2>Soluciones marítimas</h2>
                    <p>¡Descubre cómo nuestros servicios marítimos pueden optimizar tu cadena de suministro y llevar tu negocio al siguiente nivel!</p>
                </div>
            </div>
        </div>
    </section>
 
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box chartering text'>
                    <h2>Transporte de carga rodada</h2>
                    <p>Contamos con servicio de frecuencia diaria entre los puertos de Mazatlán, Topolobampo y La Paz para el transporte eficiente de todo tipo carga incluyendo seca, refrigerada, peligrosa, líquida y otros tipos especiales. Nuestra moderna flota nos permite para garantizar un viaje seguro para ti y tus mercancías.</p>
                    <div className='btn_servicio_transporte'>                        
                        <Link to="/rutas-horario-carga">Ver rutas y horarios</Link>  
                        <Link to="/tarifas-carga">Tarifas</Link>    
                        <a href="mailto:contacto.comercial@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>  
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={ferry_carga} alt='servicio maritimo baja ferries' />
                </div>
            </div>
        </div>
    </section>
    {/*
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>
                <div className='servicio_carga_box'>                    
                    <ImageSlider images={images}/>
                </div>
                <div className='servicio_carga_box'>                    
                    <div className='box_info_carga'>
                        <i class="fi fi-rs-objects-column"></i>
                        <div>
                            <label>Carga seca</label>
                            <p>Mercancías que no requieren refrigeración o control de temperatura durante su transporte. Esto incluye una amplia gama de productos como ropa, electrodomésticos, muebles, maquinaria, productos electrónicos, entre otros.</p>
                        </div>                        
                    </div>
                    <div  className='box_info_carga'>
                        <i class="fi fi-rr-snow-blowing"></i>
                        <div>
                            <label>Carga Refrigerada</label>
                            <p>Mercancías que requieren un control preciso de la temperatura durante su transporte para mantener su frescura o integridad. Estas cargas suelen incluir productos perecederos como frutas, verduras, carne, pescado, lácteos, medicamentos y otros productos sensibles a la temperatura.</p>
                        </div>                        
                    </div>
                    <div  className='box_info_carga'>
                        <i class="fi fi-rr-water"></i>
                        <div>
                            <label>Carga líquida</label>
                            <p>Mercancías que se transportan en forma líquida, como petróleo, productos químicos, vino, agua, jugos, entre otros. Estos productos se almacenan y transportan en tanques específicos diseñados para líquidos.</p>
                        </div>                        
                    </div>
                </div>
            </div>
        </div>
    </section>
    */}
    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
            <div className='servicio_carga_box img'>
                    <img src={feedering} alt='servicio maritimo baja ferries' />
                </div>               
                <div className='servicio_carga_box feedering text'>
                    <h2>Feedering </h2>
                    <p>Nuestro servicio de Feedering ofrece una solución integral y eficiente para la distribución de carga dentro de puertos y regiones cercanas. Con una flota moderna y versátil, y un equipo altamente capacitado, garantizamos un transporte rápido, seguro y confiable de contenedores y carga a granel entre puertos. Ya sea para conectar con buques de largo recorrido o para facilitar la distribución local, nuestro servicio está diseñado para satisfacer tus necesidades específicas y optimizar sus operaciones logísticas.</p>
                    <div className='btn_servicio_transporte'>                        
                        <a href="mailto:cristobal.calderon@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>                                                       
                    </div>
                </div>
                
            </div>
        </div>
    </section>

    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'>                
                <div className='servicio_carga_box chartering text'>
                    <h2>Chartering</h2>
                    <p>Nuestro servicio de chartering  ofrece una solución flexible y eficiente para el transporte de mercancías contenerizadas, ensacadas, granel y otros tipos especiales hasta su destino final. Ya sea que necesites alquilar un buque completo o una parte de su capacidad para viajes únicos, contratos a largo plazo para periodos específicos o viajes múltiples, nuestro equipo especializado está listo para adaptarse a tus necesidades. </p>
                    <div className='btn_servicio_transporte'>                        
                        <a href="mailto:cristobal.calderon@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>  
                                                                                   
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={chartering} alt='servicio maritimo baja ferries' />
                </div>
            </div>
        </div>
    </section>


    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content'> 
            <div className='servicio_carga_box img'>
                    <img src={agentenaviero} alt='servicio terrestre baja ferries' />
                </div>               
                <div className='servicio_carga_box feedering  text'>
                    <h2>Agente Naviero </h2>
                    {/*<p>Un Agente Naviero, también conocido como Agente Marítimo o Agente de Buques, es una entidad que representa a los armadores o navieras en los puertos y actúa en su nombre para gestionar diversos aspectos operativos y administrativos relacionados con la llegada, estancia y salida de los buques. Estos agentes proporcionan una amplia gama de servicios, que incluyen coordinación de atracaderos, trámites aduaneros y portuarios, gestión de carga y descarga, asistencia en operaciones de carga y descarga, y resolución de problemas logísticos o administrativos que puedan surgir durante la estadía de un buque en puerto. Los agentes navieros son esenciales para facilitar las operaciones eficientes y sin problemas de los buques en los puertos, actuando como intermediarios entre las navieras y las autoridades portuarias, así como entre los clientes y los operadores de buques.</p>*/}
                    <p>Gestionamos de manera eficiente  todas las operaciones portuarias y administrativas relacionadas con tus embarcaciones. Nos encargamos de coordinar todas las actividades, desde la llegada hasta la salida de los buques en los puertos, con soluciones adaptadas a  tus necesidades.</p>
                    <div className='btn_servicio_transporte'>                        
                     
                    <a href="mailto:alexis.godoy@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>                                              
                    </div>
                </div>
                
            </div>
        </div>
    </section>

    <section className='servicio_carga'>
        <div className='container'>
            <div className='servicio_carga_content '>                
                <div className='servicio_carga_box proyecto text'>
                    <h2>Carga proyecto</h2>
                    {/*<p>Nuestra división de carga de proyectos se especializa en el transporte de cargas sobredimensionadas, pesadas y de alto valor. Con una amplia experiencia en el manejo de proyectos logísticos complejos, ofrecemos soluciones personalizadas para el transporte de equipos, maquinaria y componentes industriales. Nuestro equipo experto se encarga de cada etapa, desde la planificación hasta la ejecución, asegurando la entrega segura y oportuna de la carga a su destino final. </p>*/}
                    <p>Si necesitas transportar mercancía de grandes dimensiones y peso, trabajamos contigo para gestionar tu proyecto especial sin importar el tamaño, peso, complejidad o configuración, tenemos la embarcación para llevar tu carga a su destino final.</p>
                    <div className='btn_servicio_transporte'>                        
                        
                        <a href="mailto:cristobal.calderon@bajaferries.com.mx" rel="noopener noreferrer">Contactar</a>                                                           
                    </div>
                </div>
                <div className='servicio_carga_box img'>
                    <img src={carga_proyecto} alt='servicio maritimo baja ferries' />
                </div>
            </div>
        </div>
    </section>

    {/* 
    <section style={{background:'#eee', padding:'4rem 0rem'}}>
    <ZohoForm />
    </section>
   */}
    <section className="callToAction carga">
        <div className='container'>
           
            <div className='callToAction_row'>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar tu espacio?</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros; un miembro de nuestro equipo te ayudará a gestionar tu reserva en nuestros buques.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>    
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Soluciones multimodales</h4>
                            </div>
                            <div>
                                <p>Integramos transporte marítimo, autotransporte y ferroviario para garantizar flexibilidad y adaptabilidad en cada envío, todo bajo un mismo documento de embarque.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/multimodal">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container carga'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Contáctanos</h4>
                            </div>
                            <div>
                                <p>Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                        
                            <a href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a>                  
                        </div>
                    </div>
                </div>
            </div>

        </div>
    </section>

    <FooterCarga />
    <Outlet />
    
    </>
  )
}
