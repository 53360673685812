import React, {useState} from 'react'
import { Outlet, Link } from "react-router-dom";
import '../../css/faqs.css'

import Contactanos from '../contacto/Contactanos';
import Footer from '../Footer'
import AccordionMenu from '../Acordion';
import '../../css/accordion.css'
/**/
import bookingIcon from '../../assets/images/iconsPreguntas/calendario1.png'
import reservar  from "../../assets/images/iconsPreguntas/equipaje-de-viaje2.png";
import carga from '../../assets/images/iconsPreguntas/barco-de-carga.png'
import avisos from '../../assets/images//iconsPreguntas/megafono.png'
import pagos_facturas from '../../assets/images/iconsPreguntas/factura.png'
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
    
const questions = [
    {
        id: 1,
        text: '¿Cuáles son las identificaciones oficiales para viajar?',
        answer:( 
            <ul>
                <li>Identificación oficial vigente, expedida por el Instituto Nacional Electoral (INE)</li>
                <li>Pasaporte vigente</li>
                <li>Cédula profesional vigente con fotografía. </li>
                <li>En el caso de menores de edad: acta de nacimiento original, credencial emitida por Instituciones de Educación Pública o Privada con reconocimiento de validez oficial con fotografía y firma o pasaporte. </li>
                <li>Si eres extranjero: Pasaporte o documento migratorio vigente que corresponda, emitido por autoridad competente (en su caso, prórroga o refrendo migratorio)</li>               
            </ul>
        ),
    },
    {
        id: 2,
        text: 'No cuento con identificación oficial, ¿puedo viajar?',
        answer: 'Para utilizar cualquiera de nuestros servicios, es indispensable presentar identificación oficial.',
    },
    {
        id: 3,
        text: '¿Puede viajar un menor de edad sin acompañante?',
        answer: 'Es necesario que los menores de edad viajen en compañía de una persona mayor a 18 años',
    },
    {
        id: 4,
        text: '¿Puede viajar un menor sin sus padres o sin su tutor?',
        answer: 'Si es posible que un menor viaje sin sus padres o tutores legales, para esto, solicitamos que lleven una carta de autorización escrita y firmada por los padres especificando que autorizan que viaje con el adulto responsable de ellos, además deberán llevar una copia del INE de ambos padres. ',
    },
    {
        id: 5,
        text: '¿Qué documentación necesito para viajar con mi automóvil? ',
        answer: ( 
            <>
            <p>Para que un vehículo nacional viaje con nosotros solo es necesario contar con la información principal del auto al momento de realizar la compra del boleto:</p>
            <ul>
                <li>Número de placas y estado de procedencia</li>
            </ul>
            <p>Sin embargo, los puertos en los que operamos son recintos fiscalizados y el control de ingreso a las instalaciones se encuentra a cargo de API (Administración Portuaria Integral) o de ASIPONA (Administraciones del Sistema Portuario Nacional) </p>
            <p>Para ingresar un vehículo al puerto las autoridades le podrían solicitar el pedimento de importación para acreditar la nacionalidad del vehículo, por lo que te sugerimos llevar una copia de la factura del automóvil.</p>
            <p>Además fuera y dentro del puerto operan distintas autoridades quienes cuentan con controles de seguridad propios y podrían solicitarte documentación específica para corroborar el estatus legal del vehículo como:</p>
            <ul>
                <li>Identificación oficial</li>                                   
                <li>Tarjeta de circulación</li>
                <li>Copia de la factura o carta factura</li>
                <li>Póliza de seguro contra daños a terceros</li>
            </ul>
            <p>En caso de que tu vehículo sea fronterizo o tenga placas de otro país, necesitarás tramitar el permiso de “Importación Temporal”, el cual puedes obtener en las instalaciones de Banjercito. Puedes consultar las tarifas y documentos requeridos en su página web o al teléfono 6121234457</p>
            
            </>
            
        ),
    },
 

];

const Documentacion = ({ faqs, handleClick }) => {

   
  

  return (
    <> 
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                <h3>Preguntas frecuentes</h3>
                <p>Si tienes dudas o necesitas consultar algo, puedes llamar al 800 337 7437.</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='container'>
            <div className='box_titulo_faqs '>
                <div className='lista_preguntas'>
                    <h3>Documentación</h3>                           
                    <ul>
                        {questions.map(question => (
                        <li key={question.id}>
                            <Link to={`/preguntas-frecuentes-documentacion/${question.id}`}>{question.text}</Link>
                        </li>
                        ))}
                    </ul>           
                </div>
            <div className='lista_temas'>
                <h3>Otros temas frecuentes</h3>
                <div className='box_lista_temas'>
                    <ul>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={bookingIcon} alt='antesViajar' />
                                <Link to="/antes-de-viajar-faqs">Antes de viajar</Link>
                            </div>
                        </li>
                        <li> 
                            <div className='content_lista_temas'>
                                <img src={reservar} alt='antesViajar' />
                                <Link to="/reserva">Reservar</Link> 
                            </div>
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={pagos_facturas} alt='antesViajar' />
                                <Link to="/pagos-facturas">Pagos y facturas</Link> 
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={carga} alt='antesViajar' />
                                <Link to="/carga">Carga</Link>  
                            </div>  
                        </li>
                        <li>
                            <div className='content_lista_temas'>
                                <img src={avisos} alt='antesViajar' />
                                <Link to="/automovil">Automóvil</Link>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Consultar</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Dinos que opinas</h4>
                            </div>
                            <div>
                                <p>Tú opinión para nosotros es muy importante para continuar ofreciondote el mejor servicio. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>                            
                            <a href="https://bo-latam.opinat.com/index.php/survey/noapiqr?user=edorantes.mexico&pass=e3f391ec274cd03488365c20827762e3dc893500&nif=COLR039&centro=7888&campanya=6711&oleada=100982&idm=es   " target="_blank" rel="noopener noreferrer">Contactar</a>
                            
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                        
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
        </section>
    <Footer />
        
    </>
  )
}


export default Documentacion;