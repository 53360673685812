import React, { useState } from 'react';
import '../css/destinos_horizontal.css';
import { Outlet, Link } from "react-router-dom";
import { mazatlan, mochis } from '../assets/base64'
import pichilingue from '../assets/images/pichilingue.webp'
const Destinos_horizontal = () => {
  const items = [
    { id: 1, enlace: '/destino-la-paz', text: 'La Paz, Baja California Sur.',  imageUrl: pichilingue },
    { id: 2, enlace: '/destino-mazatlan', text: 'Mazatlán, Sinaloa.',  imageUrl: mazatlan },
    { id: 3, enlace: '/destino-los-mochis', text: 'Los Mochis, Sinaloa.', imageUrl: mochis },
    
  ];

  const [scrollPosition, setScrollPosition] = useState(0);
  const [showLeftButton, setShowLeftButton] = useState(true);
  const [showRightButton, setShowRightButton] = useState(false);

  const handleScrollRight = () => {
    setScrollPosition((prevPosition) => prevPosition + 130);
    setShowLeftButton(true);
    setShowRightButton(false);
  };

  const handleScrollLeft = () => {
    setScrollPosition((prevPosition) => prevPosition - 130);
    setShowLeftButton(false);
    setShowRightButton(true);
  };

  return (
    <>
        <div className='promociones_title_home_phone '>
            <div>
                <h2>Destinos</h2>
                <div className='text_btn_scroll'>
                    <p>Baja Ferries te conecta con increíbles destinos</p> 
                    <div className='btn_scroll'>
                      {/*
                        <button onClick={handleScrollLeft} style={{ display: showLeftButton ? 'inline-block' : 'none' }}>
                            <i className="fi fi-rr-angle-right"></i>
                        </button>
                        <button onClick={handleScrollRight} style={{ display: showRightButton ? 'inline-block' : 'none' }}>
                            <i className="fi fi-sr-angle-left"></i>
                        </button>
                        */}
                    </div>
                </div>                   
            </div>                                    
        </div>
        <div className="horizontal-scroll-container">
            <div className="scroll-content" style={{ transform: `translateX(${scrollPosition}px)` }}>
                {items.map((item) => (
                <div key={item.id} className="scroll-item-promociones">
                   <Link to={item.enlace}> 
                  <div className='box_promocion_phone'>
                    <img src={item.imageUrl} alt={`Imagen ${item.id}`} />                                                                    
                    <p style={{color:'#fff'}}>{item.text}</p>
                                    
                  </div>
                  </Link> 
                </div>
                ))}
            </div>     
        </div>
        <Outlet />
    </>
  );
};

export default Destinos_horizontal;

