    import React, { useState, useEffect  } from 'react';
    import axios from 'axios';
    // Componente para el Paso 1
    function Step1({ onNext }) {
    const [companyData, setCompanyData] = useState({
        companyName: '',
        contactName: '',
        contactPhone: '',
        contactEmail: ''  
    });

    const handleChange = (e) => {
        setCompanyData({
        ...companyData,
        [e.target.name]: e.target.value
        });
    };

    const handleNext = () => {
        onNext(companyData);
    };

    return (
        <div>
            
        <h2 style={{fontSize:'20px'}}>Datos de empresa y contacto</h2>
        <div className='box_formulario_carga'>
            <input type="text" name="companyName" value={companyData.companyName} onChange={handleChange} placeholder="Nombre de empresa" />
            <input type="text" name="contactName" value={companyData.contactName} onChange={handleChange} placeholder="Nombre de contacto" />
        </div>
        <div className='box_formulario_carga'>
            <input type="text" name="contactPhone" value={companyData.contactPhone} onChange={handleChange} placeholder="Teléfono de contacto" />
            <input type="email" name="contactEmail" value={companyData.contactEmail} onChange={handleChange} placeholder="Correo electrónico" />
        </div>
        <div className='box_formulario_carga'>
            <button onClick={handleNext}>Siguiente</button>
        </div>

        </div>
    );
    }

    // Componente para el Paso 2
    function Step2({ onNext }) {
    const [travelInfo, setTravelInfo] = useState({
        originPort: '',
        destinationPort: '',
        departureDate: '',
        returnDate: ''
    });

    const handleChange = (e) => {
        setTravelInfo({
        ...travelInfo,
        [e.target.name]: e.target.value
        });
    };

    const handleNext = () => {
        onNext(travelInfo);
    };

    return (
        <div>
        <h2 style={{fontSize:'20px'}}>Información de viaje</h2>
        <div className='box_formulario_carga'>
            <input type="text" name="originPort" value={travelInfo.originPort} onChange={handleChange} placeholder="Puerto de origen" />
            <input type="text" name="destinationPort" value={travelInfo.destinationPort} onChange={handleChange} placeholder="Puerto destino" />
        </div>
        <div className='box_formulario_carga'>
            <input type="date" name="departureDate" value={travelInfo.departureDate} onChange={handleChange} placeholder="Fecha de salida" />
            <input type="date" name="returnDate" value={travelInfo.returnDate} onChange={handleChange} placeholder="Fecha de retorno" />
        </div>
        <div className='box_formulario_carga'>
            <button onClick={handleNext}>Siguiente</button>
        </div>
        </div>
    );
    }

    // Componente para el Paso 3
    function Step3({ onDone }) {
    const [unitData, setUnitData] = useState({
        unitType: '',
        length: '',
        width: '',
        height: '',
        netWeight: '',
        cargoType: ''
    });

    const handleChange = (e) => {
        setUnitData({
        ...unitData,
        [e.target.name]: e.target.value
        });
    };

    const handleSubmit = () => {
        onDone(unitData);       
    };

    return (
        <div>
            { alert &&(
                    <div>
                    <p>
                        mensaje correo enviado
                    </p>
                </div>
            )}
            <h2 style={{fontSize:'20px'}}>Datos de unidad</h2>
            <div className='box_formulario_carga'>
                <input type="text" name="unitType" value={unitData.unitType} onChange={handleChange} placeholder="Tipo de unidad" />
                <input type="text" name="length" value={unitData.length} onChange={handleChange} placeholder="Medida (largo)" />
            </div>
            <div className='box_formulario_carga'>
                <input type="text" name="width" value={unitData.width} onChange={handleChange} placeholder="Medida (ancho)" />
                <input type="text" name="height" value={unitData.height} onChange={handleChange} placeholder="Medida (alto)" />
            </div>
            <div className='box_formulario_carga'>
                <input type="text" name="netWeight" value={unitData.netWeight} onChange={handleChange} placeholder="Peso neto" />
                <input type="text" name="cargoType" value={unitData.cargoType} onChange={handleChange} placeholder="Tipo de mercancía" />
            </div>
            <div className='box_formulario_carga'>
                <button onClick={handleSubmit}>Enviar</button>
            </div>
        </div>
    );
    }
    

    // Componente principal del formulario multi-paso
    function MultiStepForm() {
    const [step, setStep] = useState(1);
    const [formData, setFormData] = useState({});
    const [alert, setAlert] = useState('false');


    const handleNext = (data) => {
        setFormData({
        ...formData,
        ...data
        });
        setStep(step + 1);
    };

    const handleDone = (data) => {
        setFormData({
        ...formData,
        ...data,
        formulario: "cargaMaritima"
        });
        // Aquí puedes enviar los datos del formulario al servidor o realizar otra acción con ellos
        console.log('Datos para enviar:', formData);
        
    };

    useEffect(() => {
       // console.log('Datos del formulario1:', formData);
        if (Object.keys(formData).length > 10) {
            enviarCorreo(formData);
        }
    }, [formData]);

    // Función para limpiar los campos del formulario
    const limpiarFormulario = () => {
        setFormData({
            companyName: '',
            contactName: '',
            contactPhone: '',
            contactEmail: '',
            cargoType: ''
          });
    };
    const enviarCorreo = async (data) => {
        try {

            const response = await axios.post('https://g4dcvwgqon5wrdugzyyvchbuei0cpjbr.lambda-url.us-east-1.on.aws/enviar-correo', data, {

            }, {
                headers: {
                    'Content-Type': 'application/json',
                    // Agrega otros encabezados si son necesarios
                },
            });          

            console.log('Respuesta del servidor:', response.data); 

            //alert('Correo enviado correctamente ', response.data);
            setAlert(true);
            limpiarFormulario();
        } catch (error) {
            console.error('Error al enviar el correo:', error);
            alert('Error al enviar el correo');
        }
    };

    return (
        <div>
            {step === 1 && <Step1 onNext={handleNext} />}
            {step === 2 && <Step2 onNext={handleNext} />}
            {step === 3 && <Step3 onDone={handleDone} />}
        </div>
    );

    }

    

    export default MultiStepForm;
