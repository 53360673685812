import React, { useState, useEffect } from 'react'
import '../css/tarifario.css'
import { Col, Row,  } from 'antd';
import { Outlet, Link } from "react-router-dom";
import Booking from '../components/form/Booking';
import Footer from '../components/Footer';
import reservacion from '../assets/images/cta/reservacion.svg'
import promociones from '../assets/images/cta/promociones.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'


export default function Tarifas() {
    const [selectElegirRuta, setSelectElegirRuta] = useState(false);
    const [nameOption, setNameOption] = useState('La Paz - Topolobampo');

    const chooseRutasVisible = () => {
        setSelectElegirRuta(!selectElegirRuta);
    };
    const rutaSelected = (option) => {
        setNameOption(option);            
        setSelectElegirRuta(!selectElegirRuta);
    };


  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Tarifas</h3>
                    <p>Consulta nuestras tarifas y encuentra la mejor opción para tu viaje. </p>
                </div>                
            </div>
        </div>
    </section>
    
 
    <section className='tarifario'>
        
    <div>
    <Booking />
    </div>
                    
        <div className='select_tarifario'>
            <div className='container'>
            <div className="rutas_horario_tabs">
                    <div className='select_route_ruta_horario'>           
                        <label>Selecciona una ruta</label>
                        <div className='box_select_route' onClick={chooseRutasVisible} >                    
                            <div className='select_ruta__horario'>                               
                                    <label>{nameOption} </label>
                                    <i class="fi fi-br-angle-small-down"></i>
                                </div>                                                      
                            <div>
                                {selectElegirRuta && 
                                <div className='optionListRoutes'>
                                    <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                    <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Mazatlan')}>La Paz - Mazatlán</div>   
                                    <div className='optionRoutes' onClick={() => rutaSelected('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                    <div className='optionRoutes' onClick={() => rutaSelected('Mazatlan - La Paz')}>Mazatlán - La Paz</div>
                                </div>
                                }
                            </div>
                        </div>                              
                    </div>                                                    
                </div>

                {/*<div>           
                    <div className='box_select_ruta_horario' onClick={chooseRutasVisible} >
                        <div style={{width: 'fit-content'}}  >                        
                            <div className='select_ruta'>
                                <label>Selecciona una ruta</label>
                                <label>{nameOption} <i class="fi fi-br-angle-small-down"></i></label>
                            </div>
                        </div>    
                        <div>
                            {selectElegirRuta && 
                            <div className='optionListRoutes'>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Topolobampo')}>La Paz - Topolobampo</div>
                                <div className='optionRoutes' onClick={() => rutaSelected('La Paz - Mazatlan')}>La Paz - Mazatlan</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Topolobampo - La Paz')}>Topolobampo - La Paz</div>   
                                <div className='optionRoutes' onClick={() => rutaSelected('Mazatlan - La Paz')}>Mazatlan - La Paz</div>
                            </div>
                            }
                        </div>
                    </div>      
                </div>*/}
            </div>
        </div>
        <div className='container'>
        {nameOption === 'La Paz - Topolobampo' || nameOption === 'Topolobampo - La Paz' ? (
            <>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                        <tr>
                            <th>Pasajero</th>
                            <th>Tarifa</th>
                        </tr>
                        <tr>
                            <td>Adulto <br /> <p>Derecho a embarque</p></td>
                            <td>$1,950</td>
                        </tr>
                        <tr>
                            <td>Infante <br /> <p>De 0 a 2 años</p></td>
                            <td>Sin costo</td>
                        </tr>
                        <tr>
                            <td>Niños <br /> <p>De 3 a 11 años</p></td>
                            <td>$975</td>
                        </tr>
                        <tr>
                            <td>Adulto Mayor <br /> <p>A partir de 60 años</p></td>
                            <td>$975</td>
                        </tr>
                        </tbody>
                    </table>            
                </div>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Vehículo</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td>Motocicleta <br /> <p>Hasta 2 mts.</p></td>
                                <td>$3,125</td>
                            </tr>
                            <tr>
                                <td>Automóvil/Pickup <br /> <p>Hasta 6 mts.</p></td>
                                <td>$5,610</td>
                            </tr>
                            <tr>
                                <td>Auto con remolque <br /> <p>Hasta 11 mts.</p></td>
                                <td>$12,120</td>
                            </tr>
                            <tr>
                                <td>Motorhome <br /> <p>Hasta 11 mts.</p></td>
                                <td>$12,120</td>
                            </tr>
                            <tr>
                                <td>Autobús <br /> <p>Hasta 13 mts.</p></td>
                                <td>$16,040</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Cabinas</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td> <br /> <p>De 1 a 4 personas (2 literas)</p><p>TV Satelital, Baño completo.</p></td>
                                <td>$2,400</td>
                            </tr>
                                                                        
                        </tbody>
                    </table>           
                </div>
                <div className='tabla-tarifa'>
                    
                    <div className='tabla_grid img'>
                        <div className=''>
                            {/*<label>Explora nuestra sección de carga donde encontraras las tarifas y un formulario de contacto</label>*/}
                            <div className='tabla_grid_button'>
                                <Link to="/tarifas-carga">Ver tarifas de carga <i class="fi fi-rr-arrow-small-right"></i> </Link> 
                            </div>                                    
                        </div>                        
                    </div>                                    
                   
                </div>
            </div>
            </>
        ): (
            <>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                        <tr>
                            <th>Pasajero</th>
                            <th>Tarifa</th>
                        </tr>
                        <tr>
                            <td>Adulto <br /> <p>Derecho a embarque</p></td>
                            <td>$1,950</td>
                        </tr>
                        <tr>
                            <td>Infante <br /> <p>De 0 a 2 años</p></td>
                            <td>Sin costo</td>
                        </tr>
                        <tr>
                            <td>Niños <br /> <p>De 3 a 11 años</p></td>
                            <td>$975</td>
                        </tr>
                        <tr>
                            <td>Adulto Mayor <br /> <p>A partir de 60 años</p></td>
                            <td>$975</td>
                        </tr>
                        </tbody>
                    </table>            
                </div>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Vehículo</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td>Motocicleta <br /> <p>Hasta 2 mts.</p></td>
                                <td>$3,650</td>
                            </tr>
                            <tr>
                                <td>Automóvil/Pickup <br /> <p>Hasta 6 mts.</p></td>
                                <td>$6,365</td>
                            </tr>
                            <tr>
                                <td>Auto con remolque <br /> <p>Hasta 11 mts.</p></td>
                                <td>$16,980</td>
                            </tr>
                            <tr>
                                <td>Motorhome <br /> <p>Hasta 11 mts.</p></td>
                                <td>$16,980</td>
                            </tr>
                            <tr>
                                <td>Autobús <br /> <p>Hasta 13 mts.</p></td>
                                <td>$24,435</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className='content-tabla-tarifa'>
                <div className='tabla-tarifa'>
                    <table>
                        <tbody>
                            <tr>
                                <th>Cabinas</th>
                                <th>Tarifa</th>
                            </tr>
                            <tr>
                                <td> <br /> <p>2 camas individuales</p><p>TV, Baño completo</p></td>
                                <td>$2,400</td>
                            </tr>
                            <tr>
                                <td><br /> <p>2 literas</p><p>TV, Baño completo</p></td>
                                <td>$2,400</td>
                            </tr>                                               
                        </tbody>
                    </table>           
                </div>
                <div className='tabla-tarifa'>
                    
                    <div className='tabla_grid img'>
                        <div className=''>
                            {/*<label>Explora nuestra sección de carga donde encontraras las tarifas y un formulario de contacto</label>*/}
                            <div className='tabla_grid_button'>
                                <Link to="/tarifas-carga">Ver tarifas de carga <i class="fi fi-rr-arrow-small-right"></i> </Link> 
                            </div>                                    
                        </div>                        
                    </div>                                                       
                </div>
            </div>
            </>
        )}
        </div>
    </section>

    <section className="callToAction">
            <div className='container'>
                <div>
                    <h3> </h3>
                </div>
                <div className='callToAction_row'>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Promociones y Descuentos</h4>
                                </div>
                                <div>
                                    <p> Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/promociones">Ver más</Link>     
                            </div>
                        </div>
                    </div>
                    <div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Rutas y horarios</h4>
                                </div>
                                <div>
                                    <p>Explora nuestras rutas y encuentra el horario que mejor se ajuste a tus planes de viaje.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                                <Link to="/rutas_horario">Más información</Link>     
                            </div>
                        </div>
                    </div>
                    {/*<div className='callToAction_container'>
                        <div className='callToAction_icon'>
                            <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                        </div>
                        <div className='callToAction_content'>
                            <div className='callToAction_box'>
                                <div>
                                    <h4>Envianos un WhatsApp</h4>
                                </div>
                                <div>
                                    <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                                </div>
                            </div>
                            <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                            </div>
                        </div>
                    </div>*/}
                     <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
                </div>
            </div>
    </section>

    <Outlet />
    <Footer />
    </>
  )
}

