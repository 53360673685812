import React, {useState} from 'react'
import logoGif from '../assets/images/logo-bf.gif'
import {  Link } from "react-router-dom";
import '../css/kitComunicacion.css'
import Footer from '../components/Footer'
import logoBF from '../assets/images/logosKit/bf-logo-menu.png'
import imagotipo from '../assets/images/logosKit/BF_Imagotipo.png'
import logoBlack from '../assets/images/logosKit/media-kit-logo-bla.png'
import logoHorizontal from '../assets/images/logosKit/media-kit-logo-hor.png'
import isoBlack from '../assets/images/logosKit/bfisoweb.webp'
import bannerDownload from '../assets/images/banner-logo-download.webp'
import {
    DownloadOutlined, CloseCircleOutlined
  } from '@ant-design/icons';


export default function KitComunicacion() {
    const [activeButton, setActiveButton] = useState('BtnTodo'); // Inicializa el estado con el primer botón activo
    const [isModalVisible, setIsModalVisible] = useState(false); // Estado para manejar la visivilidad del modal
    const [isBtnEnable, setIsBtnEnable] = useState({
        nombre:'',
        correo: '',
        empresa:'',
        mensaje: '',
    })// Estado para habilitar o desabilitar el boton solicitar uso de imagen
    const handleChange = e =>{
        setIsBtnEnable({...isBtnEnable, [e.target.name]: e.target.value})
        //console.log(e.target.name, e.target.value);
    }

    const handleButtonClick = (buttonId) => {
        setActiveButton(buttonId); // Actualiza el estado cuando se hace clic en un botón
    };

    const handleBoxTextLogoClick = () => {
      setIsModalVisible(true); // Show the modal when the box_text_logo is clicked
    };
  
    const closeModal = () => {
      setIsModalVisible(false); // Hide the modal when needed
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log(task);
        
        console.log(isBtnEnable);
      
    }

  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Kit de comunicación</h3>
                    <p>En Baja Ferries compartimos el uso de nuestra marca y recursos de forma gratuita</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='container'>
        <div className='context_kit'>
            <div className='context_text_kit'>
                <div className='box_context_kit'>
                    <h3>Imagotipo</h3>
                    <p>La versión básica del logo, es el logotipo e isotipo con el que será reconocida la empresa. No deberá modificarse, ni utilizarse de forma que pueda condundirse a Baja Ferries con otra marca. Si tienes alguna duda, comunícate con nosotros.</p>
                    <div className='box_btn_kit'>
                        <Link to="/contacto">Contactar ahora </Link>
                    </div>
                </div>
            </div>
            <div className='context_gif_kit'>
                <img src={logoGif} alt='logo baja ferries gif' />
            </div>
        </div>
        <div className='banner-kit-descarga'>
            <div className='banner-descarga'>
                <img src={bannerDownload} alt='descargar logos de bajaferries' />
                <div className='box_msg_descarga'>
                    <h3>Logos</h3>
                    <p>Tu solicitud para descargar el logo se revisará y si es aprobada se enviara al correo que ingresaste.</p>
                </div>
                
            </div>
            
        </div>
    </section>
    <section  className='container'>
        <div className='optionsDownload'>
            <button onClick={() => handleButtonClick('BtnTodo')} className={activeButton === 'BtnTodo' ? 'active' : ''}>Todo</button>
            <button onClick={() => handleButtonClick('BtnWeb')} className={activeButton === 'BtnWeb' ? 'active' : ''}>Web</button>
            <button onClick={() => handleButtonClick('BtnImpresion')} className={activeButton === 'BtnImpresion' ? 'active' : ''}>Impresión</button>
            <div>
            {activeButton === 'BtnTodo' && (
                <div>
                    <div>
                        <h3>Web</h3>
                        <hr />
                    </div>
                    <div className='logosList'>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={logoBF} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={imagotipo} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={logoBlack} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={logoHorizontal} alt='Logo Baja Ferries' />
                                </div>
                                <div  className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>                
                    </div>
                    <div>
                        <h3>Impresión</h3>
                        <hr />
                    </div>
                    <div className='logosList'>                        
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={logoBF} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={imagotipo} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={logoBlack} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                        <div className='itemList_logo'>
                            <div className='box_content_logo'>
                                <div className='box_logo'>
                                    <img src={logoHorizontal} alt='Logo Baja Ferries' />
                                </div>
                                <div className='box_text_logo' onClick={handleBoxTextLogoClick}>
                                    <label>Baja Ferries rgb, png</label>
                                    <DownloadOutlined />
                                </div>
                            </div>
                        </div>
                
                    </div>
                </div>                                    
            )}
            {activeButton === 'BtnWeb' && (
            <div>
                <div>
                    <h3>Web</h3>
                    <hr />
                </div>
                <div className='logosList'>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={logoBF} alt='Logo Baja Ferries' />
                            </div>
                            <div  className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={imagotipo} alt='Logo Baja Ferries' />
                            </div>
                            <div  className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={logoBlack} alt='Logo Baja Ferries' />
                            </div>
                            <div className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={logoHorizontal} alt='Logo Baja Ferries' />
                            </div>
                            <div  className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
            )}
            {activeButton === 'BtnImpresion' && (
            <div>
                <div>
                    <h3>Impresión</h3>
                    <hr />
                </div>
                <div className='logosList'>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={logoBF} alt='Logo Baja Ferries' />
                            </div>
                            <div  className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={imagotipo} alt='Logo Baja Ferries' />
                            </div>
                            <div  className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={logoBlack} alt='Logo Baja Ferries' />
                            </div>
                            <div className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>
                    <div className='itemList_logo'>
                        <div className='box_content_logo'>
                            <div className='box_logo'>
                                <img src={logoHorizontal} alt='Logo Baja Ferries' />
                            </div>
                            <div  className='box_text_logo'>
                                <label>Baja Ferries rgb, png</label>
                                <DownloadOutlined />
                            </div>
                        </div>
                    </div>                
                </div>
            </div>
            )}
            </div>
        </div>
        
    </section>
    <section className='container'>
        <div className='legal_media_kit'>
            <div className='legal_logo_kit'>
                <img src={isoBlack} alt='isotipo baja ferries'/>
            </div>
            <div className='legal_text_kit'>
                <p>
                    Al utilizar la imagen de Baja Ferries, aceptas los términos y politicas     de uso. Baja Ferries
                    se reserva el derecho de cancelar, modificar o cambiar la autorizacion de directrices
                    señaladas en el manual de identidad en cualquier momento.
                </p>
            </div>

        </div>
    </section>


    {isModalVisible && (
        <div className="modal">
            <div className="modal-content">
                <div className='cerrarbtn_modal'>
                    <button onClick={closeModal}><CloseCircleOutlined /></button>
                </div>                
                <div className='modal_box'>
                    <div className='logoModal'>
                        <img src={logoHorizontal} alt='logo baja ferries horizontal'/>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className='contentbox_contactform'>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Nombre<i className='vineta'>*</i></label>
                                    <input type='text' name='nombre' className="custom-input" onChange={handleChange}/>
                                </div>                                                        
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Correo<i className='vineta'>*</i></label>
                                    <input type='text' name='correo' className="custom-input" onChange={handleChange}/>
                                </div>                                                        
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Empresa<i className='vineta'>*</i></label>
                                    <input type='text' name='empresa' className="custom-input" onChange={handleChange}/>
                                </div>                                                        
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <label>Motivo por el que seas solicitar el uso de imagen<i className='vineta'>*</i></label>
                                    <textarea rows={4} cols={40} name='mensaje' onChange={handleChange}>                                    
                                    </textarea>
                                </div>
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform_check'>                                    
                                    <input type='checkbox' className="custom-input" />
                                    <label>Al enviar mi información personal, entiendo y acepto los términos y políticas de uso de Baja Ferries.</label>
                                </div>
                            </div>
                            <div className='box_contactform'>
                                <div className='boxes_contactform'>
                                    <button disabled={!isBtnEnable.nombre || !isBtnEnable.correo || !isBtnEnable.empresa || !isBtnEnable.mensaje}>Solicitar uso de imagen</button>                                    
                                </div>                                                                
                            </div>                                
                        </div>                                                      
                    </form>                                         
                </div>
            </div>
        </div>
      )}





    <Footer />

    </>
  )
}
