

import React from 'react'

export const MapRoutes = () => {
  return (

    
    <iframe
        title="Rutas"
        src="https://www.google.com/maps/d/embed?mid=1c9sHmyh7BWThtjJLGDmzlblHSKIWhis&ehbc=2E312F"
        width="100%"
        height="100%"
        frameBorder="0"


    />
  )
}
export default MapRoutes;