import React, {useState, useEffect} from 'react'
import { useNavigate, useParams } from 'react-router-dom';
export default function TaskForm() {
    const [editing, setEditing] = useState (false);
    const [task, setTask] = useState({
        ruta:'',
        embarque: '',
        fecha_salida:'',
        hora_salida: '',
        fecha_llegada:'',
        hora_llegada: '',
        buque:'',
        estatus: '',
        dia:'',
        visible: '',
    })
    const navigate = useNavigate();
    const params = useParams();

    const handleChange = (e) =>
        setTask({...task, [e.target.name]: e.target.value});
        //console.log(e.target.name, e.target.value);
    
    const loadTask = async (id) => {
        const response = await fetch(`http://localhost:3004/tasks/${id}`);
        const data = await response.json();
        //console.log(data);
        setTask({ruta: data.ruta, embarque: data.embarque, fecha_salida: data.fecha_salida, hora_salida: data.hora_salida, buque: data.buque, estatus: data.estatus, dia: data.dia, visible: data.visible});
        setEditing(true);
    }

    useEffect(() =>{
        if(params.id){
            loadTask(params.id);
        }
        
    },[params.id]);
   
    
    const handleSubmit = async (e) => {
        e.preventDefault();
        //console.log("task: "+task); 
        if(editing){
            const response =await fetch(`http://localhost:3004/tasks/${params.id}`,{
                method:'PUT',                
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(task),
            });
            const data = await response.json();
            //console.log("Editando.." + data);
        }else{
            await fetch('http://localhost:3004/tasks',{
                method:'POST',
                body: JSON.stringify(task),
                headers: {'Content-Type': 'application/json'},
            });
        
           // const data = await res.json();
        }

        //console.log(data);
        navigate("/tasklist");

        //Redirigir al usuario a la página de inicio (por ejemplo, '/')
        //history.push('/');
        //history.push('/home.js');
        //window.location.href = '/';
    }
  return (
    <section className='container'>
        <form onSubmit={handleSubmit}>
            <div>
                <label>Ruta</label>
                <input type='text' name='ruta' value={task.ruta} onChange={handleChange}/>
            </div>
            <div>
                <label>Embarque</label>
                <input type='text' name='embarque' value={task.embarque} onChange={handleChange} />
            </div>
            <div>
                <label>Fecha_salida</label>
                <input type='text' name='fecha_salida' value={task.fecha_salida} onChange={handleChange}/>
            </div>
            <div>
                <label>Hora_salida</label>
                <input type='text' name='hora_salida' value={task.hora_salida} onChange={handleChange} />
            </div>
            <div>
                <label>Fecha_llegada</label>
                <input type='text' name='fecha_llegada' value={task.fecha_llegada} onChange={handleChange}/>
            </div>
            <div>
                <label>Hora_llegada</label>
                <input type='text' name='hora_llegada' value={task.hora_llegada} onChange={handleChange} />
            </div>
            <div>
                <label>Buque</label>
                <input type='text' name='buque' value={task.buque} onChange={handleChange}/>
            </div>
            <div>
                <label>Estatus</label>
                <input type='text' name='estatus' value={task.estatus} onChange={handleChange} />
            </div>
            <div>
                <label>Día</label>
                <input type='text' name='dia' value={task.dia} onChange={handleChange}/>
            </div>
            <div>
                <label>Visible</label>
                <input type='text' name='visible' value={task.visible} onChange={handleChange} />
            </div>
            <div>
            {/* 
            <button disabled={!task.title || !task.description} >
                Enviar
            </button>
            */}

            <button type='submit'>
                Enviar
            </button>
            
            </div>
        </form>

    </section>
   
  )
}
