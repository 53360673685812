import React, { useState } from 'react'
import logo from '../../assets/images/LOGO-BF-BLANCO.png'
import '../../css/menu_sostenibilidad.css'
import { Outlet, Link } from "react-router-dom";
const MenuSostenibilidad = ({ fullpageApi }) => {
    const [menuAbierto, setMenuAbierto] = useState(false);

    const toggleMenu = () => {
        setMenuAbierto(!menuAbierto);
    };

  return (
    <section className='menu_sostenibilidad'>
        <div className=''>
            <div className='menu_sostenibilidad_content'>
            <Link to="/">
                <div className='box_menu_sostenibilidad'>
                                     
                </div>
        </Link>
                <div className='box_menu_sostenibilidad'>
                    <div className='btn_menu_sostenibilidad' onClick={toggleMenu}>
                        <i class="fi fi-rr-menu-burger"></i>                        
                    </div>
                </div>
            </div>
        </div>
        {menuAbierto && 
        <div className='content_lateral_rse'>
            <div className='menu_lateral_rse'>
                <div className='box_menu_lateral_rse'>
                    <div className='btn_menu_lateral_rse'>
                        <div  onClick={toggleMenu}  >
                            <i class="fi fi-br-cross"></i>
                        </div>
                    </div>
                    <div className='list_menu_lateral_rse'>
                        <ul>
                            <li><Link to="/">Regresar</Link> </li>
                            <li onClick={() => fullpageApi.moveTo(1)}>Inicio</li>
                            <li onClick={() => fullpageApi.moveTo(2)}>Economía y Sociedad</li>
                            <li onClick={() => fullpageApi.moveTo(3)}>Bienestar Empresarial</li>
                            <li onClick={() => fullpageApi.moveTo(4)}>Ecosistemas Terrestres</li>
                            <li onClick={() => fullpageApi.moveTo(5)}>Ecosistemas Marinos</li>
                            <li onClick={() => fullpageApi.moveTo(6)}>Regístrate</li>
                        </ul>
                    </div>                
                </div>                
            </div>
        </div>
        
        }
    </section>
  )
}

export default MenuSostenibilidad;