import React from 'react'
import '../css/promo.css'
import { Outlet, Link } from "react-router-dom";
import whatsapp from '../assets/images/chat.svg'
import rutas from '../assets/images/cta/rutasyhorarios.svg'
import preguntas from '../assets/images/pregutas.png'
import Booking from '../components/form/Booking';
import Footer from '../components/Footer'

//Imagenes
import transportandoCarga from '../assets/images/promociones/transportamas.webp'
import puroBajaSur from '../assets/images/promociones/puroBajaSur.png'
import promoMascota from '../assets/images/promociones/mascota.webp'

import viajeEnGrupo from '../assets/images/viajeGrupo/grupoImg.webp'


export default function Promociones2() {
  return (
    <>
    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones</h3>
                    <p>Aprovecha nuestras ofertas y ¡planea tu próximo viaje hoy mismo!</p>
                </div>                
            </div>
        </div>
    </section>
    <section className='promociones_zone'>
        <Booking />
        <div className='container'>
            <div className='promociones_zone_container'>
                <div className='promociones_zone_box'>
                    <div>
                        <img src={transportandoCarga} alt='transporta carga con baja ferries' />
                    </div>
                    <div className='promociones_zone_content'>                                                     
                        <h1>15% de descuento transportando carga por primera vez</h1>
                        <p>Si es tu primera vez transportando carga por barco, obtén un 15% de descuento</p>                                                          
                        <Link to="/transportando-carga">Ver promoción</Link>                        
                    </div>
                </div>
                <div className='promociones_zone_box'>
                    <div>
                        <img src={puroBajaSur} alt='transporta carga con baja ferries' />
                    </div>
                    <div className='promociones_zone_content'>
                    
                        <h1>Viaja con tu auto y hasta 5 pasajeros</h1>
                        <p>Si es tu primera vez transportando carga por barco, obtén un 15% de descuento</p>                        
                        <Link to='/puro-baja-sur'>Ver promoción</Link>
                    </div>
                </div>
                <div className='promociones_zone_box'>
                    <div>
                        <img src={promoMascota} alt='transporta carga con baja ferries' />
                    </div>
                    <div className='promociones_zone_content'>
                        <h1>Celebra el día mundial del perro </h1>
                        <p>Llévalos de viaje con un increíble 50% de descuento en todas nuestras rutas.</p>
                        <Link to='/promo-mascota'>Ver promoción</Link>
                    </div>
                </div>
                
                
            </div>
        </div>        

    </section>

    <section className='viajeEnGrupo-section'>
        <div className='container'>
            <div className='viajaEnGrupo-cta'>
                <div className='viajaEnGrupo-cta-img'>
                    <img src={viajeEnGrupo} alt='Viaja en grupo'/>
                </div>
                <div className='viajaEnGrupo-cta-text'>
                    <h2>¿Estas organizando un viaje en grupo? </h2>
                    <p>Ofrecemos tarifas especiales y servicios personalizados para grupos de 20 personas o más. Comparte con nosotros la siguiente información, nos contactaremos contigo.</p>
                    <div><Link to="/viaje-en-grupo">Ver más</Link></div>
                </div>

            </div>

        </div>
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={preguntas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Más información</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={rutas} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Rutas y Horarios</h4>
                            </div>
                            <div>
                                <p>Explora nuestras rutas y encuentra el horario que mejor se ajuste a tus planes de viaje.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/rutas_horario">Ir ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
    <Footer />  
    
    </>
  )
}
