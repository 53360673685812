// QuestionDetails.js

import React from 'react';
import { useParams } from 'react-router-dom';

const questions = [
  { id: 1, text: '¿Cuál es la capital de Francia?', answer: 'París' },
  { id: 2, text: '¿Cuál es el océano más grande?', answer: 'Océano Pacífico' },
  { id: 3, text: '¿Quién escribió "Don Quijote de la Mancha"?', answer: 'Miguel de Cervantes' }
];

function FAQPage() {
  const { id } = useParams();
  const question = questions.find(q => q.id === parseInt(id));

  if (!question) {
    return <div>Pregunta no encontrada</div>;
  }

  return (
    <div>
      <h1>Pregunta: {question.text}</h1>
      <h2>Respuesta: {question.answer}</h2>
    </div>
  );
}



export default FAQPage;
