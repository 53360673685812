import React from 'react'
import '../../css/promocion.css'
import promo1 from '../../assets/images/promociones/promo-buen-fin.jpg'
import lateralPromo1 from '../../assets/images/promociones/2410-10-Promocion Operador final_482x310px.jpg'
import lateralPromo2 from '../../assets/images/promociones/inapam.webp'

import { Outlet, Link } from "react-router-dom";
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import Footer from '../../components/Footer'

export default function Promo9() {
//    const targetDate = new Date('2024-09-30T23:59:59').getTime();
  return (
   <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones para viajar</h3>
                    <p>¡El Buen Fin llegó a Baja Ferries con increíbles descuentos!
                    </p>
                </div>                
            </div>
        </div>
    </section> 
    
    <section className='container'>
        <div className='banner-header-promo'>
            <img src={promo1} alt='buen-fin-bajaferries' />
        </div>
    </section>
  
    <section className='sectionPromo_content'>
        <div className='container'>
            <div className='box_promo'>
                <div className='content_text_promo'>
                    <div className='content_promo'>
                        
                       <p>15% de descuento en viajes sencillos a cualquiera de nuestras rutas.  </p>
                       <p>Usa el código <strong>𝗕𝗨𝗘𝗡𝟭𝟱</strong> al reservar en nuestro sitio web, mencionándolo en el contact center o en puntos de venta. </p>
                       <p>¿Prefieres un viaje redondo? Obtén un 30% de descuento utilizando el código <strong>𝗕𝗨𝗘𝗡𝟯𝟬</strong> de la misma manera.</p>
                       <br />
                       <p>Aprovecha los descuentos y prepárate para viajar del 11 de noviembre 2024 al 31 de marzo de 2025.</p>                                                 

                        
                        <div className='content_promo_btn'>                                                
                            <a className='cta_promo' href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a> 
                            {/*<a target="_blank" className='cta_promo' rel='noopener noreferrer' href='https://wa.me/5218003377437?text=Transportando%20Carga'>Contactar por WhatsApp</a>*/}
                        </div>
                    </div>
                    <div className='content_promo condiciones-promo'>                                      
                        <h4>Términos y condiciones</h4>                    
                        <ul>                            
                            <li>• Aplican restricciones</li>
                            <li>• Válido en todas las reservas con auto y de 1 hasta 5 pasajeros.</li>                                                                                                                                                        
                        </ul>
                    </div>                                           
                   
                 
                </div>
                <div className='content_contador_promo'>

                    <div className='content_promo'>
                      
                        <h4>Otras promociones</h4>
                        <div className='lateral-promo'>
                            <Link to="/promo-ayudante-operador">
                                <img src={lateralPromo1} alt='promo-ninos-bajaferries' />
                            </Link> 
                        </div>
                        <div className='lateral-promo'>
                            <Link to="/promo-adulto-mayor">
                                <img src={lateralPromo2} alt='transportando-carga-bajaferries' />
                            </Link> 
                        </div>
                        
                        
                    </div>
                    
                </div>
            </div>
        </div>        
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Conoce nuestras soluciones logísiticas</h4>
                            </div>
                            <div>
                                <p>Descubre cómo nuestras distintas soluciones pueden optimizar tus operaciones. Junto a ti, llevamos tu logística al siguiente nivel.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/soluciones-logisticas">Ir ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas de carga</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y encuentra la mejor opción para tu viaje.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas-carga">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Contacta a nuestro equipo y asegura tu próximo viaje hoy mismo. Estamos aquí para ayudarte a planificar.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />

    <Footer />
   
   </>
  )
}
