import React, { useState, useEffect } from 'react'; 
import { DatePicker, Space, ConfigProvider } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import es_ES from 'antd/locale/es_ES';
import '../../css/reserva.css';
import '../../css/datepicker.css';

dayjs.extend(customParseFormat);
const { RangePicker } = DatePicker;

const Reservapruebas = () => {
  const [formData, setFormData] = useState({
    origen: '',
    destino: '',
    travelType: 'RETURN',
    fechaIda: '',
    fechaVuelta: '',
  });
  const [selectedTravelType, setSelectedTravelType] = useState('RETURN');
  const [mostrarCamposFecha, setMostrarCamposFecha] = useState(false);
  const [isFocused, setIsFocused] = useState(false);

  // Configuración de días permitidos por ruta
  const allowedDaysByRoute = {
    'P~PIC-P~TPB': [1, 2, 3, 4, 5, 6], // Lunes a Sábado
    'P~TPB-P~PIC': [0, 1, 2, 3, 4, 5], // Domingo a Viernes
    'P~PIC-P~MZT': [2, 4, 6], // Martes, Jueves y Sábado
    'P~MZT-P~PIC': [0, 3, 5], // Domingo, Miércoles y Viernes
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleChangeReservation = e => {    
    const name = e.target.name;
    const value = e.target.value;

    if (name === 'travelType') {
      setSelectedTravelType(value);
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleDateChange = (date, dateString, type) => {
    setFormData({
      ...formData,
      [type]: dateString,
    });
  };

  const handleIntercambiar = () => {    
    setFormData({
      ...formData,
      origen: formData.destino,
      destino: formData.origen,
    });        
  };

  useEffect(() => {
    if (formData.origen && formData.destino) {
      setMostrarCamposFecha(true);
    } else {
      setMostrarCamposFecha(false);
    }
  }, [formData.origen, formData.destino]);

  const opcionesRuta = [
    { value: '', label: '' },
    { value: 'P~PIC', label: 'La Paz' },
    { value: 'P~MZT', label: 'Mazatlán' },
    { value: 'P~TPB', label: 'Topolobampo' },
  ];

  const rutasNoPermitidas = {
    'P~MZT': ['P~TPB'],
    'P~TPB': ['P~MZT'],
  };

  const getOpcionesFiltradas = (opciones, seleccionado, noPermitidos) => {
    if (rutasNoPermitidas[seleccionado]) {
      return opciones.filter((opcion) => !noPermitidos.includes(opcion.value) && opcion.value !== seleccionado);
    }
    return opciones.filter((opcion) => opcion.value !== seleccionado);
  };

  const getAllowedDays = (origen, destino) => {
    const route = `${origen}-${destino}`;
    return allowedDaysByRoute[route] || [];
  };

  const disabledDate = (current, allowedDays) => {
    // Can not select days before today and today
    if (!current) return false;
    const day = current.day();
    const isBeforeToday = current && current < dayjs().startOf('day');
    const isAllowedDay = allowedDays.includes(day);
    return isBeforeToday || !isAllowedDay;
  };

  return (
    <form className='formBooking' onSubmit={(e) => e.preventDefault()}>
      <div className='formBooking_container'>
        {/* Radio buttons */}
        <div className='radioBooking'>
          <div className='box_radio_booking'>
            <div className='radioButons'>
              <input type="radio" name="travelType" value="RETURN" checked={selectedTravelType === 'RETURN'} onChange={handleChangeReservation} />
              <label>Ida y vuelta</label>
            </div>
            
            <div className='radioButons'>
              <input type="radio" name="travelType" value="ONEWAY" onChange={handleChangeReservation} />
              <label>Solo Ida</label>
            </div>
          </div>  
        </div>

        {/* Selects Origen */}
        <div className='contentBooking'>
          <div className={`box_formOrigen ${isFocused ? 'focus' : ''}`} onFocus={handleFocus}>
            <label>Origen:</label>
            <select name="origen" value={formData.origen} onChange={handleChangeReservation}>
              <option value=""></option>
              {getOpcionesFiltradas(opcionesRuta, formData.destino, rutasNoPermitidas[formData.destino] || []).map((opcion) => (
                <option key={opcion.value} value={opcion.value}>
                  {opcion.label}
                </option>
              ))}
            </select>        
          </div>

          {/* Botón para intercambiar valores entre Origen y Destino */}
          <div className='box_formButton'>
            <button type="button" onClick={handleIntercambiar}><i className="fi fi-rr-exchange-alt"></i></button>
          </div>

          {/* Selects Destino */}
          <div className={`box_formDestino ${isFocused ? 'focus' : ''}`} onFocus={handleFocus}>
            <label>Destino:</label>
            <select name="destino" value={formData.destino} onChange={handleChangeReservation}>
              <option value=""></option>
              {getOpcionesFiltradas(opcionesRuta, formData.origen, rutasNoPermitidas[formData.origen] || []).map((opcion) => (
                <option key={opcion.value} value={opcion.value}>
                  {opcion.label}
                </option>
              ))}
            </select>    
          </div>      
        </div>  

        {/* Inputs Fecha */}
        {mostrarCamposFecha && formData.travelType === 'RETURN' && (
          <div className='contentBooking'>
            <div className={`box_fechaSoloIda ${isFocused ? 'focus' : ''}`} onFocus={handleFocus}>
              <label>Fecha:</label>
              <div style={{display:'flex'}}>
                <ConfigProvider locale={es_ES}>
                  <Space direction="vertical" size={24} className="custom-datepicker">
                    <DatePicker
                      disabledDate={(current) => disabledDate(current, getAllowedDays(formData.origen, formData.destino))}
                      name="fechaIda"
                      onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')}
                    />
                  </Space>
                </ConfigProvider>
                <ConfigProvider locale={es_ES}>
                  <Space direction="vertical" size={24} className="custom-datepicker">
                    <DatePicker
                      disabledDate={(current) => disabledDate(current, getAllowedDays(formData.destino, formData.origen))}
                      name="fechaRegreso"
                      onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaRegreso')}
                    />
                  </Space>
                </ConfigProvider>
              </div>
            </div>
          </div>
        )}

        {mostrarCamposFecha && formData.travelType === 'ONEWAY' && (
          <div className='contentBooking'>
            <div className='box_fechaSoloIda'>
              <label>Salida</label>
              <ConfigProvider locale={es_ES}>  
                <Space direction="vertical" size={24} className="custom-datepicker">
                  <DatePicker
                    disabledDate={(current) => disabledDate(current, getAllowedDays(formData.origen, formData.destino))}
                    name="fechaIda"
                    onChange={(date, dateString) => handleDateChange(date, dateString, 'fechaIda')}
                  />            
                </Space>          
              </ConfigProvider>        
            </div>                             
          </div>
        )}

        <div className='contentBooking btnBuscar'>
          <button type='submit' disabled={!formData.origen && !formData.destino} className='btnBuscarViaje'>  
            <div className='textPlaceholder'>
              <span className="textBuscar">BUSCAR </span> 
              <span className="icon"><i className="fi fi-sr-angle-circle-right" style={{display:'flex', alignItems:'center', fontSize:'20px'}}></i></span> 
            </div>  
          </button>                                                                                                                  
        </div> 
      </div>
    </form>
  );
};

export default Reservapruebas;
