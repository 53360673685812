import React, { useState, useEffect } from 'react'
import Hero from '../components/Hero';
import BodyContent from './BodyContent';
import ValoracionForm from '../components/form/ValoracionForm';
import loader from '../assets/images/loader.gif'
import '../css/loader.css'
export default function Home() {


    const [loading, setLoading] = useState(true);
  
    useEffect(() => {
      // Simulando una operación que tome tiempo (puede ser una llamada a una API, etc.)
      setTimeout(() => {
        setLoading(false); // Cuando la operación haya terminado, cambiar el estado de carga para ocultar el loader
      }, 3000); // Simulando una carga de 3 segundos
    }, []);
  return (
    <>

    <div>
      {loading ? (
        <div className='loader_box'>
          <img src={loader} alt="Loader GIF" className='loader_gif' />
        </div>
        
      ) : (
        <div>
          <Hero />
          {/*<ValoracionForm />*/}
          <BodyContent />
        </div>
      )}
    </div>
        
    </>
  )
}
