import React from 'react'
import '../../css/promocion.css'
import promo1 from '../../assets/images/promociones/2410-10-Promocion Operador final_1920x381px.jpg'
import lateralPromo1 from '../../assets/images/promociones/promo-abordo.png'
import lateralPromo2 from '../../assets/images/promociones/inapam.webp'

import { Outlet, Link } from "react-router-dom";
import reservacion from '../../assets/images/cta/reservacion.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/mapa.svg'
import Footer from '../../components/Footer'

export default function Promo6() {
//    const targetDate = new Date('2024-09-30T23:59:59').getTime();
  return (
   <>

    <section>
        <div className='header_section'>
            <div className='container header_section_box'>
                <div className='header_section_title'>
                    <h3>Promociones para viajar</h3>
                    <p>¡Ayudantes de operador viajan con un 20% de descuento!</p>
                </div>                
            </div>
        </div>
    </section> 
    
    <section className='container'>
        <div className='banner-header-promo'>
            <img src={promo1} alt='buen-fin-bajaferries' style={{border: '1px solid #ddd'}}/>
        </div>
    </section>
  
    <section className='sectionPromo_content'>
        <div className='container'>
            <div className='box_promo'>
                <div className='content_text_promo'>
                    <div className='content_promo'>
                        
                        <p>Ahorra y aumenta la eficiencia, seguridad y productividad de tus operaciones. </p>
                        <p>Al elegir a Baja Ferries como parte de tu cadena logística obtienes:</p>
                        <ul>
                            <li>1. <strong>Entrega puntual de tu carga:</strong> Tus operaciones siguen siempre en movimiento.</li>
                            <li>2. <strong>Servicios a bordo:</strong> Los operadores y sus acompañantes de carga comercial pueden acceder a opciones de alimentos y áreas de descanso para un viaje cómodo.</li>
                            <li>3. <strong>Flexibilidad en el transporte:</strong> Transporta cargas de diversos tamaños, adaptándose a las necesidades específicas de tu empresa</li>
                        </ul>
                        
                        <p>¡Reserva aquí y obtén esta oferta!</p>
                        <div className='content_promo_btn'>                                                
                            <a className='cta_promo' href="tel:8003377437" rel="noopener noreferrer"><span >Llamar ahora</span></a> 
                            {/*<a target="_blank" className='cta_promo' rel='noopener noreferrer' href='https://wa.me/5218003377437?text=Transportando%20Carga'>Contactar por WhatsApp</a>*/}
                        </div>                     
                    </div>
                    <div className='content_promo condiciones-promo'>
                    <h4>Términos y condiciones</h4> 
                        {/*<p>El tiempo se acaba, ¡Lugares limitados hasta agotar existencias!.</p>*/}
                        <ul>
                            
                            <li>• Aplican restricciones</li>
                            <li>• Válido del 15 de octubre al 15 de diciembre 2024</li>
                            {/*<li>• No aplica para tarifas preferenciales (niños y 60 y más).</li>
                            <li>• No aplica en tarifas grupales.</li>   */}                         
                        </ul>
                    </div>
                    {/*<div className='content_promo btn-termino-condiciones'>                                                
                        <Link to="/condiciones-de-servicio">Consulta términos y condiciones</Link> 
                        
                    </div>*/}
                </div>
                <div className='content_contador_promo'>

                    <div className='content_promo'>
                      
                        <h4>Otras promociones</h4>
                        <div className='lateral-promo'>
                            <Link to="/promo-abordo">
                                <img src={lateralPromo1} alt='promo-ninos-bajaferries' />
                            </Link> 
                        </div>
                        <div className='lateral-promo'>
                            <Link to="/promo-adulto-mayor">
                                <img src={lateralPromo2} alt='transportando-carga-bajaferries' />
                            </Link> 
                        </div>
                                                
                    </div>
                    
                </div>
            </div>
        </div>        
    </section>
    
    <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Conoce nuestras soluciones logísiticas</h4>
                            </div>
                            <div>
                                <p>Descubre cómo nuestras distintas soluciones pueden optimizar tus operaciones. Junto a ti, llevamos tu logística al siguiente nivel.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/soluciones-logisticas">Ir ahora</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas de carga</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y encuentra la mejor opción para tu viaje.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas-carga">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Contacta a nuestro equipo y asegura tu próximo viaje hoy mismo. Estamos aquí para ayudarte a planificar.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={reservacion} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>¿Listo para reservar?</h4>
                            </div>
                            <div>
                                <p>Inicia tu aventura hoy. Compra tus boletos y emprende un inolvidable viaje por el Mar de Cortés. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="tel:8003377437" target="_blank" rel="noopener noreferrer">Llamar ahora </a>                        
                        </div>
                    </div>
                </div>
            </div>

        </div>
   </section>

        <Outlet />

    <Footer />
   
   </>
  )
}
