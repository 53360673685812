import React, { useState, useEffect } from 'react';

function Saludo() {
  const [saludo, setSaludo] = useState('');
  const [icono, setIcono] = useState('');

  useEffect(() => {
    const obtenerSaludo = () => {
      const horaActual = new Date().getHours();

      if (horaActual >= 6 && horaActual < 12) {
        setSaludo('Buenos días');
        setIcono('fi-rr-sun');
      } else if (horaActual >= 12 && horaActual < 20) {
        setSaludo('Buenas tardes');
        setIcono('fi-rr-sunset');
      } else {
        setSaludo('Buenas noches');
        setIcono('fi-rr-moon');
      }
    };

    obtenerSaludo();

    // Actualiza el saludo cada minuto
    const intervalo = setInterval(obtenerSaludo, 60000);

    return () => clearInterval(intervalo);
  }, []);

  return (
    <div className='box_saludo'>
      <i className={`fi ${icono}`} style={{ fontSize: '1.2rem', marginRight: '0.4rem' }}></i>
      <label>{saludo}</label>
    </div>
  );
}

export default Saludo;
/*
<p><i class="fi fi-tr-cloud-sun"></i> ¡Buen día!</p>
<p><i class="fi fi-tr-sunset"></i> ¡Buena tarde!</p>
<p><i class="fi fi-ts-moon-stars"></i> ¡Buen noche!</p>
*/