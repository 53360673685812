import React from 'react'

/*<iframe aria-label='Contacto' frameborder="0" style="height:500px;width:99%;border:none;" src='https://forms.zohopublic.com/bajaferries/form/Contacto1/formperma/zaX40gIuAAuAlBOWcoiTFfezxAT5fwfRfIJh4mAfUpc'></iframe>*/

export const ContactoZoho = () => {
  return (
    <iframe
        title="Contacto"
        //src="https://open.spotify.com/embed/playlist/0nLiWJB8D0wilN3dE0Z2OQ"
        //https://open.spotify.com/embed/playlist/2aPmLdkJwltd6hVJTPBbcc?utm_source=generator
        src='https://forms.zohopublic.com/bajaferries/form/Contacto1/formperma/zaX40gIuAAuAlBOWcoiTFfezxAT5fwfRfIJh4mAfUpc'
        width="100%"
        height="700px"
        frameBorder="0"        
        
    />
  )
}
export default ContactoZoho;
