import React, {useEffect, useState} from 'react'
import { useNavigate,  } from 'react-router-dom';

export default function TaskList() {
    const [tasks, setTasks] = useState([]);
    const navigate = useNavigate();
 


    const handleDelete = async (id) => {

    try {
        await fetch(`http://localhost:3004/tasks/${id}`,{
            method: 'DELETE',
        })

        setTasks(tasks.filter(tasks => tasks.id !== id));
        } catch (error) {
            console.log(error)
        }
                
    }

    
    

  const loadTasks = async () => {
    const response = await fetch('http://localhost:3004/tasks');
    const data = await response.json();
    console.log(data);
    setTasks(data);
  }

  useEffect(() =>{
    loadTasks();
  },[])

    const handleNew = () => {
        navigate("/tasks/new");

    };


  return (
    <>
    <div>
        <h2>TaskList</h2>
        <div>
            <button onClick={() => handleNew()}>Nueva tarea</button>
        </div>
    </div>
    
    {
      
        <table>
        <thead>
          <tr>
            <th>Ruta</th>
            <th>Embarque</th>
            <th>Fecha salida</th>
            <th>Hora salida</th>
            <th>Fecha llegada</th>
            <th>Hora llegada</th>
            <th>Buque</th>
            <th>Estatus</th>
            <th>Día</th>
            <th>Visible</th>
            <th>Opción editar</th>
            <th>Opción eliminar</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (

            <tr key={task.id}>
              <td>{task.ruta}</td>
              <td>{task.embarque}</td>
              <td>{task.fecha_salida}</td>
              <td>{task.hora_salida}</td>
              <td>{task.fecha_llegada}</td>
              <td>{task.hora_llegada}</td>
              <td>{task.buque}</td>
              <td>{task.estatus}</td>
              <td>{task.dia}</td>
              <td>{task.visible}</td>
              <td>
                <button onClick={() => navigate(`/tasks/${task.id}/edit`) }>Editar</button>
              </td>
              <td>
                <button onClick={() => handleDelete(task.id)}>Eliminar</button>
              </td>

            </tr>
          ))}
        </tbody>
      </table>
     }
     {
        <table>
        <thead>
          <tr>
            <th>Ruta</th>
            <th>Embarque</th>
            <th>Fecha salida</th>
            <th>Hora salida</th>
            <th>Fecha llegada</th>
            <th>Hora llegada</th>
            <th>Buque</th>
            <th>Estatus</th>
            <th>Día</th>
            <th>Visible</th>

          </tr>
        </thead>
        <tbody>
            {tasks.map((task) => {
            // Filtrar las rutas deseadas y verificar si task.visible es true
            if (
                (task.ruta === 'La Paz - Topolobampo' || task.ruta === 'Topolobampo - La Paz') && (task.dia === 'lunes') &&
                task.visible
            ) {
                return (
                <tr key={task.id}>
                    <td>{task.ruta}</td>
                    <td>{task.embarque}</td>
                    <td>{task.hora_salida}</td>
                    <td>{task.hora_llegada}</td>
                    <td>{task.buque}</td>
                    <td>{task.estatus}</td>         
                </tr>
                );
            } else {
                // Si no cumple con las condiciones, retornar null o un fragmento vacío
                return null;
            }
            })}

        </tbody>
      </table>
     }

    
    </>
    
  )
}
