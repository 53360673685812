import React from 'react'
import '../../css/destino.css'
import { Outlet, Link } from "react-router-dom";
import promociones from '../../assets/images/cta/promociones.svg'
import formulario from '../../assets/images/formulario-de-contacto.svg'
import mapa from '../../assets/images/pregutas.png'
import Footer from '../Footer'
import ImageSlider from '../sliders/SliderDestinos';
import {img1Maza, img2Maza, img3Maza, } from '../../assets/base64'
export default function Mazatlan() {
    const images = [
        {
            image: img1Maza,
            title: 'Acuario Mazatlán',
            text: 'Este recinto alberga más de 200 especies distintas siendo uno de los más grandes de Latinoamérica. Además, el acuario ofrece espectáculos impresionantes como el show de lobos marinos, de buceo, nado con tiburones y snorkel en el tanque de rayas.',
            subtext: 'Mazatlán, Sinaloa.',
        },
        {
            image: img2Maza,
            title: 'Las Labradas',
            text: 'Las Labradas es una zona arqueológica de grabados rupestres donde se pueden apreciar más de 700 figuras talladas en rocas volcánicas de al menos mil años de antigüedad. Estos misteriosos petroglifos situados junto al mar representan figuras humanas, animales, vegetales y también algunas abstractas; no hay dos figuras iguales en todo el lugar.',
            subtext: 'Mazatlán, Sinaloa.',
        },
        {
            image: img3Maza,
            title: 'El Faro',
            text: 'Ubicado en el Cerro del Crestón, es considerado como el faro natural más alto del mundo con un alcance luminoso de 48 millas náuticas. Es un recorrido de 30 minutos compuesto por 300 escalones.',
            subtext: 'Parque Natural Faro Mazatlán',
        },
        // Agrega más imágenes según sea necesario
      ];
  return (
    <>
        <section>
            <div className='header_section'>
                <div className='container header_section_box'>
                    <div className='header_section_title'>
                        <h3>Mazatlán, Sinaloa.</h3>
                        <p>Conoce Mazatlán como destino</p>
                    </div>                
                </div>
            </div>
        </section>
        <section>
            <ImageSlider images={images}/>
        </section>
        <section>            
            <div className='container'>
                <div className='destino_info_util'>
                    <div className='content_destinos_info'>
                        <div className='box_destinos_info'>
                            <h3>Geografía y Clima</h3>
                            <ul>
                                <li><strong>Ubicación: </strong>Mazatlán está situado en la costa del Pacífico de México, al sur de Sinaloa.</li>
                                <li><strong>Apodo: </strong> Conocida como "La Perla del Pacífico".</li>
                                <li><strong>Población: </strong>Aproximadamente 500,000 habitantes.</li>
                            </ul>
                        </div>
                        <div className='box_destinos_info'>
                            <h3>Clima</h3>
                            <ul>
                                <li><strong>Tipo de Clima: </strong>Tropical húmedo.</li>
                                <li><strong>Temperaturas: </strong> Varían entre 20°C en invierno y 32°C o más en verano.</li>
                                <li><strong>Temporada de Lluvias: </strong>De junio a octubre.</li>
                            </ul>
                        </div>
                    
                        <div className='box_destinos_info'>
                            <h3>Economía</h3>
                            <label>Sectores Principales:</label>
                            <ul>
                                <li><strong>Turismo: </strong>Principal motor económico, con una gran cantidad de hoteles, resorts, restaurantes y actividades turísticas.</li>
                                <li><strong>Pesca y Mariscos: </strong>Mazatlán es uno de los puertos pesqueros más importantes de México.</li>
                                <li><strong>Comercio y Servicios: </strong>Sectores dinámicos que apoyan tanto a la población local como a los turistas.</li>
                                
                            </ul>
                        </div>   
                        <div className='box_destinos_info'>
                            <h3>Transporte</h3>
                            <ul>
                                <li><strong>Aeropuerto Internacional General Rafael Buelna (MZT): </strong>Principal aeropuerto que conecta Mazatlán con otras ciudades de México y destinos internacionales.</li>
                                <li><strong>Transporte Urbano: </strong>Autobuses, taxis y pulmonías (vehículos abiertos típicos de Mazatlán) son comunes para moverse dentro de la ciudad.</li>
                                <li><strong>Puerto de Mazatlán: </strong> Importante puerto comercial y de cruceros.</li>                       
                            </ul>
                        </div>                 
                    </div>
                    <div className='content_destinos_info'>                    
                        <div className='box_destinos_info'>
                            <h3>Turismo</h3>
                            <label>Principales Atractivos:</label>
                            <ul>
                                <li><strong>Malecón de Mazatlán: </strong>Uno de los malecones más largos del mundo, ideal para caminar, andar en bicicleta y disfrutar de vistas al mar.</li>
                                <li><strong>Centro Histórico: </strong>Con arquitectura colonial, plazas, y la Plazuela Machado, rodeada de restaurantes y bares.</li>
                                <li><strong>Catedral Basílica de la Inmaculada Concepción: </strong>Hermosa catedral del siglo XIX.</li>
                                <li><strong>El Faro de Mazatlán: </strong>Uno de los faros naturales más altos del mundo, ofrece impresionantes vistas panorámicas.</li>
                                <li><strong>Isla de la Piedra: </strong>Playa tranquila accesible en lancha, famosa por sus mariscos frescos.</li>
                                <li><strong>Acuario Mazatlán: </strong>Uno de los acuarios más grandes de América Latina, con una gran variedad de especies marinas.</li>
                            </ul>
                        </div>
                        
                        <div className='box_destinos_info'>
                            <h3>Naturaleza y Actividades al Aire Libre</h3>
                            <label>Parques Nacionales y Reservas:</label>
                            <ul>
                                <li><strong>Playa Olas Altas: </strong>Popular entre surfistas.</li>
                                <li><strong>Playa Norte: </strong>Amplia y concurrida, ideal para nadar y tomar el sol.</li>
                                <li><strong>Playa Cerritos: </strong>Más tranquila y adecuada para familias.</li>                       
                            </ul>
                            <h3>Actividades:</h3>
                            <ul>
                                <li>Pesca deportiva, avistamiento de ballenas, surf, kayak, y golf en campos de categoría mundial.</li>                   
                            </ul>
                        </div>

                    </div>
                </div>
                
            </div>
        </section>
        <section className="callToAction">
        <div className='container'>
            <div>
                <h3> </h3>
            </div>
            <div className='callToAction_row'>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={formulario} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Consulta nuestras tarifas</h4>
                            </div>
                            <div>
                                <p>Consulta nuestras tarifas y reserva con anticipación para obtener el mejor precio para viajar.  </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/tarifas">Ver tarifas</Link>     
                        </div>
                    </div>
                </div>
                <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={mapa} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Preguntas frecuentes</h4>
                            </div>
                            <div>
                                <p>Aquí encontrarás respuestas sobre nuestros servicios, políticas y más información para un viaje sin contratiempos. </p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                            <Link to="/preguntas-frecuentes">Más información</Link>     
                        </div>
                    </div>
                </div>
                {/*<div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={whatsapp} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Envianos un WhatsApp</h4>
                            </div>
                            <div>
                                <p>Comunícate con nosotros al instante. Ya sea que tengas preguntas sobre nuestro servicio, o sobre tu reservación nuestro equipo está listo para ayudarte.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <a href="https://wa.me/5218003377437?text=Me%20interesa%20una%20cotizaci%C3%B3n" target="_blank" rel="noopener noreferrer">Enviar WhatsApp </a>                    
                        </div>
                    </div>
                </div>*/}
                 <div className='callToAction_container'>
                    <div className='callToAction_icon'>
                        <img src={promociones} alt="bookingIcon" className='bookingIcon' />
                    </div>
                    <div className='callToAction_content'>
                        <div className='callToAction_box'>
                            <div>
                                <h4>Promociones y Descuentos</h4>
                            </div>
                            <div>
                                <p>Descubre las mejores promociones y tarifas especiales para planificar tu viaje al mejor precio.</p>
                            </div>
                        </div>
                        <div className='callToAction_box'>
                        <Link to="/promociones">Ir ahora</Link>                      
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <Outlet />
        <Footer />
        
    </>
  )
}
